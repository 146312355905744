<template>
    <ImprovementView
        :push-messages="pushMessages"
        :push-action-text="pushActionText"
        :is-adjustable="true"
        :on-push="onPush"
    >
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <div v-if="aboveMobile">
                    <EntityPill type="campaign-group" :content="campaignGroupName" />
                    <Spacer height="2rem" />
                </div>

                <Text as="p">
                    In the campaign group listed above, your <b>product ads</b> are being triggered
                    by search terms containing the word listed below. This word has spent a
                    significant amount, but converted few searchers by comparison. Consider adding
                    it as a negative.
                </Text>
                <Spacer height="2rem" />
                <EntityTransform
                    :entityBefore="keyword"
                    entityBeforeType="word"
                    :entityAfter="keyword"
                    entityAfterType="negative-keyword"
                />
                <Spacer height="2rem" />
                <Text as="p">
                    Click <Text as="span" weight="600" color="green">{{ pushActionText }}</Text> to
                    add this word to a shared negative list that covers all of your shopping
                    campaigns. Opteo will add the negative to <b>{{ defaultSharedSet }}</b
                    >. Click <b>Adjust</b> to choose another list.
                </Text>
            </ImprovementHeader>
            <ImprovementHelpLink path="/en/articles/900534-add-negative-keyword">
                Click here to learn more about adding negative keywords in Opteo.
            </ImprovementHelpLink>
        </ImprovementContent>

        <ImprovementContent>
            <ScatterPointChart
                :items="[{ x: conversions, y: cost, label: keyword, highlighted: true }]"
                :graphKeys="['Needs Attention', 'Above Average CPA', 'Below Average CPA']"
                :cutOff="targetedCpa"
                :axis="{
                    x: { label: 'Conversions' },
                    y: { label: 'Cost', dataType: 'money', currency },
                }"
                :metric="{ label: 'CPA', dataType: 'money', currency, inverted: true }"
            />
            <Spacer height="2rem" />
            <ImprovementNote>
                <b>Note:</b> If you are actively targeting products related to this word, and
                <b><Money :value="cpa" /></b> is an acceptable CPA for this account, feel free to
                dismiss this improvement.
            </ImprovementNote>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementStatistics :items="improvementStatistics">
                <template v-slot:cost>
                    <b><Money :value="cost" /></b> was spent on this word over the last
                    <b>90 days</b>.
                </template>
                <template v-slot:conversions>
                    Queries containing this word received
                    <b><Number :value="conversions" /></b> {{ copy.conversions }} over the last
                    <b>90 days</b>.
                </template>
                <template v-slot:wordCPA>
                    Over the last <b>90 days</b>, this word has an average CPA of
                    <b><Money :value="cpa" /></b>.
                </template>
                <template v-slot:targetCPA>
                    The current {{ copy.targetOrAverage }} CPA for this campaign group is
                    <b><Money :value="targetedCpa" /></b>.
                </template>
            </ImprovementStatistics>
        </ImprovementContent>

        <ImprovementContent>
            <RelatedSearchTerms :items="searchTerms" :keyword="keyword"> </RelatedSearchTerms>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="90" />
        </ImprovementContent>

        <template v-slot:adjust-step-1>
            <ImprovementContent>
                <ImprovementHeader title="Select Negative List">
                    <Text as="p">
                        By default, Opteo will add the negative keyword to a list called
                        <b>{{ defaultSharedSet }}</b
                        >. If you need to assign this keyword to another negative list, select it
                        from the dropdown menu below.
                    </Text>
                </ImprovementHeader>
                <div class="shared-negative-list-container">
                    <oInput
                        v-if="!sharedNegativeListsLoading && sharedSets.length"
                        v-model="selectedSharedSet"
                        :items="sharedSets"
                        type="select"
                        label="Shared Negative List"
                    />
                </div>
            </ImprovementContent>
        </template>
    </ImprovementView>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

import {
    ImprovementHeader,
    ImprovementContent,
    ImprovementNote,
    ScatterPointChart,
    ImprovementStatistics,
    ImprovementCreatedDate,
    EntityPill,
    EntityTransform,
    oInput,
    Number,
    Spacer,
    Text,
    RelatedSearchTerms,
} from '@opteo/components-next'
import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import Money from '@/components/global/oMoney.vue'
import Ent from '@/components/util/Ent.vue'
import useMediaQuery from '@/composition/global/useMediaQuery'
import { useAddNegativeListShopping } from '@/composition/improvement/types/useAddNegativeListShopping'

export default defineComponent({
    name: 'AddNegativeListShopping',
    components: {
        ImprovementHeader,
        ImprovementContent,
        ImprovementNote,
        ScatterPointChart,
        ImprovementStatistics,
        ImprovementCreatedDate,
        ImprovementView,
        ImprovementHelpLink,
        EntityPill,
        EntityTransform,
        oInput,
        Ent,
        Money,
        Number,
        Spacer,
        Text,
        RelatedSearchTerms,
    },
    setup() {
        return { ...useAddNegativeListShopping(), ...useMediaQuery() }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';
.shared-negative-list-container {
    @include container;
    @include pa-32;
}
</style>
