<template>
    <PageContent>
        <div class="changelog-wrapper">
            <div class="changelog-post">
                <div class="changelog-text">
                    <Text size="f-2" weight="600" as="h2">Adjust Ad Group Targets</Text>
                    <Spacer height="0.75rem" />
                    <Text size="f-8" color="gray">Published 6th July 2023 at 17:00pm</Text>
                    <Spacer height="2.25rem" />
                    <Text>
                        Today we're excited to introduce <b>Adjust Ad Group Targets</b>, our new
                        improvement that recommends target adjustments for individual ad groups in
                        campaigns with a Target ROAS or Target CPA.
                        <Spacer height="1.25rem" />
                        When setting a campaign-level target, Google optimises towards this target
                        regardless of the performance of individual ad groups. In reality, ad groups
                        can produce drastically different results, with CPA/ROAS values much higher
                        or lower than the campaign average.
                    </Text>
                </div>
                <Spacer height="3.5rem" />
                <div class="carousel-container">
                    <Carousel
                        v-if="true"
                        :arrows="true"
                        :options="{ align: 'center', speed: 7 }"
                        padding="4.75rem 0rem 4.75rem 0rem"
                        arrow-horizontal-padding="2rem"
                    >
                        <template #slides>
                            <div
                                class="slide"
                                v-for="image in adGroupTargetsImages"
                                :key="image.image"
                            >
                                <div
                                    :style="[
                                        { paddingTop: (472 / 756) * 100 + '%' },
                                        { height: '0px' },
                                    ]"
                                    :class="[image.noShadow ? 'no-shadow' : '', 'slide-wrapper']"
                                >
                                    <oImage :src="image.image" />
                                </div>
                            </div>
                        </template>
                    </Carousel>
                </div>
                <Spacer height="3.5rem" />
                <div class="changelog-text">
                    <Text>
                        <b>Adjust Ad Group Targets</b> works by adjusting the targets of individual
                        ad groups based on historical performance data, working to bring CPA/ROAS
                        values closer in line with the campaign average.
                    </Text>
                    <Spacer height="1.25rem" />
                    <Text>
                        This protects against less-than-optimal budget allocation, preventing Google
                        from bidding on queries that lead to expensive conversions, or queries that
                        produce a low ROAS. For more detailed information and technical notes,
                        please head over to our
                        <a
                            href="https://opteo.com/docs/en/articles/8102723-adjust-ad-group-targets"
                            target="_blank"
                            >help docs</a
                        >.
                    </Text>
                    <Spacer height="1.25rem" />
                    <Text>
                        As always, we'll be working hard to fix any bugs that come up. If you notice
                        anything that looks like a bug, or if you have an idea that could help
                        improve your Adjust Ad Group Targets experience, please make sure to send
                        our support team a
                        <Text :clickable="true" @click="startNewMessage">message</Text>.
                    </Text>
                </div>
            </div>
            <div class="changelog-post">
                <div class="changelog-text">
                    <Text size="f-2" weight="600" as="h2">Opteo + ChatGPT</Text>
                    <Spacer height="0.75rem" />
                    <Text size="f-8" color="gray">Published 2nd June 2023 at 11:00am</Text>
                    <Spacer height="2.25rem" />
                    <Text>
                        We're excited to announce the release of our ChatGPT integration —
                        <b>Chat</b>, the missing link between your Google Ads data and ChatGPT.
                        <Spacer height="1.25rem" />
                        Send snippets of performance data to ChatGPT in a few keystrokes, start
                        leveraging the power of AI to analyse and interpret your data. Get best
                        practice advice, generate new keyword ideas, write high quality RSA
                        headlines, build target audiences, and so much more.
                    </Text>
                </div>
                <Spacer height="3.5rem" />
                <div class="carousel-container">
                    <Carousel
                        v-if="true"
                        :arrows="true"
                        :options="{ align: 'center', speed: 7 }"
                        padding="4.75rem 0rem 4.75rem 0rem"
                        arrow-horizontal-padding="2rem"
                    >
                        <template #slides>
                            <div class="slide" v-for="image in chatImages" :key="image.image">
                                <div
                                    :style="[
                                        { paddingTop: (472 / 756) * 100 + '%' },
                                        { height: '0px' },
                                    ]"
                                    :class="[image.noShadow ? 'no-shadow' : '', 'slide-wrapper']"
                                >
                                    <oImage :src="image.image" />
                                </div>
                            </div>
                        </template>
                    </Carousel>
                </div>
                <Spacer height="3.5rem" />
                <div class="changelog-text">
                    <Text>
                        To get started, click the blue icon at the top left of the screen. You can
                        reference campaigns, ad groups, and keywords from your Google Ads account by
                        typing the <span class="entity">@</span> symbol — when referencing an
                        entity, Opteo automatically attaches the relevant performance data to your
                        query, giving Chat everything it needs to respond. For more on entities and
                        attached data, click <b>Entities & Attached Data</b> in the left sidebar.
                    </Text>
                    <Spacer height="1.25rem" />
                    <Text>
                        We also have a <b>Cheatsheet</b> filled with example queries and starting
                        points for useful conversations. Whether you're generating keywords or RSA
                        creative, there's something for everyone. If you have any ideas for
                        additions to the cheatsheet, please don't hesitate to let us know!
                    </Text>
                    <Spacer height="1.25rem" />
                    <Text>
                        We consider this release to be an early version of Chat, we have plans to
                        integrate and develop new features as they become available. We are
                        currently on the waitlist for GPT-4 developer access — as soon as we get
                        access, we'll make the model available for everyone. This also means
                        exciting possibilities like Opteo-designed plugins and even a toolkit for
                        creating display and video creative.
                    </Text>
                    <Spacer height="1.25rem" />
                    <Text>
                        We're making a bet on AI and the potential it has to revolutionise our day
                        to day work. While not perfect at this point in time, we believe in staying
                        ahead of the curve and being positioned to take advantage of any upcoming
                        developments in the space. With this being said, if you have any ideas for
                        new integrations, or improvements we could make to the feature, please
                        submit a
                        <a
                            href="https://opteo.canny.io/feature-requests"
                            rel="noopener"
                            target="_blank"
                        >
                            feature request
                        </a>
                        or contact support.
                    </Text>
                    <Spacer height="1.25rem" />
                    <Text>
                        As always, we'll be working hard to fix any bugs that might come up. If you
                        notice anything that looks like a bug, or if you have an idea that could
                        help improve your Chat experience, please make sure to send the customer
                        support team a
                        <Text :clickable="true" @click="startNewMessage">message</Text>.
                    </Text>
                </div>
            </div>
            <div class="changelog-post">
                <div class="changelog-text">
                    <Text size="f-2" weight="600" as="h2">Bidding Experiments</Text>
                    <Spacer height="0.75rem" />
                    <Text size="f-8" color="gray">Updated 24th April 2023 at 12:00pm</Text>
                    <Spacer height="2.25rem" />
                    <div class="changelog-note">
                        <Text style="color: inherit">
                            This release introduces the following updates:
                            <ul>
                                <li>An improved recommendation algorithm</li>
                                <li>Clearer, more useful error messaging</li>
                                <li>The option to cancel ongoing experiments</li>
                                <li>A host of interface updates</li>
                                <li>Improved confidence calculations</li>
                            </ul>
                            These updates should mean higher quality recommendations and a more
                            effective, reliable, and easy to use tool. For more information, you can
                            read the original changelog post below, otherwise, head over to the
                            <b>Toolkit</b> page to get started.
                        </Text>
                    </div>
                    <Spacer height="2.25rem" />
                    <Text>
                        Today we're excited to release <b>Bidding Experiments</b>, our new tool for
                        creating bidding strategy experiments in Google Ads. This tool makes it easy
                        to find the top performing bidding strategies across your account, with
                        recommended experiments that can be set up in just a few clicks. You can
                        also create multi-campaign experiments, see all of your past experiments,
                        and get alerts whenever an experiment ends.
                    </Text>
                </div>
                <Spacer height="3.5rem" />
                <div class="carousel-container">
                    <Carousel
                        v-if="true"
                        :arrows="true"
                        :options="{ align: 'center', speed: 7 }"
                        padding="4.75rem 0rem 4.75rem 0rem"
                        arrow-horizontal-padding="2rem"
                    >
                        <template #slides>
                            <div
                                class="slide"
                                v-for="image in biddingExperimentImages"
                                :key="image"
                            >
                                <div
                                    :style="[
                                        { paddingTop: (472 / 756) * 100 + '%' },
                                        { height: '0px' },
                                    ]"
                                    class="slide-wrapper"
                                >
                                    <oImage :src="image" />
                                </div>
                            </div>
                        </template>
                    </Carousel>
                </div>
                <Spacer height="3.5rem" />
                <div class="changelog-text">
                    <Text>
                        To get started, head on over to <b>Toolkit</b> and open
                        <b>Bidding Experiments</b>. Otherwise, keep reading for a brief summary of
                        the incoming features.
                    </Text>
                    <Spacer height="1.25rem" />
                    <Text>
                        <b>Bidding Experiments</b> — Take a campaign (or group of campaigns) and see
                        if they perform better using a different bidding strategy. Set some basic
                        parameters for your experiment, click <b>Create Experiment</b> and wait for
                        the results to start filtering in. Opteo automatically splits your chosen
                        campaigns into base and experiment versions, comparing their performance and
                        recommending to either promote or remove the experiment once an appropriate
                        confidence score is reached.
                    </Text>
                    <Spacer height="1.25rem" />
                    <Text>
                        <b>Active Experiments</b> — Monitor the progress of your experiments in real
                        time, see per-campaign breakdowns, overall experiment group statistics,
                        performance graphs, experiment parameters, and more.
                    </Text>
                    <Spacer height="1.25rem" />
                    <Text>
                        <b>Multi-Campaign Experiments</b> — Add multiple campaigns to an experiment,
                        get the benefits of more experiment data, and save time by running one
                        shorter experiment rather than several longer ones.
                    </Text>
                    <Spacer height="1.25rem" />
                    <Text>
                        <b>Recommended Experiments</b> — Opteo makes recommendations for bidding
                        experiments based on a variety of campaign attributes. Your campaign data is
                        used to generate sensible experiment parameters, including targets, budget
                        allocation, experiment duration, and more.
                    </Text>
                    <Spacer height="1.25rem" />
                    <Text>
                        <b>Confidence Score</b> — A percentage score that calculates whether you
                        have enough data to make a statistically confident decision, taking into
                        account conversion minimums, impression data, and more.
                    </Text>
                    <Spacer height="1.25rem" />
                    <Text>
                        We hope you find Bidding Experiments useful. If you have a question, please
                        don't hesitate to
                        <Text :clickable="true" @click="startNewMessage">
                            send our customer support team a message</Text
                        >.
                    </Text>
                    <Spacer height="1.25rem" />
                    <Text>
                        As always, we'll be monitoring for any bugs that might come up over the next
                        few days. If you notice anything that looks like a bug, or if you have an
                        idea that could help improve the feature, please let us know.
                    </Text>
                </div>
            </div>
            <div class="changelog-post">
                <div class="changelog-text">
                    <Text size="f-2" weight="600" as="h2">ROAS Performance Mode</Text>
                    <Spacer height="0.75rem" />
                    <Text size="f-8" color="gray">Published 21st March 2023 at 12:00pm</Text>
                    <Spacer height="2.25rem" />
                    <Text>
                        Today we're excited to release <b>ROAS Performance Mode</b>. This update
                        introduces full ROAS compatibility across Opteo, powering everything from
                        improvements to performance graphs, reports, and more.
                        <Spacer height="1.25rem" />
                        You can enable ROAS Performance Mode by heading to the <b>Settings</b> page,
                        scrolling to the Performance Mode section, and selecting ROAS (Return On Ad
                        Spend) from the dropdown menu provided.
                    </Text>
                </div>
                <Spacer height="3.5rem" />
                <div class="carousel-container">
                    <Carousel
                        v-if="true"
                        :arrows="true"
                        :options="{ align: 'center', speed: 7 }"
                        padding="4.75rem 0rem 4.75rem 0rem"
                        arrow-horizontal-padding="2rem"
                    >
                        <template #slides>
                            <div class="slide" v-for="image in roasImages" :key="image.image">
                                <div
                                    :style="[
                                        { paddingTop: (433 / 756) * 100 + '%' },
                                        { height: '0px' },
                                    ]"
                                    :class="[image.noShadow ? 'no-shadow' : '', 'slide-wrapper']"
                                >
                                    <oImage :src="image.image" />
                                </div>
                            </div>
                        </template>
                    </Carousel>
                </div>
                <Spacer height="3.5rem" />
                <div class="changelog-text">
                    <Text>
                        CPA has long been a foundational performance metric, however we understand
                        that some advertisers prioritise ROAS because it allows them to understand
                        the relationship between revenue and ad spend in more detail. With this
                        update, you have access to both the ROAS and Conversion Value data needed to
                        make profit-focused decisions.
                    </Text>
                    <Spacer height="1.25rem" />
                    <Text> The following features have been updated to work with ROAS: </Text>
                    <Spacer height="1.25rem" />
                    <Text>
                        <b>Improvements</b> — 17 improvement types benefit from ROAS support,
                        including keyword management and bid optimisation improvements like Add
                        Negative N-Gram, Fix Below First Page Bid, Pause Keyword, and Adjust
                        Demographic Bids. In addition, traffic exclusions such as Disable Search
                        Partners and Content Exclusions, are also compatible. With ROAS mode
                        enabled, improvements will be generated based on ROAS and Conversion Value
                        data, rather than CPA and Conversions.
                    </Text>
                    <Spacer height="1.25rem" />
                    <Text>
                        <b>Performance</b> — Monitor performance, diagnose issues and explore
                        segments with new ROAS and Conversion Value performance charts.
                    </Text>
                    <Spacer height="1.25rem" />
                    <Text>
                        <b>Reports</b> — Get new ROAS focused report slides like Conversion Value
                        Summary, ROAS Summary, and ROAS Targets. In addition, our existing slides
                        have been updated to feature ROAS and Conversion Value data. These slides
                        include Google Ads Summary, Top Performing Ads, Top Performing Keywords, and
                        Top Performing Segments.
                    </Text>
                    <Spacer height="1.25rem" />
                    <Text>
                        <b>Toolkit</b> — Access ROAS and Conversion Value data in Scorecard, RSA
                        Writer and Bidding Experiments. Analyse search terms based on ROAS and
                        Conversion Value data when writing RSAs.
                    </Text>
                    <Spacer height="1.25rem" />
                    <Text>
                        As always, we hope you find these updates useful. We'll be working to fix
                        any bugs that pop up over the coming days. If you notice anything that looks
                        like a bug, or have an idea that could improve this feature, please don't
                        hesitate to
                        <Text :clickable="true" @click="startNewMessage">
                            send our customer support team a message</Text
                        >.
                    </Text>
                </div>
            </div>
            <div class="changelog-post">
                <div class="changelog-text">
                    <Text size="f-2" weight="600" as="h2">Campaign Groups</Text>
                    <Spacer height="0.75rem" />
                    <Text size="f-8" color="gray">Published 18th January 2023 at 12:00pm</Text>
                    <Spacer height="2.25rem" />
                    <Text>
                        This release introduces a number of updates to Campaign Groups —with
                        automatic campaign sorting, improved multi-select functionality and more
                        detailed performance metrics, organising your campaign groups and setting
                        performance targets is easier than ever before.
                        <Spacer height="1.25rem" />
                        For anyone unfamiliar with Campaign Groups — by comparing the performance of
                        similar campaigns, aggregating their data, tracking specific conversion
                        actions and defining shared targets, Opteo can generate more specific,
                        higher quality recommendations that align with your overall goals (whether
                        conversions, traffic, or otherwise).
                    </Text>
                </div>
                <Spacer height="3.5rem" />
                <div class="carousel-container">
                    <Carousel
                        v-if="true"
                        :arrows="true"
                        :options="{ align: 'center', speed: 7 }"
                        padding="4.75rem 0rem 4.75rem 0rem"
                        arrow-horizontal-padding="2rem"
                    >
                        <template #slides>
                            <div
                                class="slide"
                                v-for="image in campaignGroupsImages"
                                :key="image.image"
                            >
                                <div
                                    :style="[
                                        { paddingTop: (472 / 756) * 100 + '%' },
                                        { height: '0px' },
                                    ]"
                                    :class="[image.noShadow ? 'no-shadow' : '', 'slide-wrapper']"
                                >
                                    <oImage :src="image.image" />
                                </div>
                            </div>
                        </template>
                    </Carousel>
                </div>
                <Spacer height="3.5rem" />
                <div class="changelog-text">
                    <Text>
                        To take a look at the new-and-improved Campaign Groups, head over to
                        <b>Improvements</b> and click the <b>Campaign Groups</b> button at the top
                        of the page. Keep reading below for a summary of the incoming features:
                    </Text>
                    <Spacer height="1.25rem" />
                    <Text>
                        <b>Automatic Campaign Grouping</b> — By default, Opteo automatically sorts
                        your campaigns into groups according to campaign type, with branding
                        campaigns filtered into a separate group.
                    </Text>
                    <Spacer height="1.25rem" />
                    <Text>
                        <b>Custom Campaign Grouping</b> — In addition to automatic grouping,
                        campaigns can also be grouped manually for more granular targeting. Select
                        and move multiple campaigns at once using the multi-select function, create
                        new campaign groups, rename existing campaign groups, remove old campaign
                        groups — all at the click of a button.
                    </Text>
                    <Spacer height="1.25rem" />
                    <Text>
                        <b>Automatic Targeting</b> — Opteo sets the performance target for each of
                        your campaign groups to the 90 Day Average CPA or ROAS of the campaigns
                        contained within the group. This helps to steadily optimise performance over
                        time, as performance improves, your targets adjust accordingly.
                        Alternatively, if you have more specific requirements, you can enter custom
                        CPA or ROAS targets for each group.
                    </Text>
                    <Spacer height="1.25rem" />
                    <Text>
                        <b>Conversion Actions</b> — Select the conversion actions you want Opteo to
                        optimise towards. Opteo will aim to generate more of these actions at a
                        below-target rate and prioritise them when making suggestions.
                    </Text>
                    <Spacer height="1.25rem" />
                    <Text>
                        <b>Performance Comparison</b> — See all of your campaign groups in one
                        unified table and compare their performance at a glance. Understand
                        performance across campaign types, find your highest performing channels,
                        learn where to increase spend and where to pull back.
                    </Text>
                    <Spacer height="1.25rem" />
                    <Text>
                        We hope you find these updates useful. As always, we'll be working to fix
                        any bugs that pop up over the coming days. If you notice anything that looks
                        like a bug, or have an idea that could improve this feature, please don't
                        hesitate to
                        <Text :clickable="true" @click="startNewMessage">
                            send our customer support team a message</Text
                        >.
                    </Text>
                </div>
            </div>
            <div class="changelog-post">
                <div class="changelog-text">
                    <Text size="f-2" weight="600" as="h2">RSA Writer</Text>
                    <Spacer height="0.75rem" />
                    <Text size="f-8" color="gray">Published 22nd November 2022 at 3:00pm</Text>
                    <Spacer height="2.25rem" />
                    <Text>
                        Today we're excited to announce the release of <b>RSA Writer</b>, our tool
                        for writing and publishing responsive search ads. With a host of new
                        features, ranging from AI generated assets to recommended search terms, as
                        well as proven classics like copyable competitor ads, Opteo now has all the
                        tools you need to write best-in-class search creative.
                    </Text>
                </div>
                <Spacer height="3.5rem" />
                <div class="carousel-container">
                    <Carousel
                        v-if="true"
                        :arrows="true"
                        :options="{ align: 'center', speed: 7 }"
                        padding="4.75rem 0rem 4.75rem 0rem"
                        arrow-horizontal-padding="2rem"
                    >
                        <template #slides>
                            <div class="slide" v-for="image in rsaWriterImages" :key="image">
                                <div
                                    :style="[
                                        { paddingTop: (472 / 756) * 100 + '%' },
                                        { height: '0px' },
                                    ]"
                                    class="slide-wrapper"
                                >
                                    <oImage :src="image" />
                                </div>
                            </div>
                        </template>
                    </Carousel>
                </div>
                <Spacer height="3.5rem" />
                <div class="changelog-text">
                    <Text>
                        To get started now, head over to <b>Toolkit</b> and open the RSA Writer
                        tool. Otherwise, keep reading for an overview of the incoming features, as
                        well as our plans to improve RSA asset management in Google Ads.
                    </Text>
                    <Spacer height="1.25rem" />
                    <Text>
                        <b>AI Generated Assets</b> — We've partnered with
                        <a href="https://anyword.com" target="_blank" rel="noopener">Anyword</a> to
                        provide AI generated assets for each of your ad groups. The Anyword
                        algorithm is trained on $250m of monthly ad spend, and generates suggestions
                        based on high-performing ad creative. These assets can be copied to your RSA
                        at the click of a button, or adjusted as required.
                    </Text>
                    <Spacer height="1.25rem" />
                    <Text>
                        <b>Ad Score</b> — Each of your campaigns and ad groups is assigned an Ad
                        Score by Opteo. This score is based on a numer of factors like search term
                        inclusion, click through rate, creative freshness, and number of headline
                        assets. As a general rule, groups with low ad scores need the most attention
                        and might perform better with some fresh creative.
                    </Text>
                    <Spacer height="1.25rem" />
                    <Text>
                        <b>Campaign Library</b> — All available headline and description assets in
                        the parent campaign are available to copy with the click of a button. No
                        more switching between ads to copy snippets, get everything you need to
                        write consistent, high-performing ad creative in one place.
                    </Text>
                    <Spacer height="1.25rem" />
                    <Text>
                        <b>Realistic Previews</b> — Preview your RSAs in our replica Google SERP,
                        see exactly how your RSAs will look and feel before pushing them live.
                    </Text>
                    <Spacer height="1.25rem" />
                    <Text>
                        <!-- We also have plans to build an all-in-one asset library to manage and update
                        shared assets across your account. This feature will be useful for pushing
                        bulk changes to ads, as well as ensuring that your brand assets are rendered
                        consistently across campaigns. Coming 2023. -->
                        We also have plans to build an all-in-one asset library to manage and update
                        shared assets across your account. This feature will be useful for pushing
                        bulk changes to ads, as well as ensuring your brand assets render
                        consistently across campaigns. Scheduled for release in 2023.
                    </Text>
                    <Spacer height="1.25rem" />
                    <Text>
                        We hope you find RSA Writer useful. If you have any questions, please don't
                        hesitate to message our support team. If you need to see a more detailed
                        feature breakdown, please head over to our
                        <Link href="https://opteo.com/docs/en/"> help docs</Link>.
                    </Text>
                    <Spacer height="1.25rem" />
                    <Text>
                        As always, we'll be working to fix any bugs or issues that pop up over the
                        coming days. If you notice anything that looks like a bug, or if you have
                        any ideas that could help us improve RSA Writer, please don't hesitate to
                        <Text :clickable="true" @click="startNewMessage">
                            send our customer support team a message</Text
                        >.
                    </Text>
                </div>
            </div>
            <div class="changelog-post">
                <div class="changelog-text">
                    <Text size="f-2" weight="600" as="h2">
                        Automatically pause spend before going over budget
                    </Text>
                    <Spacer height="0.75rem" />
                    <Text size="f-8" color="gray">Published 7th November 2022 at 2:00pm</Text>
                    <Spacer height="2.25rem" />
                    <Text>
                        The first of our upcoming budget management features, this small but
                        powerful feature provides an option to automatically pause campaign spend
                        before exceeding your monthly budget. Simply set your budget, toggle
                        <b>Pause spend when over budget</b> and let Opteo do the rest.
                    </Text>
                    <Spacer height="1.25rem" />
                    <Text>
                        If an account is trending over budget, Opteo will generate an alert to let
                        you know. In advance of pausing any campaigns, Opteo will give you the
                        option to adjust your budget or cancel the scheduled pause.
                    </Text>
                </div>
                <Spacer height="3.5rem" />
                <div class="carousel-container">
                    <Carousel
                        v-if="true"
                        :arrows="true"
                        :options="{ align: 'center', speed: 7 }"
                        padding="4.75rem 0rem 4.75rem 0rem"
                        arrow-horizontal-padding="2rem"
                    >
                        <template #slides>
                            <div
                                class="slide"
                                v-for="image in pauseSpendOverBudgetImages"
                                :key="image"
                            >
                                <div
                                    :style="[
                                        { paddingTop: (472 / 756) * 100 + '%' },
                                        { height: '0px' },
                                    ]"
                                    :class="[image.noShadow ? 'no-shadow' : '', 'slide-wrapper']"
                                >
                                    <oImage :src="image.image" />
                                </div>
                            </div>
                        </template>
                    </Carousel>
                </div>
                <Spacer height="3.5rem" />
                <div class="changelog-text">
                    <Text>
                        If your campaigns are paused due to a budget overspend, Opteo will
                        automatically re-activate them on the first day of the next calendar month.
                        You will also receive alerts to notify you of these changes.
                    </Text>
                    <Spacer height="1.25rem" />
                    <Text>
                        Feel safe knowing you have protection against accidental overspend, prevent
                        potentially embarassing situations with demanding clients.
                    </Text>
                    <Spacer height="1.25rem" />
                    <Text>
                        We hope you find this feature useful. If you have any questions, please
                        don't hesitate to message our support team. If you need to see a more
                        detailed feature breakdown, please head over to our
                        <Link
                            href="https://opteo.com/docs/en/articles/6579831-pause-spend-when-over-budget"
                        >
                            help docs</Link
                        >.
                    </Text>
                    <Spacer height="1.25rem" />
                    <Text>
                        As always, we'll be monitoring for any bugs that might come up over the next
                        few days. If you notice anything that looks like a bug, or if you have an
                        idea that could help improve this feature,
                        <Text :clickable="true" @click="startNewMessage">send us a message</Text>.
                    </Text>
                </div>
            </div>
            <div class="changelog-post">
                <div class="changelog-text">
                    <Text size="f-2" weight="600" as="h2"> Improvement Updates </Text>
                    <Spacer height="0.75rem" />
                    <Text size="f-8" color="gray">Published 25th April 2022 at 12:00pm</Text>
                    <Spacer height="2.25rem" />
                    <Text>
                        As well as working on new sets of improvements, we're also updating our
                        existing improvement logic. This release includes five new versions of
                        existing improvements with various upgrades to help deliver better
                        performance and an improved user experience. This also helps us prepare for
                        the soon-to-be-released ROAS and Profit modes.
                    </Text>
                </div>
                <Spacer height="3.5rem" />
                <div class="carousel-container">
                    <Carousel
                        v-if="true"
                        :arrows="true"
                        :options="{ align: 'center', speed: 7 }"
                        padding="4.75rem 0rem 4.75rem 0rem"
                        arrow-horizontal-padding="2rem"
                    >
                        <template #slides>
                            <div
                                class="slide"
                                v-for="image in improvementUpdateCarouselImages"
                                :key="image"
                            >
                                <div
                                    :style="[
                                        { paddingTop: (472 / 756) * 100 + '%' },
                                        { height: '0px' },
                                    ]"
                                    class="slide-wrapper"
                                >
                                    <oImage :src="image" />
                                </div>
                            </div>
                        </template>
                    </Carousel>
                </div>
                <Spacer height="3.5rem" />
                <div class="changelog-text">
                    <Text>
                        <b>Adjust Location Bids</b> — Now accounts for six location types, ranging
                        from country to zip code. Dynamic lookback windows allow for more accurate
                        performance comparisons and more relevant improvement suggestions. In
                        addition, Opteo now uses aggregate data to make smarter decisions with low
                        volume campaigns.
                    </Text>
                    <Spacer height="1.25rem" />
                    <Text>
                        <b>Pause Duplicate Keyword</b> — Opteo now ensures duplicate keywords target
                        the same traffic and share key attributes before recommending a change. In
                        addition, more variables (Quality Score, CPA, CTR) are taken into account
                        when deciding the winning and losing duplicates.
                    </Text>
                    <Spacer height="1.25rem" />
                    <Text>
                        <b>Replace Broad Keyword</b> — Opteo now checks performance for each match
                        type across the parent campaign group and only recommends replacing broad
                        keywords when they underperform significantly.
                    </Text>
                    <Spacer height="1.25rem" />
                    <Text>
                        <b>Disable Locations of Interest</b> — Opteo now uses aggregate data to make
                        smarter decisions with low volume campaigns, so you can make adjustments
                        before performance is impacted negatively.
                    </Text>
                    <Spacer height="1.25rem" />
                    <Text>
                        <b>Disable Search Partners</b> — Much the same as Locations of Interest,
                        this improvement now uses aggregate data to make smarter decisions with low
                        volume campaigns, as well as data-driven predictions that consider any
                        potential loss in conversions, impressions, and clicks.
                    </Text>
                    <Spacer height="1.25rem" />
                    <Text>
                        As always, we'll be working to fix any bugs or issues that pop up over the
                        coming days. If you notice anything that looks like a bug, or if you have
                        any ideas to improve any of these improvements, please don't hesitate to
                        <Text :clickable="true" @click="startNewMessage">
                            send our customer support team a message.
                        </Text>
                    </Text>
                </div>
            </div>
            <div class="changelog-post">
                <div class="changelog-text">
                    <Text size="f-2" weight="600" as="h2">
                        Introducing N-Gram and Shopping Improvements
                    </Text>
                    <Spacer height="0.75rem" />
                    <Text size="f-8" color="gray">Published 28th March 2022 at 3:00pm</Text>
                    <Spacer height="2.25rem" />
                    <Text>
                        The first of our improvement-focused releases — we're excited to introduce
                        several new improvement types to the Opteo ecosystem.
                    </Text>
                </div>
                <Spacer height="3.5rem" />
                <div class="carousel-container">
                    <Carousel
                        v-if="true"
                        :arrows="true"
                        :options="{ align: 'center', speed: 7 }"
                        padding="4.75rem 0rem 4.75rem 0rem"
                        arrow-horizontal-padding="2rem"
                    >
                        <template #slides>
                            <div
                                class="slide"
                                v-for="image in improvementCarouselImages"
                                :key="image"
                            >
                                <div
                                    :style="[
                                        { paddingTop: (472 / 756) * 100 + '%' },
                                        { height: '0px' },
                                    ]"
                                    class="slide-wrapper"
                                >
                                    <oImage :src="image" />
                                </div>
                            </div>
                        </template>
                    </Carousel>
                </div>
                <Spacer height="3.5rem" />
                <div class="changelog-text">
                    <Text>
                        <b>Reduce N-Gram Bids</b> — This improvement was designed to reduce wasted
                        spend by identifying low-performing keyword n-grams and recommending bid
                        reductions where appropriate.
                    </Text>
                    <Spacer height="1.25rem" />
                    <Text>
                        <b>Add N-Gram Negative</b> — A similar focus to above, however instead of
                        recommending bid reductions, this improvement suggests adding significantly
                        underperforming n-grams as negative keywords.
                    </Text>
                    <Spacer height="1.25rem" />
                    <Text>
                        <b>Adjust Product Group Bid</b> — This improvement compares individual
                        product group performance against your targets, recommending appropriate bid
                        adjustments according to your performance data.
                    </Text>
                    <Spacer height="1.25rem" />
                    <Text>
                        <b>Resync Product Groups</b> — Staying in sync with Google Merchant Center
                        can be painful when adding new products on a regular basis. This improvement
                        checks for new product entries, recommending new subdivisions based on your
                        existing group and performance data.
                    </Text>
                </div>
                <Spacer height="3.5rem" />
                <div class="inline-screenshot">
                    <oImage :src="conversionSettingsScreenshot" :height="472" :width="756" />
                </div>
                <Spacer height="3.5rem" />
                <div class="changelog-text">
                    <Text>
                        For the Shopping-based improvements listed above, we've added a section to
                        the <b>Settings</b> page so you can enable your campaigns in Opteo and set
                        appropriate targets for each campaign. This section is only temporary, and
                        will eventually be merged into Campaign Groups once all of our improvements
                        have been updated.
                    </Text>
                    <Spacer height="1.25rem" />
                    <Text>
                        As always, we'll be working to fix any bugs or issues that pop up over the
                        coming days. If you notice anything that looks like a bug, or if you have
                        any ideas to improve any of these improvements, please don't hesitate to
                        <Text :clickable="true" @click="startNewMessage">
                            send our customer support team a message.
                        </Text>
                    </Text>
                </div>
            </div>
            <div class="changelog-post">
                <div class="changelog-text">
                    <Text size="f-2" weight="600" as="h2">A New Opteo</Text>
                    <Spacer height="0.75rem" />
                    <Text size="f-8" color="gray">Published 14th March 2022 at 12:00pm</Text>
                    <Spacer height="2.25rem" />
                    <Text>
                        We've been working hard over the past 18 months to rebuild Opteo from the
                        ground up — to set the foundations for an exciting future.
                    </Text>
                    <Spacer height="1.25rem" />
                    <Text>
                        We want to give Opteo users the innovative features they deserve, to help
                        marketing managers solve real problems that move the needle, and to respond
                        to the ever-changing world of Google Ads in a much more timely fashion. This
                        release is the first step towards that future.
                    </Text>
                </div>
                <Spacer height="3.5rem" />
                <div class="carousel-container">
                    <Carousel
                        v-if="true"
                        :arrows="true"
                        :options="{ align: 'center', speed: 7 }"
                        padding="4.75rem 0rem 4.75rem 0rem"
                        arrow-horizontal-padding="2rem"
                    >
                        <template #slides>
                            <div class="slide" v-for="image in carouselImages" :key="image">
                                <div
                                    :style="[
                                        { paddingTop: (472 / 756) * 100 + '%' },
                                        { height: '0px' },
                                    ]"
                                    class="slide-wrapper"
                                >
                                    <oImage :src="image" />
                                </div>
                            </div>
                        </template>
                    </Carousel>
                </div>
                <Spacer height="3.5rem" />
                <div class="changelog-text">
                    <Text>
                        With this release, nothing much should change in terms of your day to day
                        Opteo experience. The application should load faster, be easier to use, more
                        accessible, and generally much nicer all round. A few things have moved to
                        new locations, most notably <b>Campaign Groups</b> and <b>Scorecard</b>, but
                        otherwise everything should be much the same.</Text
                    >
                    <Spacer height="1.25rem" />
                    <Text>
                        More importantly, this release sets the stage for our upcoming slate of
                        features and updates. Over the next few months, we're scheduled to release a
                        complete overhaul of improvements, brand new tools like RSA Writer and
                        N-Gram Generator, our Microsoft Ads integration, Algorithm Settings, some
                        interface goodies — and so much more.
                    </Text>
                    <Spacer height="1.25rem" />
                    <Text>
                        In the meantime, we'll be working to fix any bugs or issues that might come
                        up. If you notice anything that doesn't look quite right, or if you have an
                        idea that could improve your day to day Opteo experience, please don't
                        hesitate to
                        <Text :clickable="true" @click="startNewMessage">
                            send our customer support team a message</Text
                        >.
                    </Text>
                    <Spacer height="1.25rem" />
                    <Text>
                        For more information about our upcoming release schedule — and details about
                        any new features — please read the section below.
                    </Text>
                    <Spacer height="3.75rem" />
                    <Text size="f-5" weight="600" as="h3">Upcoming Release Schedule</Text>
                    <Spacer height="2rem" />
                </div>

                <!-- Upcoming Release Schedule -->
                <div v-for="item in upcomingReleases" :key="item.title" class="release-card">
                    <div class="release-card-hero">
                        <img v-if="item.hero_image" :src="item.hero_image" />
                    </div>
                    <div class="release-card-info">
                        <div class="release-card-header">
                            <Text as="h4" size="f-6" weight="600">{{ item.title }}</Text>
                            <ColorTag
                                color="blue"
                                :title="item.release_date_title"
                                :content="item.release_date"
                            />
                        </div>
                        <Spacer height="1.25rem" />
                        <Text size="f-8">
                            {{ item.text }}
                            <b>{{ item.release_date }}</b
                            >.
                        </Text>
                    </div>
                </div>
            </div>
        </div>
    </PageContent>
</template>

<script lang="ts">
import { ref } from 'vue'
import PageContent from '@/layouts/PageContent.vue'

import { Text, Spacer, Carousel, ColorTag, oImage, Link } from '@opteo/components-next'
import { useIntercom } from '@/lib/intercom/useIntercom'

// Adjust Ad Group Targets
import adGroupTargetsOne from '@/assets/img/changelog/ad-group-targets-one.png'
import adGroupTargetsTwo from '@/assets/img/changelog/ad-group-targets-two.png'

// Chat
import chatOne from '@/assets/img/changelog/chat-one.png'
import chatTwo from '@/assets/img/changelog/chat-two.png'
import chatThree from '@/assets/img/changelog/chat-three.png'

// ROAS
import RoasSettings from '@/assets/img/changelog/roas-settings.png'
import RoasImprovements from '@/assets/img/changelog/roas-improvements.png'
import RoasPerformance from '@/assets/img/changelog/roas-performance.png'
import RoasReports from '@/assets/img/changelog/roas-reports.png'

// Campaign Groups
import campaignGroupsOne from '@/assets/img/changelog/campaign-groups.png'
import campaignGroupsTwo from '@/assets/img/changelog/campaign-groups-targets.png'
import campaignGroupsThree from '@/assets/img/changelog/campaign-groups-actions.png'
import campaignGroupsFour from '@/assets/img/changelog/campaign-groups-select.png'

// Bidding Experiments
import biddingExperimentsOne from '@/assets/img/changelog/bidding-experiments-one.png'
import biddingExperimentsTwo from '@/assets/img/changelog/bidding-experiments-two.png'
import biddingExperimentsThree from '@/assets/img/changelog/bidding-experiments-three.png'
import biddingExperimentsFour from '@/assets/img/changelog/bidding-experiments-four.png'
import biddingExperimentsFive from '@/assets/img/changelog/bidding-experiments-five.png'

// RSA Writer
import rsaWriterScreenshot from '@/assets/img/changelog/rsa-writer-screenshot.png'
import rsaPreviewScreenshot from '@/assets/img/changelog/rsa-preview-screenshot.png'
import rsaOpenCampaignScreenshot from '@/assets/img/changelog/rsa-open-campaign-screenshot.png'

// Pause Spend When Over Budget
import pauseSpendAccountCentreScreenshot from '@/assets/img/changelog/pause-spend-account-centre-screenshot.png'
import pauseSpendSettingsScreenshot from '@/assets/img/changelog/pause-spend-settings-screenshot.png'

// Initial Release
import reportsScreenshot from '@/assets/img/changelog/reports-screenshot.png'
import improvementsScreenshot from '@/assets/img/changelog/improvements-screenshot.png'
import scorecardScreenshot from '@/assets/img/changelog/scorecard-screenshot.png'
import performanceScreenshot from '@/assets/img/changelog/performance-screenshot.png'

// Improvements Release
import adjustNgramBidsScreenshot from '@/assets/img/changelog/adjust-ngram-bids-screenshot.png'
import negativeNgramScreenshot from '@/assets/img/changelog/negative-ngram-screenshot.png'
import resyncProductGroupScreenshot from '@/assets/img/changelog/resync-product-group-screenshot.png'
import adjustProductGroupBidScreenshot from '@/assets/img/changelog/adjust-product-group-bid-screenshot.png'
import conversionSettingsScreenshot from '@/assets/img/changelog/conversion-settings-screenshot.png'

// Improvements Release
import adjustLocationBidsScreenshot from '@/assets/img/changelog/adjust-location-bids-screenshot.png'
import pauseDuplicateKeywordScreenshot from '@/assets/img/changelog/pause-duplicate-keyword-screenshot.png'
import replaceBroadKeywordScreenshot from '@/assets/img/changelog/replace-broad-keyword-screenshot.png'
import disableLocationsOfInterestScreenshot from '@/assets/img/changelog/disable-locations-of-interest-screenshot.png'
import disableSearchPartnerSitesScreenshot from '@/assets/img/changelog/disable-search-partner-sites-screenshot.png'

// Schedule Images
import microsoftAdsImage from '@/assets/img/changelog/microsoft-ads-integration.png'
import newImprovementsImage from '@/assets/img/changelog/new-improvements.png'
import rsaWriterImage from '@/assets/img/changelog/rsa-writer.png'
import budgetManagementImage from '@/assets/img/changelog/budget-management.png'

export default {
    name: 'Changelog',
    components: { PageContent, Text, Spacer, Carousel, ColorTag, oImage, Link },
    setup() {
        // const posts = [{ title: 'A New Opteo', date: '8th March 2022 at 12:00pm' }]
        const carouselImages = ref([
            reportsScreenshot,
            improvementsScreenshot,
            scorecardScreenshot,
            performanceScreenshot,
        ])
        const improvementCarouselImages = ref([
            adjustNgramBidsScreenshot,
            negativeNgramScreenshot,
            resyncProductGroupScreenshot,
            adjustProductGroupBidScreenshot,
        ])
        const improvementUpdateCarouselImages = ref([
            adjustLocationBidsScreenshot,
            pauseDuplicateKeywordScreenshot,
            replaceBroadKeywordScreenshot,
            disableLocationsOfInterestScreenshot,
            disableSearchPartnerSitesScreenshot,
        ])
        const pauseSpendOverBudgetImages = ref([
            { image: pauseSpendAccountCentreScreenshot, noShadow: true },
            { image: pauseSpendSettingsScreenshot, noShadow: false },
        ])
        const rsaWriterImages = ref([
            rsaWriterScreenshot,
            rsaOpenCampaignScreenshot,
            rsaPreviewScreenshot,
        ])
        const biddingExperimentImages = ref([
            biddingExperimentsOne,
            biddingExperimentsTwo,
            biddingExperimentsThree,
            biddingExperimentsFour,
            biddingExperimentsFive,
        ])
        const campaignGroupsImages = ref([
            { image: campaignGroupsOne, noShadow: false },
            { image: campaignGroupsFour, noShadow: false },
            { image: campaignGroupsTwo, noShadow: true },
            { image: campaignGroupsThree, noShadow: true },
        ])
        const roasImages = ref([
            { image: RoasSettings, noShadow: false },
            { image: RoasImprovements, noShadow: false },
            { image: RoasPerformance, noShadow: false },
            { image: RoasReports, noShadow: false },
        ])
        const chatImages = ref([
            { image: chatOne, noShadow: false },
            { image: chatTwo, noShadow: false },
            { image: chatThree, noShadow: true },
        ])
        const adGroupTargetsImages = ref([
            { image: adGroupTargetsOne, noShadow: false },
            { image: adGroupTargetsTwo, noShadow: false },
        ])

        // the dates at the end of the .text string have been removed
        // this will be appended in the markup
        const upcomingReleases = [
            {
                title: 'Improvements 2.0',
                release_date_title: 'First Release',
                release_date: 'March 28th',
                hero_image: newImprovementsImage,
                text: 'New versions of Opteo improvements — with updated logic for the modern world of Google Ads (think ROAS, Shopping, Smart Bidding, RSAs, and more). The first round of releases begins on',
            },
            {
                title: 'RSA Writer',
                release_date_title: 'Release Date',
                release_date: 'April 12th',
                hero_image: rsaWriterImage,
                text: 'Write Responsive Search Ads with as many headlines and descriptions as you need, use position pinning, and more. Compare competitor ads, view dozens of different combinations. Due for release on',
            },
            {
                title: 'Microsoft Ads Integration',
                release_date_title: 'Release Date',
                release_date: 'April 26th',
                hero_image: microsoftAdsImage,
                text: 'The first version of our Microsoft Ads integration, with a small sample of improvements generated by the Opteo algorithm and some simple campaign and target management tools. Releasing on',
            },
            {
                title: 'Budget Management',
                release_date_title: 'Release Date',
                release_date: 'May 10th',
                hero_image: budgetManagementImage,
                text: 'Simple budget management features like an option to pause spend whenever an account is over budget, alongside variance thresholds to allow for natural fluctuations. Scheduled for release on',
            },
        ]

        const { showBubble, showNewMessage } = useIntercom()

        const startNewMessage = () => {
            showBubble()
            showNewMessage()
        }

        return {
            // posts,
            conversionSettingsScreenshot,
            carouselImages,
            improvementCarouselImages,
            improvementUpdateCarouselImages,
            pauseSpendOverBudgetImages,
            upcomingReleases,
            startNewMessage,
            rsaWriterImages,
            biddingExperimentImages,
            campaignGroupsImages,
            roasImages,
            chatImages,
            adGroupTargetsImages,
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.changelog-wrapper {
    width: 100%;
    max-width: 1000px;
    @include mt-32;
}
.changelog-post {
    margin-bottom: 6rem;
}
.changelog-post h2 {
    line-height: 2.375rem;
}
.changelog-text {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
}
.carousel-container {
    @include container;
    @include br-24;
}
.slide {
    // Essential CSS for slide
    // this flex value sets the width of the card
    // https://www.embla-carousel.com/guides/slide-sizes/
    flex: 0 0 756px;
    max-width: 100%;
    height: 100%;
    max-height: 29.5rem;
    // margin-right sets the gap between each card
    // https://www.embla-carousel.com/guides/slide-gaps/
    margin-right: 2.75rem;
}
.slide-wrapper {
    @include container;
    overflow: hidden;
    @include relative;
    @include br-20;
}
.slide-wrapper.no-shadow {
    box-shadow: none !important;
}
.slide-wrapper .o-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.inline-screenshot {
    width: 100%;
    max-width: 47.25rem;
    @include container;
    margin: 0 auto;
    overflow: hidden;
}
.release-card {
    @include container;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    overflow: hidden;
    @include mb-32;
}
.release-card:last-child {
    @include mb-0;
}
.release-card-hero {
    border-bottom: 1px solid;
    @include opteo-border;
}
.release-card-header {
    display: flex;
    align-items: flex-start;
    flex-direction: column-reverse;
}
.release-card-header :deep(h4) {
    margin-top: 1rem;
}
.release-card-info {
    @include ph-28;
    @include pv-28;
}

.changelog-note {
    background: $opteo-dark-blue-translucent;
    color: $opteo-dark-blue;
    @include br-24;
    @include ph-28;
    @include pv-24;
}
.changelog-note ul {
    list-style: disc;
    text-indent: 0.75rem;
    list-style-position: inside;
    margin: 0.875rem 0;
}

@media (min-width: $mq-480-max) {
    .release-card-header {
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
    }
    .release-card-header :deep(h4) {
        margin-top: 0rem;
    }
}
@media (min-width: $mq-767-max) {
    .changelog-wrapper {
        margin: 6rem auto;
    }
}

// All screens below 1024px
@media (max-width: $mq-1024-max) {
    .slide {
        padding: 0 2rem;
    }
}
</style>
