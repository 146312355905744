<template>
    <ImprovementView :push-messages="pushMessages" :push-action-text="pushActionText">
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <div v-if="aboveMobile">
                    <div v-for="(entity, index) in entityPillList" :key="entity.content">
                        <div v-if="entityPillList.length === index + 1">
                            <EntityPill :type="entity.type" :content="entity.content" />
                        </div>
                    </div>
                </div>
                <Spacer height="2rem" />
                <Text as="p">
                    In the product group above, Opteo has detected new products that are not
                    currently synced with the existing
                    <b>{{ suggestedSubdivison }}</b> subdivisions. Click
                    <Text as="span" color="green" weight="600">{{ pushActionText }}</Text> to update
                    your product groups according to the breakdown below.
                </Text>
                <Spacer height="1rem" />
                <Text as="p">
                    {{
                        unsyncedProductGroups > 1
                            ? 'The unsynced products are'
                            : 'This unsynced product is'
                    }}
                    grouped together in the following product group:
                </Text>
                <Spacer height="2rem" />
                <EntityPillList
                    v-if="
                        !aboveMobile &&
                        `Everything else in &quot;' + productGroupLabel + '&quot;`.length > 30
                    "
                    :items="[
                        {
                            type: 'product-group',
                            content: 'Everything else in &quot;' + productGroupLabel + '&quot;',
                        },
                    ]"
                />
                <EntityPill
                    v-else
                    type="product-group"
                    :content="'Everything else in &quot;' + productGroupLabel + '&quot;'"
                />
                <Spacer height="2rem" />
                <Text as="p">
                    <!-- 
                    TODO: put this bit back in when/if we have the info in the imp body
                    which represents <b><Percent :value="0.4" /></b> of cost in the
                    <b>Footwear</b> product group
                -->
                    Because the products in this group use the same default bid, your bidding
                    strategy cannot account for the difference in profit margin or impression share
                    between each product. Resyncing your product groups will allow for more specific
                    bids to account for these differences and provide increased granularity when
                    optimising future bids.
                </Text>
            </ImprovementHeader>
        </ImprovementContent>

        <ImprovementContent :fullWidth="true">
            <div
                ref="tableContainer"
                class="resync-table-container flex justify-center align-items-center mb4"
            >
                <ResyncTableContainer
                    :current="true"
                    :depth="unsyncedProductGroupDepth"
                    :hovered-product-group="hoveredProductGroup"
                    :hovered="activeTable === 'current'"
                    :label-distance="labelDistance"
                >
                    <ResyncRecursiveRow
                        :current="true"
                        :depth="0"
                        :product-group-label="productGroupLabel"
                        :product-group="productGroupTree"
                        :resynced-product-group="resyncedProductGroup"
                    />
                </ResyncTableContainer>

                <ResyncTableContainer
                    :current="false"
                    :depth="unsyncedProductGroupDepth"
                    :hovered-product-group="hoveredProductGroup"
                    :hovered="activeTable === 'resynced'"
                    :label-distance="labelDistance"
                >
                    <ResyncRecursiveRow
                        :current="false"
                        :depth="0"
                        :product-group-label="productGroupLabel"
                        :product-group="productGroupTree"
                        :generated-product-groups="generatedProductGroupTree"
                        :resynced-product-group="resyncedProductGroup"
                    />
                </ResyncTableContainer>
            </div>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="lookbackWindow" />
        </ImprovementContent>
    </ImprovementView>
</template>
<script lang="ts">
import {
    ImprovementHeader,
    ImprovementContent,
    Money,
    Percent,
    BarChart,
    ImprovementNote,
    ImprovementStatistics,
    ImprovementCreatedDate,
    oInput,
    oTable,
    Number,
    EntityPill,
    EntityPillList,
    Spacer,
    Text,
} from '@opteo/components-next'

import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import Ent from '@/components/util/Ent.vue'

import { useResyncProductGroups } from '@/composition/improvement/types/useResyncProductGroups'
import ResyncTableContainer from './ResyncTableContainer.vue'
import ResyncRecursiveRow from './ResyncRecursiveRow.vue'
import useMediaQuery from '@/composition/global/useMediaQuery'

export default {
    name: 'ResyncProductGroups',
    components: {
        ImprovementHeader,
        ImprovementContent,
        ImprovementHelpLink,
        Money,
        Percent,
        BarChart,
        ImprovementNote,
        ImprovementStatistics,
        ImprovementCreatedDate,
        ImprovementView,
        oInput,
        oTable,
        Ent,
        Number,
        EntityPill,
        EntityPillList,
        Spacer,
        Text,
        ResyncTableContainer,
        ResyncRecursiveRow,
    },
    setup() {
        return { ...useResyncProductGroups(), ...useMediaQuery() }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';
.resync-table-container {
    @include relative;
    flex-direction: column;
    align-items: center;
}

.path {
    display: none;
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: line 3s ease-out 0.5s forwards;
}

.suggested-group {
    box-shadow: $opteo-shadow-focus !important;
}

@keyframes line {
    from {
        stroke-dashoffset: 1000;
    }
    to {
        stroke-dashoffset: 0;
    }
}

@media screen and (min-width: 920px) {
    .resync-table-container {
        flex-direction: row;
        align-items: flex-start;
    }
}

@media screen and (min-width: 1050px) {
    .path {
        display: inline;
    }
}

:deep(#svg-canvas .bezier-line) {
    stroke-dasharray: 6;
    stroke-dashoffset: 0;
    animation: dash 5s linear infinite reverse;
    stroke-width: 1px;
    transition: opacity 0.75s cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 1;
    fill: none;
    stroke: rgba(0, 0, 0, 0.15);
}

@keyframes dash {
    to {
        stroke-dashoffset: 60;
    }
}
</style>
