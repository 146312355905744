<template>
    <oTable :headers="nGramToolHeaders" :items="items" fixed-layout :border-radius="24">
        <template #header.ngram>
            <Spacer width="1.875rem" />
            <Checkbox />
            <Spacer width="1.25rem" />
            <Text size="f-8" weight="500">N-Gram</Text>
            <Spacer width="1rem" />
            <oInput
                ref="searchInput"
                type="text"
                class="table-search-input"
                name="ngramTextSearchInput"
                autocomplete="off"
            />
        </template>
        <template #header.vpm>
            <Text as="span" size="f-8" weight="500">VPM</Text>
            <img
                class="info-circle-icon"
                style="margin-left: 0.5rem"
                src="@/assets/img/icon-info.svg"
            />
        </template>
        <template #column.ngram>
            <!-- add click to the table cell for a larger clickable area -->
            <div class="ngram-cell">
                <Checkbox />
                <Spacer width="1.25rem" />
                <EntityPill :type="Improvement.LocationEntity.NGram">
                    <Skeleton
                        :shimmer="loading"
                        width="6rem"
                        color="hsla(174, 100%, 22%, 0.2)"
                        shimmer-color="hsla(174, 100%, 22%, 0.06)"
                    />
                </EntityPill>
            </div>
        </template>
        <template #column.cost>
            <Skeleton :shimmer="loading" width="3rem" />
        </template>
        <template #column.impressions>
            <Skeleton :shimmer="loading" width="3rem" />
        </template>
        <template #column.conversions>
            <Skeleton :shimmer="loading" width="3rem" />
        </template>
        <template #column.conversionValue>
            <Skeleton :shimmer="loading" width="3rem" />
        </template>
        <template #column.cpa>
            <Skeleton :shimmer="loading" width="3rem" />
        </template>
        <template #column.roas>
            <Skeleton :shimmer="loading" width="3rem" />
        </template>
        <template #column.cpm>
            <Skeleton :shimmer="loading" width="3rem" />
        </template>
        <template #column.vpm>
            <Skeleton :shimmer="loading" width="3rem" />
        </template>
        <template #column.vsAvg>
            <Skeleton :shimmer="loading" width="3rem" />
        </template>
        <template #column.nscoreSortValue>
            <NScoreCell
                :loading="true"
                :nscore="0"
                nScoreColor="#e6e6e6"
                :percent-of-campaign-volume="0"
            />
        </template>
        <template #column.potentialSavings>
            <Pill>
                <Skeleton
                    :shimmer="loading"
                    width="4.75rem"
                    color="hsla(174, 100%, 22%, 0.2)"
                    shimmer-color="hsla(174, 100%, 22%, 0.06)"
                />
            </Pill>
        </template>
        <template #column.actions>
            <ActionsCell :loading="true" :item="emptyItem" :item-type="'nGram'" />
        </template>
        <template #footer>
            <div v-if="!loading" class="skeleton-gradient" />
        </template>
    </oTable>
</template>

<script setup lang="ts">
import { oTable, Checkbox, EntityPill, Spacer, Text, oInput } from '@opteo/components-next'
import Skeleton from '@/components/util/Skeleton.vue'
import Pill from '@/components/toolkit/ngram/Pill.vue'
import ActionsCell from '@/components/toolkit/ngram/ActionsCell.vue'
import { Improvement } from '@opteo/types'

import { useNGramHeaders } from '@/composition/toolkit/nGramTool/useNGramHeaders'
import NScoreCell from './NScoreCell.vue'

import type { PanelItem } from '@/composition/toolkit/nGramTool/types/index'

const props = withDefaults(defineProps<{ numberOfRows?: number; loading?: boolean }>(), {
    numberOfRows: 20,
    loading: true,
})

const { nGramToolHeaders } = useNGramHeaders()

const items: {}[] = new Array(props.numberOfRows).fill({})

const emptyItem = {} as PanelItem
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

:deep(.skeleton-gradient) {
    position: absolute;
    border-radius: 24px;
    overflow: hidden;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    background: radial-gradient(100% 100% at 50% 0%, rgba(255, 255, 255, 0) 0%, #ffffff 75%);
    z-index: 3;
    animation: fade-in 0.2s;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 100;
    }
}
</style>
