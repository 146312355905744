<template>
    <div class="pulse" />
</template>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

//Mixins
@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}
@mixin animation($str) {
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -ms-animation: #{$str};
    -o-animation: #{$str};
    animation: #{$str};
}

// Animation
@include keyframes(ringPulse) {
    0% {
        opacity: 0;
        transform: scale(1);
    }
    2% {
        opacity: 0;
        transform: scale(1);
    }
    50% {
        opacity: 0.4;
    }
    98% {
        opacity: 0;
        transform: scale(2);
    }
    100% {
        opacity: 0;
        transform: scale(2);
    }
}

.pulse {
    --pulse-width: 10px;
    --pulse-height: 10px;
    --border-width: 1px;
    --color-yellow: #f3ed8b;
    position: relative;
    background-color: $opteo-red;
    border-radius: 50%;
    width: var(--pulse-width);
    height: var(--pulse-width);
    cursor: pointer;
    z-index: 998;
    display: inline-block;
    margin: 0 2px;

    &:before,
    &:after {
        position: absolute;
    }

    // Pulsing ring
    &:before {
        position: absolute;
        content: '';
        border-radius: 50%;
        width: calc(var(--pulse-width) + var(--border-width));
        height: calc(var(--pulse-width) + var(--border-width));
        top: calc((var(--border-width) / 2) * -1);
        left: calc((var(--border-width) / 2) * -1);
        border: var(--border-width) solid $opteo-red;
        transform-origin: 50% 50%;
        transition: all 0.5s;
        @include animation(ringPulse 4s infinite);
    }
    &:after {
        background-color: #fff;
    }
}
</style>
