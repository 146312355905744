<template>
    <div>
        <ImprovementView
            :push-messages="pushMessages"
            :push-action-text="pushActionText"
            :is-adjustable="true"
            :on-push="onPush"
        >
            <ImprovementContent>
                <ImprovementHeader :title="title">
                    <div v-if="aboveMobile">
                        <EntityPill :content="campaignName" type="campaign" />
                        <Spacer height="2rem" />
                    </div>
                    <Text as="p">
                        In the campaign listed above, searchers in the
                        <b>{{ parentalStatus }}</b> group are converting at an average cost of
                        <b>{{ statsCpa }}</b> per conversion. In comparison, the parent campaign has
                        an average cost per conversion of <b>{{ statsCampaignCpa }}</b
                        >. This means that searchers in the <b>{{ parentalStatus }}</b> group cost
                        <Text as="span" weight="600" color="red">
                            <Percent :value="parentalStatusCpaToCampaignRatio" />
                        </Text>
                        more to convert than the campaign average.
                        <!-- Spacer -->
                        <Spacer height="1rem" />
                        Opteo recommends applying a
                        <Text as="span" weight="600" color="red">
                            <Percent :value="-negativeBidMod / 100" :include-sign="true" />
                        </Text>
                        bid adjustment to this segment. This should reduce overall campaign CPA and
                        limit wasted spend. Click
                        <Text as="span" weight="600" color="green">{{ pushActionText }}</Text> to
                        apply this change. To use a different adjustment percentage, click
                        <b>Adjust</b>.
                    </Text>
                </ImprovementHeader>
                <ImprovementHelpLink path="/en/articles/902970-adjust-demographic-bids">
                    Click here to learn more about demographic bid adjustments in Opteo.
                </ImprovementHelpLink>
            </ImprovementContent>

            <ImprovementContent>
                <!-- {{ TableItems }} -->
                <oTable
                    :headers="TableHeaders"
                    :items="TableItems"
                    responsive-mode="columns"
                    responsive-breakpoint="768px"
                    order-by="difference"
                    order="DESC"
                >
                    <template v-slot:column.parental_status="parental_status">
                        <Text
                            v-if="parental_status.value === parentalStatus"
                            as="span"
                            color="blue"
                            weight="500"
                        >
                            {{ parental_status.value }}
                        </Text>
                        <span v-else>
                            {{ parental_status.value }}
                        </span>
                    </template>
                    <template v-slot:column.cost="cost">
                        <Money :value="cost.value" :currency="domainCurrencyCode" />
                    </template>
                    <template v-slot:column.cpa="cpa">
                        <Money
                            v-if="cpa.value > 0"
                            :value="cpa.value"
                            :currency="domainCurrencyCode"
                        />
                        <span v-else>N/A</span>
                    </template>
                    <template v-slot:column.average-cpa="avgCPA">
                        <Money :value="avgCPA.value" :currency="domainCurrencyCode" />
                    </template>
                    <template v-slot:column.difference="difference">
                        <Text v-if="difference.value > 0" as="span" color="red" weight="500">
                            +<Percent :value="difference.value" />
                        </Text>
                        <Text v-if="difference.value === 0" as="span" color="black" weight="400">
                            <Percent :value="difference.value" />
                        </Text>
                        <Text
                            v-if="difference.value < 0 && difference.value > -1"
                            as="span"
                            color="green"
                            weight="500"
                        >
                            <Percent :value="difference.value" />
                        </Text>
                        <Text
                            v-else-if="difference.value === -1"
                            as="span"
                            color="black"
                            weight="400"
                        >
                            N/A
                        </Text>
                    </template>
                </oTable>
            </ImprovementContent>

            <ImprovementContent>
                <ImprovementStatistics
                    :items="[
                        {
                            key: 'segmentCPA',
                            value: statsCpa,
                            title: 'Segment CPA',
                        },
                        {
                            key: 'campaignCPA',
                            value: statsCampaignCpa,
                            title: 'Campaign CPA',
                        },
                        {
                            key: 'campaignGroupCPA',
                            value: statsBucketCpa,
                            title: 'Campaign Group CPA',
                        },
                    ]"
                >
                    <template #segmentCPA>
                        Over the last <b>60</b> days, the <b>{{ parentalStatus }}</b> group has a
                        CPA of <b>{{ statsCpa }}</b
                        >. Please note, this CPA is based on conversion types set in Campaign
                        Groups.
                    </template>
                    <template #campaignCPA>
                        In comparison, the average CPA for this campaign is
                        <b>{{ statsCampaignCpa }}</b> over the same time period. This means that
                        searchers in the <b>{{ parentalStatus }}</b> group cost
                        <b><Percent :value="parentalStatusCpaToCampaignRatio" /> more</b> to convert
                        than the campaign average.
                    </template>
                    <template #campaignGroupCPA>
                        The parent campaign group
                        <b>{{ campaignGroupName }}</b> has an average CPA of
                        <b>{{ statsBucketCpa }}</b> over the last <b>60</b> days. This means, in the
                        campaign at the top of the page, searchers in the
                        <b>{{ parentalStatus }}</b>
                        group cost
                        <b><Percent :value="parentalStatusCpaToCampaignGroupRatio" /> more</b>
                        to convert than the campaign group average.
                    </template>
                </ImprovementStatistics>
            </ImprovementContent>

            <ImprovementContent>
                <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="60" />
            </ImprovementContent>

            <template v-slot:adjust-step-1>
                <ImprovementContent>
                    <ImprovementBidAdjustment
                        ref="impBidAdjustmentRef"
                        bid-type="parental"
                        :current-bid="adjustedBid"
                        :new-bid="currentBidModRelativeToUnitBid"
                        @on-bid-update="onBidUpdate"
                    >
                        <template v-slot:entity-pill>
                            <EntityPill
                                v-if="aboveMobile"
                                :content="campaignName"
                                type="campaign"
                            />
                        </template>
                        <template v-slot:text>
                            In the campaign listed above, searchers in the
                            <b>{{ parentalStatus }}</b> group are converting at an average cost of
                            <b>{{ statsCpa }}</b> per conversion. In comparison, the parent campaign
                            has an average cost per conversion of <b>{{ statsCampaignCpa }}</b
                            >. This means that searchers in the <b>{{ parentalStatus }}</b> group
                            cost
                            <Text as="span" weight="600" color="red">
                                <Percent :value="parentalStatusCpaToCampaignRatio" />
                            </Text>
                            more to convert than the campaign average.
                            <!-- Spacer -->
                            <Spacer height="1rem" />
                            Opteo recommends applying a
                            <Text as="span" weight="600" color="red">
                                <Percent :value="-negativeBidMod / 100" :include-sign="true" />
                            </Text>
                            bid adjustment to this segment.
                        </template>
                    </ImprovementBidAdjustment>
                </ImprovementContent>
            </template>
        </ImprovementView>
    </div>
</template>
<script lang="ts">
import {
    ImprovementHeader,
    ImprovementContent,
    Percent,
    ImprovementStatistics,
    ImprovementCreatedDate,
    BarChart,
    Text,
    Ent,
    EntityPill,
    EntityPillList,
    oTable,
    Money,
    Spacer,
} from '@opteo/components-next'

import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import useMediaQuery from '@/composition/global/useMediaQuery'

import { useAdjustParentalBid } from '@/composition/improvement/types/useAdjustParentalBid'
import ImprovementBidAdjustment from '@/components/improvement/ImprovementBidAdjustment.vue'

export default {
    name: 'AdjustParentalBid',
    setup() {
        return { ...useAdjustParentalBid(), ...useMediaQuery() }
    },
    components: {
        ImprovementHeader,
        ImprovementHelpLink,
        ImprovementView,
        ImprovementContent,
        Percent,
        ImprovementStatistics,
        ImprovementCreatedDate,
        BarChart,
        Ent,
        EntityPill,
        EntityPillList,
        oTable,
        Money,
        ImprovementBidAdjustment,
        Spacer,
        Text,
    },
}
</script>
