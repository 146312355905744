<template>
    <ImprovementView
        :push-messages="pushMessages"
        :push-action-text="pushActionText"
        :steps="adjustSteps"
        :on-step-complete="onStepComplete"
        :on-push="onPush"
    >
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <div v-if="aboveMobile">
                    <EntityPill type="keyword" :content="improvement.body.keyword_text_mt" />
                    <Spacer width="100%" height="2rem" />
                </div>
                <Text as="p">
                    This keyword is a top-performer in your account, accounting for
                    <b><Percent :value="improvement.body.percentage_of_total / 100" /></b>
                    of total {{ improvement.body.mode }}. Opteo recommends creating a
                    <b>Single Keyword Ad Group (SKAG)</b> for this keyword. This should help you
                    increase quality scores and reduce overall CPA.
                    <Spacer height="1rem" />
                    Click <Text as="span" color="blue" weight="600">{{ adjustSteps[0] }}</Text> to
                    create an ad for your new ad group. Once you've created your new ad, you will
                    then be prompted to push your new SKAG to Google Ads.
                </Text>
                <Spacer height="2rem" />
                <ImprovementNote>
                    <Text as="p" size="f-8">
                        <b>Note:</b> Creating SKAGs is one of our more complex improvements. We've
                        explained how this improvement works below. You can also learn more by
                        clicking the following link.
                    </Text>
                </ImprovementNote>
            </ImprovementHeader>
            <ImprovementHelpLink path="/improvements/create-single-keyword-ad-group-skag">
                Learn more about the benefits of creating Single Keyword Ad Groups.
            </ImprovementHelpLink>
        </ImprovementContent>

        <ImprovementContent>
            <Text as="h3" size="f-4" weight="600">Creating a SKAG</Text>
            <Spacer height="2rem" />
            <div class="how-to-container">
                <div class="how-to-section">
                    <div class="sub-heading">
                        <ColorTag color="blue" title="Step 1" content="" class="number" />
                        <Text as="p" size="f-6" weight="600">Create New Ad Group</Text>
                    </div>
                    <Text as="p" size="f-8" class="text-content">
                        The high-performing keyword was found in the campaign
                        <Ent>{{ improvement.body.campaign_label }}</Ent
                        >, so Opteo will create a new ad group in this campaign called
                        <Ent>SKAG for: {{ improvement.body.keyword_text }}</Ent
                        >.
                    </Text>
                </div>
                <div class="how-to-section">
                    <div class="sub-heading">
                        <ColorTag color="blue" title="Step 2" content="" class="number" />
                        <Text as="p" size="f-6" weight="600">Add Exact Match Keyword</Text>
                    </div>
                    <Text as="p" size="f-8" class="text-content">
                        Opteo will add
                        <Ent>{{ improvement.body.keyword_text_mt }}</Ent> as an exact match keyword
                        to the newly-created
                        <Ent>SKAG for: {{ improvement.body.keyword_text }}</Ent> ad group.
                    </Text>
                </div>
                <div class="how-to-section">
                    <div class="sub-heading">
                        <ColorTag color="blue" title="Step 3" content="" class="number" />
                        <Text as="p" size="f-6" weight="600">Add High Performing Text Ad</Text>
                    </div>
                    <Text as="p" size="f-8" class="text-content">
                        Opteo will automatically add your highest-performing text ad to the new ad
                        group. Below is the chosen ad creative, based on historical performance.
                    </Text>
                    <!-- Spacer -->
                    <Spacer height="1.5rem" />
                    <TextAd
                        :ad="{
                            headlineOne: `${bestAd.expanded_text_ad.headline_part1}`,
                            headlineTwo: `${bestAd.expanded_text_ad.headline_part2}`,
                            headlineThree: `${bestAd.expanded_text_ad.headline_part3}`,
                            descriptionOne: `${bestAd.expanded_text_ad.description}`,
                            descriptionTwo: `${bestAd.expanded_text_ad.description2}`,
                            displayUrl: `${adDomain}`,
                        }"
                    />
                </div>
                <div class="how-to-section">
                    <div class="sub-heading">
                        <ColorTag color="blue" title="Step 4" content="" class="number" />
                        <Text as="p" size="f-6" weight="600">Add New Text Ad</Text>
                    </div>
                    <Text as="p" size="f-8" class="text-content">
                        Opteo will also add the ad you write in this improvement, so you have an ad
                        test as soon as your ad group is launched. Remember, customised ads using
                        specific keyword terms are a crucial component of a powerful SKAG.
                    </Text>
                </div>
            </div>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementStatistics
                :items="[
                    {
                        key: 'cost',
                        value: statsCost,
                        title: 'Cost',
                    },
                    {
                        key: 'clicks',
                        value: statsClicks,
                        title: 'Clicks',
                    },
                    {
                        key: 'conversions',
                        value: statsConversions,
                        title: 'Conversions',
                    },
                ]"
            >
                <template #cost>
                    <b><Money :value="improvement.body.cost" /></b> was spent on this keyword over
                    the last <b>60</b> days.
                </template>
                <template #clicks>
                    Over the same time period, your ads were clicked on a total of
                    <b>{{ improvement.body.clicks }}</b> times by searchers after triggering the
                    keyword <b>{{ improvement.body.keyword_text_mt }}</b
                    >.
                </template>
                <template #conversions>
                    This keyword generated
                    <b>{{ improvement.body.conversions }}</b> conversions over the last
                    <b>60</b> days.
                </template>
            </ImprovementStatistics>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="60" />
        </ImprovementContent>

        <!-- AD WRITER -->
        <template v-slot:adjust-step-1>
            <!-- <ImprovementContent :adWriter="true"> -->
            <AdWriter
                ref="adWriter"
                :adgroup-id="+adGroupId"
                :improvement-id="improvementId"
                :domain-id="domainId"
                :campaign-id="+campaignId"
                :competitor-terms="topSearchTerms"
                @ad-writer-resolve="setNewAd"
            />
            <!-- </ImprovementContent> -->
        </template>

        <!-- AD PREVIEW -->
        <template v-slot:adjust-step-2>
            <ImprovementContent>
                <ImprovementHeader title="Preview New Ad" />
            </ImprovementContent>
            <ImprovementContent :adWidth="true">
                <TextAd
                    :ad="{
                        headlineOne: `${newAd.headline_part_1}`,
                        headlineTwo: `${newAd.headline_part_2}`,
                        headlineThree: `${newAd.expanded_text_ad_headline3 ?? ''}`,
                        descriptionOne: `${newAd.description}`,
                        descriptionTwo: `${newAd.expanded_text_ad_description2 ?? ''}`,
                        displayUrl: `${newAd.destination_url}`,
                        pathOne: `${newAd.path_1 ?? ''}`,
                        pathTwo: `${newAd.path_2 ?? ''}`,
                    }"
                >
                </TextAd>
            </ImprovementContent>
            <ImprovementContent>
                <Spacer height="3rem" />
                <Text as="p">
                    When you click
                    <Text as="span" weight="600" color="green">{{ pushActionText }}</Text
                    >, Opteo will push the following changes:
                </Text>
                <Spacer height="2rem" />
                <div class="how-to-container">
                    <div class="how-to-section">
                        <div class="sub-heading">
                            <ColorTag color="blue" title="Step 1" content="" class="number" />
                            <Text as="p" size="f-6" weight="600">Create New Ad Group</Text>
                        </div>
                        <Text as="p" size="f-8" class="text-content">
                            The high-performing keyword was found in the campaign
                            <Ent>{{ improvement.body.campaign_label }}</Ent
                            >, so Opteo will create a new ad group in this campaign called
                            <Ent>SKAG for: {{ improvement.body.keyword_text }}</Ent
                            >.
                        </Text>
                    </div>
                    <div class="how-to-section">
                        <div class="sub-heading">
                            <ColorTag color="blue" title="Step 2" content="" class="number" />
                            <Text as="p" size="f-6" weight="600">Add Exact Match Keyword</Text>
                        </div>
                        <Text as="p" size="f-8" class="text-content">
                            Opteo will add
                            <Ent>{{ improvement.body.keyword_text_mt }}</Ent> as an exact match
                            keyword to the newly-created
                            <Ent>SKAG for: {{ improvement.body.keyword_text }}</Ent> ad group.
                        </Text>
                    </div>
                    <div class="how-to-section">
                        <div class="sub-heading">
                            <ColorTag color="blue" title="Step 3" content="" class="number" />
                            <Text as="p" size="f-6" weight="600">Add High Performing Text Ad</Text>
                        </div>
                        <Text as="p" size="f-8" class="text-content">
                            Opteo will automatically add your highest-performing text ad to the new
                            ad group. Below is the chosen ad creative, based on historical
                            performance.
                        </Text>
                        <!-- Spacer -->
                        <Spacer height="1.5rem" />
                        <TextAd
                            :ad="{
                                headlineOne: `${bestAd.expanded_text_ad.headline_part1}`,
                                headlineTwo: `${bestAd.expanded_text_ad.headline_part2}`,
                                headlineThree: `${bestAd.expanded_text_ad.headline_part3}`,
                                descriptionOne: `${bestAd.expanded_text_ad.description}`,
                                descriptionTwo: `${bestAd.expanded_text_ad.description2}`,
                                displayUrl: `${adDomain}`,
                            }"
                        />
                    </div>
                    <div class="how-to-section">
                        <div class="sub-heading">
                            <ColorTag color="blue" title="Step 4" content="" class="number" />
                            <Text as="p" size="f-6" weight="600">Add New Text Ad</Text>
                        </div>
                        <Text as="p" size="f-8" class="text-content">
                            Opteo will also add the ad you write in this improvement, so you have an
                            ad test as soon as your ad group is launched. Remember, customised ads
                            using specific keyword terms are a crucial component of a powerful SKAG.
                        </Text>
                    </div>
                </div>
            </ImprovementContent>
        </template>
    </ImprovementView>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import {
    ImprovementHeader,
    ImprovementNote,
    ImprovementContent,
    oButton,
    oInput,
    Percent,
    ImprovementStatistics,
    ImprovementCreatedDate,
    Spacer,
    Text,
    Ent,
    TextAd,
    Link,
    oForm,
    SerpPreview,
    EntityPill,
    EntityPillList,
    ColorTag,
} from '@opteo/components-next'

import Money from '@/components/global/oMoney.vue'
import ImprovementView from '@/components/improvement/ImprovementView.vue'
import AdWriter from '@/composition/improvement/components/ad-writer/AdWriter.vue'
import useMediaQuery from '@/composition/global/useMediaQuery'

import { useAddSkagV2 } from '@/composition/improvement/types/useAddSkagV2'
import ImprovementBidAdjustment from '@/components/improvement/ImprovementBidAdjustment.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'

export default defineComponent({
    name: 'AddSkagV2',
    setup() {
        const { aboveMobile } = useMediaQuery()
        return { ...useAddSkagV2(), aboveMobile }
    },
    components: {
        ImprovementHeader,
        ImprovementContent,
        ImprovementNote,
        Percent,
        Money,
        ImprovementStatistics,
        ImprovementCreatedDate,
        ImprovementView,
        Ent,
        ImprovementBidAdjustment,
        Spacer,
        Text,
        TextAd,
        Link,
        oForm,
        oButton,
        oInput,
        SerpPreview,
        EntityPill,
        EntityPillList,
        ColorTag,
        AdWriter,
        ImprovementHelpLink,
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.how-to-container {
    @include container;
}

.how-to-section {
    @include ph-32;
    @include pb-32;
    @include pt-24;
    @include relative;
    border-bottom: 1px solid;
    @include opteo-border;

    & .sub-heading {
        @include flex;
        @include pb-24;
        margin-right: 68px;

        & .number {
            @include absolute;
            right: 24px;
            top: 25px;
        }
    }

    & .text-content {
        @include container;
        @include pa-24;
    }
}

.how-to-section:last-child {
    border-bottom: none;
}

.skag-preview-steps {
    @include container;
}

@media screen and (max-width: $mq-767-max) {
    .how-to-section {
        @include ph-24;
        @include pb-24;
    }
}
@media screen and (max-width: $mq-375-max) {
    .how-to-section {
        @include ph-24;
        @include pb-24;
        & .sub-heading {
            @include block;
            @include pb-24;
            margin-right: unset;

            & .number {
                position: unset;
                width: fit-content;
                @include mb-12;
            }
        }
    }
}
</style>
