<template>
    <div class="live-link-footer-container" v-if="mode !== 'pdf'">
        <div class="live-link-footer">
            <div class="domain">
                <slot v-if="mode === 'app'" name="avatar"></slot>
                <Spacer v-if="mode === 'app' && $slots.avatar" width="1.375rem" />
                <div class="title-container">
                    <Text as="p" class="title" weight="600">{{ title }}</Text>
                    <Text as="p" class="created-date" color="gray">
                        Generated {{ formattedDate }}
                    </Text>
                </div>

                <!-- Is not live -->
                <div class="view-tag" v-if="mode === 'app'">
                    <!-- Auto-generated -->
                    <div v-if="isAutoGenerated" class="inner-container">
                        <OpteoLogo :width="28" :height="28" />
                        <Spacer width="0.625rem" />
                        <Text as="p" size="f-9" color="gray"> Auto Generated </Text>
                    </div>
                    <!-- Has data -->
                    <div
                        v-else-if="!isAutoGenerated && createdBy?.userFullName"
                        class="inner-container"
                    >
                        <ProfileImage
                            :image-url="createdBy?.profileImageUrl"
                            :width="28"
                            :user-id="createdBy?.userId || createdBy?.userFullName"
                        />
                        <Spacer width="0.625rem" />
                        <Text as="p" size="f-9" color="gray">
                            by {{ createdBy?.userFullName }}
                        </Text>
                    </div>
                    <!-- Loading or error -->
                    <div v-else class="inner-container">
                        <Skeleton :width="28" :height="28" />
                        <Spacer width="0.625rem" />
                        <Skeleton :width="138" :height="13" style="margin: 0.125rem 0" />
                    </div>
                </div>

                <!-- Is Live -->
                <Popout
                    v-else
                    v-model="viewerPopoutOpen"
                    :z-index="9999999999999"
                    :view-background-z-index="10001"
                    :offset="aboveMobile ? [0, 6] : [-72, 36]"
                    :trap-focus="true"
                    :border-radius="20"
                    :externalToggleButton="aboveMobile ? false : true"
                    :placement="aboveMobile ? 'top' : 'top-start'"
                >
                    <div v-if="aboveMobile">
                        <div :class="['view-tag', { 'is-live': mode === 'live' }]">
                            <!-- Loading or error -->
                            <div v-if="viewedBy?.length < 1" class="inner-container">
                                <Skeleton :width="28" :height="28" />
                                <Spacer width="0.625rem" />
                                <Skeleton :width="138" :height="13" style="margin: 0.125rem 0" />
                            </div>
                            <div v-else class="inner-container">
                                <div class="stacked-profile-images">
                                    <div
                                        v-for="(viewer, index) in filteredViewedBy"
                                        key="index"
                                        class="border"
                                    >
                                        <ProfileImage
                                            :image-url="viewer.profileImageUrl"
                                            :width="28"
                                            :user-id="viewer.userId"
                                        />
                                    </div>
                                </div>
                                <Spacer width="0.625rem" />

                                <Text v-if="viewedBy?.length === 1" as="p" size="f-9" color="gray">
                                    Seen by {{ viewedBy[0].name }}
                                </Text>
                                <Text
                                    v-else-if="viewedBy?.length > 1"
                                    as="p"
                                    size="f-9"
                                    color="gray"
                                >
                                    Seen by {{ viewedBy?.length }} people
                                </Text>
                            </div>
                        </div>
                    </div>

                    <template v-slot:content>
                        <div class="viewer-popout-container">
                            <div class="header">
                                <Text as="p">
                                    Seen by {{ viewedBy?.length }}
                                    {{ viewedBy?.length === 1 ? 'person' : 'people' }}
                                </Text>
                            </div>
                            <Spacer height="1rem" />

                            <!-- <perfect-scrollbar class="total-viewers"> -->
                            <div class="total-viewers">
                                <div v-for="(viewer, index) in viewedBy" :key="viewer.userId">
                                    <div class="inner-container">
                                        <ProfileImage
                                            :image-url="viewer.profileImageUrl"
                                            :width="28"
                                            :user-id="viewer.userId"
                                            :is-active="viewer.seenRecently"
                                        />
                                        <Spacer width="0.625rem" />
                                        <Text as="p" size="f-9" class="viewer-name">{{
                                            viewer.name
                                        }}</Text>
                                        <Text v-if="viewer.isYou" as="span" size="f-9" color="gray"
                                            >&nbsp;(You)</Text
                                        >
                                    </div>
                                    <Spacer v-if="index !== viewedBy?.length - 1" height="1rem" />
                                </div>
                            </div>
                            <!-- </perfect-scrollbar> -->
                        </div>
                    </template>
                </Popout>
            </div>

            <div v-if="aboveMobile" class="buttons">
                <oButton
                    ref="downloadPdfButton"
                    @click.prevent="downloadScorecard"
                    :loading="downloadLoading"
                    :error="downloadFailed"
                    size="medium"
                    color="white"
                    icon-before
                >
                    <template #icon>
                        <svg
                            width="20"
                            height="21"
                            viewBox="0 0 20 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M19 3.5V17.5C19 18.6046 18.1046 19.5 17 19.5H3C1.89543 19.5 1 18.6046 1 17.5V6.32843C1 6.06321 1.10536 5.80886 1.29289 5.62132L0.585786 4.91421L1.29289 5.62132L5.12132 1.79289C5.30886 1.60536 5.56321 1.5 5.82843 1.5H17C18.1046 1.5 19 2.39543 19 3.5Z"
                                stroke="#010105"
                                stroke-width="2"
                            />
                            <path
                                d="M10 15.5C11.6569 15.5 13 14.1569 13 12.5C13 10.8431 11.6569 9.5 10 9.5C8.34315 9.5 7 10.8431 7 12.5C7 14.1569 8.34315 15.5 10 15.5Z"
                                stroke="#010105"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M6 5.5H14V2"
                                stroke="#010105"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </template>
                    Download PDF
                </oButton>
                <Spacer v-if="mode === 'app'" width="0.625rem" />
                <oButton
                    v-if="mode === 'app'"
                    ref="shareButton"
                    @clicked="copySharingLink"
                    :loading="linkShareLoading"
                    size="medium"
                    color="blue"
                    icon-before
                >
                    <template #icon>
                        <svg
                            width="20"
                            height="19"
                            viewBox="0 0 20 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M9.4656 4.19375L11.3219 2.3375C12.1078 1.62118 13.1395 1.23522 14.2026 1.25984C15.2657 1.28445 16.2784 1.71774 17.0303 2.46966C17.7822 3.22158 18.2155 4.23432 18.2401 5.29741C18.2648 6.36051 17.8788 7.39221 17.1625 8.17813L14.5094 10.8219C14.127 11.2057 13.6726 11.5102 13.1723 11.718C12.6719 11.9258 12.1355 12.0328 11.5937 12.0328C11.052 12.0328 10.5155 11.9258 10.0152 11.718C9.51486 11.5102 9.06047 11.2057 8.6781 10.8219"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M10.5343 14.8062L8.67807 16.6625C7.89215 17.3788 6.86045 17.7648 5.79735 17.7402C4.73426 17.7155 3.72152 17.2823 2.9696 16.5303C2.21767 15.7784 1.78439 14.7657 1.75978 13.7026C1.73516 12.6395 2.12112 11.6078 2.83744 10.8219L5.49057 8.17811C5.87294 7.79431 6.32732 7.48978 6.82765 7.28198C7.32799 7.07419 7.86442 6.96722 8.40619 6.96722C8.94796 6.96722 9.4844 7.07419 9.98473 7.28198C10.4851 7.48978 10.9394 7.79431 11.3218 8.17811"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </template>
                    Copy Sharing Link
                </oButton>
                <Spacer v-if="mode === 'app'" width="0.625rem" />
                <oButton
                    v-if="mode === 'app'"
                    color="white"
                    :circle="true"
                    size="small"
                    class="delete-button"
                    @clicked="deleteScorecard"
                >
                    <template #icon>
                        <ScorecardBinIcon />
                    </template>
                </oButton>
            </div>
            <div v-else>
                <RsaButton
                    double
                    :max-width="false"
                    @leftClicked="viewerPopoutOpen = !viewerPopoutOpen"
                    @rightClicked="downloadScorecard"
                >
                    <template #left>
                        <!-- Viewers Icon -->
                        <div class="icon-slot">
                            <div class="viewer-avatar-mobile-wrapper">
                                <Skeleton :width="24" :height="24" class="skeleton" />
                                <div v-for="viewer in viewedBy?.slice(0, 1)" class="avatar">
                                    <ProfileImage
                                        :image-url="viewer.profileImageUrl"
                                        :width="24"
                                        :user-id="viewer.userId"
                                    />
                                </div>
                            </div>
                            <!-- <svg
                                style="display: block"
                                width="20"
                                height="14"
                                viewBox="0 0 20 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M10 1C15 1 18 5 19 7C18 9 15 13 10 13C5 13 2 9 1 7C2 5 5 1 10 1Z"
                                    stroke="#040406"
                                    stroke-width="2"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M13 6.99951C13 8.65637 11.6569 9.99951 10 9.99951C8.34315 9.99951 7 8.65637 7 6.99951C7 5.34266 8.34315 3.99951 10 3.99951C11.6569 3.99951 13 5.34266 13 6.99951Z"
                                    stroke="#040406"
                                    stroke-width="2"
                                />
                            </svg> -->
                        </div>
                    </template>
                    <template #right>
                        <!-- Download Icon -->
                        <div class="icon-slot">
                            <Spinner v-if="downloadLoading" />
                            <svg
                                v-else
                                style="display: block"
                                ref="downloadPdfButton"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M3 7L8 12L13 7"
                                    stroke="#040406"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M8 1V12"
                                    stroke="#040406"
                                    stroke-width="2"
                                    stroke-miterlimit="10"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M15 15L1 15"
                                    stroke="#040406"
                                    stroke-width="2"
                                    stroke-miterlimit="10"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </div>
                    </template>
                </RsaButton>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, PropType } from 'vue'
import { Scorecard } from '@opteo/types'
import Skeleton from '@/components/util/Skeleton.vue'
import ScorecardBinIcon from '@/components/scorecard/ScorecardBinIcon.vue'

import formatDate from 'date-fns/format'
import PerfectScrollbar from 'perfect-scrollbar'
import useMediaQuery from '@/composition/global/useMediaQuery'

import {
    oButton,
    ProfileImage,
    Text,
    Spacer,
    Popout,
    RsaButton,
    Spinner,
    OpteoLogo,
} from '@opteo/components-next'

interface CreatedBy {
    userFullName: string
    userId: string
    profileImageUrl: string
}

export default defineComponent({
    name: 'LiveLinkFooter',
    components: {
        oButton,
        ProfileImage,
        Text,
        Spacer,
        Popout,
        Skeleton,
        PerfectScrollbar,
        RsaButton,
        Spinner,
        ScorecardBinIcon,
        OpteoLogo,
    },
    props: {
        title: {
            type: String,
            required: true,
            default: 'Live Link',
        },
        createdOn: {
            type: [String, Date] as PropType<string | Date>,
            required: true,
        },
        width: {
            type: String,
            required: false,
            default: '62.75rem',
        },
        mode: {
            type: String as PropType<'live' | 'app' | 'pdf'>,
            required: false,
        },
        createdBy: {
            type: Object as PropType<CreatedBy>,
            required: false,
        },
        viewedBy: {
            type: Array as PropType<Scorecard.LiveViewer[]>,
            required: false,
        },
        linkShareLoading: {
            type: Boolean,
            required: false,
            default: false,
        },
        downloadLoading: {
            type: Boolean,
            required: false,
            default: false,
        },
        downloadFailed: {
            type: Boolean,
            required: false,
            default: false,
        },
        isAutoGenerated: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    emits: ['copy-share-link', 'open-in-new-tab', 'download-scorecard', 'delete-scorecard'],
    setup(props, { emit }) {
        const viewerPopoutOpen = ref(false)

        const formattedDate = computed(() => {
            const date = new Date(props.createdOn)
            return formatDate(date, 'do LLLL yyyy')
        })

        const filteredViewedBy = computed(() => {
            if (aboveMobile.value) {
                return props.viewedBy?.slice(0, 3)
            } else {
                return props.viewedBy?.slice(0, 1)
            }
        })

        const shareButton = ref()

        // TODO: Implement Success / Error states
        function copySharingLink() {
            emit('copy-share-link')
        }

        function deleteScorecard() {
            emit('delete-scorecard')
        }

        function downloadScorecard() {
            emit('download-scorecard')
        }

        const { aboveMobile } = useMediaQuery()

        return {
            viewerPopoutOpen,
            formattedDate,
            filteredViewedBy,
            shareButton,
            copySharingLink,
            downloadScorecard,
            aboveMobile,
            deleteScorecard,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

// Base
.live-link-footer-container {
    @include w-100;
    max-width: calc(v-bind(width) + 3rem);
    @include ph-24;
    position: fixed;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10000;
}
.live-link-footer {
    @include bg-opteo-white;
    box-shadow: $opteo-shadow-xl;
    height: 5rem;
    @include ph-24;
    @include br-24;
    touch-action: none;
    overflow: hidden;
    @include w-100;
    @include flex;
    @include items-center;
    @include justify-between;
}
.live-link-footer .domain {
    @include flex;
    @include items-center;
}
.live-link-footer .title-container {
    @include flex;
    flex-direction: column;
    gap: 0.0625rem;
}
.live-link-footer .title-container .title {
    font-size: 1.0625rem;
    letter-spacing: -0.0175rem;
    line-height: 1.375rem;
}
.live-link-footer .title-container .created-date {
    font-size: 12px;
    line-height: 150%;
    @include fw-400;
    letter-spacing: -0.0075rem;
}

.live-link-footer .view-tag {
    @include flex;
    @include items-center;
    @include container;
    @include br-16;
    height: 3.375rem;
    padding: 0.75rem 1rem;
    @include ml-20;
}
.live-link-footer .view-tag.is-live:hover {
    @include pointer;
}
.live-link-footer .view-tag.is-live:active {
    @include pointer;
    outline: none;
    box-shadow:
        0 0 0 3px $opteo-blue-shadow,
        0 2px 4px -2px rgba(2, 2, 12, 0.1);
    transform: translate3d(0, 1px, 0);
}
.live-link-footer .view-tag.is-live:focus {
    @include pointer;
    outline: none;
    box-shadow:
        0 0 0 3px $opteo-blue-shadow,
        0 2px 4px -2px rgba(2, 2, 12, 0.1);
}

.live-link-footer .view-tag .inner-container .stacked-profile-images {
    @include flex;
    @include items-center;
    margin-left: 0.375rem;
}
.live-link-footer .view-tag .inner-container .stacked-profile-images .border {
    margin-left: -0.5rem;
    border-radius: 100%;
    border: 2px solid white;
    background-color: white;
}
.live-link-footer .view-tag .inner-container .stacked-profile-images .border:nth-child(1) {
    z-index: 3;
}
.live-link-footer .view-tag .inner-container .stacked-profile-images .border:nth-child(2) {
    z-index: 2;
}
.viewer-popout-container .total-viewers .inner-container .viewer-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.viewer-popout-container {
    @include pt-16;
    @include ph-24;
    @include pb-24;
}

.viewer-avatar-mobile-wrapper {
    width: 1.5rem;
    height: 1.5rem;
    @include br-999;
    overflow: hidden;
    @include flex-center;
    @include relative;
}
.viewer-avatar-mobile-wrapper .avatar {
    @include absolute;
    top: 0;
    left: 0;
}

.viewer-popout-container .header p {
    font-size: 1.125rem;
    letter-spacing: -0.02em;
    line-height: 150%;
    @include fw-600;
    @include opteo-black;
}
.viewer-popout-container .total-viewers {
    @include pv-16;
    @include pl-16;
    @include pr-20;
    @include container;
    @include br-12;
    min-width: 13.4375rem;
    max-height: 10.25rem;
    overflow: hidden;
    overflow-y: scroll;
}

.viewer-popout-container .total-viewers .inner-container,
.live-link-footer .view-tag .inner-container {
    @include flex;
    @include items-center;
}

:deep(.o-button.o-button--success) {
    background-color: $opteo-blue;
}
:deep(.o-button.o-button--success:focus) {
    outline: none;
    box-shadow:
        0 0 0 3px $opteo-blue-shadow,
        0 2px 4px -2px rgba(2, 2, 12, 0.1);
}

.live-link-footer .buttons {
    @include flex;
    @include items-center;
}

.live-link-footer .delete-button {
    width: 2.75rem !important;
    height: 2.75rem !important;
}

// Rsa button adjustments
:deep(.rsa-button) {
    border-radius: 0.75rem;
}
:deep(.rsa-button.double .left),
:deep(.rsa-button.double .right) {
    padding: 0.75rem 0.9375rem;
}
:deep(.rsa-button.double .divider) {
    height: 3rem;
}
:deep(.rsa-button.double .left .icon-slot) {
    width: 1.5rem;
}
:deep(.rsa-button.double .right .icon-slot) {
    width: 1.25rem;
    @include flex-center;
}
:deep(.rsa-button.double .left:focus),
:deep(.rsa-button.double .right:focus) {
    border-radius: 0.75rem;
}

@media screen and (max-width: $mq-767-max) {
    .live-link-footer-container {
        max-width: 26.25rem;
        @include ph-12;
        position: fixed;
        bottom: 0.75rem;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10000;
    }
    .live-link-footer {
        height: 5rem;
        @include pr-16;
        @include pl-24;
    }
    .live-link-footer .title-container .title {
        font-size: 16px;
        letter-spacing: -0.015rem;
        line-height: 150%;
        @include fw-600;
        @include opteo-black;
        white-space: nowrap;
    }
    .live-link-footer .title-container .created-date {
        white-space: nowrap;
    }
}
@media screen and (max-width: $mq-375-max) {
}
@media screen and (max-width: $mq-320-max) {
}
</style>
