<template>
    <ImprovementView
        :pushMessages="pushMessages"
        :pushActionText="pushAction"
        :onPush="onPush"
        :steps="adjustSteps"
        :onStepComplete="onStepComplete"
    >
        <ImprovementContent>
            <ImprovementHeader title="Write New Ad">
                <EntityPill :content="adgroupLabel" type="adgroup" />
                <Spacer height="2rem" />
                The ad group listed above only contains one ad. Click
                <Ent class="opteo-blue">Open Ad Writer</Ent> to create a new ad for this ad group.
                Writing new ads is an essential part of testing for the best performing creative
                approaches and incrementally improving account performance.
            </ImprovementHeader>
            <ImprovementHelpLink path="/en/articles/900356-write-new-ad">
                Click here to learn more about the benefits of split testing ad creative.
            </ImprovementHelpLink>
        </ImprovementContent>

        <ImprovementContent :adWidth="true">
            <Spacer height="0.75rem" />
            <TextAd
                :ad="currentAd"
                :featured-statistics="[
                    {
                        name: 'CTR',
                        content: unref(usePercent({ value: ctr / 100 }).displayValue),
                        color: 'blue',
                    },
                    {
                        name: 'CPI',
                        content: unref(
                            usePercent({ value: cost / impressions / 100 }).displayValue
                        ),
                        color: 'blue',
                    },
                    { name: 'Clicks', content: `${clicks}`, color: 'blue' },
                    {
                        name: 'Abs. Top Impr. Share',
                        content: unref(usePercent({ value: impr_abs_top }).displayValue),
                        color: 'blue',
                    },
                ]"
                :title-tag="{ title: 'Current Ad', classes: 'opteo-blue' }"
            />
            <Spacer height="3rem" />
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="60" />
        </ImprovementContent>

        <template v-slot:adjust-step-1>
            <AdWriter
                ref="adWriter"
                :adgroup-id="+adGroupId"
                :improvement-id="improvementId"
                :domain-id="domainId"
                :campaign-id="+campaignId"
                :competitor-terms="topSearchTerms"
                @ad-writer-resolve="setNewAd"
            >
            </AdWriter>
        </template>

        <template v-slot:adjust-step-2>
            <ImprovementContent>
                <ImprovementHeader title="Preview New Ad" />
            </ImprovementContent>

            <ImprovementContent :adWidth="true">
                <TextAd
                    :ad="{
                        headlineOne: `${newAd.headline_part_1}`,
                        headlineTwo: `${newAd.headline_part_2}`,
                        headlineThree: `${newAd.expanded_text_ad_headline3 ?? ''}`,
                        descriptionOne: `${newAd.description}`,
                        descriptionTwo: `${newAd.expanded_text_ad_description2 ?? ''}`,
                        displayUrl: `${root_domain}`,
                        pathOne: `${newAd.path_1 ?? ''}`,
                        pathTwo: `${newAd.path_2 ?? ''}`,
                    }"
                >
                </TextAd>
            </ImprovementContent>
            <ImprovementContent>
                <Spacer height="3rem" />
                <Text as="p">
                    Clicking
                    <Text as="span" weight="600" color="green">{{ pushActionText }}</Text> will push
                    this ad to your Google Ads account. Once pushed to your account, your ad will
                    compete with any other ads contained in this ad group.
                </Text>
                <Spacer height="2rem" />
                <ul class="ad-preview-steps">
                    <li>
                        <SmallCheck />
                        <Spacer width="1rem" />
                        <Text as="p">
                            Make sure to double-check your ad for spelling mistakes
                        </Text>
                    </li>
                    <li>
                        <SmallCheck />
                        <Spacer width="1rem" />
                        <Text as="p">
                            Data speaks louder than intuition. Push your ad and see what happens.
                        </Text>
                    </li>
                    <li>
                        <SmallCheck />
                        <Spacer width="1rem" />
                        <Text as="p">
                            Opteo will detect if this ad performs better than the competition.
                        </Text>
                    </li>
                    <li>
                        <SmallCheck />
                        <Spacer width="1rem" />
                        <Text as="p">For now, sit back and wait for the results.</Text>
                    </li>
                </ul>
            </ImprovementContent>
        </template>
    </ImprovementView>
</template>
<script lang="ts">
import {
    ImprovementHeader,
    ImprovementContent,
    ImprovementNote,
    ImprovementCreatedDate,
    Link,
    oInput,
    oForm,
    oButton,
    TextAd,
    Text,
    Spacer,
    EntityPill,
} from '@opteo/components-next'

import ImprovementView from '@/components/improvement/ImprovementView.vue'
import Ent from '@/components/util/Ent.vue'
import { useWriteAd } from '@/composition/improvement/types/useWriteAd'
import AdWriter from '@/composition/improvement/components/ad-writer/AdWriter.vue'
import ImprovementCopy from '@/components/improvement/ImprovementCopy.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import SmallCheck from '@/components/global/SmallCheck.vue'
import useMediaQuery from '@/composition/global/useMediaQuery'

export default {
    name: 'WriteAd',
    components: {
        ImprovementView,
        ImprovementHeader,
        ImprovementContent,
        ImprovementNote,
        ImprovementCopy,
        ImprovementHelpLink,
        ImprovementCreatedDate,
        Link,
        Ent,
        oInput,
        oForm,
        oButton,
        AdWriter,
        TextAd,
        Text,
        Spacer,
        EntityPill,
        SmallCheck,
    },
    setup() {
        return { ...useWriteAd(), ...useMediaQuery() }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.ad-preview-steps {
    @include container;
    @include ph-32;
    @include pv-28;
}
.ad-preview-steps li {
    @include flex;
    @include items-center;
    @include mb-12;
}
.ad-preview-steps li:last-child {
    @include mb-0;
}
</style>
