<template>
    <div>
        <ImprovementView
            :push-messages="pushMessages"
            :push-action-text="pushActionText"
            :steps="adjustSteps"
            :on-push="onPush"
        >
            <!-- Description -->
            <ImprovementContent>
                <ImprovementHeader :title="title">
                    <div v-if="aboveMobile">
                        <EntityPill type="adgroup" :content="improvement.body.adgroup" />
                        <Spacer width="100%" height="2rem" />
                    </div>
                    <Text as="p">
                        Opteo has detected a potential misspelling in the ad group listed above.
                        Click the
                        <Text as="span" weight="600" color="blue ">{{ adjustSteps[0] }}</Text>
                        button to view the suggested correction and make changes where needed. If
                        this improvement is a false positive, feel free to click <b>Dismiss</b>.
                    </Text>
                </ImprovementHeader>
                <ImprovementHelpLink path="/en/articles/902684-check-ad-spelling">
                    Click here to learn how Opteo detects and flags spelling errors.
                </ImprovementHelpLink>
            </ImprovementContent>

            <ImprovementContent :adWidth="true">
                <Spacer height="0.75rem" />
                <TextAd
                    :ad="flaggedAdContent"
                    :title-tag="{ title: 'FLAGGED AD', classes: 'opteo-red' }"
                >
                </TextAd>
            </ImprovementContent>

            <Spacer height="3rem" />
            <ImprovementContent :adWidth="true">
                <TextAd
                    :ad="adContent"
                    :title-tag="{ title: 'RECOMMENDED CHANGE', classes: 'opteo-green' }"
                >
                </TextAd>
            </ImprovementContent>

            <Spacer height="3rem" />
            <ImprovementContent>
                <ImprovementStatistics :items="statItems">
                    <template v-slot:cost>
                        Over the last 30 days, this misspelled ad cost
                        <b><Money :value="improvement.body.cost" /></b>.
                    </template>
                    <template v-slot:ctr>
                        The misspelled ad achieved a CTR of
                        <b><Percent :value="improvement.body.ctr" /></b> over the last 30 days.
                    </template>
                    <template v-slot:clicks>
                        Over the last 30 days, this misspelled ad got
                        <b>{{ improvement.body.clicks }}</b> clicks.
                    </template>
                    <template v-slot:impressions>
                        Over the last 30 days, this misspelled ad got
                        <b>{{ improvement.body.impressions }}</b> impressions.
                    </template>
                </ImprovementStatistics>
            </ImprovementContent>

            <ImprovementContent>
                <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="30" />
            </ImprovementContent>

            <template v-slot:adjust-step-1>
                <ImprovementContent>
                    <ImprovementHeader title="Update Ad Creative">
                        <Text>
                            Review the suggested edits below and make any further changes if needed.
                            When you're happy with your new ad, click
                            <Text as="span" weight="600" color="green">{{ pushActionText }}.</Text>
                            This will create the ad previewed below and pause the flagged ad from
                            the previous stage.
                        </Text>
                    </ImprovementHeader>
                </ImprovementContent>

                <ImprovementContent :adWidth="true">
                    <Spacer height="0.75rem" />
                    <TextAd
                        :ad="rawAdContent"
                        :title-tag="{
                            title: 'UPDATED AD PREVIEW',
                            classes: 'opteo-green',
                        }"
                    >
                    </TextAd>
                </ImprovementContent>

                <Spacer height="3rem" />

                <ImprovementContent>
                    <oForm class="spelling-form">
                        <oInput
                            v-model="displayedAdText.headline1.raw"
                            type="text"
                            name="headline1"
                            ref="headline1"
                            label="Headline One"
                            :validator="adFieldValidator"
                            :max-characters="30"
                            required
                            @updated="updateDisplayedAdText($event, 'headline1')"
                        />

                        <Spacer height="1.5rem" />
                        <oInput
                            v-model="displayedAdText.headline2.raw"
                            type="text"
                            name="headline2"
                            ref="headline2"
                            label="Headline Two"
                            :validator="adFieldValidator"
                            :max-characters="30"
                            required
                            @updated="updateDisplayedAdText($event, 'headline2')"
                        />

                        <Spacer height="1.5rem" />
                        <oInput
                            v-model="displayedAdText.headline3.raw"
                            type="text"
                            name="headline3"
                            ref="headline3"
                            label="Headline Three"
                            :validator="adFieldValidator"
                            :max-characters="30"
                            required
                            @updated="updateDisplayedAdText($event, 'headline3')"
                        />

                        <Spacer height="1.5rem" />
                        <oInput
                            v-model="displayedAdText.description.raw"
                            type="textarea"
                            name="description"
                            ref="description"
                            label="Description One"
                            :validator="adFieldValidator"
                            :max-characters="90"
                            required
                            @updated="updateDisplayedAdText($event, 'description')"
                        />

                        <Spacer height="1.5rem" />
                        <oInput
                            v-model="displayedAdText.description2.raw"
                            type="textarea"
                            name="description2"
                            ref="description2"
                            label="Description Two"
                            :validator="adFieldValidator"
                            :max-characters="90"
                            @updated="updateDisplayedAdText($event, 'description2')"
                        />
                    </oForm>
                </ImprovementContent>
            </template>
        </ImprovementView>
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

import {
    ImprovementHeader,
    ImprovementContent,
    Percent,
    ImprovementNote,
    ImprovementStatistics,
    ImprovementCreatedDate,
    EntityPill,
    EntityPillList,
    Spacer,
    Text,
    TextAd,
    oForm,
    oInput,
} from '@opteo/components-next'

import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import Money from '@/components/global/oMoney.vue'
import Ent from '@/components/util/Ent.vue'
import useMediaQuery from '@/composition/global/useMediaQuery'

import { useCheckAdSpelling } from '@/composition/improvement/types/useCheckAdSpelling'

export default defineComponent({
    name: 'CheckAdSpelling',
    setup() {
        return { ...useCheckAdSpelling(), ...useMediaQuery() }
    },
    components: {
        ImprovementHeader,
        ImprovementContent,
        ImprovementHelpLink,
        Percent,
        Money,
        ImprovementNote,
        ImprovementStatistics,
        ImprovementCreatedDate,
        ImprovementView,
        Ent,
        EntityPill,
        EntityPillList,
        Spacer,
        Text,
        TextAd,
        oForm,
        oInput,
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.remaining-chars-length.wrong {
    @include opteo-red;
}

.remaining-chars-length {
    @include f-8;
    float: right;
}
.spelling-form {
    @include container;
    @include pa-32;
}

:deep(.spelling-error) {
    background-color: $opteo-red-shadow;
}
:deep(.spelling-correction) {
    background-color: $opteo-green-shadow;
}
:deep(.spelling-error),
:deep(.spelling-correction) {
    @include br-4;
    padding-left: 0.125rem;
    padding-right: 0.125rem;
}
</style>
