<template>
    <ImprovementView :push-messages="pushMessages" :push-action-text="pushActionText">
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <div v-if="aboveMobile">
                    <EntityPill :type="entityPill.type" :content="entityPill.content" />
                    <Spacer height="2rem" />
                </div>
                <Text as="p">
                    The placement exclusion list above has yet to be applied to the campaigns below.
                    Click <Text as="span" color="green" weight="600">{{ pushActionText }}</Text> to
                    apply this exclusion list to the following campaign<span
                        v-if="campaignNames.length > 1"
                        >s</span
                    >:
                </Text>
                <Spacer height="2rem" />
                <div v-if="campaignNames.length === 1">
                    <EntityPillList
                        v-if="!aboveMobile && campaignNames[0].length > 30"
                        :items="[{ type: 'campaign', content: campaignNames[0] }]"
                    />
                    <EntityPill v-else :type="'campaign'" :content="campaignNames[0]" />
                </div>
                <EntityPillList
                    v-else
                    :items="campaignNames.map(name => ({ content: name, type: 'campaign' }))"
                />
            </ImprovementHeader>
            <ImprovementHelpLink path="/en/articles/2804818-sync-campaigns-to-exclusion-list">
                Click here to learn more about syncing exclusion lists with new campaigns.
            </ImprovementHelpLink>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="30" />
        </ImprovementContent>
    </ImprovementView>
</template>

<script lang="ts">
import {
    ImprovementHeader,
    ImprovementContent,
    ImprovementCreatedDate,
    Text,
    oButton,
    Spacer,
    Ent,
    EntityPill,
    EntityPillList,
} from '@opteo/components-next'

import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import useMediaQuery from '@/composition/global/useMediaQuery'

import { useResyncExclusionLists } from '@/composition/improvement/types/useResyncExclusionLists'

export default {
    name: 'ResyncExclusionLists',
    components: {
        ImprovementHeader,
        ImprovementContent,
        ImprovementView,
        ImprovementHelpLink,
        ImprovementCreatedDate,
        Text,
        Spacer,
        Ent,
        oButton,
        EntityPill,
        EntityPillList,
    },
    setup() {
        return { ...useResyncExclusionLists(), ...useMediaQuery() }
    },
}
</script>

<style>
.toggle-show-all-campaign-names-button {
    top: -0.5rem;
}
</style>
