<template>
    <div v-if="isBillingLocked" class="alerts-menu-popover-empty-state">
        <div class="alerts-menu-popover-empty-state-message">
            Please reactivate your opteo subscription to unlock alerts.
        </div>
    </div>

    <perfect-scrollbar
        v-else-if="miniAlertList.length > 0"
        class="alerts-menu-popover-scroll-container"
    >
        <AlertRow v-for="alert in miniAlertList" :key="alert.id" :alert="alert" />
    </perfect-scrollbar>
    <div v-else class="alerts-menu-popover-empty-state">
        <div class="alerts-menu-popover-empty-state-message">
            Opteo is monitoring your accounts for changes. When something comes up, we’ll create an
            alert to let you know.
        </div>
    </div>
    <div v-if="miniAlertList.length > 0 && !isBillingLocked" class="view-all-alerts-button">
        <oButton @clicked="goToAllAlerts" color="blue"> View All Alerts </oButton>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { oButton, Text } from '@opteo/components-next'

import AlertRow from '@/components/Alerts/AlertRow.vue'
import LeftSidebarMenu from '@/components/User/Sidebars/LeftSidebarMenu.vue'
import { Routes } from '@/router/routes'
import { useRouter } from 'vue-router'
import { useAlerts } from '@/composition/alerts/useAlerts'
import { useSubscription } from '@/composition/billing/useSubscription'

export default defineComponent({
    name: 'AlertsSidebar',
    components: {
        oButton,
        Text,
        AlertRow,
        LeftSidebarMenu,
    },
    emits: ['route-changed'],
    setup(_, { emit }) {
        const { isBillingLocked } = useSubscription()
        const { alerts, unreadCount, miniAlertList, alertsPopoverOpen } = useAlerts()

        const router = useRouter()

        function goToAllAlerts() {
            router.push({ name: Routes.Alerts })
            alertsPopoverOpen.value = false

            emit('route-changed')
        }

        return {
            alerts,
            miniAlertList,
            unreadCount,
            alertsPopoverOpen,
            goToAllAlerts,
            isBillingLocked,
        }
    },
})
</script>

<style lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.alerts-menu-popover {
    @include fixed;
    @include bg-opteo-white;
    z-index: 99998;
    box-shadow: $opteo-shadow;
    width: 484px;
    height: 100vh;
    bottom: 0;
    left: 6rem;
    overflow: hidden;
    will-change: transform;
}

.alerts-menu-popover-scroll-container {
    height: 100vh;
    overflow-y: scroll;
    @include ph-32;
    @include pt-32;
    @include pb-16;
}
.view-all-alerts-button {
    position: absolute;
    bottom: 24px;
    left: 177px;
}
.alerts-menu-popover-empty-state {
    height: 100vh;
    @include flex;
    @include items-center;
    @include justify-center;
}
.alerts-menu-popover-empty-state-message {
    @include container;
    @include pa-32;
    @include f-9;
    @include lh-150;
    @include tc;
    @include mb-16;
    width: 18.25rem;
}
</style>
