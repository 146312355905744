<template>
    <div class="performance-mode-container">
        <Label>Conversion Actions</Label>
        <button
            class="conversion-actions-button"
            @click="showConversionActionPanel = true"
            style="width: 100%"
        >
            <Text as="span"> {{ enabledConversionActions?.length ?? 0 }} Selected </Text>
            <div class="icon">
                <TableEdit />
            </div>
        </button>
        <Spacer height="1rem" />
        <Text as="p" size="f-9" style="font-size: 0.75rem; line-height: 1.125rem" color="gray">
            Opteo will optimise towards the conversion actions you select here. Please select the
            conversion actions that most accurately represent success for your account as a whole
            and exclude any old, unused, or deprecated actions.
        </Text>
    </div>

    <!-- Conversion Actions Side Panel -->
    <Panel v-model="showConversionActionPanel" title="Conversion Actions" :width="884">
        <template #title>
            <Text as="p" color="gray" class="header-blurb">
                All Conversion Actions data taken from the past 90 days
            </Text>
        </template>

        <template #content>
            <OnboardingModal v-model="panelDescriptions" expandable :visible-lines="3">
                <template #copy>
                    <b>Select your primary conversion actions.</b> Primary conversion actions
                    represent the most important outcomes for your business and are usually directly
                    responsible for revenue. Opteo will optimise towards the conversion actions
                    selected below, attempting to generate more of these conversions at a
                    below-target rate.
                    <Spacer height="1rem" />
                    Secondary conversion actions represent goals for your business that are
                    <b>not</b> directly responsible for revenue. An example of a secondary
                    conversion action might be a website visitor adding their email address to your
                    marketing list. If you intend to generate email signups, you may want to select
                    these actions.
                </template>
            </OnboardingModal>
            <Spacer height="2rem" />
            <oTable
                :headers="conversionActionTableHeaders"
                :items="conversionActionTableItems"
                :border-radius="20"
                fixed-layout
            >
                <template #header.action>
                    <div class="header-first-column-wrapper">
                        <oInput
                            type="checkbox"
                            label="Conversion Action"
                            :modelValue="allConversionsChecked"
                            @click="toggleAllConversions"
                            class="header-checkbox"
                        />
                    </div>
                </template>
                <template #column.action="cellData">
                    <div class="table-cell">
                        <Tooltip
                            placement="top-start"
                            :offset="[94, -20]"
                            :delay="[720, 0]"
                            :max-width="1200"
                            :content="cellData.value?.length > 44 ? cellData.value : ''"
                        >
                            <oInput
                                type="checkbox"
                                :name="`checkbox${cellData.row.actionId}`"
                                :modelValue="cellData.row.enabled"
                                @updated="updateConversionAction(cellData.row.actionId, $event)"
                                class="conv-actions-checkbox"
                            />
                            <Spacer width="2.625rem" />
                            <EntityPill
                                type="conversion-action"
                                :tooltip="false"
                                :content="cellData.value"
                            />
                        </Tooltip>
                    </div>
                </template>
                <template #column.conversions="cellData">
                    <Number :value="Math.round(cellData.value ?? 0)" />
                </template>
                <template #column.conversionsValue="cellData">
                    <Money :value="cellData.value ?? 0" />
                </template>
            </oTable>
        </template>
        <template #footer>
            <oButton
                ref="updateConversionActionsButton"
                size="extra-large"
                color="dark-blue"
                :loading="updatingConversionActions"
                @clicked="updateAccountConversionActions"
            >
                Save Conversion Actions
                <template #icon>
                    <svg width="12" height="12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1 11 11 1M11 1H3M11 1v8"
                            stroke="#fff"
                            stroke-width="2"
                            stroke-linecap="round"
                        />
                    </svg>
                </template>
            </oButton>
        </template>
    </Panel>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'

import {
    oInput,
    oButton,
    Text,
    Panel,
    oTable,
    Number,
    Tooltip,
    OnboardingModal,
    Spacer,
    Label,
    TableEdit,
    EntityPill,
} from '@opteo/components-next'
import { authRequest, Endpoint, useAPI } from '@/composition/api/useAPI'
import { Targets } from '@opteo/types'
import Money from '../global/oMoney.vue'
import { useAccountList } from '@/composition/user/useAccountList'
import { delay } from '@opteo/promise'

const { mutateDomainList } = useAccountList()

const { data: accountConversionActions, mutate: mutateAccountConversionActions } = useAPI<
    Targets.AccountConversionAction[]
>(Endpoint.GetAccountConversionActions)

const updatingConversionActions = ref(false)
const updateConversionActionsButton = ref()

async function updateAccountConversionActions() {
    const conversionActionsNeedUpdating = conversionActionTableItems.value?.filter(ca => {
        const originalCa = accountConversionActions.value?.find(
            oca => oca.conversionActionId === ca.actionId
        )
        return originalCa && originalCa.enabled !== ca.enabled
    })

    if (!conversionActionsNeedUpdating?.length) {
        updateConversionActionsButton.value?.flashSuccess()
        await delay(1000)

        showConversionActionPanel.value = false
        return
    }

    updatingConversionActions.value = true

    const enabledConversionIds = conversionActionsNeedUpdating
        .filter(ca => ca.enabled)
        .map(ca => ca.actionId)

    const disabledConversionIds = conversionActionsNeedUpdating
        .filter(ca => !ca.enabled)
        .map(ca => ca.actionId)

    await Promise.all([
        enabledConversionIds.length
            ? authRequest(Endpoint.UpdateAccountConversionActions, {
                  body: {
                      conversion_action_ids: enabledConversionIds,
                      enabled: true,
                  },
              })
            : undefined,
        disabledConversionIds.length
            ? authRequest(Endpoint.UpdateAccountConversionActions, {
                  body: {
                      conversion_action_ids: disabledConversionIds,
                      enabled: false,
                  },
              })
            : undefined,
    ])

    if (conversionActionsNeedUpdating?.length) {
        await mutateAccountConversionActions()
        await mutateDomainList()
    }

    updatingConversionActions.value = false
    updateConversionActionsButton.value?.flashSuccess()

    await delay(1000)

    showConversionActionPanel.value = false
}

// Conversion Actions
const showConversionActionPanel = ref(false)

const conversionActionTableItems = ref<
    {
        action: string
        actionId: number
        enabled: boolean
        category: string
        conversions: number
        conversionsValue: number
        type: 'Primary' | 'Secondary'
    }[]
>([])

watch(accountConversionActions, () => {
    conversionActionTableItems.value =
        accountConversionActions.value?.map(row => {
            const type: 'Primary' | 'Secondary' = row.isPrimary ? 'Primary' : 'Secondary'
            return {
                action: row.conversionActionName,
                actionId: row.conversionActionId,
                enabled: !!row.enabled,
                category: row.conversionActionCategory,
                conversions: row.conversions ?? 0,
                conversionsValue: row.conversionsValue ?? 0,
                type,
            }
        }) ?? []
})

const enabledConversionActions = computed(
    () => accountConversionActions.value?.filter(item => item.enabled)
)

const conversionActionTableHeaders = [
    {
        key: 'action',
        text: 'Conversion Action',
        sortable: false,
        noPadding: true,
    },
    {
        key: 'conversions',
        text: 'Conv.',
        sortable: true,
        noPadding: true,
        width: 108,
    },
    {
        key: 'conversionsValue',
        text: 'Value',
        sortable: true,
        noPadding: true,
        width: 116,
    },
    {
        key: 'type',
        text: 'Type',
        sortable: true,
        noPadding: true,
        width: 112,
    },
]

const panelDescriptions = ref(true)

const allConversionsChecked = computed(
    () =>
        conversionActionTableItems.value?.filter(ca => ca.enabled).length ===
        conversionActionTableItems.value?.length
)

const toggleAllConversions = () => {
    const enabled = !allConversionsChecked.value
    conversionActionTableItems.value?.forEach(conv => {
        updateConversionAction(conv.actionId, enabled)
    })
}

const updateConversionAction = (conversionActionId: number, value: boolean) => {
    const conversionAction = conversionActionTableItems.value?.find(
        ca => ca.actionId === conversionActionId
    )

    if (!conversionAction) throw new Error('Invalid conversion action')

    conversionAction.enabled = value
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';
.section-content .performance-mode-container,
.settings-section .performance-mode-container {
    @include pa-32;
    @include pb-28;
}

.update-button-container {
    @include flex-center;
    @include ph-32;
    padding-top: 1.625rem;
    @include pb-4;
}

// Conversion Actions Button
.conversion-actions-button {
    @include container;
    @include bg-opteo-white;
    border-radius: 0.875rem;
    outline: none;
    border: none;
    @include flex-center;
    @include justify-between;
    height: 2.75rem;
    padding: 0 0.875rem 0 0.9375rem;
    transition:
        transform 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        background 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        box-shadow 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        opacity 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        color 0.25s cubic-bezier(0.19, 1, 0.22, 1);
    cursor: pointer;
}

.conversion-actions-button:focus {
    outline: none;
    box-shadow: $opteo-shadow-focus;
}

.conversion-actions-button:active {
    outline: none;
    box-shadow: $opteo-shadow-focus;
    transform: translate3d(0, 1px, 0);
}
.conversion-actions-button span {
    font-size: 0.875rem;
    line-height: 1.5rem;
    letter-spacing: -0.0175rem;
    transform: translateY(-1px);
    white-space: nowrap;
}
.conversion-actions-button .icon {
    @include container;
    width: 1.5rem;
    height: 1.5rem;
    @include br-999;
    @include flex-center;
}
.campaigns-campaign-checkbox::after,
.conv-actions-checkbox::after {
    content: '';
    background: white;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 62.5%);
    position: absolute;
    top: 0;
    right: -1.5rem;
    height: 100%;
    width: 6rem;
    z-index: 2;
}
.conv-actions-checkbox::after {
    right: 0;
}

.table-cell {
    overflow: hidden;
    cursor: pointer;
}
.table-cell .table-entity {
    @include absolute;
    left: 3.5rem;
}
.table-cell :deep(.tooltip) {
    @include flex;
    @include items-center;
    position: relative;
    gap: 0.875rem;
    height: 4.5rem;
}
.table-cell :deep(.o-input__label) {
    cursor: pointer;
}

.conv-actions-checkbox {
    @include pl-24;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    @include absolute;
    @include flex;
    @include items-center;
    @include z-9;
}

.conv-actions-checkbox :deep(.o-input--checkbox),
.conv-actions-checkbox :deep(.o-input__checkbox) {
    width: 100%;
    height: 100%;
}

.header-blurb {
    font-size: 0.75rem;
    line-height: 1.125rem;
    letter-spacing: -0.0025rem;
    width: 11.5rem;
    @include container;
    border-radius: 0.875rem;
    padding: 0.6875rem 1rem;
}

.performance-mode-container .divider {
    width: 100%;
    height: 1px;
    @include bg-opteo-light-gray;
}

.header-first-column-wrapper {
    @include flex;
    @include items-center;
    height: 1.125rem;
    @include pl-24;
}
.header-checkbox {
    height: 1.125rem;
}
.header-checkbox :deep(.o-input__text) {
    margin-left: 0.875rem;
}
</style>
