import max from 'lodash-es/max'

import { onMounted, ref, watch } from 'vue'
import { authRequest, Endpoint, useAPI } from '../api/useAPI'
import { useAccountList } from './useAccountList'
import { useUser } from './useUser'
import { useLayouts } from '../layouts/useLayouts'
import { LS_AC_MEGASPLASH_JUST_SEEN } from '@/lib/cookies'
import { getPlatformFromId } from '@/lib/globalUtils'
import { Platform } from '@opteo/types'

interface AccountWithCampaigns {
    accountId: number
    name: string
    accountCampaigns: { campaign: string }[]
}

interface AccountCampaign {
    accountName: string
    campaignName: string
}

export function useMegasplash() {
    const { userInfo } = useUser()

    useLayouts('full-screen')

    const { data: accountWithCampaigns } = useAPI<AccountWithCampaigns[]>(
        Endpoint.GetCampaignsSample
    )

    let loadingTime = 180_000 // milliseconds
    let remainingTime = loadingTime * 0.001

    const isDisabled = ref(true)
    const timeRemaining = ref(secondsToMinutes(remainingTime))
    const progress = ref(1)
    const importMessage = ref('Starting import...')
    const remainingString = ref('minutes')
    const progressBar = ref<HTMLDivElement>()
    const uploadedImageUrl = ref('')

    const reloadMessages = (campaigns: AccountCampaign[], progress: number) => {
        let index = 0
        const changeMessage = () => {
            const campaign = campaigns[index]

            if (!campaign || progress < 90) {
                importMessage.value = 'Setting up your Opteo account...'
                clearInterval(messagesInterval)
            } else {
                importMessage.value = `Importing ${campaign.campaignName} from ${campaign.accountName}`
            }
            index++
        }

        const messagesInterval = setInterval(changeMessage, 1000)
    }

    const startProgressBar = () => {
        startTimer()
        let amount = 1
        progress.value = 1

        const barInterval = setInterval(makeProgress, loadingTime / 100)

        function makeProgress() {
            if (amount === 50) {
                markMegasplashSeen()
            }

            if (amount >= 100) {
                clearInterval(barInterval)
                isDisabled.value = false
            } else {
                amount++

                if ([10, 35, 60].includes(amount)) {
                    progress.value += 10
                } else if (
                    !(amount > 10 && amount < 20) &&
                    !(amount > 35 && amount < 45) &&
                    !(amount > 60 && amount < 70)
                ) {
                    progress.value++
                }

                if (progress.value > 3) {
                    progressBar.value!.style.width = progress.value + '%'
                }
            }
        }
    }

    const startTimer = () => {
        const timerInterval = setInterval(() => {
            if (remainingTime <= 0) {
                clearInterval(timerInterval)
            } else {
                remainingTime--
                if (remainingTime === 59) {
                    remainingString.value = 'seconds'
                }

                timeRemaining.value = secondsToMinutes(remainingTime)
            }
        }, 1000)
    }

    const reloadApp = () => {
        // avoid showing megasplash *again* while refreshing user info
        localStorage.setItem(LS_AC_MEGASPLASH_JUST_SEEN, '1')
        window.location.href = '//' + window.location.host
    }

    const accountCampaigns: AccountCampaign[] = []

    watch(
        () => accountWithCampaigns.value,
        (current, previous) => {
            if (current?.length === previous?.length) {
                return
            } else {
                importMessage.value = 'Importing Google Ads Accounts...'
            }
            for (const account of accountWithCampaigns?.value!) {
                for (const campaign of account.accountCampaigns) {
                    accountCampaigns.push({
                        accountName: account.name,
                        campaignName: campaign.campaign,
                    })
                }
            }
        }
    )

    onMounted(() => {
        // TODO: add seen_megasplash to luckymetrics types
        // @ts-ignore
        if (!userInfo.value?.seen_megasplash) {
            removeOutOfPlanDomains()
        }

        reloadMessages(accountCampaigns, 100 - progress.value)
        startProgressBar()
    })

    return {
        isDisabled,
        timeRemaining,
        progress,
        importMessage,
        progressBar,
        remainingString,
        reloadApp,
        uploadedImageUrl,
        userInfo,
    }
}

function removeOutOfPlanDomains() {
    return authRequest(Endpoint.RemoveOutOfPlanDomains)
}

function markMegasplashSeen() {
    return authRequest(Endpoint.MarkMegasplashSeen)
}

function secondsToMinutes(seconds: number): string {
    return (
        max([0, Math.floor(seconds / 60)]) +
        ':' +
        ('0' + max([0, Math.floor(seconds % 60)])).slice(-2)
    )
}
