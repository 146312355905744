<template>
    <div>
        <ImprovementView
            :push-messages="pushMessages"
            :push-action-text="pushActionText"
            :is-adjustable="true"
            :on-push="onPush"
        >
            <ImprovementContent>
                <ImprovementHeader :title="title">
                    <div v-if="aboveMobile">
                        <EntityPill :content="campaignName" type="campaign" />
                        <Spacer height="2rem" />
                    </div>

                    <Text as="p">
                        In the campaign listed above, searchers in the <b>{{ gender }}</b> group are
                        converting at an average cost of <b>{{ statsCpa }}</b> per conversion. In
                        comparison, the parent campaign has an average cost per conversion of
                        <b>{{ statsCampaignCpa }}</b
                        >. This means that searchers in the <b>{{ gender }}</b> group cost
                        <Text as="span" weight="600" color="red">
                            <Percent :value="genderCpaToCampaignRatio" />
                        </Text>
                        more to convert than the campaign average.
                        <!-- Spacer -->
                        <Spacer height="1rem" />
                        Opteo recommends applying a
                        <Text as="span" weight="600" color="red">
                            <Percent :value="-negativeBidMod / 100" :include-sign="true" />
                        </Text>
                        bid adjustment to this segment. This should reduce overall campaign CPA and
                        limit wasted spend. Click
                        <Text as="span" weight="600" color="green">{{ pushActionText }}</Text> to
                        apply this change. To use a different adjustment percentage, click
                        <b>Adjust</b>.
                    </Text>
                </ImprovementHeader>
                <ImprovementHelpLink path="/en/articles/902679-adjust-demographic-bids">
                    Click here to learn more about demographic bid adjustments in Opteo.
                </ImprovementHelpLink>
            </ImprovementContent>

            <ImprovementContent>
                <oTable
                    :headers="TableHeaders"
                    :items="TableItems"
                    responsive-mode="columns"
                    responsive-breakpoint="768px"
                    order-by="difference"
                    order="DESC"
                >
                    <template v-slot:column.gender="cell">
                        <Text v-if="cell.value === gender" as="span" color="blue" weight="500">
                            {{ cell.value }}
                        </Text>
                        <span v-else>
                            {{ cell.value }}
                        </span>
                    </template>
                    <template v-slot:column.cost="cell">
                        <Money :value="cell.value" :currency="domainCurrencyCode" />
                    </template>
                    <template v-slot:column.cpa="cell">
                        <Money
                            v-if="cell.value > 0"
                            :value="cell.value"
                            :currency="domainCurrencyCode"
                        />
                        <span v-else>N/A</span>
                    </template>
                    <template v-slot:column.average-cpa="cell">
                        <Money :value="cell.value" :currency="domainCurrencyCode" />
                    </template>
                    <template v-slot:column.difference="cell">
                        <Text v-if="cell.value > 0" as="span" color="red" weight="500">
                            +<Percent :value="cell.value" />
                        </Text>
                        <Text v-if="cell.value === 0" as="span" color="black" weight="400">
                            <Percent :value="cell.value" />
                        </Text>
                        <Text
                            v-if="cell.value < 0 && cell.value > -1"
                            as="span"
                            color="green"
                            weight="500"
                        >
                            <Percent :value="cell.value" />
                        </Text>
                        <Text v-else-if="cell.value === -1" as="span" color="black" weight="400">
                            N/A
                        </Text>
                    </template>
                </oTable>
            </ImprovementContent>

            <ImprovementContent>
                <ImprovementStatistics
                    :items="[
                        {
                            key: 'segmentCPA',
                            value: statsCpa,
                            title: 'Segment CPA',
                        },
                        {
                            key: 'campaignCPA',
                            value: statsCampaignCpa,
                            title: 'Campaign CPA',
                        },
                        {
                            key: 'campaignGroupCPA',
                            value: statsBucketCpa,
                            title: 'Campaign Group CPA',
                        },
                    ]"
                >
                    <template #segmentCPA>
                        Over the last <b>60</b> days, the <b>{{ gender }}</b> group has an average
                        CPA of <b>{{ statsCpa }}</b
                        >. Please note, this CPA is based on conversion types set in Campaign
                        Groups.
                    </template>
                    <template #campaignCPA>
                        In comparison, the average CPA for this campaign is
                        <b>{{ statsCampaignCpa }}</b> over the same time period. This means that
                        searchers in the <b>{{ gender }}</b> group cost
                        <b><Percent :value="genderCpaToCampaignRatio" /> more</b> to convert than
                        the campaign average.
                    </template>
                    <template #campaignGroupCPA>
                        <span v-if="usingTargetCpa">
                            The parent campaign group
                            <b>{{ campaignGroupName }}</b> has a Target CPA of
                            <b><Money :value="bucketCpa" :currency="domainCurrencyCode" /></b>. This
                            means, in the campaign at the top of the page, searchers in the
                            <b>{{ gender }}</b> group cost
                            <b><Percent :value="genderCpaToCampaignGroupRatio" /> more</b> to
                            convert than the campaign group Target CPA.
                        </span>
                        <span v-else>
                            The parent campaign group
                            <b>{{ campaignGroupName }}</b> has an average CPA of
                            <b>
                                <Money :value="bucketCpa" :currency="domainCurrencyCode" />
                            </b>
                            over the last <b>60</b> days. This means, in the campaign at the top of
                            the page, searchers in the <b>{{ gender }}</b> group cost
                            <b><Percent :value="genderCpaToCampaignGroupRatio" /> more</b>
                            to convert than the campaign group average.
                        </span>
                    </template>
                </ImprovementStatistics>
            </ImprovementContent>

            <ImprovementContent>
                <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="60" />
            </ImprovementContent>

            <template v-slot:adjust-step-1>
                <ImprovementContent>
                    <ImprovementBidAdjustment
                        ref="impBidAdjustmentRef"
                        bid-type="gender"
                        :current-bid="adjustedBid"
                        :new-bid="currentBidModRelativeToUnitBid"
                        @on-bid-update="onBidUpdate"
                    >
                        <template v-slot:entity-pill>
                            <EntityPill
                                v-if="aboveMobile"
                                :content="campaignName"
                                type="campaign"
                            />
                        </template>
                        <template v-slot:text>
                            In the campaign listed above, searchers in the <b>{{ gender }}</b> group
                            are converting at an average cost of <b>{{ statsCpa }}</b> per
                            conversion. In comparison, the parent campaign has an average cost per
                            conversion of <b>{{ statsCampaignCpa }}</b
                            >. This means that searchers in the <b>{{ gender }}</b> group cost
                            <Text as="span" weight="600" color="red">
                                <Percent :value="genderCpaToCampaignRatio" />
                            </Text>
                            more to convert than the campaign average.
                            <!-- Spacer -->
                            <Spacer height="1rem" />
                            Opteo recommends applying a
                            <Text as="span" weight="600" color="red">
                                <Percent :value="-negativeBidMod / 100" :include-sign="true" />
                            </Text>
                            bid adjustment to this segment.
                        </template>
                    </ImprovementBidAdjustment>
                </ImprovementContent>
            </template>
        </ImprovementView>
    </div>
</template>
<script lang="ts">
import {
    ImprovementHeader,
    ImprovementContent,
    Percent,
    ImprovementStatistics,
    ImprovementCreatedDate,
    BarChart,
    Text,
    Spacer,
    EntityPill,
    EntityPillList,
    Ent,
    Money,
    oTable,
} from '@opteo/components-next'

import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import useMediaQuery from '@/composition/global/useMediaQuery'

import { useAdjustGenderBid } from '@/composition/improvement/types/useAdjustGenderBid'
import ImprovementBidAdjustment from '@/components/improvement/ImprovementBidAdjustment.vue'

export default {
    name: 'AdjustGenderBid',
    setup() {
        return { ...useAdjustGenderBid(), ...useMediaQuery() }
    },
    components: {
        ImprovementHeader,
        ImprovementHelpLink,
        ImprovementView,
        ImprovementContent,
        Percent,
        ImprovementStatistics,
        ImprovementCreatedDate,
        BarChart,
        Ent,
        Money,
        oTable,
        ImprovementBidAdjustment,
        Text,
        Spacer,
        EntityPill,
        EntityPillList,
    },
}
</script>
