<template>
    <PageContent v-if="route.name === 'toolkittools'">
        <div class="tools-grid-container">
            <div v-for="tool in tools" :key="tool.title" class="tool-grid-item">
                <div class="tool-info">
                    <div class="tool-title">
                        <Text as="h5" size="f-6" weight="600" class="tool-title">
                            {{ tool.title }}
                        </Text>
                        <div v-if="tool.new" class="new-tag">
                            <span>New</span>
                        </div>
                    </div>
                    <Spacer height="0.875rem" />
                    <Text as="p" size="f-8" class="tool-blurb">{{ tool.blurb }}</Text>
                </div>
                <div class="button-container">
                    <div v-if="tool.disabled" class="coming-soon-badge">
                        <span>Coming Soon</span>
                    </div>
                    <div v-else>
                        <router-link :to="{ name: tool.routeName }">
                            <oButton>Open Tool</oButton>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </PageContent>
    <router-view></router-view>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { oButton, Text, Spacer, ColorTag } from '@opteo/components-next'

import PageContent from '@/layouts/PageContent.vue'
import { Routes } from '@/router/routes'
import { useRoute } from 'vue-router'
import { useUser } from '@/composition/user/useUser'
export default defineComponent({
    components: { PageContent, oButton, Text, Spacer, ColorTag },
    setup() {
        const { betaFeatures } = useUser()

        const route = useRoute()
        const tools = computed(() => [
            {
                title: 'N-Gram Finder',
                blurb: 'Create negative keywords from n-grams, reduce wasted spend and improve ad relevance.',
                routeName: Routes.ToolkitNGramTool,
                disabled: !betaFeatures.value?.ngramTool,
                new: true,
            },
            {
                title: 'Scorecard',
                blurb: 'Get an account performance scorecard with easy to parse, percentage-based scores.',
                routeName: Routes.ToolkitScorecard,
            },
            {
                title: 'RSA Writer',
                blurb: 'Write ads with supporting data from competitors, Google, and other ads in your account.',
                routeName: Routes.ToolkitRsaWriter,
            },
            {
                title: 'Bidding Experiments',
                blurb: 'Create simple experiments to find the most suitable bidding strategies for your campaigns.',
                routeName: Routes.ToolkitSmartBiddingExperiments,
            },
            // {
            //     title: 'Keyword Tool',
            //     blurb: 'View your Search Term Report alongside relevant statistics and add new keywords with ease.',
            //     disabled: true,
            // },
            {
                title: 'Audience Generator',
                blurb: 'Create new audiences easily, get smart recommendations based on industry data.',
                disabled: true,
            },
            {
                title: 'Industry Benchmark',
                blurb: 'Compare performance with other accounts in the same industry, share with ease.',
                disabled: true,
            },
        ])
        return { tools, route }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.tools-grid-container {
    @include grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2.125rem;
}

.tool-grid-item {
    @include container;
    @include br-20;
    width: 100%;
    @include relative;
    padding-top: 70%;
}
.tool-title {
    @include flex;
    @include items-center;
    gap: 0.8125rem;
}
.tool-info {
    @include absolute;
    top: 1.75rem;
    left: 1.75rem;
}
.tool-blurb {
    max-width: 12.75rem;
}
.button-container {
    @include absolute;
    bottom: 1.75rem;
    right: 1.75rem;
}

.coming-soon-badge {
    @include br-999;
    @include flex;
    @include items-center;
    height: 44px;
    font-size: 0.8125rem;
    letter-spacing: -0.00625rem;
    font-weight: 400;
    padding: 0 1.4375rem;
    background: rgba(0, 109, 255, 0.1);
    color: $opteo-dark-blue;
}
.coming-soon-badge span {
    line-height: 1.25rem;
}

.new-tag {
    padding: 0.4375rem 0.75rem;
    background: radial-gradient(
        75% 75% at 8% 80%,
        rgba(222, 120, 235, 0.1) 30%,
        rgba(0, 217, 195, 0.1) 100%
    );
    border-radius: 999px;
}
.new-tag span {
    background: linear-gradient(90deg, #d164f6 0%, #00b8a5 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    @include f-9;
    font-size: 0.75rem;
    @include fw-500;
    line-height: 0.75rem;
    @include block;
}

@media (min-width: 0px) and (max-width: 768px) {
    .tools-grid-container {
        @include grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 2.125rem;
    }
}

@media (min-width: 769px) and (max-width: 1200px) {
    .tools-grid-container {
        @include grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2.125rem;
    }
}

@media (min-width: 1500px) {
    .tool-grid-item {
        padding-top: 0;
        height: 260px;
    }
}
</style>
