<template>
    <div class="frame-by-frame-loader">
        <div class="frame one">
            <ImpactStatsLoaderSkeleton1 />
        </div>
        <div class="frame two">
            <ImpactStatsLoaderSkeleton2 />
        </div>
        <div class="frame three">
            <ImpactStatsLoaderSkeleton3 />
        </div>
    </div>
</template>

<script lang="ts">
import { onMounted } from 'vue'
import anime from 'animejs/lib/anime.es.js'

import ImpactStatsLoaderSkeleton1 from './ImpactStatsLoaderSkeleton1.vue'
import ImpactStatsLoaderSkeleton2 from './ImpactStatsLoaderSkeleton2.vue'
import ImpactStatsLoaderSkeleton3 from './ImpactStatsLoaderSkeleton3.vue'

export default {
    components: {
        ImpactStatsLoaderSkeleton1,
        ImpactStatsLoaderSkeleton2,
        ImpactStatsLoaderSkeleton3,
    },
    setup() {
        onMounted(() => {
            // Set default states
            anime.set('.frame.one', {
                opacity: () => 0,
            })
            anime.set('.frame.two', {
                opacity: () => 0,
            })
            anime.set('.frame.three', {
                opacity: () => 0,
            })

            // Timeline
            const timeline = anime.timeline()
            timeline
                .add({
                    targets: '.frame.one',
                    opacity: [0, 1],
                    translateY: [16, 0],
                    delay: 500,
                    duration: 500,
                    easing: 'easeOutCubic',
                })
                .add({
                    targets: '.frame.one',
                    opacity: [1, 0],
                    translateY: [0, 16],
                    delay: 3000,
                    duration: 500,
                    easing: 'easeOutCubic',
                })
                .add({
                    targets: '.frame.two',
                    opacity: [0, 1],
                    translateY: [16, 0],
                    delay: 0,
                    duration: 500,
                    easing: 'easeOutCubic',
                })
                .add({
                    targets: '.frame.two',
                    opacity: [1, 0],
                    translateY: [0, 16],
                    delay: 3000,
                    duration: 500,
                    easing: 'easeOutCubic',
                })
                .add({
                    targets: '.frame.three',
                    opacity: [0, 1],
                    translateY: [16, 0],
                    delay: 0,
                    duration: 500,
                    easing: 'easeOutCubic',
                })
                .add({
                    targets: '.frame.three',
                    opacity: [1, 0],
                    translateY: [0, 16],
                    delay: 3000,
                    duration: 500,
                    easing: 'easeOutCubic',
                })
            // Restart timeline
            setInterval(() => {
                timeline.restart()
            }, 12500)
        })
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.frame-by-frame-loader {
    width: 42.5rem;
    height: 24.625rem;
    margin: 0 auto;
    @include relative;
}
.frame {
    @include container;
    @include br-20;
    width: 42.5rem;
    height: 24.625rem;
    overflow: hidden;
    @include absolute;
    top: 0;
    left: 0;
    will-change: transform, opacity;
}
</style>
