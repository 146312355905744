<template>
    <ImprovementView
        :push-messages="pushMessages"
        :push-action-text="pushActionText"
        :is-adjustable="true"
        :on-push="onPush"
    >
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <div v-if="aboveMobile">
                    <EntityPill type="keyword" :content="losingKeyword.keyword" />
                    <Spacer height="2rem" />
                </div>
                <Text as="p">
                    Consider pausing this keyword. It's a duplicate of another keyword and
                    performing worse than its counterpart. Pausing this keyword should reduce cost
                    and help gather more accurate performance data. Click
                    <Text as="span" color="green" weight="600">{{ pushActionText }}</Text> to
                    {{ actionCopy.infinitive.toLowerCase() }} the
                    {{ keywordCopy.toLowerCase() }} keyword. Otherwise, click <b>Adjust</b> to pause
                    the winning duplicate.
                </Text>
            </ImprovementHeader>
            <ImprovementHelpLink path="/en/articles/902939-pause-duplicate-keyword">
                Click here to learn more about pausing duplicate keywords in Opteo.
            </ImprovementHelpLink>
        </ImprovementContent>

        <ImprovementContent>
            <Text as="h3" size="f-4" weight="600">Keyword Comparison</Text>
            <Spacer height="3rem" />
            <Keyword
                :keyword="winningKeyword"
                :statistics="winningKeywordStats"
                :title-tag="{ title: 'Winning Duplicate', classes: 'opteo-green' }"
            />
            <Spacer height="3rem" />
            <Keyword
                :keyword="losingKeyword"
                :statistics="losingKeywordStats"
                :title-tag="{ title: 'Losing Duplicate', classes: 'opteo-red' }"
            />
        </ImprovementContent>

        <ImprovementContent>
            <Text as="h3" size="f-4" weight="600">Comparison Table</Text>
            <Spacer height="2rem" />
            <oTable :headers="tableHeaders" :items="tableItems">
                <!-- Winner Header -->
                <template #header.winner>
                    <Text as="span" size="f-8" weight="500" color="green">Winning Duplicate</Text>
                </template>
                <!-- Winner Header -->
                <template #header.loser>
                    <Text as="span" size="f-8" weight="500" color="red">Losing Duplicate</Text>
                </template>
                <!-- Quality Score Replace Zero -->
                <template #column.winner="cellData">
                    <div v-if="cellData.row.id === 'qs' && cellData.value === '0'">N/A</div>
                    <div v-if="cellData.row.id === 'qs' && cellData.value != '0'">
                        {{ cellData.value }}
                    </div>
                </template>
                <template #column.loser="cellData">
                    <div v-if="cellData.row.id === 'qs' && cellData.value === '0'">N/A</div>
                    <div v-if="cellData.row.id === 'qs' && cellData.value != '0'">
                        {{ cellData.value }}
                    </div>
                </template>
            </oTable>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="90" />
        </ImprovementContent>

        <template v-slot:adjust-step-1>
            <ImprovementContent>
                <ImprovementHeader title="Select Duplicate Keyword">
                    <Text as="p">
                        In certain cases, you may need to pause the winning duplicate instead of the
                        losing duplicate. To pause the winning keyword, select
                        <b>Winning</b> from the menu below.
                    </Text>
                </ImprovementHeader>
                <div class="improvement-adjustment-container">
                    <Text as="span" size="f-8">Pause the</Text>
                    <oInput
                        v-model="affectLosingDuplicate"
                        type="select"
                        :items="[
                            { value: true, label: 'Losing' },
                            { value: false, label: 'Winning' },
                        ]"
                        class="select-duplicate-select"
                    />
                    <Text as="span" size="f-8">keyword duplicate.</Text>
                </div>
            </ImprovementContent>
        </template>
    </ImprovementView>
</template>

<script lang="ts">
import {
    ImprovementHeader,
    ImprovementContent,
    ImprovementNote,
    ImprovementCreatedDate,
    EntityPill,
    Text,
    Spacer,
    Keyword,
    oTable,
    oInput,
} from '@opteo/components-next'

import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import useMediaQuery from '@/composition/global/useMediaQuery'

import { usePauseDuplicateKeywords } from '@/composition/improvement/types/usePauseDuplicateKeywords'

export default {
    name: 'PauseDuplicateKeywords',
    components: {
        ImprovementView,
        ImprovementHeader,
        ImprovementContent,
        ImprovementNote,
        ImprovementHelpLink,
        ImprovementCreatedDate,
        EntityPill,
        Text,
        Spacer,
        Keyword,
        oTable,
        oInput,
    },
    setup() {
        return { ...usePauseDuplicateKeywords(), ...useMediaQuery() }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.improvement-adjustment-container {
    @include container;
    @include ph-32;
    @include pv-28;
    @include flex;
    @include items-center;
}
.select-duplicate-select {
    width: 7rem;
}

@media screen and (min-width: $mq-0-min) and (max-width: $mq-767-max) {
    .improvement-adjustment-container {
        flex-direction: column;
        align-items: unset;
    }

    .select-duplicate-select {
        @include w-100;
        @include mv-20;
        padding: unset;
    }
}
</style>
