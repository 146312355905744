<template>
    <ImprovementView :push-messages="pushMessages" :push-action-text="pushActionText">
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <div v-if="aboveMobile">
                    <EntityPillList
                        :items="[
                            { type: 'campaign', content: campaignLabel },
                            { type: 'adgroup', content: adgroupLabel },
                            { type: 'keyword', content: keywordText },
                        ]"
                        :scrollToRight="true"
                    />
                    <Spacer width="100%" height="1.5rem" />
                </div>
                <Text as="p" v-if="!!keywordCpa">
                    The keyword listed above spends a significant amount, but converts few
                    searchers. This keyword has an average CPA of <b>{{ keywordCpa }}</b
                    >. In comparison, the parent campaign group has an average CPA of
                    <b>{{ formattedTargetCpa }}</b
                    >. Opteo recommends pausing this keyword. <Spacer height="1rem" />Click
                    <Text as="span" weight="600" color="green">{{ pushActionText }}</Text> to do so.
                    Click <b>Dismiss</b> to dismiss this improvement.
                </Text>
                <Text as="p" v-else>
                    The keyword listed above spends a significant amount, but converts few searchers
                    in comparison. Opteo recommends pausing this keyword to help reduce wasted
                    spend. Click
                    <Text as="span" weight="600" color="green">{{ pushActionText }}</Text> to pause
                    this keyword. Click <b>Dismiss</b> to dismiss.
                </Text>
            </ImprovementHeader>
            <ImprovementHelpLink path="/articles/900649-pause-keyword">
                Click here to learn how Opteo identifies underperforming keywords.
            </ImprovementHelpLink>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementStatistics :items="statistics">
                <!-- Might be worth reconsidering this copy below, the usage of Ents/bold. it feels very full and bloated -->
                <template v-slot:cost>
                    <b>{{ formattedCost }}</b> was spent on this keyword over the last
                    <b>{{ TOTAL_DAYS }} days</b>.
                </template>
                <template v-slot:conversions>
                    This keyword generated
                    <b>{{ formattedConversions }}</b> conversions — as specified in the campaign
                    group <b>{{ campaignGroup }}</b> — over the last <b>{{ TOTAL_DAYS }}</b> days.
                </template>
                <template v-slot:averageCpa>
                    The parent campaign is in the campaign group
                    <b>{{ campaignGroup }}</b
                    >. Over the last {{ TOTAL_DAYS }} days, this campaign group has a CPA of
                    <b>{{ formattedTargetCpa }}</b
                    >.
                </template>
                <template v-if="!!keywordCpa" v-slot:keywordCpa>
                    This keyword has an average CPA of <b>{{ keywordCpa }}</b> over the last
                    <b>{{ TOTAL_DAYS }} days</b>.
                </template>
            </ImprovementStatistics>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="TOTAL_DAYS" />
        </ImprovementContent>
    </ImprovementView>
</template>

<script lang="ts">
import { usePauseKeyword } from '@/composition/improvement/types/usePauseKeyword'

import {
    ImprovementHeader,
    ImprovementContent,
    ImprovementStatistics,
    ImprovementCreatedDate,
    EntityPillList,
    Spacer,
    Ent,
    Text,
} from '@opteo/components-next'
import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import useMediaQuery from '@/composition/global/useMediaQuery'

export default {
    name: 'PauseKeyword',
    components: {
        ImprovementView,
        ImprovementHelpLink,
        ImprovementHeader,
        ImprovementContent,
        EntityPillList,
        ImprovementCreatedDate,
        ImprovementStatistics,
        Spacer,
        Ent,
        Text,
    },
    setup() {
        return { ...usePauseKeyword(), ...useMediaQuery() }
    },
}
</script>
