<template>
    <Text as="h5" size="f-5" weight="600">Add Notes</Text>
    <Spacer />
    <div class="notes-container">
        <div v-for="day in threeDays" :key="day.date" class="note-day-block">
            <note-day-block :domain-id="domainId" :day-block="day" />
        </div>
    </div>
</template>

<script lang="ts">
import { computed, PropType, ref } from 'vue'
import format from 'date-fns/format'
import sub from 'date-fns/sub'

import { Spacer, Text } from '@opteo/components-next'

import NoteDayBlock from '@/components/notes/NoteDayBlock.vue'
import { useNotes } from '@/composition/notes/useNotes'
import Skeleton from '@/components/util/Skeleton.vue'

export default {
    name: 'AlertNotes',
    components: {
        Spacer,
        NoteDayBlock,
        Text,
        Skeleton,
    },
    props: {
        alertDate: {
            type: Date as PropType<Date>,
            required: true,
        },
        domainId: {
            type: Number as PropType<number>,
            required: true,
        },
    },
    setup(props) {
        const { notesByDay } = useNotes(ref(props.domainId))

        const dates = [
            format(sub(props.alertDate, { days: 0 }), 'yyyy-MM-dd'),
            format(sub(props.alertDate, { days: 1 }), 'yyyy-MM-dd'),
            format(sub(props.alertDate, { days: 2 }), 'yyyy-MM-dd'),
        ]

        const threeDays = computed(() => notesByDay.value.filter(day => dates.includes(day.date)))

        return {
            threeDays,
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.notes-container {
    @include container;
}
.note-day-block {
    border-bottom: 1px solid;
    @include opteo-border;
}
.note-day-block:last-child {
    border-bottom: none;
}
</style>
