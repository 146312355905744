import { computed, ref, watch } from 'vue'
import { RouteRecordName, useRouter } from 'vue-router'

import { Routes } from '@/router/routes'
import { useAccount } from './useAccount'

export type UseAccountSetupBar = ReturnType<typeof useAccountSetupBar>

export type Step = {
    key: string
    name: string
    route: RouteRecordName
}

export type Steps = 'account-setup' | 'campaign-groups'

type StepValidators = Partial<
    Record<
        Steps,
        {
            callback: () => void
            loading: boolean
            error: boolean
        }
    >
>

// Account Setup bar states
const setupActive = ref(false)
const stepIndex = ref(0)

// Validators for each step (only account-setup for now)
const stepValidators = ref<StepValidators>({
    'account-setup': {
        callback: () => {},
        loading: false,
        error: false,
    },
})

export function useAccountSetupBar() {
    const steps = [
        { key: 'account-setup', name: 'Account Setup', route: Routes.Setup },
        { key: 'campaign-groups', name: 'Campaign Groups', route: Routes.CampaignGroups },
    ]

    const { accountColor, accountInitials, accountPlatform } = useAccount()

    const router = useRouter()

    const nextStepButton = ref()

    function skipAccountSetup() {
        router.push({
            name: Routes.ImprovementsActive,
        })
    }

    async function goToCampaignGroups() {
        if (stepValidators.value?.['account-setup']) {
            stepValidators.value['account-setup'].loading = true
            await stepValidators.value?.['account-setup']?.callback()

            stepValidators.value['account-setup'].loading = false

            if (stepValidators.value['account-setup'].error) {
                nextStepButton.value?.flashError()
                return
            }
        }

        router.push({
            name: Routes.CampaignGroups,
        })

        nextStep()
    }

    function completeAccountSetup() {
        hideAccountSetupBar()
        router.push({
            name: Routes.ImprovementsActive,
        })
    }

    function stepClicked(index: number) {
        setIndex(index)
        router.push({
            name: steps[index].route,
        })
    }

    const { currentRoute } = useRouter()

    const currentStep = computed(() => steps && steps[stepIndex.value])

    function setIndex(index: number) {
        stepIndex.value = index
    }

    function nextStep() {
        stepIndex.value += 1
    }

    function showAccountSetupBar() {
        setupActive.value = true
    }
    function hideAccountSetupBar() {
        setupActive.value = false
        setIndex(0)
    }

    watch(currentRoute, newValue => {
        const routeIndex = steps.findIndex(s => s.route === newValue.name)

        if (setupActive.value && steps && routeIndex > -1 && routeIndex !== stepIndex.value) {
            // setting index in the watcher should only happen when the back button is used
            setIndex(routeIndex)
        } else if (setupActive.value && steps && routeIndex === -1) {
            // close account setup bar if user navigates to a route that is not part of Account Setup
            hideAccountSetupBar()
        }
    })

    return {
        showAccountSetupBar,
        hideAccountSetupBar,
        setupActive,
        stepIndex,
        currentStep,
        skipAccountSetup,
        steps,
        completeAccountSetup,
        stepClicked,
        stepValidators,
        goToCampaignGroups,
        nextStepButton,
        accountColor,
        accountInitials,
        accountPlatform,
    }
}
