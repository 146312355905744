<template>
    <ImprovementView
        :title="title"
        :push-messages="pushMessages"
        :push-action-text="pushActionText"
        :on-push="onPush"
    >
        <ImprovementContent>
            <ImprovementHeader :title="`${title}`">
                <div v-if="aboveMobile">
                    <EntityPill :content="entityName" :type="entity" />
                    <Spacer height="2rem" />
                </div>
                <!-- Campaign Group -->
                <Text v-if="entity === 'campaign-group'" as="p">
                    Opteo has combined the performance data of several campaigns in this campaign
                    group to calculate an exclusion recommendation. Searchers using
                    <b>{{ highlightedSegment }}</b> devices are underperforming significantly. Opteo
                    recommends excluding these searchers. This should help to reduce wasted spend
                    and improve your overall {{ isUsingCpa ? 'CPA' : 'ROAS' }}.
                </Text>
                <!-- Campaign or Ad Group -->
                <Text v-else as="p">
                    In the {{ entityLabel }} <span v-if="highlightedEnum != 6">listed</span> above,
                    searchers using <b>{{ highlightedSegment }}</b> devices are underperforming
                    significantly compared to average. Opteo recommends excluding these searchers.
                    This should help to reduce wasted spend and improve your overall
                    {{ isUsingCpa ? 'CPA' : 'ROAS' }}.
                </Text>

                <Spacer height="1rem" />
                <Text
                    v-if="
                        (conversionLoss > 0 && isUsingCpa) ||
                        (conversionValueLoss > 0 && !isUsingCpa)
                    "
                >
                    This change should lead to a small decrease in
                    {{ isUsingCpa ? 'conversions' : 'conversion value' }}
                    — around
                    <b>
                        <Number v-if="isUsingCpa" :value="conversionLoss" />
                        <Money v-else :value="conversionValueLoss" :currency="domainCurrencyCode" />
                    </b>
                    per month, however your monthly spend should decline by
                    <b><Money :value="costSavings" :currency="domainCurrencyCode" /></b>
                    <Text as="span" color="green" weight="600">
                        (<Percent :value="costSavingPercent" />)</Text
                    >
                    and your overall
                    {{ isUsingCpa ? 'CPA should decrease' : 'ROAS should increase' }} from
                    <template v-if="isUsingCpa">
                        <b><Money :value="cpaBefore" :currency="domainCurrencyCode" /> </b>
                        to
                        <b><Money :value="cpaAfter" :currency="domainCurrencyCode" /> </b>
                    </template>
                    <template v-else>
                        <b><Roas :value="roasBefore" /></b>
                        to
                        <b><Roas :value="roasAfter" /></b>
                    </template>
                    <Text v-if="isUsingCpa" as="span" color="green" weight="600">
                        (<Percent :value="cpaDecreasePercent" />)</Text
                    >
                    <Text v-else as="span" color="green" weight="600">
                        (<Percent :value="roasIncreasePercent" />)</Text
                    >.
                </Text>
                <Text v-else>
                    This change should reduce your monthly spend by
                    <b><Money :value="costSavings" :currency="domainCurrencyCode" /></b>
                    <Text as="span" color="green" weight="600">
                        (<Percent :value="costSavingPercent" includeSign />)</Text
                    >
                    and
                    {{ isUsingCpa ? 'lower your overall CPA' : 'increase your overall ROAS' }} from
                    <template v-if="isUsingCpa">
                        <b><Money :value="cpaBefore" :currency="domainCurrencyCode" /> </b>
                        to
                        <b><Money :value="cpaAfter" :currency="domainCurrencyCode" /> </b>
                    </template>
                    <template v-else>
                        <b><Roas :value="roasBefore" /></b>
                        to
                        <b><Roas :value="roasAfter" /></b>
                    </template>
                    <Text v-if="isUsingCpa" as="span" color="green" weight="600">
                        (<Percent :value="cpaDecreasePercent" includeSign />)</Text
                    >
                    <Text v-else as="span" color="green" weight="600">
                        (<Percent :value="roasIncreasePercent" includeSign />)</Text
                    >
                    with <b>no loss of {{ isUsingCpa ? 'conversions' : 'conversion value' }}</b
                    >.
                </Text>
                <div v-if="entity === 'campaign-group'">
                    <Spacer height="1rem" />
                    <Text as="p">
                        For reference, this exclusion will be applied to the following campaigns:
                    </Text>
                    <Spacer height="2rem" />
                    <EntityPillList :items="entityPillList" />
                    <Spacer height="2rem" />
                </div>
                <Spacer v-else height="1rem" />
                <Text as="p">
                    Click <Text as="span" weight="600" color="green">{{ pushActionText }}</Text> to
                    apply the recommended exclusion. You can also exclude additional devices using
                    the table below. Otherwise, feel free to click <b>Dismiss</b>.
                </Text>
            </ImprovementHeader>
            <ImprovementHelpLink path="/articles/3638401-adjust-device-bids">
                Click here to learn more about device exclusions in Opteo.
            </ImprovementHelpLink>
        </ImprovementContent>

        <ImprovementContent fullWidth>
            <Spacer height="1rem" />
            <oTable
                :headers="deviceTableHeaders"
                :items="deviceTableItems"
                order-by="id"
                order="ASC"
                :per-page="aboveMobile ? 100 : 1"
                responsive-mode="columns"
                responsive-breakpoint="1120px"
                :border-radius="24"
                :fixed-layout="true"
            >
                <template #header.device>
                    <div class="device-header">Device</div>
                </template>
                <!-- Highlight device -->
                <template #column.device="device">
                    <div class="device-label-column">
                        <EntityPill
                            v-if="highlightedSegment === device.row.id"
                            type="device"
                            color="red"
                        >
                            {{ device.value }}
                        </EntityPill>
                        <EntityPill v-else type="device"> {{ device.value }} </EntityPill>
                    </div>
                </template>

                <template #column.cost="cost">
                    <Money :value="cost.value" :currency="domainCurrencyCode" />
                </template>

                <template #column.impressions="impressions">
                    <Number :value="impressions.value" />
                </template>

                <template #column.conversions="conversions">
                    <Number :value="conversions.value" :compact="true" />
                </template>

                <template #column.conversions-value="conversionsValue">
                    <Money :value="conversionsValue.value" :currency="domainCurrencyCode" />
                </template>

                <template #column.cpa="cpa">
                    <Money :value="cpa.value" :currency="domainCurrencyCode" />
                </template>

                <template #column.roas="roas">
                    <Roas :value="roas.value" />
                </template>

                <template #column.avg-cpa="avgCpa">
                    <Money :value="avgCpa.value" :currency="domainCurrencyCode" />
                </template>

                <template #column.avg-roas="avgRoas">
                    <Roas :value="avgRoas.value" />
                </template>

                <template #column.difference="difference">
                    <Text
                        v-if="difference.value < 0"
                        as="span"
                        :color="isUsingCpa ? 'green' : 'red'"
                        weight="500"
                    >
                        <Percent :value="difference.value" include-sign />
                    </Text>
                    <Text v-if="difference.value === 0" as="span" color="black" weight="400">
                        <Percent :value="difference.value" />
                    </Text>
                    <Text
                        v-if="difference.value > 0"
                        as="span"
                        :color="isUsingCpa ? 'red' : 'green'"
                        weight="500"
                    >
                        <Percent :value="difference.value" include-sign />
                    </Text>
                </template>

                <template #column.action="Action">
                    <ExclusionSwitcher
                        v-if="!Action.value.message"
                        v-model="Action.value.exclude"
                        @update:modelValue="updateExlusions(Action.value.id, Action.value.exclude)"
                    />
                    <ExclusionMessage
                        v-else
                        :message="Action.value.message"
                        :width="Action.value.width"
                    />
                </template>
            </oTable>
        </ImprovementContent>

        <div v-if="entity === 'campaign-group'">
            <ImprovementContent>
                <ImprovementNote>
                    <b>Note:</b> When individual campaigns do not have enough performance data for
                    Opteo to recommend adjustments, campaigns with insufficient data are combined to
                    generate statistically significant suggestions. Adjustments based on aggregated
                    campaign data.
                </ImprovementNote>
            </ImprovementContent>
        </div>
        <Spacer v-else height="3rem" />

        <ImprovementContent>
            <Text as="h3" size="f-4" weight="600">Cost Comparison</Text>
            <Spacer height="2rem" />
            <div class="graph-container">
                <DonutChart
                    :items="donutChartItems"
                    :metric="{ label: 'Cost', dataType: 'money', currency: domainCurrencyCode }"
                    :show-keys="true"
                />
            </div>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementStatistics :items="improvementStatisticsItems">
                <template #segmentCpa v-if="isUsingCpa">
                    Over the last <b>{{ lookbackWindow }}</b> days, in this
                    {{ entityLabel === 'campaign group' ? 'group of campaigns' : entityLabel }},
                    searchers using <b>{{ highlightedSegment }}</b> devices have an average CPA of
                    <b>{{ formattedSegmentCpa }}</b
                    >. Please note, these values are based on conversion types set in Campaign
                    Groups.
                </template>

                <template #segmentRoas v-else>
                    Over the last <b>{{ lookbackWindow }}</b> days, in this
                    {{ entityLabel === 'campaign group' ? 'group of campaigns' : entityLabel }},
                    searchers using <b>{{ highlightedSegment }}</b> devices have generated an
                    average ROAS of <b>{{ formattedSegmentRoas }}</b
                    >. Please note, these values are based on conversion types set in Campaign
                    Groups.
                </template>

                <template #entityAverage>
                    In comparison, the containing {{ entityLabel }} has an average
                    {{ isUsingCpa ? 'CPA' : 'ROAS' }} of <b>{{ formattedEntityAverage }}</b> over
                    the same time period. This means that searchers using
                    <b>{{ highlightedSegment }}</b> devices generated a
                    <b>
                        <Percent :value="Math.abs(differenceFromEntityAverage)" />
                        <span>{{ differenceFromEntityAverage > 0 ? ' higher ' : ' lower ' }}</span>
                    </b>
                    {{ isUsingCpa ? 'CPA' : 'ROAS' }} than the {{ entityLabel }} average.
                </template>

                <template #campaignGroupTarget>
                    The parent campaign group has a Target {{ isUsingCpa ? 'CPA' : 'ROAS' }} of
                    <b>{{ formattedTarget }}</b
                    >. This means — in this
                    {{ entityLabel === 'campaign group' ? 'group of campaigns' : entityLabel }} —
                    searchers using <b>{{ highlightedSegment }}</b> devices generated a
                    <b>
                        <Percent :value="Math.abs(differenceFromTarget)" />
                        <span>{{ differenceFromTarget > 0 ? ' higher ' : ' lower ' }}</span>
                    </b>
                    {{ isUsingCpa ? 'CPA' : 'ROAS' }} than the campaign group target.
                </template>
            </ImprovementStatistics>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="lookbackWindow" />
        </ImprovementContent>
    </ImprovementView>
</template>
<script lang="ts" setup>
import {
    ImprovementHeader,
    ImprovementContent,
    ImprovementCreatedDate,
    ImprovementStatistics,
    ImprovementNote,
    Text,
    Spacer,
    EntityPill,
    EntityPillList,
    oTable,
    Number,
    Percent,
    DonutChart,
    Tooltip,
    Roas,
} from '@opteo/components-next'

import Money from '@/components/global/oMoney.vue'
import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ExclusionSwitcher from '@/components/improvement/shared-components/ExclusionSwitcher.vue'
import ExclusionMessage from '@/components/improvement/shared-components/ExclusionMessage.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import { useExcludeSingleDevice } from '@/composition/improvement/types/device-bids-v2/useExcludeSingleDevice'
import useMediaQuery from '@/composition/global/useMediaQuery'

const {
    title,
    entity,
    entityLabel,
    entityName,
    entityPillList,
    highlightedSegment,
    highlightedEnum,
    deviceTableHeaders,
    deviceTableItems,
    donutChartItems,
    domainCurrencyCode,
    formattedSegmentCpa,
    formattedSegmentRoas,
    improvementStatisticsItems,
    lookbackWindow,
    updateExlusions,
    pushMessages,
    onPush,
    lastUpdated,
    pushActionText,
    isUsingCpa,
    formattedEntityAverage,
    differenceFromEntityAverage,
    differenceFromTarget,
    formattedTarget,
    conversionLoss,
    conversionValueLoss,
    costSavings,
    costSavingPercent,
    cpaBefore,
    roasBefore,
    cpaAfter,
    roasAfter,
    cpaDecreasePercent,
    roasIncreasePercent,
} = useExcludeSingleDevice()

const { aboveMobile } = useMediaQuery()
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.graph-container {
    @include container;
    @include pa-32;
}

.not-enough-data-pill {
    @include inline-flex;
    @include items-center;
    @include justify-between;
    cursor: default;
}
.not-enough-data-text {
    color: #bec1cb;
}

.not-enough-data-icon {
    color: #bec1cb;
    margin-left: 0.25rem;
    transform: translateY(3px);
}

.device-label-column {
    max-width: 100%;
    overflow: hidden;
    @include flex;
    @include items-center;
    height: 4.5rem;
    @include pl-24;
}
.device-label-column::after {
    content: '';
    background: linear-gradient(270deg, rgba(255, 255, 255, 1) 1.5rem, rgba(255, 255, 255, 0) 100%);
    @include absolute;
    top: 0;
    right: 0;
    width: 4rem;
    bottom: 0;
}
.device-header {
    @include pl-24;
}
</style>
