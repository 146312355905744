<template>
    <ImprovementContainer :close-button="true" @close-improvement="handleClose">
        <!-- Mobile Location -->
        <ImprovementContent v-if="!aboveMobile && formattedLocations">
            <EntityPillList
                v-if="formattedLocations.length > 1 || formattedLocations[0].content.length > 30"
                :items="formattedLocations"
                :scrollToRight="true"
                style="width: auto; max-width: calc(100% - 3.875rem)"
            />
            <EntityPill
                v-else
                :type="formattedLocations[0].type"
                :content="formattedLocations[0].content"
            />
        </ImprovementContent>

        <!-- Main improvement content -->
        <slot v-if="!adjusting"></slot>

        <!-- Improvement adjustment steps (max 3) -->
        <slot v-if="currentStep === 1" name="adjust-step-1"></slot>
        <slot v-if="currentStep === 2" name="adjust-step-2"></slot>
        <slot v-if="currentStep === 3" name="adjust-step-3"></slot>

        <!-- Footer (active improvement) -->
        <ImprovementFooter
            v-if="!isCompletedView && !isDismissedView && aboveMobile"
            :avatar-initials="accountInitials"
            :account-name="accountName"
            :avatar-color="accountColor"
            :locations="locations"
            :improvement-name="title"
            v-bind="adjustSteps"
            :back-button="adjusting"
            @back-clicked="handleBackButton"
            :adjust-button="!adjusting && isAdjustable"
            @adjust-clicked="handleAdjust"
            @step-one-clicked="() => handleAdjustStepChange()"
            @step-two-clicked="() => handleAdjustStepChange()"
            @step-three-clicked="() => handleAdjustStepChange()"
            :dismiss-button="!adjusting"
            :push-button="isPushButtonVisible"
            :push-button-text="pushActionText"
            :push-data-still-loading="pushDataStillLoading"
            @push-clicked="handlePush"
            :platform="platform"
        >
            <template #dismissPopout>
                <DismissPopoutContents @dismiss="handleDismiss" />
            </template>
        </ImprovementFooter>

        <!-- Mobile Footer (active improvement) -->
        <MobileImprovementFooter
            v-if="!isCompletedView && !isDismissedView && !aboveMobile"
            v-bind="adjustSteps"
            :back-button="adjusting"
            @back-clicked="handleBackButton"
            :adjust-button="!adjusting"
            :adjust-disabled="!isAdjustable"
            @adjust-clicked="handleAdjust"
            @step-one-clicked="() => handleAdjustStepChange()"
            @step-two-clicked="() => handleAdjustStepChange()"
            @step-three-clicked="() => handleAdjustStepChange()"
            :dismiss-button="!adjusting"
            :push-button="isPushButtonVisible"
            :push-button-text="pushActionText"
            :push-data-still-loading="pushDataStillLoading"
            @push-clicked="handlePush"
            :locations="locations"
        >
            <template #dismissPopout>
                <DismissPopoutContents @dismiss="handleDismiss" />
            </template>
        </MobileImprovementFooter>

        <!-- Footer (completed improvement) -->
        <ImprovementFooter
            v-if="isCompletedView && aboveMobile"
            :avatar-initials="accountInitials"
            :account-name="accountName"
            :avatar-color="accountColor"
            :locations="locations"
            :improvement-name="title"
            :completed="true"
            :completed-text="'Completed ' + completedTs"
        />
        <!-- Mobile Footer (completed improvement) -->
        <MobileImprovementFooter
            v-if="isCompletedView && !aboveMobile"
            :completed="true"
            :completed-text="'Completed ' + completedTs"
            :single-col-button="true"
        />

        <!-- Footer (dismissed improvement) -->
        <ImprovementFooter
            v-if="isDismissedView && aboveMobile"
            :avatar-initials="accountInitials"
            :account-name="accountName"
            :avatar-color="accountColor"
            :locations="locations"
            :improvement-name="title"
            :completed="isDismissedView && isDeferred"
            :completed-text="`Reverts in ${reversalDateString}`"
            :push-button="true"
            push-button-text="Revert Now"
            @push-clicked="handleRevertDismissed"
            class="dismiss-footer"
        />
        <!-- Mobile Footer (dismissed improvement) -->
        <MobileImprovementFooter
            v-if="isDismissedView && !aboveMobile"
            :completed="isDismissedView && isDeferred"
            :completed-text="`Reverts in ${reversalDateString}`"
            :push-button="true"
            push-button-text="Revert Now"
            @push-clicked="handleRevertDismissed"
            class="dismissed-view"
            :single-col-button="true"
            :dismissed-view="isDismissedView && isDeferred"
        />
    </ImprovementContainer>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {
    ImprovementContainer,
    ImprovementContent,
    ImprovementFooter,
    MobileImprovementFooter,
    EntityPillList,
    EntityPill,
    showAsyncPush,
} from '@opteo/components-next'
import { Platform } from '@opteo/types'
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import differenceInDays from 'date-fns/differenceInDays'

import { Routes } from '@/router/routes'
import {
    isCompletedImprovement,
    OnPushHandler,
    OnStepCompleteHandler,
    Step,
    useImprovement,
    checkImprovement,
    isDismissedImprovement,
} from '@/composition/improvement/useImprovement'
import DismissPopoutContents from '@/components/improvement/DismissPopoutContents.vue'
import { useActiveImprovements } from '@/composition/improvement/useActiveImprovements'
import { useDismissImprovement } from '@/composition/improvement/useDismissImprovement'
import { useImprovementQueue } from '@/composition/improvement/useImprovementQueue'
import { scrollToTop, delay } from '@/lib/globalUtils'
import { useUser } from '@/composition/user/useUser'
import useMediaQuery from '@/composition/global/useMediaQuery'
import { getPlatformFromId } from '@/lib/globalUtils'

import type { DismissDuration } from '@/composition/improvement/types'
import { useAccount } from '@/composition/account/useAccount'

export default defineComponent({
    name: 'ImprovementView',
    components: {
        ImprovementContainer,
        ImprovementContent,
        ImprovementFooter,
        MobileImprovementFooter,
        DismissPopoutContents,
        EntityPillList,
        EntityPill,
    },
    props: {
        pushActionText: {
            type: String as PropType<string>,
            required: true,
        },
        title: {
            type: String as PropType<string>,
            required: false,
        },
        pushMessages: {
            type: Array as PropType<string[]>,
            required: true,
        },
        pushDataStillLoading: {
            type: Boolean as PropType<boolean>,
            required: false,
        },
        isAdjustable: {
            type: Boolean as PropType<boolean>,
            required: false,
            default: false,
        },
        steps: {
            type: Array as PropType<string[]>,
            required: false,
        },
        /* Hooks */
        onPush: {
            type: Function as PropType<OnPushHandler>,
            required: false,
        },
        onStepComplete: {
            type: Function as PropType<OnStepCompleteHandler>,
            required: false,
        },
    },
    emits: ['back-clicked', 'adjust-reset'],
    setup(props, { emit }) {
        const route = useRoute()
        const router = useRouter()
        const { accountName, accountInitials, accountColor, accountPlatform } = useAccount()

        const { dismissImprovementWithFeedBack } = useActiveImprovements()
        const { improvement, title, currentStep, handleClose } = useImprovement()

        const { account_id: accountId } = checkImprovement(improvement)

        const platform = computed(() =>
            accountId ? getPlatformFromId(accountId) : Platform.Platform.GoogleAds
        )

        const { revertDismissImprovement } = useDismissImprovement()
        const { pushQueueSingle, removeFailedImprovement, removePushedImprovement } =
            useImprovementQueue()

        const adjusting = ref(false)
        const pushError = ref('')
        const pushStatus = ref<'loading' | 'error' | 'success'>('loading')

        // TODO: Delete me, just used for QA during development
        const pushedData = ref()

        const locations = computed(() => improvement.value?.location)
        const formattedLocations = improvement.value?.location.map(
            ({ entity: type, label: content, ...rest }) => ({
                type,
                content,
                ...rest,
            })
        )

        const isPushButtonVisible = computed(() =>
            props.steps ? currentStep.value === props.steps.length : true
        )

        // Build the adjust step buttons based on steps prop
        const adjustSteps = computed(() => {
            const [step1, step2, step3] = props.steps ?? []

            return {
                stepOneButton: typeof step1 !== 'undefined' && currentStep.value === Step.DEFAULT,
                stepOneButtonText: step1,
                stepTwoButton: typeof step2 !== 'undefined' && currentStep.value === Step.ONE,
                stepTwoButtonText: step2,
                stepThreeButton: typeof step3 !== 'undefined' && currentStep.value === Step.TWO,
                stepThreeButtonText: step3,
            }
        })

        // Dynamic title
        const computedTitle = computed(() => {
            if (props.title) {
                return props.title
            } else return title.value
        })

        // Completed view
        const isCompletedView = computed(() => route.name === Routes.CompletedImprovement)
        const completedTs = computed(() => {
            return (
                isCompletedImprovement(improvement.value!) &&
                // @ts-expect-error TODO: this is indeed a string, need to change opteo/types
                format(parseISO(improvement.value?.completed_timestamp), "MMMM do y 'at' h:mmaaa")
            )
        })

        // Dismissed/deferred view
        const isDismissedView = computed(() => route.name === Routes.DismissedImprovement)

        // It is "deferred" (as opposed to just dismissed) if it's deferred_until date is relatively soon.
        const isDeferred = computed(
            () =>
                improvement.value &&
                isDismissedImprovement(improvement.value) &&
                differenceInDays(parseISO(improvement.value.deferred_until), new Date()) < 30
        )

        // Show "Reverts in X days" if it's deferred, otherwise show nothing.
        const reversalDateString = computed(() =>
            isDismissedImprovement(improvement.value!) && isDeferred.value
                ? formatDistanceToNow(parseISO(improvement.value?.deferred_until))
                : undefined
        )

        // Set mode to adjusting
        const handleAdjust = () => {
            if (props.isAdjustable) {
                adjusting.value = true
                currentStep.value = Step.ONE
            }
        }

        const handleAdjustStepChange = () => {
            if (typeof props.onStepComplete !== 'undefined') {
                const stepValidated = props.onStepComplete({ step: currentStep.value })
                if (!stepValidated) {
                    return
                }
            }

            currentStep.value++
        }

        watch(currentStep, async newStep => {
            adjusting.value = newStep > 0
            scrollToTop('auto')
            if (!adjusting.value) {
                emit('adjust-reset')
            }
        })

        // Called when pushing the back button once in the adjust section
        const handleBackButton = () => {
            currentStep.value--
            emit('back-clicked')
        }

        // Util for checking the active step
        const isStep = (step: number) => step === currentStep.value

        const handlePush = async () => {
            if (typeof props.onPush !== 'undefined') {
                const { valid } = props.onPush()

                if (!valid) {
                    return
                }
            }

            if (!improvement.value) {
                throw new Error('cannot push improvement until it is defined')
            }

            const { improvement_id: improvementId } = improvement.value

            // Trigger async push bubble
            const asyncPush = showAsyncPush({
                platform: accountPlatform.value,
            })

            // Go back to improvement list
            router.back()

            try {
                await pushQueueSingle({
                    improvementId,
                    onPush: props.onPush,
                })

                asyncPush.showSuccessState()

                // Leave the row green-ticked for a little bit, then remove
                await delay(3000)

                await removePushedImprovement(improvementId)
                asyncPush.close()
            } catch (err) {
                pushStatus.value = 'error'

                const hasMessage =
                    err && (err instanceof Error || (typeof err === 'object' && 'message' in err))

                const errorMessage =
                    hasMessage && typeof err.message === 'string' ? err.message : 'Unknown Error'

                pushError.value = errorMessage
                asyncPush.showErrorState({
                    longErrorMessage: errorMessage,
                    buttons: [
                        {
                            label: 'Dismiss',
                            handler: () => {
                                removeFailedImprovement(improvementId)
                                asyncPush.close()
                            },
                        },
                    ],
                })
            }
        }

        const handleDismiss = async (length: DismissDuration) => {
            if (!improvement.value) {
                throw new Error('cannot dismiss nonexistent improvement')
            }

            if (!dismissImprovementWithFeedBack) {
                throw new Error(
                    'dismissImprovementWithFeedBack is not injected, something is wrong'
                )
            }

            dismissImprovementWithFeedBack(improvement.value.improvement_id, length)

            handleClose()
        }

        const handleRevertDismissed = () => {
            if (!improvement.value) {
                throw new Error('cannot revert dismissal of nonexistent improvement')
            }
            revertDismissImprovement(improvement.value.improvement_id)

            handleClose()
        }

        return {
            improvement,
            title: computedTitle,
            currentStep,
            handleAdjust,
            handleAdjustStepChange,
            handleBackButton,
            isStep,
            adjusting,
            handleDismiss,
            handlePush,
            handleClose,
            handleRevertDismissed,
            locations,
            formattedLocations,
            accountName,
            accountInitials,
            accountColor,
            isPushButtonVisible,
            adjustSteps,
            pushError,
            pushedData,
            isCompletedView,
            completedTs,
            isDismissedView,
            isDeferred,
            reversalDateString,
            pushStatus,
            platform,
            ...useMediaQuery(),
        }
    },
})
</script>

<style lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';
</style>
