<template>
    <div class="chat-entities-modal-content-wrapper">
        <section v-for="(item, index) in items" :key="'item-' + index">
            <!-- Title -->
            <Text as="h5" weight="600">
                {{ item.title }}
            </Text>
            <!-- Paragraph -->
            <Spacer v-if="item.paragraph" height="1.5rem" />
            <Text v-if="item.paragraph" as="p">
                {{ item.paragraph }}
            </Text>
            <!-- Query -->
            <Spacer height="1.75rem" />
            <div class="query-wrapper">
                <!-- Example Query -->
                <div class="example-query">
                    <div class="title">Query</div>
                    <span v-html="item.query" />
                </div>
                <!-- Example Data -->
                <div class="example-data">
                    <div class="title">Attached Data</div>
                    <span v-html="item.data" />
                </div>
            </div>
        </section>
    </div>
</template>

<script lang="ts">
// Vue
import { defineComponent } from 'vue'

// Local Components
import ChatQuery from '@/components/chat/ChatQuery.vue'

// Components
import { Text, Spacer, CardTitleTag } from '@opteo/components-next'

export default defineComponent({
    name: 'ChatEntitiesModalContent',
    components: {
        // Local Components
        ChatQuery,
        // Components
        Text,
        Spacer,
        CardTitleTag,
    },
    setup() {
        const items = [
            {
                title: 'Account',
                paragraph: `The first message in any conversation includes some overall data about your account, from your account name to key performance metrics. See the section below for an example of all the included data.`,
                query: 'Can you give me an overview of this account?',
                data: `
                    Account Name: Opteo<br/>
                    Website: https://opteo.com<br/>
                    Timezone: Europe/London<br/>
                    Date Range: 15.03.2023 to 14.04.2023 (30 Days)<br/>
                    Key Performance Metrics:<br/>
                    <ul>
                        <li>Cost: £28,250</li>
                        <li>Impressions: 3,294,350</li>
                        <li>Clicks: 14,532</li>
                        <li>Conversions: 452</li>
                        <li>Conversion Value: £98,320</li>
                        <li>CPA: £62.50</li>
                        <li>CPC: £1.94</li>
                    </ul>
                    Top Spending Keywords:<br/>
                    <ul>
                        <li>google ads software (£425.64)</li>
                        <li>google ads automation (£408.34)</li>
                        <li>ai google ads tool (£402.50)</li>
                        <li>google ads optimisation (£396.25)</li>
                        <li>google ads reports tool (£390.28)</li>
                    </ul>
                    Top Spending Campaigns:<br/>
                    <ul>
                        <li>[SE-GE-USA] Generic - United States - Exact</li>
                        <ul>
                            <li>Cost: £6,438</li>
                            <li>Impressions: 1,234,852</li>
                            <li>Clicks: 5,324</li>
                            <li>Conversions: 204</li>
                            <li>Conversion Value: £38,908</li>
                            <li>CPA: £31.56</li>
                            <li>CPC: £1.20</li>
                            <li>Bidding Strategy: Maximize Conversion Value</li>
                        </ul>
                        <li>[SE-GE-TOP] Generic - Priority Markets - Exact</li>
                        <ul>
                            <li>Cost: £3,785</li>
                            <li>Impressions: 634,925</li>
                            <li>Clicks: 2,720</li>
                            <li>Conversions: 96</li>
                            <li>Conversion Value: £18,948</li>
                            <li>CPA: £39.42</li>
                            <li>CPC: £1.39</li>
                            <li>Bidding Strategy: Maximize Conversion Value</li>
                        </ul>
                        <li>[SE-GE-ROW] Generic - Rest of World - Exact</li>
                        <ul>
                            <li>Cost: £3,693</li>
                            <li>Impressions: 523,494</li>
                            <li>Clicks: 2,980</li>
                            <li>Conversions: 82</li>
                            <li>Conversion Value: £13,550</li>
                            <li>CPA: £45.03</li>
                            <li>CPC: £1.24</li>
                            <li>Bidding Strategy: Maximize Conversion Value</li>
                        </ul>
                    </ul>
                    Top Performing RSA:<br/>
                    <ul>
                        <li>Headlines</li>
                        <ul>
                            <li>Opteo®</li>
                            <li>Official Site</li>
                            <li>A smarter way to Google Ads</li>
                            <li>Free 30 Day Trial</li>
                            <li>Automate Google Ads</li>
                            <li>Built for PPC Marketers</li>
                        </ul>
                        <li>Descriptions</li>
                        <ul>
                            <li>
                                Opteo makes managing your Google Ads accounts simple and efficient. Start your free trial.
                            </li>
                            <li>
                                Get one-click improvements, beautiful reports, key performance metrics, and so much more.
                            </li>
                            <li>
                                Built for PPC people, trusted by digital marketing agencies from all around the world.
                            </li>
                            <li>
                                Spend more time on high-level strategy and the creative work that makes a difference.
                            </li>
                        </ul>
                        <li>Metrics</li>
                        <ul>
                            <li>Cost: £1,250</li>
                            <li>Impressions: 184,532</li>
                            <li>Clicks: 865</li>
                            <li>Conversions: 42</li>
                            <li>Conversion Value: £5,320</li>
                            <li>CPA: £29.76</li>
                            <li>CPC: £1.44</li>
                        </ul>
                    </ul>
                `,
            },
            {
                title: 'Campaign',
                paragraph: `When attaching a campaign entity, key performance metrics, your top performing RSA, and other useful snippets of data are included with your message. See the section below for an example of all the included data.`,
                query: 'Can you tell me how <span class="entity campaign">Campaign</span> is performing?',
                data: `
                    Campaign Name: Campaign<br/>
                    Date Range: 15.03.2023 to 14.04.2023 (30 Days)<br/>
                    Key Performance Metrics:<br/>
                    <ul>
                        <li>Cost: £6,438</li>
                        <li>Impressions: 1,234,852</li>
                        <li>Clicks: 5,324</li>
                        <li>Conversions: 204</li>
                        <li>Conversion Value: £38,908</li>
                        <li>CPA: £31.56</li>
                        <li>CPC: £1.20</li>
                        <li>Bidding Strategy: Maximize Conversion Value</li>
                    </ul>
                    Top Spending Keywords:<br/>
                    <ul>
                        <li>google ads software (£125.64)</li>
                        <li>google ads automation (£108.34)</li>
                        <li>ai google ads tool (£102.50)</li>
                        <li>google ads optimisation (£96.25)</li>
                        <li>google ads reports tool (£90.28)</li>
                    </ul>
                    Top Performing RSA:<br/>
                    <ul>
                        <li>Headlines</li>
                        <ul>
                            <li>Opteo®</li>
                            <li>Official Site</li>
                            <li>A smarter way to Google Ads</li>
                            <li>Free 30 Day Trial</li>
                            <li>Automate Google Ads</li>
                            <li>Built for PPC Marketers</li>
                        </ul>
                        <li>Descriptions</li>
                        <ul>
                            <li>
                                Opteo makes managing your Google Ads accounts simple and efficient. Start your free trial.
                            </li>
                            <li>
                                Get one-click improvements, beautiful reports, key performance metrics, and so much more.
                            </li>
                            <li>
                                Built for PPC people, trusted by digital marketing agencies from all around the world.
                            </li>
                            <li>
                                Spend more time on high-level strategy and the creative work that makes a difference.
                            </li>
                        </ul>
                        <li>Metrics</li>
                        <ul>
                            <li>Cost: £1,250</li>
                            <li>Impressions: 184,532</li>
                            <li>Clicks: 865</li>
                            <li>Conversions: 42</li>
                            <li>Conversion Value: £5,320</li>
                            <li>CPA: £29.76</li>
                            <li>CPC: £1.44</li>
                        </ul>
                    </ul>
                `,
            },
            {
                title: 'Ad Group',
                paragraph: `When attaching an ad group entity, key performance metrics, your top performing RSA, and other useful snippets of data are included with your message. See the section below for an example of all the included data.`,
                query: 'Can you tell me how <span class="entity adgroup">Ad Group</span> is performing?',
                data: `
                    Ad Group Name: Ad Group<br/>
                    Date Range: 15.03.2023 to 14.04.2023 (30 Days)<br/>
                    Key Performance Metrics:<br/>
                    <ul>
                        <li>Cost: £893.50</li>
                        <li>Impressions: 98,352</li>
                        <li>Clicks: 645</li>
                        <li>Conversions: 18</li>
                        <li>Conversion Value: £2,305</li>
                        <li>CPA: £49.64</li>
                        <li>CPC: £1.38</li>
                    </ul>
                    Top Spending Keywords:<br/>
                    <ul>
                        <li>google ads software (£125.64)</li>
                        <li>google ads automation (£108.34)</li>
                        <li>ai google ads tool (£102.50)</li>
                        <li>google ads optimisation (£96.25)</li>
                        <li>google ads reports tool (£90.28)</li>
                    </ul>
                    Top Performing RSA:<br/>
                    <ul>
                        <li>Headlines</li>
                        <ul>
                            <li>Opteo®</li>
                            <li>Official Site</li>
                            <li>A smarter way to Google Ads</li>
                            <li>Free 30 Day Trial</li>
                            <li>Automate Google Ads</li>
                            <li>Built for PPC Marketers</li>
                        </ul>
                        <li>Descriptions</li>
                        <ul>
                            <li>
                                Opteo makes managing your Google Ads accounts simple and efficient. Start your free trial.
                            </li>
                            <li>
                                Get one-click improvements, beautiful reports, key performance metrics, and so much more.
                            </li>
                            <li>
                                Built for PPC people, trusted by digital marketing agencies from all around the world.
                            </li>
                            <li>
                                Spend more time on high-level strategy and the creative work that makes a difference.
                            </li>
                        </ul>
                        <li>Metrics</li>
                        <ul>
                            <li>Cost: £350.06</li>
                            <li>Impressions: 44,532</li>
                            <li>Clicks: 198</li>
                            <li>Conversions: 12</li>
                            <li>Conversion Value: £2,325</li>
                            <li>CPA: £29.18</li>
                            <li>CPC: £1.17</li>
                        </ul>
                    </ul>
                `,
            },
            {
                title: 'Keyword',
                paragraph: `When attaching a keyword entity, key performance metrics, associated keywords, and other snippets of data are included with your message. See the section below for an example of all the included data.`,
                query: 'Can you tell me how <span class="entity keyword">Keyword</span> is performing?',
                data: `
                    Keyword: Keyword<br/>
                    Parent Ad Group: Ad Group<br/>
                    Date Range: 15.03.2023 to 14.04.2023 (30 Days)<br/>
                    Key Performance Metrics:<br/>
                    <ul>
                        <li>Cost: £283.45</li>
                        <li>Impressions: 18,532</li>
                        <li>Clicks: 124</li>
                        <li>Conversions: 6</li>
                        <li>Conversion Value: £825.50</li>
                        <li>CPA: £47.24</li>
                        <li>CPC: £2.28</li>
                    </ul>
                    Associated Keywords:<br/>
                    <ul>
                        <li>google ads software</li>
                        <ul>
                            <li>Cost: £208.94</li>
                            <li>Impressions: 16,892</li>
                            <li>Clicks: 198</li>
                            <li>Conversions: 8</li>
                            <li>Conversion Value: £1,220</li>
                            <li>CPA: £26.12</li>
                            <li>CPC: £1.06</li>
                        </ul>
                        <li>google ads automation</li>
                        <ul>
                            <li>Cost: £182.39</li>
                            <li>Impressions: 14,325</li>
                            <li>Clicks: 160</li>
                            <li>Conversions: 5</li>
                            <li>Conversion Value: £720.23</li>
                            <li>CPA: £36.48</li>
                            <li>CPC: £1.14</li>
                        </ul>
                        <li>ai google ads tool</li>
                        <ul>
                            <li>Cost: £144.78</li>
                            <li>Impressions: 9,325</li>
                            <li>Clicks: 98</li>
                            <li>Conversions: 4</li>
                            <li>Conversion Value: £720.23</li>
                            <li>CPA: £36.19</li>
                            <li>CPC: £1.48</li>
                        </ul>
                    </ul>
                `,
            },
        ]
        return { items }
    },
})
</script>

<style lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.chat-entities-modal-content-wrapper {
    @include flex;
    @include items-center;
    flex-direction: column;
    gap: 3rem;
}

.chat-entities-modal-content-wrapper .example-query,
.chat-entities-modal-content-wrapper .example-data {
    @include flex;
    flex-direction: column;
    @include relative;
    min-height: 4.5rem;
    padding: 1.375rem 1.875rem;
    @include relative;
    line-height: 1.625rem;
    @include f-7;
    letter-spacing: -0.00625rem;
}
.chat-entities-modal-content-wrapper .example-query {
    border-bottom: 1px solid $opteo-light-gray;
}
.chat-entities-modal-content-wrapper .example-query .title,
.chat-entities-modal-content-wrapper .example-data .title {
    @include f-9;
    font-size: 0.75rem;
    margin-bottom: 0.375rem;
    opacity: 0.24;
}

.chat-entities-modal-content-wrapper .example-data ul {
    margin-left: 0.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
.chat-entities-modal-content-wrapper .example-data ul:last-child {
    margin-bottom: 0;
}
.chat-entities-modal-content-wrapper .example-data ul li {
    @include flex;
    @include relative;
    padding-left: 1.375rem;
    line-height: 1.5rem;
}
.chat-entities-modal-content-wrapper .example-data ul ul {
    padding-left: 1.375rem;
}
.chat-entities-modal-content-wrapper .example-data ul li::before {
    content: '';
    @include absolute;
    top: 0.5625rem;
    left: 0;
    width: 6px;
    height: 6px;
    border-radius: 999px;
    background-color: #000;
}

.chat-entities-modal-content-wrapper .example-query .entity {
    line-height: 1.3125rem;
    padding: 0 0.1875rem;
    margin: 0 0.25rem;
    font-size: inherit;
    @include fw-400;
    background: transparent !important;
    @include relative;
}
.chat-entities-modal-content-wrapper .example-query .entity::after {
    content: '';
    width: calc(100% + 0.375rem);
    height: 1.75rem;
    position: absolute;
    top: -0.25rem;
    right: -0.1875rem;
    border-radius: 0.375rem;
}
.chat-entities-modal-content-wrapper .example-query .campaign::after {
    background: $opteo-dark-blue-translucent;
}
.chat-entities-modal-content-wrapper .example-query .adgroup::after {
    background: $opteo-dark-purple-translucent;
}
.chat-entities-modal-content-wrapper .example-query .keyword::after {
    background: $opteo-dark-teal-translucent;
}
.chat-entities-modal-content-wrapper .example-query .campaign {
    color: $opteo-dark-blue;
}
.chat-entities-modal-content-wrapper .example-query .adgroup {
    color: $opteo-dark-purple;
}
.chat-entities-modal-content-wrapper .example-query .keyword {
    color: $opteo-dark-teal;
}

.chat-entities-modal-content-wrapper .query-wrapper {
    @include container;
    @include br-24;
}
</style>
