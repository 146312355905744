import { createApp } from 'vue'

import { LOAD_GTM, LOAD_SENTRY, SENTRY_ENV } from '@/lib/env'
import initialiseGtm from '@/lib/gtm'
import initialiseSentry from '@/lib/sentry'

import App from './App.vue'

import '@opteo/components-next/css'

// Fonts
import './assets/css/fonts/circular.css'
import './assets/css/fonts/roboto.css'
import './assets/css/fonts/ia-writer-mono.css'

import router from './router'

import OpteoComponents from '@opteo/components-next'
import { GesturePlugin } from '@vueuse/gesture'

const startApp: () => void = async () => {
    if (LOAD_GTM) {
        initialiseGtm()
    }

    const app = createApp(App)

    if (LOAD_SENTRY) {
        initialiseSentry(app, router, SENTRY_ENV)
    }

    app.use(router)
    app.use(OpteoComponents)
    app.use(GesturePlugin)

    app.mount('#app')
}

startApp()
