<template>
    <ImprovementView :push-messages="pushMessages" :push-action-text="pushActionText">
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <div v-if="aboveMobile">
                    <EntityPillList :items="entityPillList" :scrollToRight="true" />
                    <Spacer height="2rem" />
                </div>
                <Text as="p">
                    Consider replacing this <b>Broad</b> match keyword with a <b>Phrase</b> match
                    keyword. Phrase match keywords ensure that your ads are targeted more closely to
                    your audience.
                </Text>
                <Spacer height="1rem" />
                <Text v-if="isManualCpc" as="p">
                    Click
                    <Text as="span" color="green" weight="600">{{ pushActionText }}</Text> to pause
                    this <b>Broad</b> match keyword and add a new <b>Phrase</b> match keyword in its
                    place. The keyword bid will remain the same at
                    <b><Money :value="originalKeywordBid" /></b>. If using this broad keyword is a
                    strategic move, feel free to click <b>Dismiss</b>.
                </Text>
                <Text v-if="!isManualCpc" as="p">
                    Click
                    <Text as="span" color="green" weight="600">{{ pushActionText }}</Text> to pause
                    this <b>Broad</b> match keyword and add a new <b>Phrase</b> match keyword in its
                    place. If using this broad keyword is a strategic move, or you consider this
                    improvement to be a false positive, feel free to click <b>Dismiss</b>.
                </Text>
                <div v-if="otherMatchtypesExistInCampaignGroup">
                    <Spacer height="2rem" />
                    <ImprovementNote v-if="isCpaMode">
                        <b>Note:</b> While in certain instances broad keywords perform well, in this
                        campaign group their CPA is
                        <Text v-if="matchTypeCpaDiff >= 0.15" as="span" weight="600" color="red"
                            ><Percent :value="matchTypeCpaDiff" :decimal-places="2" />
                        </Text>
                        <Text v-else as="span">significantly</Text>
                        higher when compared with keywords of other match types.
                    </ImprovementNote>
                    <ImprovementNote v-else>
                        <b>Note:</b> While in certain instances broad keywords perform well, in this
                        campaign group their ROAS is
                        <Text v-if="matchTypeRoasDiff >= 0.15" as="span" weight="600" color="red"
                            ><Percent :value="matchTypeRoasDiff" :decimal-places="2" />
                        </Text>
                        <Text v-else as="span">significantly</Text>
                        lower when compared with keywords of other match types.
                    </ImprovementNote>
                </div>
            </ImprovementHeader>
            <ImprovementHelpLink path="/en/articles/903022-replace-broad-keyword">
                Learn more about the benefits of replacing broad match keywords.
            </ImprovementHelpLink>
        </ImprovementContent>

        <ImprovementContent>
            <EntityTransform
                title="Keyword Replacement"
                :entity-before="keywordText"
                entity-before-type="pause"
                :entity-after="'“' + replacementKeyword + '”'"
                entity-after-type="replacement-keyword"
            />
            <Spacer height="2rem" />
            <ImprovementNote>
                <b>Note:</b> Opteo will pause the <b>Broad</b> keyword listed above (in order to
                preserve historical performance data) and add a new <b>Phrase</b> match keyword as a
                replacement.
            </ImprovementNote>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementStatistics :items="impStatItems">
                <template v-slot:cost>
                    <b><Money :value="keywordCost" /></b> was spent on this keyword over the last
                    <b>{{ lookbackWindow }}</b> days.
                </template>

                <template v-if="isCpaMode" v-slot:conv>
                    This <b>Broad</b> match keyword generated
                    <b>{{ keywordConv }}</b> conversion<span v-if="keywordConv !== 1">s</span> over
                    the last <b>{{ lookbackWindow }}</b> days.
                </template>
                <template v-else v-slot:convValue>
                    This <b>Broad</b> match keyword generated
                    <b><Money :value="keywordConvValue" /></b> in value over the last
                    <b>{{ lookbackWindow }}</b> days.
                </template>

                <template v-if="isCpaMode" v-slot:keywordCpa>
                    Over the last <b>{{ lookbackWindow }}</b> days, this keyword had an average CPA
                    of <b><Money :value="keywordCpa" /></b>.
                </template>
                <template v-else v-slot:keywordRoas>
                    Over the last <b>{{ lookbackWindow }}</b> days, this keyword had an average ROAS
                    of <b><Roas :value="keywordRoas" /></b>.
                </template>

                <template v-if="isCpaMode" v-slot:targetCpa>
                    The {{ isAutoTargeting ? 'Average' : 'Target' }} CPA for this campaign group is
                    <b><Money :value="targetCpa" /></b>.
                </template>
                <template v-else v-slot:targetRoas>
                    The {{ isAutoTargeting ? 'Average' : 'Target' }} ROAS for this campaign group is
                    <b><Roas :value="targetRoas" /></b>.
                </template>
            </ImprovementStatistics>
        </ImprovementContent>

        <ImprovementContent>
            <RelatedSearchTerms :items="searchTerms" :keyword="keywordText" />
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="lookbackWindow" />
        </ImprovementContent>
    </ImprovementView>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import {
    ImprovementHeader,
    ImprovementContent,
    ImprovementStatistics,
    ImprovementNote,
    ImprovementCreatedDate,
    RelatedSearchTerms,
    EntityTransform,
    Percent,
    oTable,
    Text,
    EntityPillList,
    Spacer,
    oInput,
    Roas,
} from '@opteo/components-next'
import Money from '@/components/global/oMoney.vue'
import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import useMediaQuery from '@/composition/global/useMediaQuery'

import { useRemoveBroadKeywordsV2 } from '@/composition/improvement/types/useRemoveBroadKeywordsV2'

import SparklineSkeleton from '@/components/AccountCenter/SparklineSkeleton.vue'

export default defineComponent({
    name: 'RemoveBroadKeywordsV2',
    components: {
        ImprovementHeader,
        ImprovementContent,
        ImprovementView,
        ImprovementHelpLink,
        ImprovementNote,
        ImprovementStatistics,
        ImprovementCreatedDate,
        EntityPillList,
        Money,
        Percent,
        oTable,
        Text,
        Spacer,
        oInput,
        Roas,
        RelatedSearchTerms,
        EntityTransform,
        SparklineSkeleton,
    },
    setup() {
        return { ...useRemoveBroadKeywordsV2(), ...useMediaQuery() }
    },
})
</script>
