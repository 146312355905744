import isString from 'lodash-es/isString'
import { computed, ref, watch } from 'vue'
import { useRouter } from 'vue-router'

import { useDomain } from '@/composition/domain/useDomain'
import { useUser } from '@/composition/user/useUser'
import { Routes } from '@/router/routes'
import { showSnackbar } from '@opteo/components-next'
import { Scorecard } from '@opteo/types'

import { authRequest, Endpoint, useAPI } from '../../api/useAPI'

export function useScorecardSingle() {
    const router = useRouter()
    const scorecardId = computed(() => {
        const scorecardIdRoute = router.currentRoute?.value?.params?.scorecardId

        if (!isString(scorecardIdRoute)) {
            return
        }

        return scorecardIdRoute
    })
    /**
     * Data getters
     */
    const { loading: loadingDomains, domainId } = useDomain()

    const { userId } = useUser()

    const {
        data: scorecard,
        loading: loadingScorecard,
        mutate: mutateScorecard,
        error,
    } = useAPI<Scorecard.SingleScorecardFull>(Endpoint.GetScorecard, {
        uniqueId: () => scorecardId.value,
        waitFor: () => scorecardId.value,
        body: () => ({ scorecard_id: scorecardId.value }),
    })

    const isScorecardUnavailable = computed(() => error.value?.message === 'Scorecard not found')

    const { data: scorecardData, loading: loadingScorecardList } = useAPI<{
        scorecardList: Scorecard.ScorecardListItem[]
        scorecardHistory: Scorecard.ScorecardListItem[]
    }>(Endpoint.GetScorecardList, {
        waitFor: () => userId.value,
        body: () => ({ user_id: userId.value, domain_id: domainId.value }),
    })

    const scoresLoading = computed(
        () => loadingDomains.value || loadingScorecard.value || loadingScorecardList.value
    )
    const scorecardHistory = computed(() => scorecardData.value?.scorecardHistory)

    const scorecardAccountInfo = computed(() => {
        if (loadingScorecard.value) return

        if (!scorecard.value?.account) {
            throw new Error('scorecard accountInfo must be defined')
        }

        return scorecard.value.account
    })

    /**
     * Error handling
     */
    const scoresHasError = ref(false)

    watch(error, () => {
        scoresHasError.value = true
    })

    /**
     * Sharing
     */
    const linkShareLoading = ref(false)
    async function copyShareLink() {
        try {
            linkShareLoading.value = true
            const shareableLinkId = await authRequest(Endpoint.GenerateShareableLink, {
                body: { scorecard_id: scorecardId.value },
            })

            const url = `${window.location.origin}/scorecard/${shareableLinkId}`

            navigator.clipboard.writeText(url)

            showSnackbar({
                message: 'Sharing link copied to clipboard ',
                timeout: 5000,
                actionText: 'Open in new tab', // Button text
                actionHandler: () => window.open(url, '_blank')?.focus(),
            })
        } finally {
            linkShareLoading.value = false
        }
    }

    /**
     * Misc
     */

    const handleScorecardClose = () => {
        router.push({ name: Routes.Toolkit })
    }

    const hasPmaxAndProductCampaignsOnly = computed(() => {
        const potentiallyInvalidSectionTypes = [
            Scorecard.SectionTypes.matchtype,
            Scorecard.SectionTypes.sqr,
            Scorecard.SectionTypes.adExtension,
            Scorecard.SectionTypes.qs,
        ]

        if (!scorecard.value) return false

        return potentiallyInvalidSectionTypes.every(
            sectionType => scorecard.value?.body.sections[sectionType].invalid
        )
    })

    const showOnboarding = ref(true)
    const renderOnboardingStep = computed(
        () => showOnboarding.value === true && hasPmaxAndProductCampaignsOnly.value
    )

    return {
        // Scores
        scorecard,
        scorecardHistory,
        scorecardId,
        scoresHasError,
        scoresLoading,
        loadingScorecard,
        isScorecardUnavailable,

        // Actions
        mutateScorecard,
        handleScorecardClose,

        userId,
        scorecardAccountInfo,

        copyShareLink,
        linkShareLoading,

        // Onboarding
        renderOnboardingStep,
        showOnboarding,
    }
}
