<template>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#a)">
            <path
                d="M11.681 0a3.189 3.189 0 0 0-2.944 4.413 3.189 3.189 0 0 0 2.944 1.971h3.186V3.192A3.189 3.189 0 0 0 11.68 0Zm0 8.513H3.186A3.189 3.189 0 0 0 .24 12.925a3.188 3.188 0 0 0 2.945 1.971h8.495a3.186 3.186 0 0 0 3.186-3.192 3.188 3.188 0 0 0-3.186-3.191Z"
                fill="#36C5F0"
            />
            <path
                d="M31.858 11.704a3.188 3.188 0 0 0-4.406-2.948 3.19 3.19 0 0 0-1.966 2.948v3.192h3.186a3.186 3.186 0 0 0 3.186-3.192Zm-8.496 0V3.192a3.185 3.185 0 1 0-6.371 0v8.512a3.185 3.185 0 1 0 6.371 0Z"
                fill="#2EB67D"
            />
            <path
                d="M20.177 31.921a3.19 3.19 0 0 0 2.254-5.448 3.192 3.192 0 0 0-2.254-.936H16.99v3.192a3.185 3.185 0 0 0 1.966 2.949c.386.16.8.243 1.22.243Zm0-8.512h8.495a3.19 3.19 0 0 0 2.944-4.412 3.187 3.187 0 0 0-2.944-1.972h-8.495a3.185 3.185 0 0 0-2.945 1.972c-.16.387-.242.801-.241 1.22a3.187 3.187 0 0 0 3.186 3.192Z"
                fill="#ECB22E"
            />
            <path
                d="M0 20.447a3.188 3.188 0 0 0 4.406 2.948 3.186 3.186 0 0 0 1.966-2.948v-3.193H3.186A3.189 3.189 0 0 0 0 20.447Zm8.495 0v8.512a3.19 3.19 0 0 0 3.186 3.192 3.19 3.19 0 0 0 3.186-3.192v-8.512A3.188 3.188 0 0 0 10.46 17.5a3.188 3.188 0 0 0-1.966 2.948"
                fill="#E01E5A"
            />
        </g>
        <defs>
            <clipPath id="a"><path fill="#fff" d="M0 0h32v32H0z" /></clipPath>
        </defs>
    </svg>
</template>