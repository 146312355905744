import { Ref, ComputedRef } from 'vue'
import type { AreaChartTypes, LineChartTypes } from '@opteo/components-next'
import { Targets, Performance } from '@opteo/types'

export enum TimePeriod {
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month',
}

export enum Layout {
    GRID = 'grid',
    ROWS = 'rows',
}

export type DateRange = { start: Date; end: Date }

export interface AccountData {
    campaigns: Performance.Campaign[]
    campaign_groups: Targets.CampaignGroup[]
    conversions: { name: string; count: number; value: number }[]
}

export interface SidebarPresets {
    selectedViewOption: TimePeriod
    selectedLayoutOption: Layout
    deselectedCampaignIds: number[]
    selectedConversionActions: string[]
    selectedCampaignGroups: string[]
    deselectedCharts: ChartID[]
    isDefaults: boolean
}

export interface SidebarCampaign {
    channel: string
    campaigns: {
        name: string
        id: number
        selected: boolean
    }[]
}

export interface RawChartData {
    day: string
    week: number
    month: number
    year: number
    clicks: string
    cost: string
    impressions: string
    market_impressions: string
    impressions_lost_to_rank: string
    impressions_lost_to_budget: string
    search_impressions: string
    max_cpc: string
    qs: string
    'qs_components:::qs_ctr': string
    'qs_components:::qs_creative': string
    'qs_components:::qs_landing_page': string
    conversion_names: string[]
}

export interface TableDataRanges {
    selectedRangeTableData: TableData
    previousRangeTableData: TableData
}

export interface TableItem {
    id: string
    type: string
    metric: string
    selectedRange: string
    previousRange: string
    delta: { delta: number; reverse: boolean }
}

export interface TableData {
    dates: RawChartData[]
    searchImpressionShare: number
    impressionsLostToRank: number
    impressionsLostToBudget: number
    conversionValue: number
}

export interface ConversionAction {
    name: string
    selectedConversions: number
    previousConversions: number
    selectedConversionValue: number
    previousConversionValue: number
}

export interface ChartData extends RawChartData {
    total_conversions: number
    total_conversions_value: number
}

export interface ChartDataRanges {
    selectedRangeChartData: ChartData[]
    previousRangeChartData: ChartData[]
}

export enum DataType {
    MONEY = 'money',
    PERCENT = 'percentage',
    NUMBER = 'number',
}

export type ChartMetric = { label?: string; currency?: string; dataType?: DataType }

export type ChartMarker = {
    axis: 'x' | 'y'
    label?: string
    value: number
    labelPosition: 'start' | 'end'
}

export enum ChartType {
    LINE = 'line',
    AREA = 'area',
}

export enum ChartLabel {
    CLICKS = 'Clicks',
    COST = 'Cost',
    CONVERSIONS = 'Conversions',
    CONV_VAL = 'Conversion Value',
    CPA = 'Cost Per Conversion',
    ROAS = 'Return On Ad Spend',
    CTR = 'Click Through Rate',
    CPC = 'Cost Per Click',
    CR = 'Conversion Rate',
    IMPRESSIONS = 'Impressions',
    VAL_PER_CONV = 'Value Per Conversion',
    SEARCH_IMP_SHARE = 'Search Impression Share',
    QS = 'Quality Score',
    QS_COMPONENTS = 'Quality Score Components',
    AD_RANK = 'Ad Rank',
}

export enum ChartID {
    CLICKS = 'CLICKS',
    COST = 'COST',
    CONVERSIONS = 'CONVERSIONS',
    CONV_VAL = 'CONV_VAL',
    CPA = 'CPA',
    ROAS = 'ROAS',
    CTR = 'CTR',
    CPC = 'CPC',
    CR = 'CR',
    IMPRESSIONS = 'IMPRESSIONS',
    VAL_PER_CONV = 'VAL_PER_CONV',
    SEARCH_IMP_SHARE = 'SEARCH_IMP_SHARE',
    QS = 'QS',
    QS_COMPONENTS = 'QS_COMPONENTS',
    AD_RANK = 'AD_RANK',
}

export type LineChartItem = LineChartTypes.LineChartSeries
export type AreaChartItem = AreaChartTypes.AreaChartSeries

export type ChartItems = LineChartItem[] | AreaChartItem[]

export interface Chart {
    id: ChartID
    enabled: boolean
    label: ChartLabel
    chartType: ChartType
    chartData: ChartItems
    total?: string
    delta?: { delta: number; reverse: boolean }
    currentValues?: string[]
    chartMetric: ChartMetric
    chartMarkers: ChartMarker[]
    showZero: boolean
    pTotal?: string
    maxItem?: number
}

export interface UseGraphs {
    // CHARTS
    domainCurrencyCode: ComputedRef<string | undefined>
    chartDataLoading: Ref<boolean>
    chartDataValidating: Ref<boolean>
    allCharts: Ref<Chart[]>
    allVisibleCharts: ComputedRef<Chart[]>
    // SIDEBAR
    sidebarOpen: Ref<Boolean>
    capitalise: (input: string) => string

    // LAYOUT OPTION
    selectedLayoutOption: Ref<Layout>
    // CAMPAIGNS
    accountDataLoading: Ref<boolean>
    channels: ComputedRef<SidebarCampaign[]>
    selectedCampaignCount: ComputedRef<string>
    entireChannelSelected: (channelName: string) => boolean
    toggleChannel: (channelName: string, checked: boolean) => void
    toggleCampaign: (campaignId: number, checked: boolean) => void
    // CONVERSION ACTIONS
    conversionActions: ComputedRef<{ label: string; selected: boolean }[]>
    selectedConversionActionCount: ComputedRef<string>
    toggleConversionAction: (index: number, checked: boolean) => void

    // FULL SCREEN LOGIC
    fullScreenChart: Ref<Chart | null>
    openFullScreenPreview: (chart: Chart) => void
}
