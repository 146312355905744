<template>
    <ImprovementView
        :push-messages="pushMessages"
        :push-action-text="pushActionText"
        :isAdjustable="true"
        :on-push="onPush"
    >
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <div v-if="aboveMobile">
                    <EntityPillList :items="entityPillList" :scrollToRight="true" />
                    <Spacer height="2rem" />
                </div>
                <!-- body.keyword_text -->
                <Text>
                    The keyword listed above is eligible to run ads, but has
                    <b>Below First Page Bid</b>
                    status. Searchers are searching for this keyword, but your ads frequently appear
                    below the first page in Google's search results, and in some cases, not at all.
                </Text>
                <Spacer height="1rem" />
                <Text>
                    Consider increasing your keyword bid by
                    <b><Percent :value="body.percentage_increase / 100" :decimal-places="0" /></b> —
                    from <b><Money :value="body.keyword_max_cpc" /></b> to
                    <b><Money :value="body.new_cpc_bid" /></b> —
                    <span v-if="body.keyword_max_cpc * 1.3 > body.first_page_cpc">
                        in order to exceed the
                    </span>
                    <span v-else>to move closer towards the</span> first page bid estimate of
                    <b><Money :value="body.first_page_cpc" /></b>. Click
                    <Text as="span" color="green" weight="600">Increase Keyword Bid</Text> to
                    increase your keyword bid by
                    <b><Percent :value="body.percentage_increase / 100" :decimal-places="0" /></b>.
                    Click <b>Adjust</b> to set a specific bid amount.
                </Text>
            </ImprovementHeader>
            <ImprovementHelpLink path="/en/articles/2410538-fix-below-first-page-bids">
                Learn how Opteo flagged this keyword and calculated the adjustment.
            </ImprovementHelpLink>
        </ImprovementContent>
        <ImprovementContent>
            <ImprovementStatistics
                title="Recommended Bid Adjustment"
                :items="[
                    {
                        key: 'bidAdjustment',
                        deltaMode: true,
                        beforeValue: formattedCurrentBid,
                        beforeTitle: 'Current CPC Bid',
                        afterValue: formattedNewBid,
                        afterTitle: 'Adjusted CPC Bid',
                    },
                ]"
            >
                <template #bidAdjustment>
                    Opteo recommends a
                    <Text as="span" weight="600" color="green">
                        <Percent
                            :value="body.percentage_increase / 100"
                            :decimal-places="0"
                            :include-sign="true"
                        />
                    </Text>
                    bid adjustment.
                </template>
            </ImprovementStatistics>
        </ImprovementContent>
        <ImprovementContent header="Improvement Statistics">
            <ImprovementStatistics :items="stats">
                <template #firstPageBid>
                    Google currently recommends bidding
                    <b><Money :value="body.first_page_cpc" /></b> on
                    <b>{{ body.keyword_text }}</b> for your ads to appear on the first page.
                </template>
                <template #cost>
                    <b><Money :value="body.stats.cost" /></b> was spent on
                    <b>{{ body.keyword_text }}</b> over the last 30 days.
                </template>
                <template #cpc>
                    You're paying an average of
                    <b><Money :value="body.stats.avg_cpc" /></b> per click.
                </template>
                <template #impressions>
                    This keyword has generated <b>{{ body.stats.impressions }}</b>
                    {{ +body.stats.impressions === 1 ? 'impression' : 'impressions' }} over the past
                    <b>30 days</b>.
                </template>
                <template #clicks>
                    This keyword has generated <b>{{ body.stats.clicks }}</b>
                    {{ +body.stats.clicks === 1 ? 'click' : 'clicks' }} over the past 30 days.
                </template>
                <template #qs>
                    This keyword has a Quality Score of <b>{{ body.stats.qs }}</b
                    >.
                </template>
                <template #searchImprShare>
                    On average, your ads appear in
                    <b><Percent :value="body.stats.search_impr_share / 100" /></b> of auctions
                    entered.
                </template>
            </ImprovementStatistics>
        </ImprovementContent>
        <ImprovementContent>
            <!-- <Text as="h4">Impression Statistics</Text> -->
            <Text as="h3" size="f-4" weight="600">Impression Statistics</Text>
            <Spacer height="2rem" />
            <Text>
                The graph below indicates how often this keyword lost search impression share to
                competitors because of low Ad Rank. A higher bid should help improve Ad Rank.
            </Text>
            <Spacer height="2rem" />
            <div class="graph-container">
                <AreaChart
                    :series="imprShareSeries"
                    :show-zero="true"
                    :axis-titles="{
                        y: 'Search Impression Share (%)',
                    }"
                    :metric="{
                        dataType: 'percentage',
                        label: 'Search Impression Shares',
                    }"
                    disable-fallback
                />
            </div>
            <Spacer height="3rem" />
            <Text>
                The graph below illustrates the consistency of impressions received by this keyword
                over the last 30 days. This keyword's <b>Below First Page Bid</b> status could be
                caused by a recent change, or because of an ongoing issue.
            </Text>

            <!-- TODO : make sure to show message: No statistics to see here – this keyword hasn't had any impressions in the past 30 days. When no impressions -->
            <Spacer height="2rem" />
            <div class="graph-container">
                <AreaChart
                    :series="impressionSeries"
                    :show-zero="true"
                    :metric="{
                        label: 'Impressions',
                    }"
                    :axis-titles="{
                        y: 'Impressions',
                        x: `Date (${formattedStartDate} → ${formattedEndDate})`,
                    }"
                    disable-fallback
                />
            </div>
        </ImprovementContent>
        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="30" />
        </ImprovementContent>
        <template v-slot:adjust-step-1>
            <ImprovementContent>
                <ImprovementHeader title="Adjust Keyword Bid">
                    <EntityPill type="keyword" :content="body.keyword_text" />
                    <Spacer height="2rem" />
                    <Text as="p">
                        Consider increasing your bid for the keyword listed above. The current bid
                        for this keyword is <b><Money :value="body.keyword_max_cpc" /></b>. For
                        reference, the first page bid estimate is
                        <b><Money :value="body.first_page_cpc" /></b>.
                    </Text>
                </ImprovementHeader>
                <div class="adjustment-container">
                    <Text as="span" size="f-8">Set keyword bid to</Text>
                    <Spacer width="1rem" />
                    <oForm ref="bidInputForm">
                        <oInput
                            type="number"
                            name="bid"
                            v-model.number="bidToPush"
                            :prefix="currencySymbol"
                            class="adjustment-input"
                            autofocus
                            required
                            min="0.01"
                        />
                    </oForm>
                </div>
            </ImprovementContent>
        </template>
    </ImprovementView>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

import {
    ImprovementHeader,
    ImprovementContent,
    ImprovementCreatedDate,
    ImprovementStatistics,
    EntityPillList,
    EntityPill,
    oInput,
    oForm,
    Text,
    Spacer,
    TextAd,
    Percent,
    Ent,
    AreaChart,
} from '@opteo/components-next'
import Money from '@/components/global/oMoney.vue'

import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import CircleButton from '@/components/global/CircleButton.vue'
import useMediaQuery from '@/composition/global/useMediaQuery'

import { useFixBelowFirstPageBid } from '@/composition/improvement/types/useFixBelowFirstPageBid'

export default defineComponent({
    name: 'FixBelowFirstPageBid',
    components: {
        ImprovementHeader,
        ImprovementContent,
        ImprovementCreatedDate,
        ImprovementView,
        ImprovementHelpLink,
        ImprovementStatistics,
        EntityPillList,
        EntityPill,
        oInput,
        oForm,
        Text,
        Spacer,
        TextAd,
        CircleButton,
        Percent,
        Money,
        Ent,
        AreaChart,
    },
    setup() {
        return { ...useFixBelowFirstPageBid(), ...useMediaQuery() }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

// Adjustment

.adjustment-container {
    @include container;
    @include ph-32;
    @include pv-28;
    @include flex;
    @include items-center;
}

.input-container {
    @include flex;
    @include items-center;
}
.adjustment-input {
    width: 8rem;
}
.graph-container {
    @include container;
    @include pa-32;
}

// Responsive

@media (min-width: $mq-321-min) and (max-width: $mq-375-max) {
    .adjustment-input {
        width: 100%;
    }
}

@media screen and (min-width: $mq-0-min) and (max-width: $mq-767-max) {
    .adjustment-container {
        flex-direction: column;
        align-items: unset;
    }
    .adjustment-input {
        width: 100%;
    }
}
</style>
