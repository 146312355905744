<template>
    <PageHeader page-title="Notes" :no-margin="!aboveMobile">
        <template #end-content>
            <div class="export-button">
                <oButton @clicked="exportNotes" color="white" class="export-notes-button">
                    Export Notes
                </oButton>
            </div>
            <!-- <div v-if="loading">
                <Skeleton :height="44" style="width: 116px" />
            </div> -->
            <!-- <DatePicker
                v-if="notesByDay.length"
                :presetDate="new Date()"
                :dates="datesWithNotes"
                @change="jumpToDate"
            /> -->
        </template>
    </PageHeader>
    <PageContent>
        <div v-for="month in notesByMonth" :key="month.month" class="">
            <div class="mb-32">
                <Text as="h5">{{ month.month }}</Text>
            </div>
            <div class="note-block">
                <div
                    v-for="day in month.days"
                    :key="day.date"
                    class="note-day-block"
                    :id="`day-${day.date}`"
                >
                    <div v-if="loading" class="skeleton-container">
                        <Skeleton :height="16" :width="150" />
                        <oButton size="small" color="white" disabled>Add Note</oButton>
                    </div>
                    <note-day-block v-else :domain-id="domainId" :day-block="day" />
                </div>
            </div>
        </div>
    </PageContent>

    <!-- Notes onboarding -->
    <OnboardingTour
        step-name="tour_notes"
        :buttonText="'Continue to Notes'"
        contentMaxWidth="480px"
    >
        <template v-slot:header>Notes</template>
        <template v-slot:copy>
            Notes are for keeping track of important changes in your account. Any improvements
            completed in Opteo are automatically added to your notes. Notes are visible to — and can
            be edited by — all users connected to an account. Click the button below to continue.
        </template>
    </OnboardingTour>
</template>

<script lang="ts">
import PageHeader from '@/layouts/PageHeader.vue'
import PageContent from '@/layouts/PageContent.vue'
import { oButton, DatePicker, Text } from '@opteo/components-next'
import Skeleton from '@/components/util/Skeleton.vue'
import OnboardingTour from '@/components/User/OnboardingTour.vue'

import { useDomain } from '@/composition/domain/useDomain'
import NoteDayBlock from '@/components/notes/NoteDayBlock.vue'
import { useNotes } from '@/composition/notes/useNotes'
import useMediaQuery from '@/composition/global/useMediaQuery'

export default {
    name: 'Notes',
    components: {
        oButton,
        DatePicker,
        PageHeader,
        PageContent,
        Skeleton,
        NoteDayBlock,
        Text,
        OnboardingTour,
    },
    setup() {
        // const jumpToDate = async (date: Date) => {
        //     // This is needed because the datepicker popover needs a moment to release focus.
        //     await delay(10)
        //     scrollTo(`day-${formatDate(date, 'yyyy-MM-dd')}`)
        // }
        const { domainInfo, domainId } = useDomain()
        const { aboveMobile } = useMediaQuery()
        return {
            ...useNotes(domainId),
            domainId,
            domainInfo,
            aboveMobile,
            // jumpToDate,
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.skeleton-container {
    @include ph-24;
    @include pv-20;
    @include flex;
    @include justify-between;
    @include items-center;
}

.note-block {
    @include container;
    @include mb-40;
    @include br-20;
}

.show-more-container {
    @include flex;
    @include justify-center;
}

.note-day-block {
    border-bottom: 1px solid;
    @include opteo-border;
}
.note-day-block:last-child {
    border-bottom: none;
}
</style>
