<template>
    <ImprovementView
        :push-messages="pushMessages"
        :push-action-text="pushActionText"
        :is-adjustable="true"
        :on-push="onPush"
    >
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <div v-if="aboveMobile">
                    <EntityPill :content="campaignName" type="campaign" />
                    <Spacer height="2rem" />
                </div>
                <Text as="p">
                    <!-- Intro -->
                    In the campaign listed above, searchers using
                    <b>{{ improvementDeviceType }}</b> devices are converting at an average cost of
                    <b>{{ formattedDeviceCPA }}</b
                    >. In comparison, the parent campaign has an average cost per conversion of
                    <b>{{ formattedCampaignCPA }}</b
                    >. This means searchers using <b>{{ improvementDeviceType }}</b> devices cost
                    <Text
                        :color="cpaToCampaignRatioLabel === 'less' ? 'green' : 'red'"
                        as="span"
                        weight="600"
                    >
                        <Percent :value="cpaToCampaignRatio" />
                    </Text>
                    {{ cpaToCampaignRatioLabel }} to convert than the campaign average.
                    <!-- Spacer -->
                    <Spacer height="1rem" />
                    <!-- If user has an existing bid modifier applied -->
                    <div v-if="currentBidMod">
                        Opteo recommends {{ isIncreasingBid ? 'increasing' : 'decreasing' }} the bid
                        adjustment for this segment from
                        <Text :color="currentBidMod > 0 ? 'green' : 'red'" as="span" weight="600">
                            <Percent :value="currentBidMod / 100" />
                        </Text>
                        to
                        <Text :color="bidModifier > 0 ? 'green' : 'red'" as="span" weight="600">
                            <Percent :value="bidModifier / 100" /></Text
                        >. This should help you
                        <span v-if="usingTargetCPA">
                            optimise towards your Target CPA (<b>
                                <Money :value="targetCPA" :currency="domainCurrencyCode" /></b
                            >).
                        </span>
                        <span v-else>
                            <span v-if="isIncreasingBid">
                                generate more conversions at a reasonable cost.
                            </span>
                            <span v-if="!isIncreasingBid">
                                reduce the overall CPA of the parent campaign.
                            </span>
                        </span>
                    </div>
                    <!-- If existing bid modifier is 0 -->
                    <div v-else-if="currentBidMod === 0">
                        Opteo recommends applying a
                        <Text :color="bidModifier > 0 ? 'green' : 'red'" as="span" weight="600">
                            <span v-if="bidModifier > 0">+</span>
                            <Percent :value="bidModifier / 100" />
                        </Text>
                        bid adjustment to this segment. This should help
                        <span v-if="isIncreasingBid">
                            you generate more conversions at a reasonable cost.
                        </span>
                        <span v-if="!isIncreasingBid">
                            reduce your overall campaign CPA and limit wasted spend.
                        </span>
                    </div>
                    <!-- If user has no bid modifier applied -->
                    <div v-else-if="!currentBidMod">
                        Opteo recommends applying a
                        <Text :color="bidModifier > 0 ? 'green' : 'red'" as="span" weight="600">
                            <span v-if="bidModifier > 0">+</span>
                            <Percent :value="bidModifier / 100" />
                        </Text>
                        bid adjustment to this segment. This should help
                        <span v-if="isIncreasingBid">
                            you generate more conversions at a reasonable cost.
                        </span>
                        <span v-if="!isIncreasingBid">
                            reduce your overall campaign CPA and limit wasted spend.
                        </span>
                    </div>
                    <!-- Click to push -->
                    <Spacer height="1rem" />
                    Click <Text as="span" weight="600" color="green">{{ pushActionText }}</Text> to
                    apply the recommended adjustment to this segment. If you need to use a different
                    percentage to the one suggested above, click <b>Adjust</b>.
                </Text>
            </ImprovementHeader>
            <ImprovementHelpLink path="/articles/3638401-adjust-device-bids">
                Click here to learn more about device bid adjustments in Opteo.
            </ImprovementHelpLink>
        </ImprovementContent>

        <ImprovementContent>
            <oTable
                :headers="campaignDevicesTableHeaders"
                :items="campaignDevicesTableItems"
                responsive-mode="columns"
                responsive-breakpoint="768px"
                order-by="difference"
                order="DESC"
            >
                <template v-slot:column.device="device">
                    <Text
                        v-if="device.value === improvementDeviceType"
                        as="span"
                        color="blue"
                        weight="500"
                    >
                        {{ device.value }}
                    </Text>
                    <span v-else>
                        {{ device.value }}
                    </span>
                </template>
                <template v-slot:column.cost="cost">
                    <Money :value="cost.value" :currency="domainCurrencyCode" />
                </template>
                <template v-slot:column.cpa="cpa">
                    <Money v-if="cpa.value > 0" :value="cpa.value" :currency="domainCurrencyCode" />
                    <span v-else>N/A</span>
                </template>
                <template v-slot:column.average-cpa="avgCPA">
                    <Money :value="avgCPA.value" :currency="domainCurrencyCode" />
                </template>
                <template v-slot:column.difference="difference">
                    <Text v-if="difference.value > 0" as="span" color="red" weight="500">
                        +<Percent :value="difference.value" />
                    </Text>
                    <Text v-if="difference.value === 0" as="span" color="black" weight="400">
                        <Percent :value="difference.value" />
                    </Text>
                    <Text
                        v-if="difference.value < 0 && difference.value > -1"
                        as="span"
                        color="green"
                        weight="500"
                    >
                        <Percent :value="difference.value" />
                    </Text>
                    <Text v-else-if="difference.value === -1" as="span" color="black" weight="400">
                        N/A
                    </Text>
                </template>
            </oTable>
        </ImprovementContent>
        <ImprovementContent>
            <Text as="h3" size="f-4" weight="600">Cost Comparison</Text>
            <Spacer height="2rem" />
            <div class="graph-container">
                <DonutChart
                    :items="donutChartItems"
                    :metric="{ label: 'Cost', dataType: 'money', currency: domainCurrencyCode }"
                    color="blue"
                />
            </div>
        </ImprovementContent>
        <ImprovementContent>
            <ImprovementStatistics
                :items="[
                    {
                        key: 'segmentCPA',
                        value: formattedDeviceCPA,
                        title: 'Segment CPA',
                    },
                    {
                        key: 'campaignCPA',
                        value: formattedCampaignCPA,
                        title: 'Campaign CPA',
                    },
                    {
                        key: 'campaignGroupCPA',
                        value: formattedCampaignGroupCPA,
                        title: 'Campaign Group CPA',
                    },
                ]"
            >
                <template v-slot:segmentCPA>
                    Over the last <b>60</b> days, searchers using
                    <b>{{ improvementDeviceType }}</b> devices have a CPA of
                    <b><Money :value="deviceCPA" :currency="domainCurrencyCode" /></b>. Please note,
                    this CPA is based on conversion types set in Campaign Groups.
                </template>
                <template v-slot:campaignCPA>
                    In comparison, the average CPA for this campaign is
                    <b><Money :value="campaignCPA" :currency="domainCurrencyCode" /></b>
                    over the same time period, meaning
                    <b>{{ improvementDeviceType }}</b> users cost
                    <b><Percent :value="cpaToCampaignRatio" /> {{ cpaToCampaignRatioLabel }}</b>
                    to convert than the campaign average.
                </template>
                <template v-slot:campaignGroupCPA>
                    <span v-if="usingTargetCPA">
                        The parent campaign group
                        <b>{{ campaignGroupName }}</b> has a Target CPA of
                        <b><Money :value="campaignGroupCPA" :currency="domainCurrencyCode" /></b>.
                        This means <b>{{ improvementDeviceType }}</b> users in the campaign at the
                        top of the page cost
                        <b><Percent :value="cpaToGroupRatio" /> {{ cpaToGroupRatioLabel }}</b> to
                        convert than the campaign group Target CPA.
                    </span>
                    <span v-else>
                        The parent campaign group
                        <b>{{ campaignGroupName }}</b> has an average CPA of
                        <b><Money :value="campaignGroupCPA" :currency="domainCurrencyCode" /></b>
                        over the last <b>60</b> days. This means
                        <b>{{ improvementDeviceType }}</b> users in the campaign at the top of the
                        page cost
                        <b><Percent :value="cpaToGroupRatio" /> {{ cpaToGroupRatioLabel }}</b> to
                        convert than the campaign group average.
                    </span>
                </template>
            </ImprovementStatistics>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="30" />
        </ImprovementContent>

        <template v-slot:adjust-step-1>
            <ImprovementContent>
                <ImprovementBidAdjustment
                    ref="impBidAdjustmentRef"
                    bid-type="device"
                    :current-bid="unitBid"
                    :new-bid="recommendedBidRelativeToUnitBid"
                    :entity-pill-list="improvementEntityPillList"
                    @on-bid-update="onBidUpdate"
                >
                    <template v-slot:entity-pill>
                        <EntityPill v-if="aboveMobile" :content="campaignName" type="campaign" />
                    </template>
                    <template v-slot:text>
                        <!-- Copied from Intro -->
                        In the campaign listed above, searchers using
                        <b>{{ improvementDeviceType }}</b> devices are converting at an average cost
                        of <b>{{ formattedDeviceCPA }}</b
                        >. In comparison, the parent campaign has an average cost per conversion of
                        <b>{{ formattedCampaignCPA }}</b
                        >. This means searchers using <b>{{ improvementDeviceType }}</b> devices
                        cost
                        <Text
                            :color="cpaToCampaignRatioLabel === 'less' ? 'green' : 'red'"
                            as="span"
                            weight="600"
                        >
                            <Percent :value="cpaToCampaignRatio" />
                        </Text>
                        {{ cpaToCampaignRatioLabel }} to convert than the campaign average.
                        <!-- Spacer -->
                        <Spacer height="1rem" />
                        <!-- If currentBidMod exists -->
                        <span v-if="currentBidMod">
                            The current bid adjustment applied to this segment is
                            <Text
                                :color="currentBidMod > 0 ? 'green' : 'red'"
                                as="span"
                                weight="600"
                            >
                                <span v-if="currentBidMod > 0">+</span>
                                <Percent :value="currentBidMod / 100" /> </Text
                            >.
                            <Spacer height="1rem" />
                            Opteo recommends updating this bid adjustment to
                            <Text :color="bidModifier > 0 ? 'green' : 'red'" as="span" weight="600">
                                <span v-if="bidModifier > 0">+</span>
                                <Percent :value="bidModifier / 100" /> </Text
                            >.
                        </span>
                        <!-- If currentBidMod value is 0, or does not exist -->
                        <span v-else-if="currentBidMod === 0 || !currentBidMod">
                            Opteo recommends applying a
                            <Text :color="bidModifier > 0 ? 'green' : 'red'" as="span" weight="600">
                                <span v-if="bidModifier > 0">+</span>
                                <Percent :value="bidModifier / 100" />
                            </Text>
                            bid adjustment to this segment.
                        </span>
                    </template>
                </ImprovementBidAdjustment>
            </ImprovementContent>
        </template>
    </ImprovementView>
</template>
<script lang="ts">
import {
    ImprovementHeader,
    ImprovementContent,
    Percent,
    ImprovementNote,
    ImprovementStatistics,
    ImprovementCreatedDate,
    oInput,
    BarChart,
    DonutChart,
    Text,
    EntityPill,
    EntityPillList,
    Spacer,
    oTable,
    Money,
} from '@opteo/components-next'

import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import { useAdjustDeviceBid } from './useAdjustDeviceBid'
import ImprovementBidAdjustment from '@/components/improvement/ImprovementBidAdjustment.vue'
import useMediaQuery from '@/composition/global/useMediaQuery'

// import Money from '@/components/global/oMoney.vue'

import Ent from '@/components/util/Ent.vue'

export default {
    name: 'AdjustDeviceBid',
    components: {
        ImprovementHeader,
        ImprovementContent,
        Money,
        Percent,
        ImprovementHelpLink,
        ImprovementNote,
        ImprovementStatistics,
        ImprovementCreatedDate,
        ImprovementView,
        oInput,
        BarChart,
        DonutChart,
        Ent,
        ImprovementBidAdjustment,
        Text,
        EntityPill,
        EntityPillList,
        Spacer,
        oTable,
    },
    setup() {
        return { ...useAdjustDeviceBid(), ...useMediaQuery() }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.graph-container {
    @include container;
    @include pa-32;
}
</style>
