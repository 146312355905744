<template>
    <!-- Ad Writer Sidebar -->
    <div class="ad-writer-sidebar-mobile-title">
        <Text as="h3" size="f-3" weight="600">Write New Ad</Text>
        <oButton color="white" size="small" @clicked="clearAd()">Clear Form</oButton>
    </div>
    <div class="ad-writer-sidebar-container">
        <perfect-scrollbar class="ad-writer-sidebar">
            <oForm ref="adWriterAdForm">
                <!-- Headline One -->
                <oInput
                    ref="HeadlineOne"
                    v-model="newAd.headline_part_1"
                    name="headline"
                    label="Headline One"
                    placeholder="Headline One"
                    :max-characters="30"
                    :custom-counter="getCharCount"
                    autofocus
                    autocomplete="off"
                />
                <!-- Spacer -->
                <Spacer height="1.5rem" />
                <!-- Headline Two -->
                <oInput
                    ref="HeadlineTwo"
                    v-model="newAd.headline_part_2"
                    name="headline2"
                    label="Headline Two"
                    placeholder="Headline Two"
                    :max-characters="30"
                    :custom-counter="getCharCount"
                    autocomplete="off"
                />
                <!-- Spacer -->
                <Spacer height="1.5rem" />
                <!-- Headline Three -->
                <oInput
                    ref="HeadlineThree"
                    v-model="newAd.expanded_text_ad_headline3"
                    name="headline3"
                    label="Headline Three"
                    placeholder="Headline Three"
                    :max-characters="30"
                    :custom-counter="getCharCount"
                    autocomplete="off"
                />
                <!-- Spacer -->
                <Spacer height="1.5rem" />
                <!-- Paths -->
                <div class="w-100 relative inline-flex opteo-dis">
                    <div class="w-50 mr3 relative">
                        <oInput
                            ref="PathOne"
                            v-model="newAd.path_1"
                            name="path1"
                            label="Path One"
                            placeholder="Path One"
                            :max-characters="15"
                            autocomplete="off"
                        />
                    </div>
                    <div class="w-50 relative">
                        <oInput
                            ref="PathTwo"
                            v-model="newAd.path_2"
                            name="path2"
                            label="Path Two"
                            placeholder="Path Two"
                            :max-characters="15"
                            autocomplete="off"
                        />
                    </div>
                </div>
                <!-- Spacer -->
                <Spacer height="1.5rem" />
                <!-- Description One -->
                <oInput
                    v-model="newAd.description"
                    ref="Description"
                    name="description1"
                    type="textarea"
                    label="Description One"
                    placeholder="Description One"
                    :max-characters="90"
                    :custom-counter="getCharCount"
                    autocomplete="off"
                />
                <!-- Spacer -->
                <Spacer height="1.5rem" />
                <!-- Description Two -->
                <oInput
                    v-model="newAd.expanded_text_ad_description2"
                    ref="DescriptionTwo"
                    name="description2"
                    type="textarea"
                    label="Description Two"
                    placeholder="Description Two"
                    :max-characters="90"
                    :custom-counter="getCharCount"
                    required
                    autocomplete="off"
                />
                <!-- Spacer -->
                <Spacer height="1.5rem" />
                <oInput
                    ref="DestinationUrl"
                    v-model="newAd.destination_url"
                    name="finalURL"
                    type="url"
                    label="Final URL"
                    placeholder="https://example.com"
                    required
                    autocomplete="off"
                />
                <!-- Spacer -->
                <Spacer height="1.75rem" />
                <div class="clear-form-button">
                    <oButton color="white" @clicked="clearAd()">Clear Form</oButton>
                </div>
            </oForm>
        </perfect-scrollbar>
    </div>

    <!-- Ad Writer Content -->
    <ImprovementContent>
        <div class="ad-writer-content">
            <div class="ad-preview-container flex items-top z-9999">
                <div class="w-100">
                    <TextAd
                        :ad="{
                            headlineOne: stripAdCustomisers(`${newAd?.headline_part_1 ?? ''}`),
                            headlineTwo: stripAdCustomisers(`${newAd?.headline_part_2 ?? ''}`),
                            headlineThree: stripAdCustomisers(
                                `${newAd?.expanded_text_ad_headline3 ?? ''}`
                            ),
                            descriptionOne: stripAdCustomisers(`${newAd?.description ?? ''}`),
                            descriptionTwo: stripAdCustomisers(
                                `${newAd?.expanded_text_ad_description2 ?? ''}`
                            ),
                            displayUrl: `${getDomainFromUrl(newAd?.destination_url)}`,
                            pathOne: `${newAd?.path_1 ?? ''}`,
                            pathTwo: `${newAd?.path_2 ?? ''}`,
                        }"
                        :title-tag="{ title: 'Ad Preview', classes: 'opteo-green' }"
                    >
                    </TextAd>
                </div>
            </div>

            <!-- Current Ads -->
            <Text as="h3" size="f-3" weight="600">Current Ads</Text>
            <Spacer height="2rem" />

            <div v-if="inspirationLoading" class="skeleton">
                <div v-for="i in 1" :key="i">
                    <SkeletonTextAd />
                    <Spacer height="2rem" />
                </div>
            </div>
            <div
                v-else
                v-for="currentAd in inspirationPackage?.ads?.slice(0, 4)"
                :key="currentAd.ad_id"
                class="current-ad-container"
            >
                <TextAd
                    :ad="
                        currentAd.legacy
                            ? {
                                  headlineOne: `${currentAd.headline ?? ''}`,
                                  descriptionOne: `${currentAd.description_line1 ?? ''}`,
                                  descriptionTwo: `${currentAd.description_line2 ?? ''}`,
                                  displayUrl: `${
                                      currentAd.display_url ||
                                      getDomainFromUrl(newAd.destination_url)
                                  }`,
                              }
                            : {
                                  headlineOne: `${currentAd.headline1 ?? ''}`,
                                  headlineTwo: `${currentAd.headline2 ?? ''}`,
                                  headlineThree: `${currentAd.expanded_text_ad_headline3 ?? ''}`,
                                  descriptionOne: `${currentAd.description ?? ''}`,
                                  descriptionTwo: `${
                                      currentAd.expanded_text_ad_description2 ?? ''
                                  }`,
                                  displayUrl: `${currentAd.display_url ?? ''}`,
                              }
                    "
                    :featured-statistics="[
                        {
                            name: 'CTR',
                            content: unref(usePercent({ value: currentAd.ctr / 100 }).displayValue),
                            color: 'green',
                        },
                        {
                            name: 'CPI',
                            content: unref(usePercent({ value: currentAd.cpi / 100 }).displayValue),
                            color: 'green',
                        },
                        { name: 'Clicks', content: `${currentAd.clicks}`, color: 'green' },
                        {
                            name: 'Abs. Top Impr. Share',
                            content: unref(
                                usePercent({ value: currentAd.impr_abs_top }).displayValue
                            ),
                            color: 'green',
                        },
                    ]"
                >
                </TextAd>

                <oButton
                    @clicked="prefill(currentAd)"
                    v-tippy="{
                        animateFill: 'false',
                        animation: 'shift-away',
                        duration: '250',
                        placement: 'top',
                        theme: 'circle-icon',
                        distance: 8,
                    }"
                    :circle="true"
                    :content="`<span class='f7 lh-copy'>Copy Ad</span>`"
                    color="white"
                    class="copy-circle"
                >
                    <template v-slot:icon>
                        <CopyAdIcon />
                    </template>
                </oButton>
            </div>
            <!-- Spacer -->
            <Spacer height="2rem" />

            <!-- Paused Ads -->
            <Text as="h3" size="f-3" weight="600">Paused Ads</Text>
            <Spacer height="2rem" />
            <div v-if="inspirationLoading" class="skeleton">
                <div v-for="i in 4" :key="i">
                    <SkeletonTextAd />
                    <Spacer height="2rem" />
                </div>
            </div>
            <div v-else-if="!pausedAds?.length">
                <div class="no-ads-container">
                    <Text as="span" size="f-8">No paused ads found in this adgroup.</Text>
                </div>
            </div>
            <div
                v-else
                v-for="pausedAd in pausedAds?.slice(0, 4)"
                :key="pausedAd.ad_id"
                class="paused-ad-container"
            >
                <TextAd
                    :ad="
                        pausedAd.legacy
                            ? {
                                  headlineOne: `${pausedAd.headline ?? ''}`,
                                  descriptionOne: `${pausedAd.description_line1 ?? ''}`,
                                  descriptionTwo: `${pausedAd.description_line2 ?? ''}`,
                                  displayUrl: `${
                                      pausedAd.display_url ||
                                      getDomainFromUrl(newAd.destination_url)
                                  }`,
                              }
                            : {
                                  headlineOne: `${pausedAd.headline1 ?? ''}`,
                                  headlineTwo: `${pausedAd.headline2 ?? ''}`,
                                  headlineThree: `${pausedAd.expanded_text_ad_headline3 ?? ''}`,
                                  descriptionOne: `${pausedAd.description ?? ''}`,
                                  descriptionTwo: `${pausedAd.expanded_text_ad_description2 ?? ''}`,
                                  displayUrl: `${
                                      pausedAd.display_url ||
                                      getDomainFromUrl(newAd.destination_url)
                                  }`,
                                  pathOne: `${pausedAd.path1 ?? ''}`,
                                  pathTwo: `${pausedAd.path2 ?? ''}`,
                              }
                    "
                    :featured-statistics="[
                        {
                            name: 'CTR',
                            content: unref(usePercent({ value: pausedAd.ctr / 100 }).displayValue),
                            color: 'green',
                        },
                        {
                            name: 'CPI',
                            content: unref(usePercent({ value: pausedAd.cpi / 100 }).displayValue),
                            color: 'green',
                        },
                        { name: 'Clicks', content: `${pausedAd.clicks}`, color: 'green' },
                        {
                            name: 'Abs. Top Impr. Share',
                            content: unref(
                                usePercent({ value: pausedAd.impr_abs_top }).displayValue
                            ),
                            color: 'green',
                        },
                    ]"
                >
                </TextAd>

                <oButton
                    @clicked="prefill(pausedAd)"
                    v-tippy="{
                        animateFill: 'false',
                        animation: 'shift-away',
                        duration: '250',
                        placement: 'top',
                        theme: 'circle-icon',
                        distance: 8,
                    }"
                    :circle="true"
                    :content="`<span class='f7'>Copy Ad</span>`"
                    class="copy-circle"
                    color="white"
                >
                    <template v-slot:icon>
                        <CopyAdIcon />
                    </template>
                </oButton>
            </div>
            <!-- Spacer -->
            <Spacer height="2rem" />
            <!-- Competitor Ads -->
            <Text as="h3" size="f-3" weight="600">Competitor Ads</Text>
            <Spacer height="2rem" />
            <div v-if="!competitorAds?.length">
                <div class="no-ads-container">
                    <Text as="span" size="f-8">No competitor ads found for this search term.</Text>
                </div>
            </div>
            <div
                v-else
                v-for="(competitorAd, index) in competitorAds.slice(0, 4)"
                :key="index"
                class="competitor-ad-container"
            >
                <TextAd
                    :ad="{
                        headlineOne: `${competitorAd.headline_part1 ?? ''}`,
                        headlineTwo: `${competitorAd.headline_part2 ?? ''}`,
                        headlineThree: `${competitorAd.headline_part3 ?? ''}`,
                        descriptionOne: `${competitorAd.description ?? ''}`,
                        descriptionTwo: `${competitorAd.description2 ?? ''}`,
                        displayUrl: `${competitorAd.domain ?? ''}`,
                        pathOne: `${competitorAd.path1 ?? ''}`,
                        pathTwo: `${competitorAd.path2 ?? ''}`,
                    }"
                >
                </TextAd>

                <oButton
                    @clicked="prefill(competitorAd)"
                    v-tippy="{
                        animateFill: 'false',
                        animation: 'shift-away',
                        duration: '250',
                        placement: 'top',
                        theme: 'circle-icon',
                        distance: 8,
                    }"
                    :circle="true"
                    :content="`<span class='f7'>Copy Ad</span>`"
                    color="white"
                    class="copy-circle"
                >
                    <template v-slot:icon>
                        <CopyAdIcon />
                    </template>
                </oButton>
            </div>
            <!-- Spacer -->
            <Spacer height="2rem" />
            <!-- SERP Preview -->
            <Text as="h3" size="f-3" weight="600">SERP Preview</Text>
            <Spacer height="2rem" />
            <div class="w-100">
                <SerpPreview title="" :image-url="buildSerpImageUrl()" />
            </div>
        </div>
    </ImprovementContent>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import {
    ImprovementHeader,
    ImprovementNote,
    ImprovementContent,
    oButton,
    oInput,
    Percent,
    ImprovementStatistics,
    ImprovementCreatedDate,
    Spacer,
    Text,
    Ent,
    Money,
    TextAd,
    Link,
    oForm,
    SerpPreview,
} from '@opteo/components-next'

import { useAdWriter, Props, EmittedEvent } from './useAdWriter'
import CopyAdIcon from './CopyAdIcon.vue'
import SkeletonTextAd from './SkeletonTextAd.vue'

export default defineComponent({
    name: 'AdWriter',
    props: {
        label: {
            required: false,
            type: String,
        },
        domainId: {
            required: false,
            type: Number,
        },
        improvementId: {
            required: true,
            type: Number,
        },
        campaignId: {
            required: true,
            type: Number,
        },
        adgroupId: {
            required: true,
            type: Number,
        },
        adgroup_label: {
            required: false,
            type: String,
        },
        competitorTerms: {
            required: false,
            type: Array,
            default: [],
        },
    },
    emits: ['ad-writer-resolve'],
    setup(props: Props, { emit }: { emit: EmittedEvent }) {
        return useAdWriter(props, emit)
    },
    components: {
        ImprovementHeader,
        ImprovementContent,
        ImprovementNote,
        Percent,
        Money,
        ImprovementStatistics,
        ImprovementCreatedDate,
        Ent,
        Spacer,
        Text,
        TextAd,
        Link,
        oForm,
        oButton,
        oInput,
        SerpPreview,
        CopyAdIcon,
        SkeletonTextAd,
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.ad-writer-sidebar-container {
    @include container;
    // 100vh - improvementFooter - improvementFooterMargin - sidebarMargin
    max-height: calc(100vh - 5rem - 1.5rem - 4rem);
    height: auto;
    position: fixed;
    top: 2rem;
    left: calc(50vw - 21.0625rem - 13.1875rem);
    z-index: 5;
    background: $opteo-white;
}
.clear-form-button {
    @include sticky;
    @include z-2;
    bottom: 0;
    width: 100%;
    @include flex;
    @include justify-center;
}

.ad-writer-sidebar-title {
    @include flex;
    @include items-center;
    @include justify-between;
    @include ph-32;
    height: 5rem;
    border-bottom: 1px solid;
    @include opteo-border;
}
.ad-writer-sidebar {
    width: 22.5rem;
    // 100vh - improvementFooter - improvementFooterMargin - sidebarMargin
    max-height: calc(100vh - 5rem - 1.5rem - 4rem);
    height: 100%;
    @include pa-32;
}

.ad-writer-content {
    padding: 0;
    width: 42.5rem;
    margin-left: calc(50% - 21.25rem + 13.1875rem);
}
.skeleton {
    overflow: auto;
    padding: 2px;
    width: calc(100% - 2px);
}
.ad-preview-container {
    min-height: 15rem;
}
.inline-ad-writer-form {
    display: none;
}

.current-ad-container,
.paused-ad-container,
.competitor-ad-container {
    @include relative;
    @include mb-32;
}
.copy-circle {
    width: 4rem !important;
    height: 4rem !important;
    right: -2rem;
    top: calc(50% - 2rem);
    z-index: 2;
    position: absolute !important;
}

.no-ads-container {
    @include container;
    @include pa-32;
    @include mb-32;
}

.ad-writer-sidebar-mobile-title {
    display: none;
}

// Responsive
@media screen and (min-width: 0px) and (max-width: 767px) {
    .ad-writer-sidebar-mobile-title {
        @include flex;
        @include items-center;
        @include justify-between;
        @include mb-32;
        width: calc(100% - calc(3rem + 4px));
        margin-left: calc(1.5rem + 2px);
        margin-right: calc(1.5rem + 2px);
    }
    .ad-writer-sidebar-container {
        display: block;
        position: relative;
        left: 0;
        top: 0;
        max-height: unset;
        width: calc(100% - calc(3rem + 4px));
        margin-left: calc(1.5rem + 2px);
        margin-right: calc(1.5rem + 2px);
        @include mb-64;
    }
    .ad-writer-sidebar {
        max-height: unset;
        width: 100%;
        @include pa-24;
    }
    .ad-writer-sidebar-title {
        display: none;
    }
    .ad-writer-content {
        margin-left: 0;
        width: auto;
    }
    .copy-circle {
        width: 4rem !important;
        height: 4rem !important;
        right: -1.25rem;
        top: calc(0px - 1.25rem);
        z-index: 2;
        position: absolute !important;
    }
}
@media screen and (min-width: 768px) and (max-width: 1220px) {
    .ad-writer-sidebar-mobile-title {
        @include flex;
        @include items-center;
        @include justify-between;
        @include mb-32;
        max-width: 37.5rem;
        margin-left: auto;
        margin-right: auto;
    }
    .ad-writer-sidebar-container {
        display: block;
        position: relative;
        margin: 0 calc(50% - 18.75rem);
        left: 0;
        top: 0;
        max-height: unset;
        width: 100%;
        @include mb-64;
        max-width: 37.5rem;
    }
    .ad-writer-sidebar {
        max-height: unset;
        width: 100%;
    }
    .ad-writer-sidebar-title {
        display: none;
    }
    .ad-writer-content {
        margin-left: 0;
        width: auto;
    }
}
</style>
