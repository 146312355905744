<template>
    <div class="experiment-container">
        <!-- Header -->
        <header class="header">
            <div class="left">
                <Avatar
                    v-if="domainColour && domainInitials"
                    :platform="Platform.Platform.GoogleAds"
                    :color="domainColour"
                    :width="40"
                    :height="40"
                    showAccountType
                >
                    {{ domainInitials }}
                </Avatar>
                <Skeleton v-else :width="40" :height="40" />
                <Spacer width="1.75rem" />
                <Text as="h1" weight="600">{{ baseBidStrategy }}</Text>
                <div class="vs">vs</div>
                <Text as="h1" weight="600"> {{ experimentBiddingStrategy }} </Text>
            </div>
            <Transition name="fade">
                <RsaButton
                    v-if="!experimentIsComplete"
                    value="Cancel Experiment"
                    @click="deleteModal = !deleteModal"
                >
                    <rsaBinIcon />
                </RsaButton>
            </Transition>
            <Modal v-model="deleteModal" width="528px" title="Cancel Experiment">
                <template v-slot:content>
                    <Text as="p" size="f-8">
                        To cancel this experiment, click the <b>Cancel Experiment</b> button below.
                        This will reset your campaign back to its original state and remove the
                        experiment campaign in Google Ads. <br /><br />
                        You will be able to see a record of this experiment and access all of the
                        data collected in the
                        <b>Completed</b> experiments tab.
                    </Text>
                </template>
                <template v-slot:buttons>
                    <oButton color="white" @clicked="deleteModal = false">Close Modal</oButton>
                    <Spacer width="0.625rem" />
                    <Tooltip
                        :content="
                            !experimentDeletable
                                ? 'Experiment created too recently. Please wait a moment.'
                                : ''
                        "
                    >
                        <oButton
                            ref="deleteExperimentButton"
                            :loading="deleteExperimentLoading"
                            :disabled="!experimentDeletable"
                            color="red"
                            @clicked="deleteExperiment"
                        >
                            Cancel Experiment
                        </oButton>
                    </Tooltip>
                </template>
            </Modal>
        </header>
        <Spacer height="4.5rem" />
        <div class="content">
            <!-- Overall Comparison -->
            <div class="section-container">
                <header class="comparison-header">
                    <Text as="h3" size="f-4" weight="600">Overall Comparison</Text>
                    <!-- Target -->
                    <div class="confidence-score-donut">
                        <ScorecardDonut
                            :score="(confidenceScore ?? 0) * 100"
                            :compact="true"
                            :width="28"
                            hideScore
                            :strokeWidth="3"
                            :customColor="
                                Math.round(confidenceScore) >= 0.95 ? '#00A861' : '#006DFF'
                            "
                        />
                        <div class="confidence-score-donut-content">
                            <Text
                                as="h4"
                                size="f-6"
                                weight="600"
                                :color="Math.round(confidenceScore) >= 0.95 ? 'green' : 'blue'"
                            >
                                <Percent :value="confidenceScore" :decimal-places="0" />
                                Confidence
                            </Text>
                            <Text
                                v-if="Math.round(confidenceScore) >= 0.95"
                                as="span"
                                color="gray"
                                size="f-9"
                                style="font-size: 0.75rem"
                            >
                                Data is statistically significant
                            </Text>
                            <Text
                                v-else
                                as="span"
                                color="gray"
                                size="f-9"
                                style="font-size: 0.75rem"
                            >
                                <span v-if="experimentIsComplete">Experiment inconclusive</span>
                                <span v-else>Experiment gathering data</span>
                            </Text>
                        </div>
                    </div>
                </header>
                <Spacer height="1.75rem" />
                <oTable
                    :headers="experimentTableHeaders"
                    :items="overallComparisonItems"
                    fixed-layout
                    :borderRadius="24"
                >
                    <template #header.campaign>
                        <div class="pl-24">Experiment Group</div>
                    </template>
                    <!-- Campaign column -->
                    <template v-slot:column.campaign="campaign">
                        <div class="campaign-name table-cell overflow">
                            <EntityPill
                                v-if="campaign.row.isExperiment"
                                type="experiment"
                                :content="campaign.value"
                            />
                            <EntityPill v-else type="campaign" :content="campaign.value" />

                            <!-- Experiment Winning / Losing / Learning Labels -->
                            <ColorTag v-if="isExperimentLearning" color="blue" title="Learning" />
                            <ColorTag
                                v-else-if="
                                    currentExperiment?.isExperimentWinning === null &&
                                    (percentComplete > 0.95 || experimentIsComplete)
                                "
                                color="gray"
                                title="Inconclusive"
                            />
                            <ColorTag
                                v-else-if="
                                    currentExperiment?.isExperimentWinning === null &&
                                    percentComplete <= 0.95 &&
                                    !experimentIsComplete
                                "
                                color="blue"
                                title="In Progress"
                            />
                            <ColorTag
                                v-else-if="
                                    isExperimentCampaignsWinning && campaign.row.isExperiment
                                "
                                color="green"
                                :title="experimentIsComplete ? 'Winner' : 'Winning'"
                            />
                            <ColorTag
                                v-else-if="
                                    !isExperimentCampaignsWinning && campaign.row.isExperiment
                                "
                                color="red"
                                :title="experimentIsComplete ? 'Loser' : 'Losing'"
                            />
                            <ColorTag
                                v-else-if="
                                    !isExperimentCampaignsWinning && !campaign.row.isExperiment
                                "
                                color="green"
                                :title="experimentIsComplete ? 'Winner' : 'Winning'"
                            />
                            <ColorTag
                                v-else-if="
                                    isExperimentCampaignsWinning && !campaign.row.isExperiment
                                "
                                color="red"
                                :title="experimentIsComplete ? 'Loser' : 'Losing'"
                            />
                        </div>
                    </template>

                    <template v-slot:column.cost="cost">
                        <Money :value="cost.value" />
                    </template>

                    <template v-slot:column.allConversions="allConversions">
                        <Number :value="allConversions.value" />
                    </template>

                    <template v-slot:column.allConversionsValue="allConversionsValue">
                        <Money :value="allConversionsValue.value" />
                    </template>

                    <template v-slot:column.cpa="cpa">
                        <Money :value="cpa.value" />
                    </template>

                    <template v-slot:column.roas="roas">
                        <Roas :value="roas.value" />
                    </template>

                    <template v-slot:column.impressions="impressions">
                        <Number :value="impressions.value" />
                    </template>
                </oTable>
            </div>
            <Spacer height="2rem" />
            <!-- Campaign Selection -->
            <div class="section-container">
                <header>
                    <Text as="h4" weight="600">Campaign Selection</Text>
                    <Spacer width="1.5rem" />
                    <Text
                        v-if="!experimentIsComplete"
                        as="p"
                        size="f-10"
                        style="font-size: 0.75rem; width: 22rem; color: rgba(0, 0, 0, 0.32)"
                    >
                        Opteo automatically selects campaigns with better performance. You can also
                        select campaigns manually using the section below.
                    </Text>
                </header>
                <Spacer height="1.75rem" />
                <div class="comparison-pairs">
                    <div v-for="pair in campaignExperimentPairsItems" :key="'pair-' + pair[0].id">
                        <oTable
                            :headers="experimentTableHeaders"
                            :items="pair"
                            fixed-layout
                            :borderRadius="24"
                        >
                            <template #header.campaign>
                                <div class="pl-24">Select Campaign</div>
                            </template>
                            <template #column.campaign="row">
                                <div
                                    class="campaign-name table-cell overflow"
                                    style="cursor: pointer; gap: 0.875rem"
                                    @click="setWinning(row.row)"
                                >
                                    <CheckIcon
                                        animated
                                        checkable
                                        :color="!experimentIsComplete ? 'blue' : 'green'"
                                        :checked="selectWinning(row.row)"
                                        :style="
                                            !experimentIsComplete
                                                ? { cursor: 'pointer' }
                                                : { cursor: 'default' }
                                        "
                                        style="flex-shrink: 0"
                                    />
                                    <EntityPill
                                        v-if="row.row.isExperiment"
                                        type="experiment"
                                        :content="row.value"
                                    />
                                    <EntityPill v-else type="campaign" :content="row.value" />
                                </div>
                            </template>

                            <template v-slot:column.cost="cost">
                                <Money :value="cost.value" />
                            </template>

                            <template v-slot:column.allConversions="allConversions">
                                <Number :value="allConversions.value" />
                            </template>

                            <template v-slot:column.allConversionsValue="allConversionsValue">
                                <Money :value="allConversionsValue.value" />
                            </template>

                            <template v-slot:column.impressions="impressions">
                                <Number :value="impressions.value" />
                            </template>

                            <template v-slot:column.cpa="cpa">
                                <Money :value="cpa.value" />
                            </template>

                            <template v-slot:column.roas="roas">
                                <Roas :value="roas.value" />
                            </template>
                        </oTable>
                    </div>
                </div>
            </div>
            <Spacer height="2rem" />
            <!-- Performance Graphs -->
            <div class="section-container">
                <header>
                    <Text as="h4" weight="600">Performance Graphs</Text>
                </header>
                <Spacer height="1.75rem" />

                <NoChartData v-if="showGraphEmptyState" :empty-state="showGraphEmptyState" />

                <div v-else>
                    <OnboardingModal
                        v-model="discardedGraphDataModal"
                        @closed="!discardedGraphDataModal"
                        button-text="Okay, thanks"
                        style="border-radius: 16"
                    >
                        <template #copy>
                            For experiments using smart bidding strategies, Opteo automatically
                            discounts the first week of data. This is because these strategies have
                            an initial Learning phase that should be excluded from any data
                            comparison.
                        </template>
                    </OnboardingModal>

                    <Spacer v-if="discardedGraphDataModal" height="1.5rem" />

                    <PerformanceChartContainer
                        :chart-label="chart?.primaryChart.chartLabel"
                        :current-values="chart?.primaryChart.currentValues"
                    >
                        <LineChart
                            :series="chart?.primaryChart.data"
                            :metric="chart?.primaryChart.metric"
                            :show-zero="true"
                            disable-fallback
                        />
                    </PerformanceChartContainer>

                    <Spacer height="1.5rem" />

                    <PerformanceChartContainer
                        :chart-label="chart?.secondaryChart.chartLabel"
                        :current-values="chart?.secondaryChart.currentValues"
                    >
                        <LineChart
                            :series="chart?.secondaryChart.data"
                            :metric="chart?.secondaryChart.metric"
                            :show-zero="true"
                            disable-fallback
                        />
                    </PerformanceChartContainer>
                </div>
            </div>
            <Spacer height="2rem" />
            <!-- Experiment Settings -->
            <div class="section-container">
                <header>
                    <Text as="h4" weight="600">Experiment Settings</Text>
                </header>
                <Spacer height="1.75rem" />
                <div class="settings-container">
                    <!-- Name -->
                    <section class="section-settings">
                        <Label>Name</Label>
                        <Text as="h5" weight="600" style="letter-spacing: -0.045rem">{{
                            currentExperiment?.name
                        }}</Text>
                    </section>
                    <!-- Description -->
                    <section v-if="currentExperiment?.description" class="section-settings">
                        <Label>Description</Label>
                        <Text
                            as="p"
                            size="f-8"
                            style="
                                -webkit-line-clamp: 3;
                                display: -webkit-box !important;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                            "
                        >
                            {{ currentExperiment?.description }}
                        </Text>
                    </section>
                    <section class="section-settings-flex">
                        <div class="left">
                            <Label>Base Bidding Strategy</Label>
                            <Text as="h6" size="f-6" weight="500">{{ baseBidStrategy }}</Text>
                        </div>
                        <div class="right">
                            <Label>Experiment Bidding Strategy</Label>
                            <Text as="h6" size="f-6" weight="500">
                                {{ experimentBiddingStrategy }}
                            </Text>
                        </div>
                    </section>
                    <section v-if="experimentIsManualCpc" class="section-settings-flex">
                        <div class="left">
                            <Label>Maximum Ad Group CPC Bid Limit</Label>
                            <Text as="h6" size="f-6" weight="500">
                                <Money :value="currentExperiment?.settings.maxAdGroupCpcBidLimit" />
                            </Text>
                        </div>
                        <div class="right">
                            <Label>Experiment Uses Enhanced CPC?</Label>
                            <Text as="h6" size="f-6" weight="500">
                                {{ experimentUsesEnhancedCpc ? 'Yes' : 'No' }}
                            </Text>
                        </div>
                    </section>
                    <section v-if="experimentIsTargetRoas" class="section-settings-flex">
                        <div class="left">
                            <Label>Target ROAS</Label>
                            <Text as="h6" size="f-6" weight="500">
                                {{ `${currentExperiment?.settings.targetRoas * 100}%` }}
                            </Text>
                        </div>
                        <div class="right" v-if="experimentHasMaxCpc">
                            <Label>Maximum CPC Bid Limit</Label>
                            <Text as="h6" size="f-6" weight="500">
                                <Money :value="maxCpcBidLimit" />
                            </Text>
                        </div>
                    </section>
                    <section v-if="experimentIsTargetCpa" class="section-settings-flex">
                        <div class="left">
                            <Label>Target CPA</Label>
                            <Text as="h6" size="f-6" weight="500">
                                <Money :value="currentExperiment?.settings.targetCpa" />
                            </Text>
                        </div>
                        <div class="right" v-if="experimentHasMaxCpc">
                            <Label>Maximum CPC Bid Limit</Label>
                            <Text as="h6" size="f-6" weight="500">
                                <Money :value="maxCpcBidLimit" />
                            </Text>
                        </div>
                    </section>
                    <section v-if="experimentIsTargetImpShare" class="section-settings-flex">
                        <div class="left">
                            <Label>Where do you want ads to appear?</Label>
                            <Text as="h6" size="f-6" weight="500">
                                {{ targetImpressionShareLocation }}
                            </Text>
                        </div>
                        <div class="right">
                            <Label>Impression Share Target</Label>
                            <Text as="h6" size="f-6" weight="500">
                                <Percent
                                    :value="currentExperiment?.settings.impressionSharePercent"
                                />
                            </Text>
                        </div>
                    </section>
                    <section
                        v-if="experimentIsTargetImpShare && experimentHasMaxCpc"
                        class="section-settings"
                    >
                        <Label>Maximum CPC Bid Limit</Label>
                        <Text as="h6" size="f-6" weight="500">
                            <Money :value="maxCpcBidLimit" />
                        </Text>
                    </section>
                    <section class="section-settings">
                        <div class="section-content">
                            <Label>Budget Allocation</Label>
                            <ProgressBar
                                id="budgetAllocation"
                                :progress="currentExperiment?.baseBudgetAllocation / 100"
                                :barColor="'rgba(0, 109, 255, 1)'"
                                :background-color="'rgba(0, 109, 255, 0.3)'"
                            />
                            <Spacer height="0.625rem" />
                            <div class="budget-labels-container">
                                <div class="budget-label">
                                    <svg width="9" height="9" viewBox="0 0 9 9" fill="none">
                                        <circle
                                            cx="4.5"
                                            cy="4.5"
                                            r="4.5"
                                            fill="rgba(0, 109, 255, 1)"
                                        />
                                    </svg>
                                    <Spacer width="0.5rem" />
                                    <span class="budget-label-text">
                                        Base Campaigns
                                        <b>{{ currentExperiment?.baseBudgetAllocation }}%</b>
                                    </span>
                                </div>
                                <div class="budget-label">
                                    <svg width="9" height="9" viewBox="0 0 9 9" fill="none">
                                        <circle
                                            cx="4.5"
                                            cy="4.5"
                                            r="4.5"
                                            fill="rgba(0, 109, 255, 0.3)"
                                        />
                                    </svg>
                                    <Spacer width="0.5rem" />
                                    <span class="budget-label-text">
                                        Experiment Campaigns
                                        <b>{{ 100 - currentExperiment?.baseBudgetAllocation }}%</b>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="section-settings">
                        <Label>Experiment Duration</Label>
                        <div style="display: flex; align-items: center">
                            <Text as="h6" size="f-6" weight="500">{{ totalDays }} Days</Text>
                            <Spacer width="0.625rem" height="0.625rem" />
                            <!--
                                This is if the experiment is complete but still has days left,
                                i.e. has ended early. This needs to be done better by recording
                                the *actual* end date of the experiment.
                            -->

                            <ColorTag
                                v-if="
                                    experimentIsComplete &&
                                    isExperimentEndEarly(
                                        currentExperiment?.endDate!,
                                        currentExperiment?.actualEndDate!
                                    )
                                "
                                color="gray"
                                title="Experiment Ended Early"
                                style="display: inline-flex"
                            />
                            <!-- If experiment is cancelled -->
                            <ColorTag
                                v-else-if="
                                    currentExperiment?.status ===
                                    SmartBiddingExperiments.OpteoExperimentStatus.CANCELLED
                                "
                                color="gray"
                                title="Experiment Cancelled"
                                style="display: inline-flex"
                            />
                            <!-- If experiment is complete -->
                            <ColorTag
                                v-else-if="experimentIsComplete"
                                color="green"
                                title="Experiment Complete"
                                style="display: inline-flex"
                            />

                            <!-- Else days remaining -->
                            <ColorTag
                                v-else
                                color="blue"
                                :title="daysLeft + ' Days Remaining'"
                                style="display: inline-flex"
                            />
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <!-- Experiment Progress + Apply -->
        <div class="experiment-progress-wrapper">
            <div class="experiment-progress-container">
                <!-- In Progress -->
                <Text
                    v-if="!experimentIsComplete && percentComplete < 1"
                    as="h6"
                    size="f-6"
                    weight="600"
                    color="blue"
                >
                    <Percent :value="percentComplete" :decimalPlaces="0" /> Complete
                </Text>
                <!-- Awaiting Decision -->
                <Text
                    v-else-if="!experimentIsComplete && percentComplete === 1"
                    as="h6"
                    size="f-6"
                    weight="600"
                    color="green"
                >
                    Awaiting Decision
                </Text>
                <!-- Cancelled -->
                <Text
                    v-else-if="
                        currentExperiment?.status ===
                        SmartBiddingExperiments.OpteoExperimentStatus.CANCELLED
                    "
                    as="h6"
                    size="f-6"
                    weight="600"
                    color="gray"
                >
                    Experiment Cancelled
                </Text>
                <!-- Complete -->
                <Text v-else as="h6" size="f-6" weight="600" color="green">
                    Experiment Complete
                </Text>
                <Spacer height="0.625rem" />
                <!-- Progress Bar -->
                <ProgressBar
                    id="progressBar"
                    :progress="experimentIsComplete ? 1 : percentComplete"
                    :barColor="barColor"
                />
                <Spacer height="0.5rem" />
                <!-- Sub-line -->
                <Text
                    v-if="!experimentIsComplete && percentComplete > 0.95"
                    as="span"
                    size="f-9"
                    style="font-size: 0.75rem"
                    color="gray"
                >
                    Opteo recommends applying this experiment to
                    <b>
                        {{ winningExperimentsCountDefault }} of
                        {{ campaignExperimentPairsItems.length }} campaigns.
                    </b>
                </Text>
                <Text
                    v-else-if="!experimentIsComplete"
                    as="span"
                    size="f-9"
                    style="font-size: 0.75rem"
                    color="gray"
                >
                    Opteo needs to gather more data before making a recommendation.
                </Text>
                <Text v-else as="span" size="f-9" style="font-size: 0.75rem" color="gray">
                    Experiment applied to
                    <b>
                        {{ winningExperimentsCount }} of
                        {{ campaignExperimentPairsItems.length }} campaigns.
                    </b>
                </Text>
            </div>
            <!-- Button with Popout -->
            <Popout
                v-if="percentComplete <= 0.95 && !experimentIsComplete"
                v-model="showExperimentIncompletePopout"
                placement="top"
                :externalToggleButton="true"
                :zIndex="9999999999"
                :popperOptions="{
                    strategy: 'fixed',
                }"
                :offset="[0, 16]"
                maxWidth="24.25rem"
            >
                <oButton
                    size="extra-large"
                    color="blue"
                    @clicked="showExperimentIncompletePopout = true"
                >
                    Promote Selected Campaigns
                    <template v-slot:icon>
                        <svg width="12" height="12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M1 11 11 1M11 1H3M11 1v8"
                                stroke="#fff"
                                stroke-width="2"
                                stroke-linecap="round"
                            />
                        </svg>
                    </template>
                </oButton>
                <template v-slot:content>
                    <div class="experiment-completed-popout-container">
                        <Text as="p" size="f-8" align="center">
                            Opteo recommends waiting another <b>{{ daysLeft }} days</b> to give this
                            experiment enough time to collect the required data to make a
                            statistically confident decision.
                        </Text>
                        <Spacer height="1.25rem" />
                        <div class="buttons">
                            <oButton
                                @clicked="showExperimentIncompletePopout = false"
                                color="white"
                            >
                                Cancel
                            </oButton>
                            <oButton color="green" @clicked="completeExperiment()">
                                Apply Selected Experiments
                            </oButton>
                        </div>
                    </div>
                </template>
            </Popout>
            <!-- Button -->
            <oButton
                v-else-if="percentComplete > 0.95 && !experimentIsComplete"
                size="extra-large"
                color="green"
                @clicked="completeExperiment()"
            >
                {{
                    winningExperimentsCount > 0
                        ? 'Apply Selected Experiments'
                        : 'Complete Experiment'
                }}
                <template v-slot:icon>
                    <svg width="12" height="12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1 11 11 1M11 1H3M11 1v8"
                            stroke="#fff"
                            stroke-width="2"
                            stroke-linecap="round"
                        />
                    </svg>
                </template>
            </oButton>
        </div>
    </div>
</template>

<script lang="ts">
// Imports
import { ref, unref, computed, onMounted, onUnmounted } from 'vue'
import anime from 'animejs/lib/anime.es.js'
import { SmartBiddingExperiments, Platform } from '@opteo/types'
import { enums } from '@opteo/types/gads'
import { useDomain } from '@/composition/domain/useDomain'
import { useDomainMoney } from '@/composition/domain/useDomainMoney'
import {
    useExperiments,
    PerformanceMetricTypes,
    CampaignPerformanceData,
    biddingStrategyTypeMap,
} from '@/composition/toolkit/experiments/useExperiments'

// Components
import {
    Modal,
    oButton,
    oTable,
    Roas,
    Number,
    Spacer,
    LineChart,
    CheckIcon,
    Percent,
    ScorecardDonut,
    Tooltip,
    Popout,
    PerformanceChartContainer,
    Text,
    useRoas,
    useNumber,
    CircleCampaignIcon,
    CircleAdgroupIcon,
    CircleExperimentIcon,
    ColorTag,
    OnboardingModal,
    Avatar,
    oInput,
    Label,
    RsaButton,
    rsaBinIcon,
    EntityPill,
    usePercent,
} from '@opteo/components-next'

// Local Components
import Money from '@/components/global/oMoney.vue'
import Skeleton from '@/components/util/Skeleton.vue'
import ProgressBar from '@/components/global/ProgressBar.vue'
import NoChartData from '@/components/biddingExperiments/NoChartData.vue'
import { authRequest, Endpoint } from '@/composition/api/useAPI'
import router from '@/router'
import { Routes } from '@/router/routes'
import { delay } from '@/lib/globalUtils'

// Table Items
type CpaItems = {
    allConversions: number
    cpa: number
}
type RoasItems = {
    allConversionsValue: number
    roas: number
}
type TargetImpressionShareItems = {
    allConversions: number
    impressions: number
}
type CommonItems = {
    id?: number
    campaign: string
    clicks: number
    cost: number
    genericName: 'Base Campaign' | 'Experiment Campaign'
    strategy: (typeof biddingStrategyTypeMap)[keyof typeof biddingStrategyTypeMap]
    isExperiment: boolean
}

type TableItems = CommonItems & (CpaItems | RoasItems | TargetImpressionShareItems)

// Chart Items
type Label = 'CPA' | 'ROAS' | 'Search impression share' | 'Conv.' | 'Value'

const chartMetricLabelToChartTitle = {
    CPA: 'Cost Per Conversion',
    ROAS: 'Return On Ad Spend',
    ['Search Imp. Share']: 'Search Impression Share',
    ['Conv.']: 'Conversions',
    ['Value']: 'Conversion Value',
}

type Chart = Record<
    'primaryChart' | 'secondaryChart',
    {
        chartLabel: string
        metric: {
            label: Label
            dataType: 'number' | 'money' | 'cpa' | 'roas'
            currency?: string
        }
        data: {
            name: `Base ${Label}` | `Experiment ${Label}`
            items: {
                x: Date
                y: number
            }[]
        }[]
        currentValues: string[]
    }
>

export default {
    components: {
        Modal,
        oInput,
        Label,
        oButton,
        oTable,
        Money,
        Roas,
        Number,
        Spacer,
        LineChart,
        CheckIcon,
        Percent,
        ScorecardDonut,
        Tooltip,
        Popout,
        PerformanceChartContainer,
        Text,
        CircleCampaignIcon,
        CircleAdgroupIcon,
        CircleExperimentIcon,
        ProgressBar,
        ColorTag,
        NoChartData,
        OnboardingModal,
        Avatar,
        Skeleton,
        RsaButton,
        rsaBinIcon,
        EntityPill,
    },
    name: 'ExperimentModal',
    setup() {
        const {
            getDataSetup,
            getIsExperimentCompleted,
            getIsExperimentLearning,
            getPercentComplete,
            getDaysLeft,
            getTotalDays,
            isExperimentEndEarly,
            formatBiddingStrategy,
            currentExperiment,
            completeExperiment,
            mutateGetCampaignsAndExperiments,
            mutateGetAccountExperimentData,
        } = useExperiments()

        const baseBidStrategy = computed(() => {
            if (!currentExperiment.value) return
            return formatBiddingStrategy(currentExperiment.value.currentBiddingStrategy)
        })

        const experimentBiddingStrategy = computed(() => {
            if (!currentExperiment.value) return
            return formatBiddingStrategy(
                currentExperiment.value.settings.experimentBiddingStrategy,
                currentExperiment.value.settings
            )
        })

        const experimentIsManualCpc = computed(() => {
            if (!currentExperiment.value) return false
            return (
                currentExperiment.value.settings.experimentBiddingStrategy ===
                enums.BiddingStrategyType.MANUAL_CPC
            )
        })

        const experimentUsesEnhancedCpc = computed(() => {
            if (!currentExperiment.value) return false
            return (
                currentExperiment.value
                    .settings as SmartBiddingExperiments.ExperimentSettings.manualCpcSearchSettings
            ).useEnhancedCpc
        })

        const experimentHasMaxCpc = computed(() => {
            if (!currentExperiment.value) return false

            const { setMaxCpc } = currentExperiment.value
                .settings as SmartBiddingExperiments.ExperimentSettings.maximiseClicksSearchSettings

            if (typeof setMaxCpc !== 'undefined') {
                return setMaxCpc
            } else {
                const { maxCpcBidLimit } = currentExperiment.value
                    .settings as SmartBiddingExperiments.ExperimentSettings.targetImpressionShareSearchSettings

                return !!maxCpcBidLimit
            }
        })

        const maxCpcBidLimit = computed(() => {
            if (!currentExperiment.value) return

            const { maxCpc } = currentExperiment.value.settings as { maxCpc?: number }

            if (typeof maxCpc !== 'undefined') {
                return maxCpc
            } else {
                const { maxCpcBidLimit } = currentExperiment.value
                    .settings as SmartBiddingExperiments.ExperimentSettings.targetImpressionShareSearchSettings

                return maxCpcBidLimit
            }
        })

        const experimentIsTargetRoas = computed(() => {
            if (!currentExperiment.value) return false

            return (
                currentExperiment.value.settings.experimentBiddingStrategy ===
                    enums.BiddingStrategyType.TARGET_ROAS ||
                (
                    currentExperiment.value
                        .settings as SmartBiddingExperiments.ExperimentSettings.maximiseConversionValueSearchSettings
                ).isTargetRoas
            )
        })

        const experimentIsTargetCpa = computed(() => {
            if (!currentExperiment.value) return false

            return (
                currentExperiment.value.settings.experimentBiddingStrategy ===
                    enums.BiddingStrategyType.TARGET_CPA ||
                (
                    currentExperiment.value
                        .settings as SmartBiddingExperiments.ExperimentSettings.maximiseConversionsSearchSettings
                ).isTargetCpa
            )
        })

        const barColor = computed(() => {
            if (
                currentExperiment.value?.status ===
                SmartBiddingExperiments.OpteoExperimentStatus.CANCELLED
            ) {
                return '#adadaf'
            }
            if (experimentIsComplete.value || percentComplete.value > 0.95) {
                return 'client-green'
            } else return 'client-blue'
        })

        const experimentIsTargetImpShare = computed(() => {
            if (!currentExperiment.value) return false
            return (
                currentExperiment.value.settings.experimentBiddingStrategy ===
                enums.BiddingStrategyType.TARGET_IMPRESSION_SHARE
            )
        })

        const targetImpressionShareLocation = computed(() => {
            if (!currentExperiment.value) return
            switch (
                (
                    currentExperiment.value
                        .settings as SmartBiddingExperiments.ExperimentSettings.targetImpressionShareSearchSettings
                ).targetImpressionShareLocation
            ) {
                case enums.TargetImpressionShareLocation.ANYWHERE_ON_PAGE:
                    return 'Anywhere on results page'
                case enums.TargetImpressionShareLocation.TOP_OF_PAGE:
                    return 'Top of results page'
                case enums.TargetImpressionShareLocation.ABSOLUTE_TOP_OF_PAGE:
                    return 'Absolute top of results page'
                default:
                    return
            }
        })

        const isExperimentLearning = computed(() => {
            if (!currentExperiment.value) return false
            return getIsExperimentLearning(currentExperiment.value.status)
        })

        const isExperimentCampaignsWinning = computed(
            () => currentExperiment.value?.isExperimentWinning ?? false
        )

        const confidenceScore = computed(() => currentExperiment.value?.confidenceLevel ?? 0)

        const popoutOpen = ref(false)

        const { currencyCode, domainInitials, domainColour } = useDomain()

        const campaignExperimentPairs = computed(
            () => currentExperiment.value?.campaignExperimentPairs ?? []
        )

        const selectWinning = (campaign: TableItems) => {
            if (campaign.genericName === 'Base Campaign') {
                const relevantCampaignPair = campaignExperimentPairs.value?.find(
                    c => c.baseCampaignMetrics.id === campaign.id
                )

                return !relevantCampaignPair?.isExperimentWinning
            }

            const relevantCampaignPair = campaignExperimentPairs.value?.find(
                c => c.experimentCampaignMetrics.id === campaign.id
            )

            return relevantCampaignPair?.isExperimentWinning
        }

        const experimentIsComplete = computed(() => {
            if (!currentExperiment.value!) return false
            return getIsExperimentCompleted(currentExperiment.value.status)
        })

        const setWinning = (campaign: TableItems) => {
            // Don't allow selecting if completed
            if (experimentIsComplete.value === true) {
                return
            }

            if (campaign.genericName === 'Base Campaign') {
                const relevantCampaignPair = campaignExperimentPairs.value?.find(
                    c => c.baseCampaignMetrics.id === campaign.id
                )!

                relevantCampaignPair.isExperimentWinning = false
                return
            }

            const relevantCampaignPair = campaignExperimentPairs.value?.find(
                c => c.experimentCampaignMetrics.id === campaign.id
            )!

            relevantCampaignPair.isExperimentWinning = true
        }

        const dataSetup = computed<
            (typeof PerformanceMetricTypes)[keyof typeof PerformanceMetricTypes]
        >(() => {
            if (!currentExperiment.value) {
                return PerformanceMetricTypes.cpa
            }

            return getDataSetup({
                experimentBiddingStrategy:
                    currentExperiment.value.settings.experimentBiddingStrategy,
                baseBiddingStrategy: currentExperiment.value.currentBiddingStrategy,
            })
        })

        const experimentTableHeaders = computed<
            { key: string; text: string; sortable?: boolean; width?: number; noPadding?: boolean }[]
        >(() => {
            const headers = [
                { key: 'campaign', text: 'Campaign', sortable: true, noPadding: true },
                { key: 'cost', text: 'Cost', sortable: true, width: 112, noPadding: true },
            ]

            if (dataSetup.value === PerformanceMetricTypes.cpa) {
                headers.push(
                    {
                        key: 'allConversions',
                        text: 'Conv.',
                        sortable: true,
                        width: 98,
                        noPadding: true,
                    },
                    { key: 'cpa', text: 'CPA', sortable: true, width: 88, noPadding: true }
                )
            }

            if (dataSetup.value === PerformanceMetricTypes.roas) {
                headers.push(
                    {
                        key: 'campaign',
                        text: 'Campaign',
                        sortable: true,
                        width: 454,
                        noPadding: true,
                    },
                    { key: 'cost', text: 'Cost', sortable: true, width: 106, noPadding: true },
                    {
                        key: 'allConversionsValue',
                        text: 'Value',
                        sortable: false,
                        width: 108,
                        noPadding: true,
                    },
                    { key: 'roas', text: 'ROAS', sortable: true, width: 98, noPadding: true }
                )
            }

            if (dataSetup.value === PerformanceMetricTypes.targetImpressionShare) {
                headers.push(
                    {
                        key: 'allConversions',
                        text: 'Conv.',
                        sortable: true,
                        width: 98,
                        noPadding: true,
                    },
                    {
                        key: 'impressions',
                        text: 'Impr.',
                        sortable: true,
                        width: 88,
                        noPadding: true,
                    }
                )
            }

            // Add strategy to the last column
            headers.push({
                key: 'strategy',
                text: 'Strategy',
                sortable: true,
                width: 134,
                noPadding: true,
            })

            return headers
        })

        const overallComparisonItems = computed<TableItems[]>(() => {
            if (!currentExperiment.value) return []

            return setOverallComparisonItems({
                experimentTotalData: currentExperiment.value.experimentTotalData,
                baseTotalData: currentExperiment.value.baseTotalData,
                experimentBiddingStrategy:
                    currentExperiment.value.settings.experimentBiddingStrategy,
                baseBiddingStrategy: currentExperiment.value.currentBiddingStrategy,
            })
        })

        const campaignExperimentPairsItems = computed<TableItems[][]>(() => {
            if (!currentExperiment.value) return []

            return setCampaignExperimentPairsItems({
                campaignExperimentPairs: currentExperiment.value.campaignExperimentPairs,
                experimentBiddingStrategy:
                    currentExperiment.value.settings.experimentBiddingStrategy,
                baseBiddingStrategy: currentExperiment.value.currentBiddingStrategy,
            })
        })

        const chart = computed<Chart | undefined>(() => {
            if (!currentExperiment.value) return

            const chartMetrics = {
                primaryMetric: setPrimaryChartMetric(),
                secondaryMetric: setSecondaryChartMetric({
                    experimentBiddingStrategy:
                        currentExperiment.value.settings.experimentBiddingStrategy,
                    baseBiddingStrategy: currentExperiment.value.currentBiddingStrategy,
                }),
            }

            const { label: chartSetupLabel } = chartMetrics.primaryMetric

            const primaryChartData = setChartData({
                baseTotalData: currentExperiment.value.baseTotalData,
                experimentTotalData: currentExperiment.value.experimentTotalData,
                chartSetupLabel,
                isSecondaryChart: false,
            })

            const primaryChartCurrentValues = primaryChartData.map(series => {
                const lastItem = series.items[series.items.length - 1]?.y ?? 0

                if (chartSetupLabel === 'CPA') {
                    return useDomainMoney({ value: lastItem }).value.displayValue.value
                }

                if (chartSetupLabel === 'ROAS') {
                    return useRoas({ value: lastItem }).displayValue.value
                }

                if (chartSetupLabel === 'Search Imp. Share') {
                    return usePercent({ value: lastItem }).displayValue.value
                }

                return useNumber({ value: lastItem }).displayValue.value
            })

            const secondaryChartData = setChartData({
                baseTotalData: currentExperiment.value.baseTotalData,
                experimentTotalData: currentExperiment.value.experimentTotalData,
                chartSetupLabel,
                isSecondaryChart: true,
            })

            const secondaryChartCurrentValues = secondaryChartData.map(series => {
                const lastItem = series.items[series.items.length - 1]?.y ?? 0

                // Conversion value
                if (chartSetupLabel === 'ROAS') {
                    return useDomainMoney({ value: lastItem }).value.displayValue.value
                }

                return useNumber({ value: lastItem }).displayValue.value
            })

            return {
                primaryChart: {
                    chartLabel: chartMetricLabelToChartTitle[chartMetrics.primaryMetric.label],
                    metric: chartMetrics.primaryMetric,
                    data: primaryChartData,
                    currentValues: primaryChartCurrentValues,
                },
                secondaryChart: {
                    chartLabel: chartMetricLabelToChartTitle[chartMetrics.secondaryMetric.label],
                    metric: chartMetrics.secondaryMetric,
                    data: secondaryChartData,
                    currentValues: secondaryChartCurrentValues,
                },
            }
        })

        const hasChartData = computed(() => {
            if (!chart.value) return false

            const { primaryChart, secondaryChart } = chart.value

            const joinedCharts = [primaryChart, secondaryChart]

            const chartDataLengths = joinedCharts.flatMap(({ data }) =>
                data.map(({ items }) => items.length)
            )

            return chartDataLengths.every(dataLength => dataLength > 1)
        })

        const showGraphEmptyState = computed(() => {
            const primaryChartIsZeroes = chart.value?.primaryChart.data.every(line =>
                line.items.every(p => p.y === 0)
            )
            const secondaryChartIsZeroes = chart.value?.secondaryChart.data.every(line =>
                line.items.every(p => p.y === 0)
            )

            const allChartsZeroes = primaryChartIsZeroes && secondaryChartIsZeroes

            if (hasChartData.value && !allChartsZeroes) {
                // chart has data
                return
            }

            if (!experimentIsComplete.value && !hasChartData.value) {
                // exp in progress, chart has no points
                return 'no-points'
            }

            if (!experimentIsComplete.value && hasChartData.value && allChartsZeroes) {
                // exp in progress, chart has points but all zeroes
                if (
                    [
                        enums.BiddingStrategyType.TARGET_CPA,
                        enums.BiddingStrategyType.TARGET_ROAS,
                        enums.BiddingStrategyType.MAXIMIZE_CONVERSIONS,
                        enums.BiddingStrategyType.MAXIMIZE_CONVERSION_VALUE,
                        enums.BiddingStrategyType.ENHANCED_CPC,
                        enums.BiddingStrategyType.MANUAL_CPC,
                    ].includes(
                        currentExperiment.value?.settings
                            .experimentBiddingStrategy as enums.BiddingStrategyType
                    )
                ) {
                    return 'insufficient-conversion-data'
                }

                if (
                    [enums.BiddingStrategyType.TARGET_SPEND].includes(
                        currentExperiment.value?.settings
                            .experimentBiddingStrategy as enums.BiddingStrategyType
                    )
                ) {
                    return 'insufficient-impression-data'
                }
            }

            if (experimentIsComplete.value && (!hasChartData.value || allChartsZeroes)) {
                // exp done, chart has no points or all points zero
                return 'experiment-complete'
            }
        })

        const setOverallComparisonItems = ({
            experimentTotalData,
            baseTotalData,
            experimentBiddingStrategy,
            baseBiddingStrategy,
        }: {
            experimentTotalData: CampaignPerformanceData
            baseTotalData: CampaignPerformanceData
            experimentBiddingStrategy: enums.BiddingStrategyType
            baseBiddingStrategy: enums.BiddingStrategyType
        }) => {
            return [baseTotalData, experimentTotalData].map((data, i) => {
                const items = getCampaignItems({
                    campaignPerformanceData: data,
                    biddingStrategyType: i === 0 ? baseBiddingStrategy : experimentBiddingStrategy,
                    genericName: i === 0 ? 'Base Campaign' : 'Experiment Campaign',
                })

                return items
            })
        }

        const setCampaignExperimentPairsItems = ({
            campaignExperimentPairs,
            experimentBiddingStrategy,
            baseBiddingStrategy,
        }: {
            campaignExperimentPairs: SmartBiddingExperiments.CampaignExperimentPair[]
            experimentBiddingStrategy: enums.BiddingStrategyType
            baseBiddingStrategy: enums.BiddingStrategyType
        }) => {
            return campaignExperimentPairs.map(campaignPair => {
                const experimentSpecificItems = getCampaignItems({
                    campaignPerformanceData: campaignPair.experimentCampaignMetrics,
                    biddingStrategyType: experimentBiddingStrategy,
                    genericName: 'Experiment Campaign',
                })

                const baseSpecificItems = getCampaignItems({
                    campaignPerformanceData: campaignPair.baseCampaignMetrics,
                    biddingStrategyType: baseBiddingStrategy,
                    genericName: 'Base Campaign',
                })

                return [baseSpecificItems, experimentSpecificItems]
            })
        }

        const getCampaignItems = ({
            campaignPerformanceData,
            biddingStrategyType,
            genericName,
        }: {
            campaignPerformanceData: SmartBiddingExperiments.PerformanceData
            biddingStrategyType: enums.BiddingStrategyType
            genericName: 'Experiment Campaign' | 'Base Campaign'
        }): TableItems => {
            const { name, clicks, cost, allConversions, allConversionsValue, impressions, id } =
                campaignPerformanceData

            const dataSetupSpecificItems: Record<
                typeof dataSetup.value,
                CpaItems | RoasItems | TargetImpressionShareItems
            > = {
                cpa: {
                    allConversions,
                    cpa: cost / allConversions || 0,
                },
                roas: {
                    allConversionsValue,
                    roas: allConversionsValue / cost || 0,
                },
                targetImpressionShare: {
                    allConversions,
                    impressions,
                },
            }

            const isExperiment = genericName === 'Experiment Campaign'
            const strategy = isExperiment
                ? formatBiddingStrategy(biddingStrategyType, currentExperiment?.value?.settings)
                : formatBiddingStrategy(biddingStrategyType)

            return {
                id,
                campaign: name ?? genericName ?? 'Campaign',
                clicks,
                cost,
                ...dataSetupSpecificItems[dataSetup.value],
                genericName,
                strategy,
                isExperiment,
            }
        }

        // Set Chart Section
        const setPrimaryChartMetric = () => {
            const metrics = {
                roas: {
                    label: 'ROAS',
                    dataType: 'roas',
                },
                cpa: {
                    label: 'CPA',
                    currency: unref(currencyCode) ?? 'GBP',
                    dataType: 'money',
                },
                targetImpressionShare: {
                    label: 'Search Imp. Share',
                    dataType: 'percentage',
                },
            } as const

            return metrics[dataSetup.value]
        }

        const setSecondaryChartMetric = ({
            experimentBiddingStrategy,
            baseBiddingStrategy,
        }: {
            experimentBiddingStrategy: enums.BiddingStrategyType
            baseBiddingStrategy: enums.BiddingStrategyType
        }) => {
            const metrics = {
                conversions: {
                    label: 'Conv.',
                    dataType: 'number',
                },
                conversionsValue: {
                    label: 'Value',
                    currency: unref(currencyCode) ?? 'GBP',
                    dataType: 'money',
                },
            } as const

            const isRoas = SmartBiddingExperiments.roasBiddingStrategies.some(
                biddingStrategyType => biddingStrategyType === experimentBiddingStrategy
            )

            return isRoas ? metrics.conversionsValue : metrics.conversions
        }

        const setChartData = ({
            baseTotalData,
            experimentTotalData,
            chartSetupLabel,
            isSecondaryChart,
        }: {
            experimentTotalData: CampaignPerformanceData
            baseTotalData: CampaignPerformanceData
            chartSetupLabel: 'CPA' | 'ROAS' | 'Search Imp. Share'
            isSecondaryChart?: boolean
        }) => {
            return [baseTotalData, experimentTotalData].map((entity, i) => {
                const campaignExperimentType = i === 0 ? 'Base' : 'Experiment'

                let secondaryChartLabel: '' | 'Conv.' | 'Value' = ''
                if (isSecondaryChart) {
                    secondaryChartLabel = dataSetup.value === 'roas' ? 'Value' : 'Conv.'
                }

                const name = `${campaignExperimentType} ${
                    secondaryChartLabel || chartSetupLabel
                }` as const

                const items = entity.performanceData.map(performance => {
                    const {
                        cost,
                        allConversions,
                        allConversionsValue,
                        searchImpressionShare,
                        date,
                    } = performance

                    if (!date) {
                        throw new Error('MISSING_DATE')
                    }

                    const x = new Date(date)
                    let y!: number

                    if (isSecondaryChart) {
                        y = dataSetup.value === 'roas' ? allConversionsValue : allConversions

                        return { x, y }
                    }

                    const chartSetupYValuePair: Record<typeof dataSetup.value, number> = {
                        cpa: allConversions ? cost / allConversions : 0,
                        roas: cost ? allConversionsValue / cost : 0,
                        targetImpressionShare: searchImpressionShare,
                    }

                    y = chartSetupYValuePair[dataSetup.value]

                    return { x, y }
                })

                return {
                    name,
                    items,
                }
            })
        }

        const percentComplete = computed(() => {
            if (!currentExperiment.value) {
                return 0
            }

            return Math.abs(
                getPercentComplete({
                    start: currentExperiment.value.creationDate,
                    end: currentExperiment.value.actualEndDate,
                })
            )
        })

        const showExperimentIncompletePopout = ref(false)

        const daysLeft = computed(() => {
            if (!currentExperiment.value!) return 0
            return getDaysLeft(currentExperiment.value.actualEndDate)
        })

        const totalDays = computed(() => {
            if (!currentExperiment.value) return
            return getTotalDays(
                currentExperiment.value.creationDate,
                currentExperiment.value.actualEndDate
            )
        })

        const winningExperimentsCount = computed(() => {
            if (!currentExperiment.value!) return 0

            if (experimentIsComplete) {
                return currentExperiment.value.campaignExperimentPairs.filter(experimentPair => {
                    return experimentPair.isExperimentWinning
                }).length
            }
        })

        const winningExperimentsCountDefault =
            currentExperiment.value?.campaignExperimentPairs.filter(experimentPair => {
                return experimentPair.isExperimentWinning
            }).length

        // TODO: Store locally
        const discardedGraphDataModal = ref(true)

        // Anime
        onMounted(() => {
            anime.timeline().add({
                targets: ['.header', '.content'],
                opacity: [0, 1],
                translateY: [16, 0],
                translateZ: 0,
                easing: 'cubicBezier(0.19, 1, 0.22, 1)',
                duration: 1200,
                delay: 300,
            })
        })

        const isExperimentCreating = computed(() => {
            return (
                currentExperiment.value?.status ===
                SmartBiddingExperiments.OpteoExperimentStatus.CREATING
            )
        })

        const deleteExperimentButton = ref()
        const deleteExperimentLoading = ref(false)

        const deleteExperiment = async () => {
            deleteExperimentLoading.value = true

            // comment out the authRequest when testing - otherwise we will delete experiments for real
            // const response = { success: true } Add this to test a successful deletion
            const response = await authRequest(Endpoint.DeleteExperimentRequest, {
                body: {
                    experiment: currentExperiment.value,
                },
            })

            await Promise.all([
                mutateGetAccountExperimentData(),
                mutateGetCampaignsAndExperiments(),
            ])

            deleteExperimentLoading.value = false

            if (response.success) {
                deleteModal.value = false
                router.push({ name: Routes.ToolkitSmartBiddingExperimentsCompleted })
            } else {
                deleteExperimentButton.value.flashError()
            }
        }

        const deleteModal = ref(false)

        const experimentDeletable = ref(false)

        let interval: NodeJS.Timeout

        interval = setInterval(() => {
            checkIfDeleteable()
        }, 5000)

        const checkIfDeleteable = async () => {
            if (experimentDeletable.value) return
            if (
                currentExperiment.value?.status ===
                    SmartBiddingExperiments.OpteoExperimentStatus.COMPLETED ||
                currentExperiment.value?.status ===
                    SmartBiddingExperiments.OpteoExperimentStatus.CANCELLED
            ) {
                return
            }

            const { success } = await authRequest<{ success: boolean }>(
                Endpoint.DeleteExperimentRequest,
                {
                    body: {
                        experimentId: currentExperiment.value?.optExperimentId,
                        experiment: currentExperiment.value,
                        probeOnly: true,
                    },
                }
            )

            if (success) {
                experimentDeletable.value = true
                clearInterval(interval)
            }
        }

        onMounted(() => checkIfDeleteable())
        onUnmounted(() => clearInterval(interval))

        return {
            dataSetup,
            experimentTableHeaders,
            overallComparisonItems,
            campaignExperimentPairsItems,
            chart,
            hasChartData,
            selectWinning,
            setWinning,
            SmartBiddingExperiments,
            getIsExperimentCompleted,
            confidenceScore,
            popoutOpen,
            isExperimentCampaignsWinning,
            isExperimentLearning,
            percentComplete,
            experimentIsComplete,
            showExperimentIncompletePopout,
            daysLeft,
            isExperimentEndEarly,
            totalDays,
            winningExperimentsCount,
            winningExperimentsCountDefault,
            baseBidStrategy,
            experimentBiddingStrategy,
            discardedGraphDataModal,
            currentExperiment,
            completeExperiment,
            domainInitials,
            domainColour,
            Platform,
            showGraphEmptyState,
            experimentIsManualCpc,
            experimentUsesEnhancedCpc,
            experimentHasMaxCpc,
            experimentIsTargetRoas,
            experimentIsTargetCpa,
            experimentIsTargetImpShare,
            targetImpressionShareLocation,
            maxCpcBidLimit,
            barColor,

            // delete experiment
            deleteExperimentButton,
            deleteExperimentLoading,
            deleteExperiment,
            isExperimentCreating,
            deleteModal,
            experimentDeletable,
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.experiment-container header,
.experiment-container .content {
    max-width: 61.25rem;
    margin: 0 auto;
}
.experiment-container header {
    @include flex;
    @include items-center;
    @include justify-between;
    height: 2.75rem;
    margin-top: 5rem;
}
.experiment-container header .left {
    @include flex;
    @include items-center;
}
.experiment-container header :deep(.rsa-button p) {
    font-weight: 500;
    font-size: 0.8125rem;
    letter-spacing: -0.0125rem;
    transform: translate3d(0, -1px, 0);
    padding-right: 0.25rem;
}

.experiment-container .content {
    margin-bottom: 5rem;
}

.section-container {
    @include container;
    @include ph-40;
    @include pb-40;
    @include pt-28;
    @include br-32;
}
.section-container header {
    @include flex;
    @include items-center;
    @include justify-start;
    height: 2.75rem;
    margin-top: 0;
}

.campaign-name {
    @include flex;
    @include items-center;
    gap: 0.625rem;
    @include relative;
    height: 4.75rem;
}
.table-cell.overflow {
    padding-left: 1.5rem;
    overflow: hidden;
}
.table-cell.overflow::after {
    content: '';
    @include absolute;
    width: 4rem;
    height: 100%;
    right: 0;
    top: 0;
    background: white;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, white 50%);
    @include z-2;
}

.comparison-pairs {
    @include flex;
    flex-direction: column;
    gap: 1.5rem;
}

.description-container {
    @include pv-28;
    @include ph-32;
    background-color: #ebf2ff;
    @include br-16;
    @include inline-flex;
}

.settings-container {
    @include container;
    @include br-24;
    max-width: 45rem;
    margin: 0 auto;
}

.settings-container .section-settings {
    @include pa-32;
    border-bottom: 1px solid $opteo-light-gray;
}

.section-container .section-settings .experiment-duration {
    @include flex;
    @include items-center;
    gap: 0.625rem;
}

.settings-container .section-settings:last-child {
    border-bottom: none;
}

.settings-container .settings-label {
    font-size: 0.75rem;
    @include fw-400;
    letter-spacing: -0.00625rem;
    @include block;
    margin-bottom: 0.875rem;
    white-space: nowrap;
}

.settings-container .section-settings-flex {
    @include flex;
    border-bottom: 1px solid $opteo-light-gray;
}

.settings-container .left {
    width: 50%;
    flex-shrink: 0;
    @include pa-32;
}

.settings-container .right {
    border-left: 1px solid $opteo-light-gray;
    width: 50%;
    flex-shrink: 0;
    @include pa-32;
}

.budget-labels-container {
    @include flex;
    @include justify-between;
}

.budget-label {
    @include flex;
    @include items-center;
}

.budget-label-text {
    font-size: 0.75rem;
    @include fw-400;
    letter-spacing: -0.00625rem;
    @include block;
    white-space: nowrap;
}

.experiment-progress-wrapper {
    @include flex-center;
    gap: 2rem;
    margin: auto;
    @include sticky;
    @include bg-opteo-white;
    @include z-10;
    @include container;
    border-radius: 0;
    @include pv-36;
    width: 100%;
    bottom: 0;
    left: 0;
}
.experiment-progress-container {
    width: 30rem;
    @include container;
    @include br-16;
    @include ph-24;
    @include pv-20;
}

.comparison-header {
    @include flex;
    justify-content: space-between !important;
    @include w-100;
}
.confidence-score-donut-content {
    @include flex;
    flex-direction: column;
    gap: 0.125rem;
}
.comparison-header .confidence-score-donut h4 {
    font-size: 1rem;
    line-height: 1.25rem;
}
.comparison-header .confidence-score-donut span {
    font-size: 0.75rem;
    line-height: 1rem;
}
.hover-popout-container {
    @include pv-24;
    @include ph-28;
    @include br-20;
    width: 22.625rem;
}
.confidence-score-donut {
    @include flex;
    @include items-center;
    cursor: default;
    gap: 0.875rem;
}

.experiment-completed-popout-container {
    @include pa-28;
    width: 24.25rem;
}
.experiment-completed-popout-container .buttons {
    @include flex-center;
    @include w-100;
    gap: 0.625rem;
}

header .vs {
    @include container;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 999rem;
    @include f-10;
    @include flex-center;
    color: rgba(0, 0, 0, 0.32);
    padding-bottom: 0.125rem;
    margin: 0 0.75rem;
}

.experiment-container .select-campaigns-info {
    width: 0.75rem;
    height: 0.75rem;
    margin-left: 0.125rem;
    transform: translateY(0.125rem);
}

.confirm-popout-content {
    @include ph-24;
    @include pt-20;
    @include pb-24;
}
.confirm-popout-buttons {
    @include flex;
    @include items-center;
    @include justify-center;
}

// Transitions
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.24s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
