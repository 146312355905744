<template>
    <ImprovementView :push-messages="pushMessages" :push-action-text="pushActionText">
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <div v-if="aboveMobile">
                    <EntityPill :content="entityPillList[0].content" type="campaign-group" />
                    <Spacer height="2rem" />
                </div>
                <Text as="p">
                    In this campaign group, your ads are
                    <span v-if="increasingBid">performing well</span
                    ><span v-else>underperforming</span> for searchers in the location listed below.
                    Searchers in this location are responsible for
                    <b><Number :value="metrics.conversions.value" /></b>
                    {{ metrics.conversions.copy }} at a cost of
                    <b><Money :value="metrics.cost.value" /></b>, giving this location a CPA of
                    <b><Money :value="metrics.cpa.value" /></b>. This is
                    <Text v-if="!cpaOverCampaignGroupCpa" as="span" color="green" weight="600"
                        ><Percent :value="cpaCampaignGroupCpaDifference"
                    /></Text>
                    <Text v-else as="span" color="red" weight="600"
                        ><Percent :value="cpaCampaignGroupCpaDifference"
                    /></Text>
                    <span v-if="!cpaOverCampaignGroupCpa"> less</span><span v-else> more</span> than
                    the parent campaign group average (<b
                        ><Money :value="metrics.campaignGroupCpa.value" /></b
                    >). Consider <span v-if="increasingBid">increasing</span
                    ><span v-else>decreasing</span> your bids.
                </Text>
                <Spacer height="2rem" />
                <EntityPill :content="entityPillList[1].content" type="location" />
                <Spacer height="2rem" />
                <Text>
                    Click <Text as="span" color="green" weight="600">{{ pushActionText }}</Text> to
                    apply a
                    <Text v-if="increasingBid" as="span" color="green" weight="600">
                        +<Percent :value="bidModifier" />
                    </Text>
                    <Text v-else as="span" color="red" weight="600">
                        -<Percent :value="bidModifier" />
                    </Text>
                    adjustment for searchers based in this location. This bid adjustment will affect
                    <b>{{ campaignCount }}</b> <span v-if="campaignCount === 1"> campaign</span
                    ><span v-else> campaigns</span> in this campaign group.
                </Text>
                <div v-if="campaignCount > 1">
                    <Spacer height="2rem" />
                    <EntityPillList :items="allCampaignNames" />
                </div>
                <div v-else>
                    <Spacer height="2rem" />
                    <div class="campaign-pill-list-container">
                        <EntityPill
                            :content="allCampaignNames[0].content"
                            :type="allCampaignNames[0].type"
                        />
                    </div>
                </div>
            </ImprovementHeader>
            <ImprovementHelpLink path="/en/articles/2033681-adjust-location-bids">
                Click here to learn more about location bid adjustments in Opteo.
            </ImprovementHelpLink>
        </ImprovementContent>

        <ImprovementContent>
            <div class="location-breakdown-image">
                <oImage :src="mapImageUrl" />
            </div>
            <Spacer height="2rem" />
            <oTable :headers="locationTableHeaders" :items="locationTableItems">
                <template v-slot:[`column.bidMod`]="bidMod">
                    <Text
                        v-if="bidMod.value[0] === '+'"
                        color="green"
                        as="span"
                        weight="500"
                        size="f-8"
                    >
                        {{ bidMod.value }}
                    </Text>
                    <Text
                        v-else-if="bidMod.value[0] === '-'"
                        color="red"
                        as="span"
                        weight="500"
                        size="f-8"
                    >
                        {{ bidMod.value }}
                    </Text>
                    <Text v-else as="span" weight="400" size="f-8">
                        {{ bidMod.value }}
                    </Text>
                </template>
            </oTable>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="90" />
        </ImprovementContent>
    </ImprovementView>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

import {
    ImprovementHeader,
    ImprovementContent,
    ImprovementCreatedDate,
    EntityPillList,
    EntityPill,
    Spacer,
    Text,
    Number,
    Percent,
    oButton,
    oTable,
    oImage,
} from '@opteo/components-next'

import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import Money from '@/components/global/oMoney.vue'
import useMediaQuery from '@/composition/global/useMediaQuery'

import { useAdjustGeoBids } from '@/composition/improvement/types/useAdjustGeoBids'

export default defineComponent({
    name: 'AdjustGeoBids',
    components: {
        ImprovementHeader,
        ImprovementContent,
        ImprovementCreatedDate,
        ImprovementView,
        ImprovementHelpLink,
        EntityPillList,
        EntityPill,
        Spacer,
        Text,
        Number,
        Money,
        Percent,
        oButton,
        oTable,
        oImage,
    },
    setup() {
        return { ...useAdjustGeoBids(), ...useMediaQuery() }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.location-breakdown-image {
    @include container;
    overflow: hidden;
}
</style>
