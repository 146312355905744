<template>
    <div v-if="localSeenMegasplash">
        <TopNavBar>
            <AccountCenterTopNav />
        </TopNavBar>
        <PageHeader pageTitle="Account Centre">
            <template #right-content>
                <Label :left="true">Sort By</Label>
                <oInput
                    v-model="sortedBy"
                    :items="[
                        { value: SortBy.Name, label: 'Account Name' },
                        { value: SortBy.Spend, label: 'Spend (30d)' },
                        { value: SortBy.TasksAvailable, label: 'Improvements' },
                        { value: SortBy.Budget, label: 'Budget Status' },
                        { value: SortBy.LastActive, label: 'Last Active' },
                    ]"
                    type="select"
                    class="sorted-by-select"
                />
            </template>
        </PageHeader>

        <PageContent>
            <!-- Skeleton rows -->
            <div v-if="loading" class="domain-list">
                <DomainListRow v-for="skeletonId in 20" :key="skeletonId" :account="{}" />
            </div>

            <!-- Domain List -->
            <div v-else-if="truncatedDomainList?.length" class="domain-list">
                <DomainListRow
                    v-for="domain in truncatedDomainList"
                    :key="domain.accountId"
                    :account="domain"
                />
            </div>

            <!-- No domains available -->
            <div v-else-if="userHasNoDomains" class="empty-state-container">
                <img
                    src="@/assets/img/no-accounts-linked.png"
                    class="no-accounts-image"
                    alt="Visit linked accounts"
                />
                <Spacer height="2rem" />
                <div class="center" style="max-width: 334px">
                    <Text as="h5" size="f-5" weight="600" align="center">
                        No accounts linked to Opteo
                    </Text>
                    <Spacer height="0.875rem" />
                    <Text as="p" size="f-8" align="center">
                        You currently do not have any Google Ads accounts linked to Opteo. Head to
                        the <a @click="goToLinkedAccounts">Linked Accounts</a> page to add a new
                        account, or to import a new MCC.
                    </Text>
                </div>
            </div>

            <!-- No result found -->
            <div v-else class="empty-state-container">
                <img
                    src="@/assets/img/no-results-account-centre.png"
                    class="empty-state-image"
                    alt="No accounts found"
                />
                <div class="empty-state-text">
                    We couldn't find an account matching your search query.
                </div>
            </div>
        </PageContent>
    </div>

    <!-- Account Centre onboarding -->
    <OnboardingTour
        step-name="tour_domainlist"
        v-if="localSeenMegasplash"
        :buttonText="'Continue to Account Centre'"
        contentMaxWidth="472px"
    >
        <template v-slot:header>Account Centre</template>
        <template v-slot:copy>
            Welcome to the Account Centre, a place for all of the accounts you have linked to Opteo.
            Each row contains recent performance trends, budget statuses, and available
            improvements. You can also update account settings and budgets using the settings
            button.
        </template>
    </OnboardingTour>

    <router-view v-if="!localSeenMegasplash" />
</template>

<script lang="ts">
import { ref, defineComponent, watch, computed, onMounted } from 'vue'
import { StorageSerializers, useInfiniteScroll, useLocalStorage } from '@vueuse/core'
import { useRouter } from 'vue-router'
import { User } from '@opteo/types'

// Composition
import { useUser } from '@/composition/user/useUser'
import { useAccountList } from '@/composition/user/useAccountList'
import { SortBy } from '@/lib/accountList'

// Components
import { oButton, oInput, Spacer, ViewToggle, Label, Text } from '@opteo/components-next'

import PageHeader from '@/layouts/PageHeader.vue'
import PageContent from '@/layouts/PageContent.vue'

import DomainListRow from '@/components/AccountCenter/DomainListRow.vue'
import { Routes } from '@/router/routes'
import Skeleton from '@/components/util/Skeleton.vue'
import OnboardingTour from '@/components/User/OnboardingTour.vue'

import AccountCenterTopNav from '@/components/AccountCenter/AccountCenterTopNav.vue'
import TopNavBar from '@/layouts/TopNavBar.vue'
import { useIntercom } from '@/lib/intercom/useIntercom'
import { pushToDatalayer } from '@/lib/datalayer'
import { LS_AC_MEGASPLASH_JUST_SEEN } from '@/lib/cookies'

export default defineComponent({
    name: 'AccountCentre',
    components: {
        PageHeader,
        DomainListRow,
        PageContent,
        oButton,
        oInput,
        Spacer,
        ViewToggle,
        Label,
        Skeleton,
        AccountCenterTopNav,
        TopNavBar,
        OnboardingTour,
        Text,
    },
    setup() {
        const { user } = useUser()
        const {
            searchedDomain,
            userHasNoDomains,
            setDomainListOrder,
            sortedBy,
            filteredDomainList,
            resetSearch,
            domainListLoading,
        } = useAccountList()

        const intercom = useIntercom()

        const loading = computed(() => !user.value || domainListLoading.value)

        const { replace, push } = useRouter()

        const localSeenMegasplash = ref(true)
        const userCompletedMegasplash = computed(() => user.value?.profile.seen_megasplash)
        const userHasJustSeenMegasplash = useLocalStorage<number>(LS_AC_MEGASPLASH_JUST_SEEN, 0, {
            serializer: StorageSerializers.number,
        })

        const checkIfMegaSplashNeeded = () => {
            const completedMegasplash = userCompletedMegasplash.value
            const userInfoLoaded = typeof completedMegasplash !== 'undefined'
            const justSeenMegasplash = !!+userHasJustSeenMegasplash.value

            if (!justSeenMegasplash && userInfoLoaded && !completedMegasplash) {
                localSeenMegasplash.value = false
                replace({ name: Routes.Megasplash })
                return
            }

            if (userInfoLoaded && completedMegasplash) {
                localStorage.setItem(LS_AC_MEGASPLASH_JUST_SEEN, '0')
            }

            intercom.trackEvent('60_saw_client_list')
            pushToDatalayer('gtm_saw_client_centre')
        }

        watch(userCompletedMegasplash, () => {
            checkIfMegaSplashNeeded()
        })

        const goToLinkedAccounts = () => {
            push({ name: Routes.LinkedAccounts })
        }

        onMounted(() => {
            resetSearch()
            checkIfMegaSplashNeeded()
        })

        /*
            Infinite scroll bits
        */
        const listTruncationCount = ref(25)
        const truncatedDomainList = computed(() => {
            return filteredDomainList.value.slice(0, listTruncationCount.value)
        })
        useInfiniteScroll(
            document,
            () => {
                listTruncationCount.value = listTruncationCount.value + 25
            },
            { distance: 500 } // 500px from bottom, allow more rows to be shown
        )

        return {
            searchedDomain,
            localSeenMegasplash,
            userHasNoDomains,
            setDomainListOrder,
            sortedBy,
            loading,
            goToLinkedAccounts,
            truncatedDomainList,
            SortBy,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.sorted-by-select {
    width: 152px;
}

.sidebar-title {
    @include f-9;
    @include fw-400;
    @include mb-24;
    @include ph-32;
    @include opteo-foreground;
    @include o-30;
}
.sidebar-content {
    @include ph-32;
    @include mb-32;
}
.sidebar-content .o-button {
    @include opteo-background-l2;
    @include opteo-foreground;
}

.paginate-links {
    width: 400px;
}

.open-sidebar-button {
    @include opteo-background;
    @include flex;
    @include items-center;
    @include justify-center;
    @include br-999;
    @include pointer;
    width: 2.75rem !important;
    height: 2.75rem !important;
}

.empty-state-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 24rem;
}
.empty-state-image {
    width: 12.5rem;
    @include mb-16;
}
.empty-state-text {
    @include f-9;
    @include lh-150;
    @include tc;
    width: 11rem;
}

.no-accounts-image {
    width: 20.375rem;
}

.domain-grid {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    grid-gap: 3rem;
}

@media screen and (min-width: $mq-1025-min) and (max-width: $mq-1279-max) {
    .domain-grid {
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        grid-gap: 3rem;
    }
}
@media screen and (min-width: 0) and (max-width: $mq-1024-max) {
    .domain-grid {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        grid-gap: 3rem;
    }
}
</style>
