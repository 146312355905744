<template>
    <ImprovementView
        :push-messages="pushMessages"
        :push-action-text="pushActionText"
        :is-adjustable="true"
        :on-push="onPush"
        @adjust-reset="onReset"
    >
        <ImprovementContent>
            <ImprovementHeader :title="`${title}`">
                <div v-if="aboveMobile">
                    <EntityPill :content="entityName" :type="entity" />
                    <Spacer height="2rem" />
                </div>
                <Text as="p">
                    In the {{ entityLabel }} listed above,
                    <span>
                        {{
                            entity === 'campaign'
                                ? 'Opteo has combined the performance of low-data ad groups to calculate recommended bid adjustments.'
                                : ''
                        }}
                    </span>
                    Opteo recommends applying bid adjustments across demographics in the
                    <b style="text-transform: capitalize">{{ demographicLabel }}</b> category. This
                    should help
                    {{ isUsingROAS ? 'increase conversion value' : 'generate more conversions' }} at
                    a reasonable cost and reduce wasted spend.
                    <Spacer height="1rem" v-if="entity === 'campaign'" />
                    <Text as="span" v-if="entity === 'campaign'">
                        For reference, these adjustments will be applied to the following ad groups:
                    </Text>
                </Text>

                <div v-if="entity === 'campaign'">
                    <Spacer height="2rem" />
                    <EntityPillList :items="entityPillList" />
                    <Spacer height="2rem" />
                </div>
                <Spacer v-else height="1rem" />

                <Text as="p">
                    Click
                    <Text as="span" color="green" weight="600"> {{ pushActionText }}</Text> to apply
                    the adjustments listed below. If you need to set specific adjustment
                    percentages, click <b>Adjust</b>. Otherwise, feel free to click <b>Dismiss</b>.
                </Text>
            </ImprovementHeader>
            <ImprovementHelpLink path="/en/articles/902679-adjust-demographic-bids">
                Click here to learn more about demographic bid adjustments in Opteo.
            </ImprovementHelpLink>
        </ImprovementContent>
        <ImprovementContent :tableWidth="aboveMobile ? true : false">
            <oTable
                :headers="tableHeaders"
                :items="tableItems"
                :fixed-layout="true"
                responsive-mode="columns"
                responsive-breakpoint="768px"
            >
                <template #column.cost="cost">
                    <Money :value="cost.value" :currency="domainCurrencyCode" />
                </template>

                <template #column.conversions="conversions">
                    <Number :value="conversions.value" :compact="true" />
                </template>
                <template #column.conversions_value="conversions_value">
                    <Money :value="conversions_value.value" :currency="domainCurrencyCode" />
                </template>
                <template #column.cpa="cpa">
                    <Money :value="cpa.value" :currency="domainCurrencyCode" />
                </template>
                <template #column.roas="roas">
                    <Roas :value="roas.value" />
                </template>
                <template #column.avg-cpa="avgCpa">
                    <Money :value="avgCpa.value" :currency="domainCurrencyCode" />
                </template>
                <template #column.avg-roas="avgROAS">
                    <Roas :value="avgROAS.value" />
                </template>
                <template #column.difference_cpa="difference_cpa">
                    <Text v-if="difference_cpa.value > 0" as="span" color="red" weight="500">
                        +<Percent :value="difference_cpa.value" />
                    </Text>
                    <Text v-if="difference_cpa.value === 0" as="span" color="black" weight="400">
                        <Percent :value="difference_cpa.value" />
                    </Text>
                    <Text v-if="difference_cpa.value < 0" as="span" color="green" weight="500">
                        <Percent :value="difference_cpa.value" />
                    </Text>

                    <NotApplicablePill
                        v-if="difference_cpa.value === 'disabled'"
                        content="Segment disabled"
                    />
                </template>
                <template #column.difference_roas="difference_roas">
                    <Text v-if="difference_roas.value < 0" as="span" color="red" weight="500">
                        <Percent :value="difference_roas.value" />
                    </Text>
                    <Text v-if="difference_roas.value === 0" as="span" color="black" weight="400">
                        <Percent :value="difference_roas.value" />
                    </Text>
                    <Text v-if="difference_roas.value > 0" as="span" color="green" weight="500">
                        +<Percent :value="difference_roas.value" />
                    </Text>

                    <NotApplicablePill
                        v-if="difference_roas.value === 'disabled'"
                        content="Segment disabled"
                    />
                </template>
                <template #column.bid-adjustment="bidAdjustment">
                    <Text
                        v-if="bidAdjustment.value.value > 0 && !bidAdjustment.value.invalid"
                        as="span"
                        color="green"
                        weight="500"
                    >
                        +<Percent :value="bidAdjustment.value.value" />
                    </Text>
                    <Text
                        v-else-if="bidAdjustment.value.value === 0 && !bidAdjustment.value.invalid"
                        as="span"
                        color="black"
                        weight="400"
                    >
                        <Percent :value="bidAdjustment.value.value" />
                    </Text>
                    <Text
                        v-else-if="bidAdjustment.value.value < 0 && !bidAdjustment.value.invalid"
                        as="span"
                        color="red"
                        weight="500"
                    >
                        <Percent :value="bidAdjustment.value.value" />
                    </Text>

                    <NotApplicablePill
                        v-if="bidAdjustment.value.value === 'disabled'"
                        content="Segment disabled."
                    />

                    <Tooltip
                        v-if="!bidAdjustment.value.invalid && bidAdjustment.value.current != 0"
                        :content="`The bid adjustment for this segment will be updated from <b>${(
                            100 * bidAdjustment?.value.current
                        ).toFixed(2)}%</b> to <b>${(100 * bidAdjustment?.value?.value).toFixed(
                            2
                        )}%</b>.`"
                        :max-width="240"
                    >
                        <div class="not-enough-data-pill">
                            <svg
                                class="not-enough-data-icon"
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                            >
                                <path
                                    d="M7 0C3.13397 0 0 3.13397 0 7C0 10.866 3.13397 14 7 14C10.866 14 14 10.866 14 7C14.0002 3.13397 10.866 0 7 0ZM8.18062 10.4205C7.84101 10.5543 7.5701 10.6563 7.36775 10.7266C7.1654 10.7969 6.93043 10.8321 6.66299 10.8321C6.25136 10.8321 5.93153 10.7318 5.70363 10.5312C5.47544 10.3306 5.36142 10.076 5.36142 9.76724C5.36142 9.64715 5.36994 9.52462 5.38711 9.39934C5.40429 9.27421 5.43171 9.13276 5.46938 8.97501L5.894 7.47282C5.93196 7.32878 5.96386 7.19254 5.98955 7.06379C6.01524 6.9352 6.02823 6.81771 6.02823 6.71148C6.02823 6.51938 5.98868 6.38573 5.90959 6.31025C5.83049 6.23491 5.67924 6.19709 5.45567 6.19709C5.34569 6.19709 5.23311 6.21427 5.11779 6.24847C5.00247 6.28282 4.90361 6.3153 4.8212 6.34619L4.93449 5.88318C5.21262 5.77002 5.47876 5.67318 5.73278 5.59249C5.9868 5.51196 6.22726 5.47169 6.45386 5.47169C6.86245 5.47169 7.17753 5.57041 7.39893 5.76742C7.62033 5.96472 7.73103 6.22105 7.73103 6.53656C7.73103 6.60179 7.72324 6.71668 7.70779 6.88122C7.69235 7.04575 7.66406 7.19687 7.62293 7.33398L7.1999 8.83111C7.1654 8.9512 7.13437 9.08831 7.10695 9.24274C7.07953 9.39717 7.06567 9.51365 7.06567 9.5926C7.06567 9.79148 7.11012 9.92701 7.19917 9.99903C7.28823 10.0711 7.44237 10.1071 7.66161 10.1071C7.76423 10.1071 7.8807 10.0891 8.01089 10.0532C8.14093 10.0171 8.23518 9.98546 8.29348 9.9579L8.18062 10.4205ZM8.106 4.34216C7.9087 4.52561 7.67128 4.6174 7.39344 4.6174C7.11561 4.6174 6.87717 4.52575 6.67829 4.34216C6.4794 4.15872 6.37996 3.9366 6.37996 3.67594C6.37996 3.41528 6.4794 3.19229 6.67829 3.00711C6.87717 2.82194 7.11561 2.72928 7.39344 2.72928C7.67128 2.72928 7.9087 2.82194 8.106 3.00711C8.30315 3.19229 8.40188 3.41528 8.40188 3.67594C8.40188 3.9366 8.30315 4.15872 8.106 4.34216Z"
                                    fill="#DDDFE4"
                                />
                            </svg>
                        </div>
                    </Tooltip>

                    <NotApplicablePill
                        v-if="
                            bidAdjustment.value.invalid && bidAdjustment.value.value !== 'disabled'
                        "
                        :content="bidAdjustment.value.reason"
                        :max-width="bidAdjustment.value.width"
                    />
                </template>
            </oTable>
        </ImprovementContent>

        <div v-if="entity === 'campaign'">
            <ImprovementContent>
                <Spacer height="2rem" />
                <ImprovementNote>
                    <b>Note:</b> When individual ad groups do not have enough performance data for
                    Opteo to recommend adjustments, ad groups with insufficient data are combined to
                    generate statistically significant suggestions. Adjustments based on aggregated
                    ad group data.
                </ImprovementNote>
            </ImprovementContent>
        </div>
        <Spacer v-else :height="aboveMobile ? '3rem' : '0rem'" />

        <div v-if="demographic === 'parental_status' || demographic === 'gender'">
            <ImprovementContent>
                <Text as="h3" size="f-4" weight="600">Cost Comparison</Text>
                <Spacer height="2rem" />
                <div class="graph-container">
                    <DonutChart
                        :items="donutChartItems"
                        :metric="{ label: 'Cost', dataType: 'money', currency: domainCurrencyCode }"
                        :show-keys="true"
                    />
                </div>
            </ImprovementContent>
        </div>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="lookbackWindow" />
        </ImprovementContent>

        <!-- Adjust Step -->
        <template #adjust-step-1>
            <ImprovementContent :noMargin="true">
                <ImprovementHeader title="Custom Bid Adjustments">
                    <Text as="p">
                        Set custom bid adjustments by clicking the pen icon next to the bid
                        adjustment value in the table below. Click
                        <Text as="span" color="green" weight="600"> {{ pushActionText }}</Text> to
                        push your changes to Google Ads.
                    </Text>
                </ImprovementHeader>
            </ImprovementContent>
            <ImprovementContent :tableWidth="aboveMobile ? true : false">
                <oTable
                    :headers="adjustTableHeaders"
                    :items="adjustTableItems"
                    :fixed-layout="true"
                    responsive-mode="columns"
                    responsive-breakpoint="768px"
                >
                    <template #column.cost="cost">
                        <Money :value="cost.value" :currency="domainCurrencyCode" />
                    </template>
                    <template #column.conversions="conversions">
                        <Number :value="conversions.value" :compact="true" />
                    </template>
                    <template #column.conversions_value="conversions_value">
                        <Money :value="conversions_value.value" :currency="domainCurrencyCode" />
                    </template>
                    <template #column.cpa="cpa">
                        <Money :value="cpa.value" :currency="domainCurrencyCode" />
                    </template>
                    <template #column.roas="roas">
                        <Roas :value="roas.value" />
                    </template>
                    <template #column.avg-cpa="avgCpa">
                        <Money :value="avgCpa.value" :currency="domainCurrencyCode" />
                    </template>
                    <template #column.avg-roas="avgROAS">
                        <Roas :value="avgROAS.value" />
                    </template>
                    <template #column.difference_cpa="difference_cpa">
                        <Text v-if="difference_cpa.value > 0" as="span" color="red" weight="500">
                            <span v-if="isFinite(+difference_cpa.value)">+</span
                            ><Percent :value="difference_cpa.value" />
                        </Text>
                        <Text
                            v-if="difference_cpa.value === 0"
                            as="span"
                            color="black"
                            weight="400"
                        >
                            <Percent :value="difference_cpa.value" />
                        </Text>
                        <Text v-if="difference_cpa.value < 0" as="span" color="green" weight="500">
                            <Percent :value="difference_cpa.value" />
                        </Text>
                    </template>
                    <template #column.difference_roas="difference_roas">
                        <Text v-if="difference_roas.value < 0" as="span" color="red" weight="500">
                            <Percent :value="difference_roas.value" />
                        </Text>
                        <Text
                            v-if="difference_roas.value === 0"
                            as="span"
                            color="black"
                            weight="400"
                        >
                            <Percent :value="difference_roas.value" />
                        </Text>
                        <Text v-if="difference_roas.value > 0" as="span" color="green" weight="500">
                            <span v-if="isFinite(+difference_roas.value)">+</span
                            ><Percent :value="difference_roas.value" />
                        </Text>
                    </template>
                    <template #column.bid-adjustment="bidAdjustment">
                        <AdjustBidCell
                            v-if="
                                bidAdjustment.value.reason !== InvalidBidReason.DISABLED &&
                                bidAdjustment.value.reason !== InvalidBidReason.EXCLUDED
                            "
                            :bid="bidAdjustment.value"
                            @updated="updateMultiplePlacements"
                        />
                        <NotApplicablePill
                            v-if="bidAdjustment.value.reason === InvalidBidReason.EXCLUDED"
                            content="Segment excluded."
                        />
                        <NotApplicablePill
                            v-if="bidAdjustment.value.reason === InvalidBidReason.DISABLED"
                            content="Segment disabled."
                        />
                    </template>
                </oTable>
                <Spacer :height="aboveMobile ? '3rem' : '0rem'" />
            </ImprovementContent>
        </template>
    </ImprovementView>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import {
    ImprovementHeader,
    ImprovementContent,
    ImprovementCreatedDate,
    ImprovementNote,
    Text,
    Spacer,
    EntityPill,
    EntityPillList,
    HelpLink,
    oTable,
    oInput,
    Money,
    Number,
    Percent,
    DonutChart,
    Tooltip,
    Roas,
} from '@opteo/components-next'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import ImprovementView from '@/components/improvement/ImprovementView.vue'
import useMediaQuery from '@/composition/global/useMediaQuery'

import AdjustBidCell from '@/components/improvement/shared-components/AdjustBidCell.vue'
import NotApplicablePill from '@/components/improvement/shared-components/NotApplicablePill.vue'
import {
    useAdjustDemoBids,
    InvalidBidReason,
} from '@/composition/improvement/types/demo-bids-v2/useAdjustDemoBids'

export default defineComponent({
    name: 'AdjustMultiplePlacementDemoBids',
    components: {
        ImprovementHeader,
        ImprovementContent,
        ImprovementCreatedDate,
        ImprovementView,
        ImprovementHelpLink,
        ImprovementNote,
        Text,
        Spacer,
        EntityPill,
        EntityPillList,
        HelpLink,
        oTable,
        oInput,
        Money,
        Roas,
        Number,
        Percent,
        DonutChart,
        AdjustBidCell,
        NotApplicablePill,
        Tooltip,
    },
    setup() {
        return { ...useAdjustDemoBids(), InvalidBidReason, ...useMediaQuery() }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.graph-container {
    @include container;
    @include pa-32;
}
.adjust-bid-input {
    display: flex;
    align-items: center;
}

.not-enough-data-pill {
    @include inline-flex;
    @include items-center;
    @include justify-between;
    cursor: default;
}
.not-enough-data-text {
    color: #bec1cb;
}
.not-enough-data-icon {
    color: #bec1cb;
    margin-left: 0.375rem;
    transform: translateY(3px);
}
</style>
