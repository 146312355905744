<template>
    <div>
        <!-- Page Header -->
        <div class="page-header">
            <div class="left">
                <Avatar
                    v-if="accountColor && accountInitials"
                    :color="accountColor"
                    :width="40"
                    :height="40"
                    showAccountType
                >
                    {{ accountInitials }}
                </Avatar>
                <Skeleton v-else :width="40" :height="40" />
                <Text
                    v-if="accountColor && accountInitials"
                    as="h1"
                    weight="600"
                    class="page-title"
                >
                    Scorecard
                </Text>
                <Skeleton v-else :width="192" :height="32" />
            </div>

            <RsaButton
                double
                :max-width="0"
                left-value="Cover Settings"
                right-value="Scorecard History"
                @left-clicked="showEditCoverModal = true"
                @right-clicked="noScorecards ? null : (showScorecardHistoryPanel = true)"
                :right-disabled="noScorecards"
            >
                <template #left>
                    <ScorecardCoverIcon />
                </template>
                <template #right>
                    <ScorecardHistoryIcon />
                </template>
            </RsaButton>
        </div>

        <!-- Scorecard History Panel -->
        <Panel
            :modelValue="showScorecardHistoryPanel"
            @close="closeScorecardHistoryPanel"
            title="Scorecard History"
            :width="884"
            noPadding
        >
            <template #content>
                <div class="panel-content-container">
                    <!-- Graph History Overview -->
                    <div class="section">
                        <div class="inner">
                            <div class="row-header">
                                <Text as="h5" weight="600">Last 12 Months</Text>
                                <!-- <Delta :delta="last12MonthChange" :threshold="0" classes="delta" /> -->
                            </div>
                            <Spacer height="0.75rem" />
                            <ScoreHistoryChart
                                v-if="scorecardHistoryChartItems?.length"
                                :all-time-high="allTimeHigh"
                                :all-time-low="allTimeLow"
                                :items="scorecardHistoryChartItems"
                            />
                        </div>
                    </div>
                    <!-- Table History Breakdown -->
                    <div class="section">
                        <div class="inner">
                            <div
                                v-for="(item, index) in filteredScorecardHistoryBreakdown"
                                key="item.id"
                                class="table"
                            >
                                <div class="row-header">
                                    <Text as="h5" weight="600">{{
                                        getCreationMonth(item.createdAt)
                                    }}</Text>
                                    <ColorTag
                                        v-if="item.allTimeHigh"
                                        title="Highest Score"
                                        :color="scoreColor(item.overallScore).name"
                                    />
                                    <ColorTag
                                        v-if="!item.allTimeHigh && item.allTimeLow"
                                        title="Lowest Score"
                                        :color="scoreColor(item.overallScore).name"
                                    />
                                </div>
                                <div class="history-table">
                                    <div
                                        v-for="(section, index) in item.sections"
                                        :key="section.type"
                                    >
                                        <div
                                            class="row"
                                            :style="
                                                index === item.sections.length - 1
                                                    ? 'border-bottom: none'
                                                    : ''
                                            "
                                        >
                                            <div class="name-table-cell">
                                                <Text
                                                    as="p"
                                                    size="f-8"
                                                    :color="section.noData ? 'gray' : 'black'"
                                                >
                                                    {{ section.title }}
                                                </Text>
                                            </div>
                                            <div class="score-table-cell">
                                                <ScorecardDonut
                                                    :score="section.score"
                                                    :compact="true"
                                                    :width="22"
                                                    :strokeWidth="2"
                                                    hide-score
                                                    :hidden="section.noData ? true : false"
                                                />
                                                <Text
                                                    as="p"
                                                    size="f-8"
                                                    weight="500"
                                                    :color="
                                                        section.noData
                                                            ? 'gray'
                                                            : scoreColor(section.score).name
                                                    "
                                                >
                                                    <span v-if="section.noData">N/A</span>
                                                    <Percent
                                                        v-else
                                                        :value="section.score / 100"
                                                        :decimal-places="0"
                                                    />
                                                </Text>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Spacer
                                    height="48px"
                                    v-if="
                                        index !==
                                        (filteredScorecardHistoryBreakdown ?? []).length - 1
                                    "
                                />
                            </div>
                            <!-- Show More Button -->
                            <div class="panel-button-container">
                                <Tooltip
                                    :content="
                                        showMoreButtonDisabled
                                            ? 'Showing all historical data above'
                                            : ''
                                    "
                                    :offset="[0, 4]"
                                >
                                    <oButton
                                        color="white"
                                        size="medium"
                                        @clicked="showMoreTables"
                                        :disabled="showMoreButtonDisabled"
                                    >
                                        Show More
                                    </oButton>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </Panel>
        <DeleteScorecard
            :scorecard-id="scorecardToDelete"
            @close-modal="isDeleteScorecardModalOpen = false"
        >
        </DeleteScorecard>

        <!-- Empty State -->
        <div v-if="noScorecards" class="scorecard-empty-state-container">
            <marquee-text :duration="50" :repeat="4">
                <div class="slides">
                    <OverviewLoaderSlide />
                    <SearchTermCoverageLoaderSlide />
                    <QualityScoreLoaderSlide />
                    <ProjectedSpendLoaderSlide />
                </div>
            </marquee-text>
        </div>

        <!-- Scorecard List -->
        <div v-else class="scorecard-list">
            <div v-for="scorecard in sortedScoreCardList" :key="scorecard.id">
                <ScorecardListItem
                    :scorecard-item="scorecard"
                    @scorecard-opened="openSingleScorecard(scorecard.id)"
                    @delete-scorecard="deleteSelectedScorecard(scorecard.id)"
                    :cover-page-data="scorecard.coverPageData"
                    :is-manually-created="!scorecard.autoGenerated"
                    :key="scorecard.id"
                />
            </div>
        </div>

        <!-- Create New Scorecard Button -->
        <a class="create-new" @click.prevent="createNewScorecard()" href="" tabindex="0">
            <div style="display: flex; align-items: center">
                <span>Generate New Scorecard</span>
                <Spacer width="0.75rem" />
                <svg width="12" height="12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M1 11 11 1M11 1H3M11 1v8"
                        stroke="#fff"
                        stroke-width="2"
                        stroke-linecap="round"
                    />
                </svg>
            </div>
        </a>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch, provide, Ref } from 'vue'
import sortBy from 'lodash-es/sortBy'
import maxBy from 'lodash-es/maxBy'
import minBy from 'lodash-es/minBy'
import { useRouter } from 'vue-router'
import { Routes } from '@/router/routes'
import {
    Text,
    Avatar,
    Spacer,
    RsaButton,
    Panel,
    Percent,
    ColorTag,
    oButton,
    Tooltip,
} from '@opteo/components-next'
import Skeleton from '@/components/util/Skeleton.vue'
import ScoreHistoryChart from '@/components/scorecard/ScoreHistoryChart.vue'
import ScorecardDonut from '@/components/scorecard/ScorecardDonut.vue'
import ScorecardListItem from '@/components/scorecard/ScorecardListItem.vue'
import { useAccount } from '@/composition/account/useAccount'
import { useScorecardCoverEditModal } from '@/composition/toolkit/scorecard/useScorecardCoverEditModal'
import { scoreColor } from '@/composition/toolkit/scorecard/utils'
import { getCreationMonth } from '@/components/scorecard/utils'
import DeleteScorecard from '@/components/scorecard/DeleteScorecard.vue'
import ScorecardCoverIcon from '@/components/scorecard/ScorecardCoverIcon.vue'
import ScorecardHistoryIcon from '@/components/scorecard/ScorecardHistoryIcon.vue'

import MarqueeText from 'vue-marquee-text-component'
import OverviewLoaderSlide from '@/components/scorecard/loader/OverviewLoaderSlide.vue'
import SearchTermCoverageLoaderSlide from '@/components/scorecard/loader/SearchTermCoverageLoaderSlide.vue'
import QualityScoreLoaderSlide from '@/components/scorecard/loader/QualityScoreLoaderSlide.vue'
import ProjectedSpendLoaderSlide from '@/components/scorecard/loader/ProjectedSpendLoaderSlide.vue'
import { useScorecardList } from '@/composition/toolkit/scorecard/useScorecardList'

const { accountColor, accountInitials } = useAccount()

const router = useRouter()

const { scorecardData, mutateScorecardList } = useScorecardList()

const noScorecards = computed(() => {
    return scorecardData?.value?.scorecardList?.length === 0
})

// Scorecard History Panel
let showScorecardHistoryPanel = ref(false)

/* Delete Scorecard */
const isDeleteScorecardModalOpen = ref(false)
const scorecardToDelete = ref('')
provide<{ isDeleteScorecardModalOpen: Ref<boolean>; mutateScorecardList: () => void }>(
    'delete-scorecard',
    {
        isDeleteScorecardModalOpen,
        mutateScorecardList,
    }
)

const deleteSelectedScorecard = (scorecardId: string) => {
    isDeleteScorecardModalOpen.value = true
    scorecardToDelete.value = scorecardId
}
const closeScorecardHistoryPanel = () => {
    resetLimitTables()
    showScorecardHistoryPanel.value = false
}

let limitTables = ref(3)
function showMoreTables() {
    limitTables.value++
}
function resetLimitTables() {
    limitTables.value = 3
}

const showMoreButtonDisabled = computed(() => {
    return limitTables.value >= tailLessScorecardHistoryBreakdown?.value?.length
})

const sortedScoreCardList = computed(() => {
    return sortBy(scorecardData.value?.scorecardList, sc => -new Date(sc?.createdAt).getTime())
})

const scorecardHistoryBreakdown = computed(() => {
    return scorecardData.value?.scorecardHistory
})

/* Removes tail of items with no data */
const tailLessScorecardHistoryBreakdown = computed(() => {
    const indexOfLastItemWithData =
        scorecardHistoryBreakdown?.value?.reverse().findIndex(sc => !sc.noData) ?? 0
    scorecardHistoryBreakdown?.value?.reverse()

    return (
        scorecardHistoryBreakdown?.value?.slice(
            0,
            scorecardHistoryBreakdown.value.length - indexOfLastItemWithData
        ) ?? []
    )
})

const filteredScorecardHistoryBreakdown = computed(() => {
    return tailLessScorecardHistoryBreakdown.value?.slice(0, limitTables.value) ?? []
})

const scorecardHistoryChartItems = computed(() => {
    const breakdownData = scorecardHistoryBreakdown?.value?.map(sc => {
        return {
            label: getCreationMonth(sc.createdAt),
            score: +sc?.overallScore?.toFixed() ?? 0,
            noData: sc.noData ?? false,
        }
    })
    return breakdownData?.slice().reverse()
})

watch(filteredScorecardHistoryBreakdown, () => {
    const scorecardsWithData = filteredScorecardHistoryBreakdown.value?.filter(sc => !sc.noData)

    if (scorecardsWithData && scorecardsWithData.length > 0) {
        const lowestScore = minBy(scorecardsWithData, s => s.overallScore)
        const highestScore = maxBy(scorecardsWithData, s => s.overallScore)
        highestScore!.allTimeHigh = true

        if (
            Math.round(lowestScore?.overallScore ?? 0) !=
            Math.round(highestScore?.overallScore ?? 0)
        ) {
            lowestScore!.allTimeLow = true
            return
        }
    }
})

// Record High / Low
const allTimeHigh = computed(() => {
    const monthsWithData = scorecardHistoryChartItems?.value?.filter(sc => !sc.noData)
    if (!monthsWithData || !monthsWithData.length) {
        return
    }
    return Math.max(...(monthsWithData?.map(sc => sc.score) ?? []))
})

const allTimeLow = computed(() => {
    const monthsWithData = scorecardHistoryChartItems?.value?.filter(sc => !sc.noData)
    if (!monthsWithData || !monthsWithData.length) {
        return
    }
    return Math.min(...(monthsWithData?.map(sc => sc?.score) ?? []))
})

function openSingleScorecard(scorecardId: string) {
    router.push({
        name: Routes.ToolkitScorecardSingle,
        params: { scorecardId },
    })
}

async function createNewScorecard() {
    router.push({ name: Routes.ToolkitScorecardCreate })
}

const { showEditCoverModal } = useScorecardCoverEditModal()
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

// Page header
.page-header {
    @include flex;
    @include items-center;
    @include justify-between;
    @include w-100;
    height: 2.75rem;
    margin-bottom: 4rem;
}

.page-header .left {
    @include flex;
    @include items-center;
    gap: 1.5rem;
}

.page-title {
    @include opteo-foreground;
    @include f-2;
    @include no-select;
    line-height: 2.75rem;
}

// Scorecard List
.scorecard-list {
    @include flex;
    flex-direction: column;
    gap: 1.25rem;
    margin-bottom: 9rem;
}

// Scorecard History Panel
.panel-content-container .section {
    border-bottom: 1px solid;
    @include opteo-border;
    @include pa-48;
}

.panel-content-container .section:last-child {
    border-bottom: unset;
}

.panel-content-container .section .inner {
    max-width: 25rem;
    margin: 0 auto;
}

.panel-content-container .section .inner .panel-button-container {
    @include w-100;
    @include flex-center;
    @include pt-40;
}

.panel-content-container .section .row-header {
    @include mb-24;
    @include flex;
    @include items-center;
    @include justify-between;
}

.panel-content-container .section .row-header {
    font-size: 1.125rem;
}

.panel-content-container .section .history-table {
    @include container;
    @include br-16;
}

.panel-content-container .section .history-table .row {
    @include flex;
    @include justify-between;
    border-bottom: 1px solid;
    @include opteo-border;
    height: 4rem;
    @include items-center;
    @include relative;
}

.panel-content-container .section .history-table .name-table-cell {
    @include pl-24;
}

.panel-content-container .section .history-table .score-table-cell {
    @include flex;
    @include items-center;
    gap: 0.5rem;
    @include pr-24;
    width: 92px;
}

// Empty states
.scorecard-empty-state-container :deep(.marquee-text-wrap) {
    @include pv-56;
}
.scorecard-empty-state-container :deep(.marquee-text-content .slides svg) {
    margin-right: 2rem;
}
.scorecard-empty-state-container {
    @include w-100;
    @include container;
    border-radius: 2.75rem;
    @include pa-0;
    @include flex;
    flex-direction: column;
    overflow: hidden;
}

.scorecard-empty-state-container .empty-state-content {
    max-width: 20rem;
    @include flex;
    @include items-center;
    flex-direction: column;
    gap: 1.5rem;
    margin: 0 auto;
    @include pb-56;
}

// Create New Scorecard Button
.create-new {
    @include fixed;
    bottom: 1.25rem;
    left: 50%;
    transform: translate3d(-50%, 0px, 0px);
    @include flex;
    @include items-center;
    @include justify-center;
    z-index: 10000;
    @include bg-opteo-blue;
    @include opteo-white;
    @include f-7;
    @include fw-500;
    @include br-999;
    height: 3.75rem;
    padding-left: 1.75rem;
    padding-right: 1.8125rem;
    box-shadow: $opteo-shadow-fill-xl;
    transition:
        transform 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        background 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        box-shadow 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        opacity 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        color 0.25s cubic-bezier(0.19, 1, 0.22, 1);
}

.create-new.errored {
    @include bg-opteo-red;
}

.create-new.no-pointer {
    cursor: default;
}

.create-new span,
.create-new svg {
    transform: translateY(-1px);
}

.create-new:focus:not(.errored) {
    outline: none;
    box-shadow: $opteo-shadow-fill-xl, $opteo-shadow-focus-ring;
}

.create-new:active:not(.no-pointer) {
    outline: none;
    box-shadow: $opteo-shadow-fill-xl, $opteo-shadow-focus-ring;
    transform: translate3d(-50%, 1px, 0);
}

@media screen and (min-width: $mq-1280-min) {
    .page-title {
        font-size: 2.25rem;
        font-weight: 600;
        letter-spacing: -0.075rem;
    }
}

:deep(.modal-content.container-shadow) {
    border-radius: 28px;
}

// Header button adjustments
:deep(.rsa-button) {
    border-radius: 0.75rem;
}
:deep(.rsa-button.double .left),
:deep(.rsa-button.double .right) {
    padding: 0.75rem 1.125rem 0.75rem 1rem;
}
:deep(.rsa-button.double .left .icon-slot) {
    width: 1.375rem;
}
:deep(.rsa-button.double .left:focus),
:deep(.rsa-button.double .right:focus) {
    border-radius: 0.75rem;
}
</style>
