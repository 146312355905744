<template>
    <ImprovementView
        :pushMessages="pushMessages"
        :pushActionText="pushActionText"
        :isAdjustable="true"
        :onPush="onPush"
        :title="title"
        @back-clicked="resetBid"
    >
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <div v-if="aboveMobile">
                    <EntityPill type="keyword" :content="formattedKeyword" />
                    <Spacer width="100%" height="2rem" />
                </div>

                <!-- CPA Intro -->
                <Text v-if="isUsingCpa">
                    The keyword above has generated
                    <b><Number :value="Math.round(keywordConversions)" /></b> conversions at an
                    average CPA of <b><Money :value="keywordCpa" /></b>. In comparison, the parent
                    campaign group has {{ isAutoTargeting ? 'an average' : 'a Target' }} CPA of
                    <b><Money :value="campaignGroupTargetCpa" /></b>.
                </Text>

                <!-- ROAS Intro -->
                <Text v-else>
                    The keyword above has generated
                    <b><Money :value="keywordConversionsValue" /></b> conversion value with a ROAS
                    of <b><Roas :value="keywordRoas" /></b>. For comparison, the parent campaign
                    group has a ROAS of <b><Roas :value="campaignGroupTargetRoas" /></b>.
                </Text>
                <Spacer height="1rem" />
                <Text v-if="isBidIncreasing">
                    Opteo recommends increasing the bid for this keyword by
                    <Text as="span" weight="600" color="green"
                        ><Percent :value="adjustedBidChange" :include-sign="false"
                    /></Text>
                    — from <b>{{ formattedCurrentBid }}</b> to <b>{{ formattedNewBid }}</b> — this
                    should
                    {{ isUsingCpa ? 'generate more conversions' : 'increase conversion value' }} at
                    a reasonable cost.
                </Text>
                <Text v-else>
                    Opteo recommends decreasing the bid for this keyword by
                    <Text as="span" weight="600" color="red"
                        ><Percent :value="Math.abs(adjustedBidChange)" :include-sign="false"
                    /></Text>
                    — from <b>{{ formattedCurrentBid }}</b> to <b>{{ formattedNewBid }}</b> — this
                    should {{ isUsingCpa ? 'reduce CPA' : 'boost ROAS' }} and allow for smarter
                    budget allocation.
                </Text>
                <Spacer height="1rem" />
                <Text>
                    Click
                    <Text as="span" color="green" weight="600">{{ pushActionText }}</Text> to apply
                    the recommended adjustment to this keyword. Click <b>Adjust</b> to
                    {{ isBidIncreasing ? 'increase' : 'decrease' }} your bid by a different amount
                    (or to {{ isBidIncreasing ? 'decrease' : 'increase' }} your bid).
                </Text>
            </ImprovementHeader>

            <ImprovementHelpLink v-if="isUsingCpa" path="/en/articles/902970-adjust-keyword-bid">
                Learn how keyword bid adjustments can help reduce overall CPA.
            </ImprovementHelpLink>
            <ImprovementHelpLink v-else path="/en/articles/902970-adjust-keyword-bid">
                Learn how keyword bid adjustments can help increase overall ROAS.
            </ImprovementHelpLink>
        </ImprovementContent>

        <div v-if="isUsingBidFactory">
            <ImprovementContent class="keyword-bid-simulation-header">
                <Text as="h3" size="f-4" weight="600">Keyword Bid Simulation</Text>
                <Spacer height="1.5rem" />
                <Text as="p">
                    To simulate {{ isUsingCpa ? 'conversions' : 'conversion value' }} and
                    {{ isUsingCpa ? 'CPA' : 'ROAS' }} for each bid, Opteo assumes a conversion rate
                    of <b><Percent :value="conversionRate" :decimal-places="2" /></b>. This is based
                    on the conversion rate of this keyword over the last
                    <b>{{ lookbackWindow }}</b> days. For reference, Opteo caps bid adjustments at
                    <b>30%</b> to reduce volatility.
                </Text>
            </ImprovementContent>
            <ImprovementContent :adWidth="true">
                <oTable
                    :headers="[
                        { key: 'cpc_bid', text: 'Key. Bid', width: 82 },
                        { key: 'cost', text: 'Cost', width: 86 },
                        { key: 'clicks', text: 'Clicks', width: 76 },
                        isUsingCpa
                            ? { key: 'conversions', text: 'Conv.', width: 82 }
                            : { key: 'conversions_value', text: 'Value', width: 82 },
                        isUsingCpa
                            ? { key: 'cpa', text: 'CPA', width: 82 }
                            : { key: 'roas', text: 'ROAS', width: 82 },
                        { key: 'analysis', text: 'Analysis', width: 150 },
                    ]"
                    :items="simulationPoints"
                    responsive-mode="columns"
                    responsive-breakpoint="768px"
                    order-by="bid"
                    order="DESC"
                    :fixed-layout="true"
                    :border-radius="16"
                >
                    <template #column.cpc_bid="cpc_bid">
                        <Money :value="cpc_bid.value" />
                    </template>
                    <template #column.cost="cost">
                        <Money :value="cost.value" :compact="cost.value > 9999 ? true : false" />
                    </template>
                    <template #column.clicks="clicks">
                        <Number
                            :value="Math.round(clicks.value)"
                            :compact="clicks.value > 99999 ? true : false"
                        />
                    </template>
                    <template #column.conversions="conversions">
                        <Number
                            :value="Math.round(conversions.value * 100) / 100"
                            :compact="conversions.value > 9999 ? true : false"
                        />
                    </template>
                    <template #column.conversions_value="conversionsValue">
                        <Money :value="conversionsValue.value" />
                    </template>
                    <template #column.cpa="cpa">
                        <Money :value="cpa.value" :compact="cpa.value > 9999 ? true : false" />
                    </template>
                    <template #column.roas="roas">
                        <Roas :value="roas.value" />
                    </template>
                    <template #column.analysis="analysis">
                        <Text
                            as="span"
                            size="f-8"
                            :color="
                                analysis.value ===
                                BiddingStrategies.BidFactory.BidAnalysis.SUGGESTED
                                    ? 'green'
                                    : 'black'
                            "
                            :weight="
                                analysis.value ===
                                BiddingStrategies.BidFactory.BidAnalysis.SUGGESTED
                                    ? '500'
                                    : '400'
                            "
                        >
                            {{ analysis.value }}
                        </Text>
                    </template>
                </oTable>
                <Spacer height="3rem" />
            </ImprovementContent>

            <ImprovementContent>
                <Text as="h3" size="f-4" weight="600">Simulation Chart</Text>
                <Spacer height="2rem" />
                <div class="graph-container">
                    <BidFactoryChart
                        v-if="isUsingCpa"
                        :prediction-items="predictionItems"
                        :simulation-points="simulationPoints"
                        :target="campaignGroupTargetCpa"
                        :performance-mode="Targets.PerformanceMode.CPA"
                        :currency-code="domainCurrencyCode"
                        :is-auto-targeting="isAutoTargeting"
                    />
                    <BidFactoryChart
                        v-else
                        :prediction-items="predictionItems"
                        :simulation-points="simulationPoints"
                        :target="campaignGroupTargetRoas"
                        :performance-mode="Targets.PerformanceMode.ROAS"
                        :currency-code="domainCurrencyCode"
                        :is-auto-targeting="isAutoTargeting"
                    />
                </div>
                <Spacer height="2rem" />
                <ImprovementNote v-if="isBidCapped">
                    <b>Note:</b> Using bid simulation data from Google Ads, Opteo is able to
                    calculate the optimal bid for this keyword. For reference, keyword bid
                    adjustments are capped at 30%.
                </ImprovementNote>
                <ImprovementNote v-else-if="isUsingCpa">
                    <b>Note:</b> Using bid simulation data from Google Ads, Opteo is able to
                    calculate the optimal bid for this keyword. This bid should generate conversions
                    at {{ isAutoTargeting ? 'an average' : 'your target' }} CPA of
                    <b><Money :value="campaignGroupTargetCpa" /></b>.
                </ImprovementNote>
                <ImprovementNote v-else>
                    <b>Note:</b> Using bid simulation data from Google Ads, Opteo is able to
                    calculate the optimal bid for this keyword. This bid should increase value at
                    {{ isAutoTargeting ? 'an average' : 'your target' }} ROAS of
                    <b><Money :value="campaignGroupTargetRoas" /></b>.
                </ImprovementNote>
            </ImprovementContent>
        </div>

        <!-- Show ScatterChart if BidFactory has below data point threshold -->
        <div v-else>
            <ImprovementContent>
                <ScatterPointChart
                    v-if="isUsingCpa"
                    :items="scatterPointChartItems"
                    :graphKeys="
                        isAutoTargeting
                            ? ['Above CPA Average', '', 'Below CPA Average']
                            : ['Above CPA Target', '', 'Below CPA Target']
                    "
                    :cutOff="campaignGroupTargetCpa"
                    :axis="{
                        x: { label: 'Conversions' },
                        y: { label: 'Cost', dataType: 'money', currency: domainCurrencyCode },
                    }"
                    :metric="{
                        label: 'CPA',
                        dataType: 'money',
                        currency: domainCurrencyCode,
                        inverted: true,
                    }"
                />
                <ScatterPointChart
                    v-else
                    :items="scatterPointChartItems"
                    :graphKeys="
                        isAutoTargeting
                            ? ['Above ROAS Average', '', 'Below ROAS Average']
                            : ['Above ROAS Target', '', 'Below ROAS Target']
                    "
                    :cutOff="campaignGroupTargetRoas"
                    :axis="{
                        x: { label: 'Cost', dataType: 'money', currency: domainCurrencyCode },
                        y: { label: 'Value', dataType: 'money', currency: domainCurrencyCode },
                    }"
                    :metric="{
                        label: 'ROAS',
                        dataType: 'roas',
                    }"
                />
            </ImprovementContent>

            <ImprovementContent>
                <ImprovementNote v-if="isUsingCpa">
                    <b>Note:</b> Hover over any point to explore keyword statistics in more detail.
                    Keywords in green zones are below your CPA target, whilst those in red zones are
                    above target.
                </ImprovementNote>

                <ImprovementNote v-else>
                    <b>Note:</b> Hover over any point to explore keyword statistics in more detail.
                    Keywords in green zones are above your ROAS target, whilst those in red zones
                    are below target.
                </ImprovementNote>
            </ImprovementContent>
        </div>

        <ImprovementContent>
            <ImprovementStatistics
                title="Recommended Bid Adjustment"
                :items="[
                    {
                        key: 'bidAdjustment',
                        deltaMode: true,
                        beforeValue: formattedCurrentBid,
                        beforeTitle: 'Current Bid',
                        afterValue: formattedNewBid,
                        afterTitle: 'Adjusted Bid',
                    },
                ]"
            >
                <template #bidAdjustment>
                    <Text as="p" size="f-8">
                        Opteo recommends {{ isBidIncreasing ? 'increasing' : 'decreasing' }} the bid
                        for this keyword by
                        <Text
                            :color="suggestedBidChange > 0 ? 'green' : 'red'"
                            as="span"
                            weight="600"
                        >
                            <Percent :value="adjustedBidChange" :include-sign="false" /> </Text
                        >.
                    </Text>
                </template>
            </ImprovementStatistics>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementStatistics :items="improvementStatsItems">
                <template #averageCPA>
                    <Text as="p" size="f-8">
                        The parent campaign group has
                        {{ isAutoTargeting ? 'an average' : 'a Target' }} CPA of
                        <Ent><Money :value="campaignGroupTargetCpa" /></Ent>.
                    </Text>
                </template>
                <template #averageRoas>
                    <Text as="p" size="f-8">
                        The parent campaign group has
                        {{ isAutoTargeting ? 'an average' : 'a Target' }} ROAS of
                        <Ent><Roas :value="campaignGroupTargetRoas" /></Ent>.
                    </Text>
                </template>
                <template #keywordCpa>
                    <Text as="p" size="f-8">
                        Over the last <b>{{ lookbackWindow }}</b> days, this keyword had an average
                        CPA of <Ent><Money :value="keywordCpa" /></Ent>.
                    </Text>
                </template>
                <template #keywordRoas>
                    <Text as="p" size="f-8">
                        Over the last <b>{{ lookbackWindow }}</b> days, this keyword had an average
                        ROAS of <Ent><Roas :value="keywordRoas" /></Ent>.
                    </Text>
                </template>
                <template #searchImprShare>
                    <Text as="p" size="f-8">
                        Your ads were shown in Google's search results
                        <Ent><Percent :value="searchImpressionShare" /></Ent> of the time they were
                        eligible.
                    </Text>
                </template>
                <template #absoluteTopImprShare>
                    <Text as="p" size="f-8">
                        Over the last <b>{{ lookbackWindow }}</b> days, ads triggered by this
                        keyword appeared in the top-most position of Google's search engine results
                        page
                        <Ent><Percent :value="absoluteTopImpressionShare" /></Ent>
                        of the time.
                    </Text>
                </template>
                <template #firstPageBid>
                    <Text as="p" size="f-8">
                        Google recommends bidding a minimum of
                        <Ent><Money :value="firstPageBid" /></Ent> to appear on the first page.
                    </Text>
                </template>
            </ImprovementStatistics>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="lookbackWindow" />
        </ImprovementContent>

        <template #adjust-step-1>
            <ImprovementContent>
                <ImprovementHeader :title="title">
                    <div v-if="aboveMobile">
                        <EntityPill type="keyword" :content="formattedKeyword" />
                        <Spacer width="100%" height="2rem" />
                    </div>
                    <Text v-if="isUsingCpa">
                        The keyword above has generated
                        <b><Number :value="Math.round(keywordConversions)" /></b> conversions at an
                        average CPA of <b><Money :value="keywordCpa" /></b>. In comparison, the
                        parent campaign group has
                        {{ isAutoTargeting ? 'an average' : 'a Target' }} CPA of
                        <b><Money :value="campaignGroupTargetCpa" /></b>.
                    </Text>

                    <Text v-else>
                        The keyword above has generated
                        <b><Money :value="keywordConversionsValue" /></b> conversion value with a
                        ROAS of <b><Roas :value="keywordRoas" /></b>. For comparison, the parent
                        campaign group has a ROAS of
                        <b><Roas :value="campaignGroupTargetRoas" /></b>.
                    </Text>
                    <Spacer height="1rem" />

                    <Text v-if="isBidIncreasing">
                        Opteo recommends increasing the bid for this keyword by
                        <Text as="span" weight="600" color="green"
                            ><Percent :value="suggestedBidChange" :include-sign="false"
                        /></Text>
                        — from <b>{{ formattedCurrentBid }}</b> to <b>{{ formattedNewBid }}</b> —
                        this should
                        {{ isUsingCpa ? 'generate more conversions' : 'increase conversion value' }}
                        at a reasonable cost.
                    </Text>
                    <Text v-else>
                        Opteo recommends decreasing the bid for this keyword by
                        <Text as="span" weight="600" color="red"
                            ><Percent :value="Math.abs(suggestedBidChange)" :include-sign="false"
                        /></Text>
                        — from <b>{{ formattedCurrentBid }}</b> to <b>{{ formattedNewBid }}</b> —
                        this should {{ isUsingCpa ? 'reduce CPA' : 'boost ROAS' }} and allow for
                        smarter budget allocation.
                    </Text>
                </ImprovementHeader>

                <InlineInputContainer :single-side="true">
                    <template #left-side>
                        <Text as="span" size="f-8" class="adjustment-text">Set keyword bid to</Text>
                    </template>
                    <template #input>
                        <oForm ref="bidInputForm">
                            <oInput
                                type="number"
                                name="bid"
                                v-model.number="adjustedBid"
                                :prefix="currencySymbol"
                                class="adjustment-input"
                                autofocus
                                required
                                min="0.01"
                                @updated="handleBidUpdate"
                            />
                        </oForm>
                    </template>
                </InlineInputContainer>
            </ImprovementContent>
        </template>
    </ImprovementView>
</template>
<script lang="ts" setup>
import {
    ImprovementHeader,
    ImprovementContent,
    Percent,
    ImprovementNote,
    ImprovementStatistics,
    ImprovementCreatedDate,
    Number,
    Spacer,
    Text,
    EntityPill,
    oTable,
    ScatterPointChart,
    BidFactoryChart,
    oInput,
    oForm,
    Roas,
} from '@opteo/components-next'

import ImprovementView from '@/components/improvement/ImprovementView.vue'
import InlineInputContainer from '@/components/improvement/shared-components/InlineInputContainer.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import Money from '@/components/global/oMoney.vue'
import Ent from '@/components/util/Ent.vue'
import useMediaQuery from '@/composition/global/useMediaQuery'

import { useAdjustKeywordBidV2 } from '@/composition/improvement/types/useAdjustKeywordBidV2'
import { BiddingStrategies, Targets } from '@opteo/types'

const {
    title,
    pushMessages,
    pushActionText,
    onPush,
    lastUpdated,
    isBidIncreasing,
    keywordConversions,
    keywordConversionsValue,
    formattedKeyword,
    suggestedBidChange,
    adjustedBidChange,
    resetBid,
    campaignGroupTargetCpa,
    campaignGroupTargetRoas,
    adjustedBid,
    formattedCurrentBid,
    formattedNewBid,
    keywordCpa,
    keywordRoas,
    absoluteTopImpressionShare,
    firstPageBid,
    improvementStatsItems,
    searchImpressionShare,
    lookbackWindow,
    isAutoTargeting,
    conversionRate,
    domainCurrencyCode,
    predictionItems,
    isUsingBidFactory,
    simulationPoints,
    currencySymbol,
    scatterPointChartItems,
    isBidCapped,
    isUsingCpa,
    handleBidUpdate,
} = useAdjustKeywordBidV2()

const { aboveMobile } = useMediaQuery()
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

// Adjustment

.adjustment-input {
    width: 100%;
}
.adjustment-text {
    line-height: 2.75rem;
}
.graph-container {
    @include container;
    @include pa-32;
}

.keyword-bid-simulation-header {
    @include mb-36;
}

// Responsive

@media screen and (min-width: $mq-767-max) {
    .adjustment-input {
        width: 9.75rem;
    }
}

@media screen and (max-width: $mq-480-max) {
    .graph-container {
        box-shadow: unset;
        padding: unset;
    }
}
</style>
