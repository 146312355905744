<template>
    <ImprovementView :push-messages="pushMessages" :push-action-text="pushActionText">
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <div v-if="aboveMobile">
                    <EntityPill type="url" :content="url" />
                    <Spacer height="2rem" />
                </div>
                <Text as="p">
                    Opteo encountered a
                    <Text as="span" color="red" weight="600">{{ reason }}</Text> error when
                    attempting to visit the link above. This link could be wasting budget in the
                    following {{ copy.campaign }}:
                </Text>
                <Spacer height="2rem" />
                <div v-if="formattedCampaignNames.length === 1">
                    <EntityPillList
                        v-if="!aboveMobile && formattedCampaignNames[0].content.length > 30"
                        :items="formattedCampaignNames"
                    />
                    <div v-else>
                        <EntityPill
                            v-for="(campaign, index) in formattedCampaignNames"
                            v-bind:key="index"
                            :type="campaign.type"
                            :content="campaign.content"
                            class="entity-pill"
                        />
                    </div>
                </div>
                <div v-else>
                    <EntityPillList :items="formattedCampaignNames" />
                </div>
                <Spacer height="2rem" />
                <Text as="p">
                    Fix the broken link if possible, otherwise contact your development team or
                    website administrator. If the link cannot be fixed in the near future, consider
                    pausing any ad groups using the link as a destination until fixes can be made.
                    Once repaired, click
                    <Text as="span" color="green" weight="600">{{ pushActionText }}</Text> to
                    confirm and this improvement will be moved to <b>Completed</b>.
                </Text>
            </ImprovementHeader>
            <ImprovementHelpLink path="/en/articles/902713-fix-broken-links-sitelinks">
                Click here to learn how Opteo detects and flags broken sitelinks.
            </ImprovementHelpLink>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="30" />
        </ImprovementContent>
    </ImprovementView>
</template>

<script lang="ts">
import {
    ImprovementHeader,
    ImprovementContent,
    ImprovementNote,
    ImprovementCreatedDate,
    EntityPillList,
    EntityPill,
    oButton,
    Text,
    Spacer,
} from '@opteo/components-next'

import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'

import useMediaQuery from '@/composition/global/useMediaQuery'
import { useFixBrokenSitelink } from '@/composition/improvement/types/useFixBrokenSitelink'

export default {
    name: 'FixBrokenSitelink',
    components: {
        ImprovementView,
        ImprovementHeader,
        ImprovementContent,
        ImprovementNote,
        ImprovementHelpLink,
        ImprovementCreatedDate,
        EntityPillList,
        EntityPill,
        oButton,
        Text,
        Spacer,
    },
    setup() {
        return { ...useFixBrokenSitelink(), ...useMediaQuery() }
    },
}
</script>
