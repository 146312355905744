<template>
    <ImprovementView
        :push-messages="pushMessages"
        :push-action-text="pushActionText"
        :isAdjustable="true"
        :on-push="onPush"
    >
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <div v-if="aboveMobile">
                    <EntityPill
                        v-if="aboveMobile"
                        type="conversion-action"
                        :content="conversionActionName"
                    />
                    <Spacer width="100%" height="2rem" />
                </div>
                <Text as="p">
                    The conversion action above is currently using a <b>Last Click</b> attribution
                    model. Last Click attribution models can be misleading because they attribute
                    all of the credit for a conversion to the last click a user makes before
                    converting. In most cases, a user's conversion path has many different touch
                    points, all of which contribute towards the conversion. When using Last Click
                    attribution, these other touch points get no credit.
                    <!-- Spacer -->
                    <Spacer height="1rem" />
                    Last Click overvalues bottom-of-funnel campaigns like
                    <b>Brand</b> and <b>Remarketing</b>, while undervaluing top-of-funnel campaigns
                    with generic, high volume keywords.
                    <!-- Spacer -->
                    <Spacer height="1rem" />
                    Click <Text as="span" color="green" weight="600">{{ pushActionText }}</Text> to
                    switch from <b>Last Click</b> attribution to <b>Position Based</b> attribution.
                    This should give you a better understanding of ad performance and help you
                    optimise ad creative across different levels of your marketing funnel.
                </Text>
            </ImprovementHeader>
        </ImprovementContent>

        <ImprovementContent :adWidth="true" style="margin-top: 3.5rem; margin-bottom: 3rem">
            <div class="attribution-model-container">
                <!-- Last Click -->
                <div class="attribution-model">
                    <div class="attribution-model-type">Current</div>
                    <div class="attribution-model-content">
                        <LastClickIcon />
                        <Spacer height="1.25rem" />
                        <Text as="p" size="f-8" style="letter-spacing: -0.0125rem" align="center">
                            Last Click
                        </Text>
                    </div>
                    <div class="attribution-model-text">
                        <Text as="p" size="f-8">
                            Gives <b>all</b> credit for a conversion to the <b>last clicked</b> ad
                            and its corresponding keyword.
                        </Text>
                    </div>
                </div>
                <!-- Spacer -->
                <Spacer width="2rem" height="3rem" />
                <!-- Position Based -->
                <div class="attribution-model">
                    <div class="attribution-model-type recommended">Recommended</div>
                    <div class="attribution-model-content">
                        <PositionBasedIcon />
                        <Spacer height="1.25rem" />
                        <Text as="p" size="f-8" style="letter-spacing: -0.0125rem" align="center">
                            Position Based
                        </Text>
                    </div>
                    <div class="attribution-model-text">
                        <Text as="p" size="f-8">
                            Gives <b>40%</b> credit to both <b>first clicked</b> and
                            <b>last clicked</b> ads and their corresponding keywords. The remaining
                            <b>20%</b> is spread across other on-path clicks.
                        </Text>
                    </div>
                </div>
            </div>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementHelpLink path="/articles/3638517-adjust-conversion-attribution-model">
                Learn more about attribution models and how they help track performance.
            </ImprovementHelpLink>
        </ImprovementContent>

        <ImprovementContent>
            <Text as="h3" size="f-4" weight="600">Conversion Action Profile</Text>
            <Spacer height="2rem" />
            <ConversionActionProfile :items="tableItems" />
        </ImprovementContent>
        <ImprovementContent>
            <Text as="h3" size="f-4" weight="600">Conversion Delay</Text>
            <Spacer height="2rem" />
            <EntityPill type="conversion-action" :content="conversionActionName" />
            <Spacer height="2rem" />
            <Text as="p">
                The chart below considers the length of time between a user clicking their first ad
                and performing the above conversion action. In
                <b><Percent :value="conversionsLongerThanDayAsAPercent" /></b> of cases, this takes
                longer than one day, and sometimes as long as {{ titleCaseLongestLagDelay }}.
                <!-- Spacer -->
                <Spacer height="1rem" />
                This suggests there are multiple touch points involved in
                <b><Percent :value="conversionsLongerThanDayAsAPercent" /></b> of conversion paths.
                Using <b>Position Based</b> attribution should provide more accurate tracking for
                this group because it allocates credit to each of the user's touch points.
            </Text>
            <!-- Spacer -->
            <Spacer height="2rem" />
            <div class="graph-container">
                <DonutChart :items="donutChartItems" :metric="{ label: 'Conversions' }" />
            </div>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="30" />
        </ImprovementContent>

        <template v-slot:adjust-step-1>
            <ImprovementContent>
                <ImprovementHeader title="Adjust Attribution Model">
                    <EntityPill type="conversion-action" :content="conversionActionName" />
                    <Spacer width="100%" height="2rem" />
                    <Text as="p">
                        Opteo recommends switching to a
                        <b>Position Based</b> attribution model.
                    </Text>
                </ImprovementHeader>
                <div class="graph-container">
                    <oInput
                        v-model="selectedAttributionModel"
                        :items="attributionModelSelectItems"
                        type="select"
                        label="Attribution Models"
                    />
                </div>
            </ImprovementContent>
        </template>
    </ImprovementView>
</template>

<script lang="ts">
import {
    ImprovementNote,
    ImprovementHeader,
    ImprovementContent,
    ImprovementCreatedDate,
    EntityPill,
    Spacer,
    Text,
    oTable,
    DonutChart,
    AreaChart,
    oInput,
    Percent,
    ConversionActionProfile,
} from '@opteo/components-next'

import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'

import LastClickIcon from './LastClickIcon.vue'
import PositionBasedIcon from './PositionBasedIcon.vue'
import useMediaQuery from '@/composition/global/useMediaQuery'

import { useAdjustAttributionModel } from '@/composition/improvement/types/useAdjustAttributionModel'

export default {
    name: 'AdjustAttributionModel',
    components: {
        ImprovementNote,
        ImprovementHeader,
        ImprovementContent,
        ImprovementView,
        ImprovementHelpLink,
        ImprovementCreatedDate,
        EntityPill,
        Spacer,
        Text,
        oTable,
        DonutChart,
        AreaChart,
        oInput,
        Percent,
        ConversionActionProfile,
        LastClickIcon,
        PositionBasedIcon,
    },
    setup() {
        return { ...useAdjustAttributionModel(), ...useMediaQuery() }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.attribution-model-container {
    @include flex;
    @include items-start;
}
.attribution-model {
    @include container;
    @include relative;
    width: 50%;
}
.attribution-model-type {
    @include absolute;
    top: -15px;
    left: 1.5rem;
    @include container;
    @include br-999;
    font-size: 0.625rem;
    text-transform: uppercase;
    height: 30px;
    @include flex;
    @include items-center;
    @include ph-16;
    @include fw-600;
}
.attribution-model-type.recommended {
    @include opteo-green;
}
.attribution-model-content {
    @include pv-44;
    @include w-100;
    @include flex-center;
    flex-direction: column;
    border-bottom: 1px solid;
    @include opteo-border;
}
.attribution-model-text {
    @include pa-24;
}
.graph-container {
    @include container;
    @include pa-32;
}

@media (max-width: 768px) {
    .attribution-model-container {
        flex-direction: column;
    }
    .attribution-model {
        width: 100%;
    }
}
</style>
