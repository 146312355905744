<template>
    <div class="day-header">
        <Text as="p" size="f-8">{{ dayBlock.formattedDate }}</Text>
        <oButton size="small" color="white" @clicked="add">Add Note</oButton>
    </div>
    <div v-if="dayBlock.notes.length">
        <div class="day-notes">
            <div v-for="note in dayBlock.notes" :key="note.id">
                <note :note="note" :domain-id="domainId" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { PropType, defineComponent, ref } from 'vue'
import { oButton, Text } from '@opteo/components-next'
import Note from '@/components/notes/Note.vue'
import { NormalisedDayBlock, useNotes } from '@/composition/notes/useNotes'

export default defineComponent({
    name: 'NoteDayBlock',
    components: { Note, Text, oButton },
    props: {
        dayBlock: {
            type: Object as PropType<NormalisedDayBlock>,
            required: true,
        },
        domainId: {
            type: Number as PropType<number>,
            required: true,
        },
    },
    setup(props) {
        const { addNote } = useNotes(ref(props.domainId))

        const add = async () => addNote(props.dayBlock.date)

        return { add }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.day-notes {
    @include pa-24;
    @include grid;
    @include justify-center;
    grid-template-columns: minmax(min-content, 600px);
    overflow: hidden;
}

.day-header {
    @include flex;
    @include justify-between;
    @include items-center;
    @include ph-24;
    @include pv-20;
}

@media (max-width: 768px) {
    .day-notes {
        display: block;
        width: 100%;
    }
}
</style>
