<template>
    <div class="megasplash">
        <OpteoLogo :width="44" :height="44" class="opteo-logo" />
        <!-- Progress Bar Section -->
        <LoginContainer :no-margin="true" :width="472" title="Importing Account(s)">
            <template v-slot:content>
                <div class="import-info-container">
                    <Text as="p" size="f-8">
                        We're doing a <Text as="span" weight="600">one-time import</Text> of your
                        Google Ads data. This usually takes a couple of minutes. While you have a
                        second, consider checking out the links below to learn more about getting
                        started with Opteo. If you have any questions, don't hesitate to message
                        support using the chat bubble at the bottom right of the screen.

                        <!-- We're doing a
                        <Text as="span" weight="600">one-time import of your accounts</Text>. This
                        usually takes around 5 minutes. While you wait, take a look at the links
                        below to learn more about getting started with Opteo. If you have any
                        questions, don’t hesitate to message support using the chat bubble. -->
                    </Text>
                </div>
                <Spacer height="1.5rem" />
                <div class="import-progress-bar-container">
                    <div class="import-progress-bar-header">
                        <Text as="h6" size="f-8">Import Progress</Text>
                        <Text as="h6" size="f-8">{{ progress }}% Complete</Text>
                    </div>
                    <div class="progress-bar-container">
                        <div
                            ref="progressBar"
                            class="progress-bar"
                            :style="progress >= 100 ? { 'background-color': '#00a861' } : {}"
                        ></div>
                    </div>
                    <div class="import-progress-bar-footer">
                        <div class="import-message">
                            <Text v-if="isDisabled" as="span" size="f-9">
                                {{ importMessage }}
                            </Text>
                            <Text v-else as="span" size="f-9">Import successful</Text>
                        </div>
                        <div class="import-time-remaining">
                            <Text as="span" size="f-9">
                                {{ timeRemaining }}
                            </Text>
                            <Text as="span" size="f-9"> remaining</Text>
                        </div>
                    </div>
                </div>
                <Spacer height="2rem" />
                <div class="import-progress-button-container">
                    <oButton size="medium" @clicked="reloadApp" :disabled="isDisabled">
                        Continue to Opteo
                    </oButton>
                </div>
            </template>
        </LoginContainer>
        <!-- Spacer -->
        <Spacer height="3rem" />
        <!-- Update Profile Section -->
        <LoginContainer :no-margin="true" :width="472" title="Update Profile">
            <template v-slot:content>
                <!-- Add Profile -->
                <ImageUploader
                    :url="userInfo?.avatar_filename || uploadedImageUrl"
                    location="user"
                    @uploaded="onImageUploaded"
                    @beforeRemove="onBeforeImageRemove"
                    @afterRemove="onAfterImageRemove"
                    :buttonOnBounds="true"
                >
                    <ProfileImage
                        :width="40"
                        :image-url="userInfo?.avatar_filename || uploadedImageUrl"
                        :avatar-url="currentUserAvatarUrl"
                    />
                    <div>
                        <p class="import-update-profile-name">{{ userInfo?.name }}</p>
                        <p class="import-update-profile-email">
                            {{ userInfo?.username }}
                        </p>
                    </div>
                </ImageUploader>
                <!-- Update Name -->
                <!-- This needs to be hooked up -->
                <oForm @submitted="sumbitProfileChange">
                    <Spacer height="2rem" />
                    <div class="import-update-profile-form">
                        <oInput
                            v-show="userInfo"
                            id="user-settings-name"
                            name="name"
                            :modelValue="userInfo?.name"
                            type="text"
                            label="Name"
                            required
                        />
                        <Spacer height="1.5rem" />
                        <oInput
                            v-show="userInfo"
                            id="user-settings-email"
                            ref="profileEmailInput"
                            name="email"
                            :modelValue="userInfo?.username"
                            type="email"
                            label="Email Address"
                            required
                        />
                    </div>
                    <Spacer height="2rem" />
                    <div class="import-update-profile-form-buttons">
                        <oButton
                            id="save-profile"
                            type="submit"
                            ref="updateProfileButton"
                            :loading="profileButtonLoading"
                            color="blue"
                            size="medium"
                        >
                            Update Profile
                        </oButton>
                    </div>
                </oForm>
            </template>
        </LoginContainer>
        <!-- Spacer -->
        <Spacer height="3rem" />
        <!-- Connect Slack Section -->
        <LoginContainer :no-margin="true" :width="472" title="Connect Slack">
            <template v-slot:content>
                <div class="import-slack-container">
                    <div class="import-slack-logo"><SlackLogo /></div>
                    <div class="import-slack-content">
                        <Text as="h6" size="f-6" :weight="600">Slack</Text>
                        <Spacer height="0.75rem" />
                        <Text as="p" size="f-8">
                            Get alerts (budget notifications, significant performance changes, and
                            performance milestones) delivered to your Slack account.
                        </Text>
                        <Spacer height="1rem" />
                        <SlackConnect :is-popup="true" :is-domain-specific-connection="false" />
                    </div>
                </div>
            </template>
        </LoginContainer>
        <!-- Spacer -->
        <Spacer height="3rem" />
        <!-- Help Centre Section -->
        <LoginContainer :no-margin="true" :width="472" title="Help Centre">
            <template v-slot:content>
                <div class="help-centre-content">
                    <Text as="p" size="f-8">
                        Visit our Help Centre to learn more about what you can do with Opteo.
                    </Text>
                    <a href="https://opteo.com/docs" target="_blank" rel="noopener">
                        <oButton color="white" size="small">Open Help Centre</oButton>
                    </a>
                </div>
                <img src="@/assets/img/help-centre.png" class="help-centre-image" />
            </template>
        </LoginContainer>
    </div>
</template>

<script lang="ts">
import { useMegasplash } from '@/composition/user/useMegasplash'
import {
    oButton,
    Text,
    Spacer,
    oForm,
    oInput,
    ProfileImage,
    OpteoLogo,
} from '@opteo/components-next'
import ImageUploader from '@/components/global/ImageUploader.vue'
import SlackLogo from '@/components/global/SlackLogo.vue'
import SlackConnect from '@/components/connectors/SlackConnect.vue'
import LoginContainer from '@/components/global/LoginContainer.vue'
import { useUserSettings } from '@/composition/user/useUserSettings'
import { useBoringAvatar } from '@/composition/user/useBoringAvatar'

export default {
    name: 'Megasplash',
    components: {
        oButton,
        Text,
        Spacer,
        oForm,
        oInput,
        ImageUploader,
        SlackLogo,
        ProfileImage,
        SlackConnect,
        OpteoLogo,
        LoginContainer,
    },
    setup() {
        return {
            ...useUserSettings(),
            ...useMegasplash(),
            ...useBoringAvatar(),
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.megasplash {
    position: absolute;
    overflow-y: auto;
    background-color: white;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding-top: 8rem;
    padding-bottom: 8rem;
}

.opteo-logo {
    @include fixed;
    top: 1.625rem;
    left: 1.625rem;
    width: 2.75rem;
    height: 2.75rem;
}

// Responsive

@media screen and (max-width: 374px) {
    .megasplash {
        padding-top: 0.5rem;
        padding-bottom: 12rem;
    }
}

@media screen and (min-width: 375px) and (max-width: $mq-767-max) {
    .megasplash {
        padding-top: 1rem;
        padding-bottom: 12rem;
    }
}

@media screen and (max-width: $mq-767-max) {
    .opteo-logo {
        display: none !important;
    }
    .import-time-remaining {
        white-space: nowrap;
    }
}

.import-info-container {
    @include container;
    @include pa-24;
}
.import-progress-bar-header {
    @include flex;
    @include items-center;
    @include justify-between;
    @include mb-16;
}
.import-progress-bar-footer {
    @include flex;
    @include items-center;
    @include justify-between;
    @include mt-12;
}
.import-message {
    max-width: 12rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.25;
}
.import-progress-bar-container {
    @include container;
    @include pa-24;
}
.import-progress-button-container,
.import-update-profile-form-buttons {
    width: 100%;
    @include flex;
    @include items-center;
    @include justify-center;
}
.progress-bar-container {
    width: 100%;
    @include bg-opteo-light-gray;
    @include br-4;
    overflow: hidden;
}
.progress-bar {
    width: 3%;
    height: 0.5rem;
    @include bg-opteo-blue;
    @include br-4;
    transition: width 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}

// Update profile

.import-update-profile-container {
    @include container;
    @include ph-24;
    @include pv-48;
    @include flex;
    @include items-center;
    @include justify-center;
}
.import-update-profile-name {
    @include ml-16;
    @include opteo-black;
    @include f-7;
    @include fw-500;
    @include lh-100;
    margin-bottom: 6px;
    white-space: nowrap;
}
.import-update-profile-email {
    @include ml-16;
    @include f-9;
    @include fw-400;
    @include lh-130;
    @include o-30;
    white-space: nowrap;
}
.import-update-profile {
    @include flex;
    @include items-center;
}
.import-update-profile-form {
    max-width: 18rem;
    margin: 0 auto;
}

// Slack container
.import-slack-container {
    @include container;
    @include pa-24;
    @include flex;
    @include items-start;
}
.import-slack-logo {
    @include container;
    @include flex;
    @include items-center;
    @include justify-center;
    width: 3.5rem;
    height: 3.5rem;
    flex-shrink: 0;
}
.import-slack-content {
    @include ml-24;
}

// Help centre

.help-centre-content {
    width: 10.5rem;
    height: 10.625rem;
    @include flex;
    @include justify-between;
    flex-direction: column;
    margin-top: 36px;
}
.help-centre-image {
    @include absolute;
    top: 2rem;
    left: 15rem;
    width: 15.625rem;
    box-shadow: $opteo-shadow;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
}
</style>
