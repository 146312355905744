<template>
    <ImprovementView
        :pushMessages="pushMessages"
        :pushActionText="pushActionText"
        :isAdjustable="true"
        :onPush="onPush"
        :title="title"
        @back-clicked="resetBid"
    >
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <div v-if="aboveMobile">
                    <EntityPill type="keyword" :content="keyword" />
                    <Spacer width="100%" height="2rem" />
                </div>
                <Text>
                    The keyword above has generated
                    <b><Number :value="Math.round(metrics.conversions.value)" /></b>
                    {{ metrics.conversions.copy }} at an average CPA of
                    <b><Money :value="metrics.cpa.value" :currency="currencyCode" /></b>. In
                    comparison, the parent campaign group has a
                    {{ campaignGroupTargetModeString }} CPA of
                    <b><Money :value="campaignGroupTargetCpa" :currency="currencyCode" /></b>.
                </Text>
                <Spacer height="1rem" />
                <Text v-if="isBidIncreasing">
                    Opteo recommends increasing the bid for this keyword by
                    <Text as="span" weight="600" color="green">
                        <Percent :value="adjustedBidChange" :include-sign="false" />
                    </Text>
                    — from <b>{{ formattedCurrentBid }}</b> to <b>{{ formattedNewBid }}</b> — this
                    should generate more conversions at a reasonable cost.
                </Text>
                <Text v-else>
                    Opteo recommends decreasing the bid for this keyword by
                    <Text as="span" weight="600" color="red">
                        <Percent :value="adjustedBidChange" :include-sign="false" />
                    </Text>
                    — from <b>{{ formattedCurrentBid }}</b> to <b>{{ formattedNewBid }}</b> — this
                    should reduce CPA and allow for smarter budget allocation.
                </Text>
                <Spacer height="1rem" />
                <Text>
                    Click
                    <Text as="span" color="green" weight="600">{{ pushActionText }}</Text> to apply
                    the recommended adjustment to this keyword. Click <b>Adjust</b> to
                    {{ isBidIncreasing ? 'increase' : 'decrease' }} your bid by a different amount
                    (or to {{ isBidIncreasing ? 'decrease' : 'increase' }} your bid).
                </Text>
            </ImprovementHeader>
        </ImprovementContent>

        <ImprovementContent>
            <ScatterPointChart
                :items="scatterPointChartItems"
                :graphKeys="[
                    `Above CPA ${campaignGroupTargetModeString}`,
                    '',
                    `Below CPA ${campaignGroupTargetModeString}`,
                ]"
                :cutOff="campaignGroupTargetCpa"
                :axis="{
                    x: { label: 'Conversions' },
                    y: { label: 'Cost', dataType: 'money', currency: currencyCode },
                }"
                :metric="{
                    label: 'CPA',
                    dataType: 'money',
                    currency: currencyCode,
                    inverted: true,
                }"
            />
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementNote>
                <b>Note:</b> Hover over any point to explore keyword statistics in more detail.
                Keywords in green zones are below your CPA
                {{ campaignGroupTargetModeString.toLowerCase() }}, whilst those in red zones are
                above {{ campaignGroupTargetModeString.toLowerCase() }}.
            </ImprovementNote>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementStatistics
                title="Recommended Bid Adjustment"
                :items="[
                    {
                        key: 'bidAdjustment',
                        deltaMode: true,
                        beforeValue: formattedCurrentBid,
                        beforeTitle: 'Current Bid',
                        afterValue: formattedNewBid,
                        afterTitle: 'Adjusted Bid',
                    },
                ]"
            >
                <template #bidAdjustment>
                    <Text as="p" size="f-8">
                        Opteo recommends {{ isBidIncreasing ? 'increasing' : 'decreasing' }} the bid
                        for this keyword by
                        <Text :color="bidChange > 0 ? 'green' : 'red'" as="span" weight="600">
                            <Percent :value="adjustedBidChange" :include-sign="false" /> </Text
                        >.
                    </Text>
                </template>
            </ImprovementStatistics>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementStatistics :items="improvementStatistics">
                <template #averageCPA>
                    <Text as="p" size="f-8">
                        The parent campaign group has a {{ campaignGroupTargetModeString }} CPA of
                        <Ent>
                            <Money :value="campaignGroupTargetCpa" :currency="currencyCode" /> </Ent
                        >.
                    </Text>
                </template>
                <template #keywordCpa>
                    <Text as="p" size="f-8">
                        Over the last <b>{{ window }}</b> days, this keyword had an average CPA of
                        <Ent><Money :value="metrics.cpa.value" :currency="currencyCode" /></Ent>.
                    </Text>
                </template>
                <template #searchImprShare>
                    <Text as="p" size="f-8">
                        Your ads were shown in Microsoft's search results
                        <Ent><Percent :value="metrics.impressionSharePercent.value" /></Ent> of the
                        time they were eligible.
                    </Text>
                </template>
                <template #absoluteTopImprShare>
                    <Text as="p" size="f-8">
                        Over the last <b>{{ window }}</b> days, ads triggered by this keyword
                        appeared in the top-most position of Microsoft's search engine results page
                        <Ent>
                            <Percent :value="metrics.absoluteTopImpressionRatePercent.value" />
                        </Ent>
                        of the time.
                    </Text>
                </template>
                <template #firstPageBid>
                    <Text as="p" size="f-8">
                        Microsoft recommends bidding a minimum of
                        <Ent>
                            <Money :value="metrics.firstPageBid.value" :currency="currencyCode" />
                        </Ent>
                        to appear on the first page.
                    </Text>
                </template>
            </ImprovementStatistics>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="window" />
        </ImprovementContent>

        <template #adjust-step-1>
            <ImprovementContent>
                <ImprovementHeader :title="title">
                    <div v-if="aboveMobile">
                        <EntityPill type="keyword" :content="keyword" />
                        <Spacer width="100%" height="2rem" />
                    </div>
                    <Text>
                        The keyword above has generated
                        <b><Number :value="Math.round(metrics.conversions.value)" /></b>
                        {{ metrics.conversions.copy }} at an average CPA of
                        <b><Money :value="metrics.cpa.value" :currency="currencyCode" /></b>. In
                        comparison, the parent campaign group has a
                        {{ campaignGroupTargetModeString }} CPA of
                        <b><Money :value="campaignGroupTargetCpa" :currency="currencyCode" /></b>.
                    </Text>
                    <Spacer height="1rem" />
                    <Text v-if="isBidIncreasing">
                        Opteo recommends increasing the bid for this keyword by
                        <Text as="span" weight="600" color="green">
                            <Percent :value="Math.abs(bidChange)" :include-sign="false" />
                        </Text>
                        — from <b>{{ formattedCurrentBid }}</b> to <b>{{ formattedNewBid }}</b> —
                        this should generate more conversions at a reasonable cost.
                    </Text>
                    <Text v-else>
                        Opteo recommends decreasing the bid for this keyword by
                        <Text as="span" weight="600" color="red">
                            <Percent :value="Math.abs(bidChange)" :include-sign="false" />
                        </Text>
                        — from <b>{{ formattedCurrentBid }}</b> to <b>{{ formattedNewBid }}</b> —
                        this should reduce CPA and allow for smarter budget allocation.
                    </Text>
                </ImprovementHeader>

                <InlineInputContainer :single-side="true">
                    <template #left-side>
                        <Text as="span" size="f-8" class="adjustment-text">Set keyword bid to</Text>
                    </template>
                    <template #input>
                        <oForm ref="bidInputForm">
                            <oInput
                                type="number"
                                name="bid"
                                v-model.number="adjustedBid"
                                :prefix="currencySymbol"
                                class="adjustment-input"
                                autofocus
                                required
                                min="0.01"
                                @updated="handleBidUpdate"
                            />
                        </oForm>
                    </template>
                </InlineInputContainer>
            </ImprovementContent>
        </template>
    </ImprovementView>
</template>

<script lang="ts" setup>
import {
    ImprovementHeader,
    ImprovementContent,
    Percent,
    ImprovementNote,
    ImprovementStatistics,
    ImprovementCreatedDate,
    Number,
    Spacer,
    Text,
    EntityPill,
    ScatterPointChart,
    oInput,
    oForm,
} from '@opteo/components-next'

import ImprovementView from '@/components/improvement/ImprovementView.vue'
import InlineInputContainer from '@/components/improvement/shared-components/InlineInputContainer.vue'
import Money from '@/components/global/oMoney.vue'
import Ent from '@/components/util/Ent.vue'
import useMediaQuery from '@/composition/global/useMediaQuery'

import { useAdjustKeywordBid } from '@/composition/improvement/types/useAdjustKeywordBid.ms'

const { aboveMobile } = useMediaQuery()
const {
    // REQUIREMENTS
    title,
    pushActionText,
    pushMessages,
    lastUpdated,
    onPush,
    window,
    // DATA
    currencySymbol,
    currencyCode,
    keyword,
    metrics,
    campaignGroupTargetCpa,
    // KEYWORD BID
    isBidIncreasing,
    bidChange,
    adjustedBid,
    adjustedBidChange,
    formattedCurrentBid,
    formattedNewBid,
    handleBidUpdate,
    resetBid,
    // OTHERS
    campaignGroupTargetModeString,
    improvementStatistics,
    scatterPointChartItems,
} = useAdjustKeywordBid()
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

// Adjustment

.adjustment-input {
    width: 100%;
}
.adjustment-text {
    line-height: 2.75rem;
}
.graph-container {
    @include container;
    @include pa-32;
}

.keyword-bid-simulation-header {
    @include mb-36;
}

// Responsive

@media screen and (min-width: $mq-767-max) {
    .adjustment-input {
        width: 9.75rem;
    }
}

@media screen and (max-width: $mq-480-max) {
    .graph-container {
        box-shadow: unset;
        padding: unset;
    }
}
</style>
