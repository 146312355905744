<template>
    <ImprovementView
        :push-messages="pushMessages"
        :push-action-text="pushActionText"
        :on-push="onPush"
        :steps="adjustSteps"
    >
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <EntityTransform
                    :entityBefore="entityPill.content"
                    entityBeforeType="word"
                    :entityAfter="entityPill.content"
                    entityAfterType="negative-keyword"
                />
                <Spacer height="2rem" />
                <Text as="p">
                    This word is a commonly used negative across Google Ads accounts in your
                    industry. Search terms containing this word have yet to accumulate enough data
                    to make a decision with statistical confidence (<b
                        ><Number :value="impressions"
                    /></b>
                    {{ copy.impressions }}, <b><Number :value="clicks" /></b> {{ copy.clicks }},
                    <b><Number :value="conversions" /></b> {{ copy.conversions }}), but in most
                    cases, adding this word as a negative should help to reduce wasted spend.
                </Text>
                <Spacer height="1rem" />
                <Text as="p">
                    Click
                    <Text as="span" weight="600" color="blue">{{ adjustSteps[0] }}</Text> to add the
                    listed word as a
                    <Text as="span" color="red" weight="600">negative keyword</Text>. On the next
                    page, you can specify which shared negative lists or campaigns you wish to add
                    your negative to. Please note, you can select more than one destination for your
                    negative.
                </Text>
            </ImprovementHeader>
            <ImprovementHelpLink path="/improvements/keywords/add-negative-keyword">
                Click here to learn more about industry negative keywords in Opteo.
            </ImprovementHelpLink>
        </ImprovementContent>

        <ImprovementContent>
            <RelatedSearchTerms :items="searchTerms" :keyword="keyword"> </RelatedSearchTerms>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="365" />
        </ImprovementContent>

        <template v-slot:adjust-step-1>
            <ImprovementContent>
                <ImprovementHeader title="Create Negative">
                    <Text as="p">
                        Click <b>Modify Keyword</b> to make any necessary adjustments.
                    </Text>
                    <Spacer height="1.875rem" />
                    <div class="create-keyword-form">
                        <Spacer height="2rem" />
                        <div class="conflict-warning-container">
                            <div class="conflict-warning">
                                <CheckIcon v-if="previewKeyword" class="icon" />
                                <WarnIcon v-else class="icon" />
                                <Text v-if="previewKeyword" as="p" size="f-8">
                                    This negative will not create keyword conflicts or a loss in
                                    conversions.
                                </Text>
                                <Text v-else as="p" size="f-8">
                                    Please select at least one word to add as a negative.
                                </Text>
                            </div>
                        </div>

                        <div v-if="previewKeyword" class="create-keyword-form-preview">
                            <EntityPill :content="previewKeyword" type="negative-keyword" />
                        </div>
                        <div v-else class="create-keyword-form-preview">
                            <Text
                                as="span"
                                size="f-6"
                                weight="600"
                                style="line-height: 2.75rem; opacity: 0.16"
                            >
                                Please select at least one word
                            </Text>
                        </div>
                        <div class="modify-keyword-button">
                            <oButton
                                color="white"
                                size="small"
                                @clicked="showKeywordSelector = !showKeywordSelector"
                            >
                                {{ showKeywordSelector ? 'Hide Section' : 'Modify Keyword' }}
                            </oButton>
                        </div>
                        <Spacer height="2rem" />
                        <div v-if="showKeywordSelector" class="modify-keyword-section">
                            <Label>Select Words</Label>
                            <div
                                v-for="selectableWord in searchTermWords"
                                v-bind:key="selectableWord.word"
                                class="search-term-word-checkbox"
                            >
                                <oInput
                                    :label="selectableWord.word"
                                    v-model="selectableWord.selected"
                                    type="checkbox"
                                    :button-mode="true"
                                    style="display: inline-flex"
                                />
                            </div>
                            <Spacer height="0.75rem" />
                            <Label>Select Match Type</Label>
                            <div
                                v-for="matchType in keywordMatchTypes"
                                v-bind:key="matchType.matchType"
                                class="match-type-special-toggle"
                            >
                                <div
                                    class="match-type-special-toggle-content"
                                    @click="toggleMatchType(matchType.matchType)"
                                    tabindex="0"
                                >
                                    <div
                                        class="match-type-special-toggle-radio"
                                        :class="[matchType.selected ? 'active' : '']"
                                    />
                                    <Spacer width="0.75rem" height="0.25rem" />
                                    <Text as="span" size="f-8">
                                        {{ KeywordMatchType[matchType.matchType].toLowerCase() }}
                                    </Text>
                                </div>
                            </div>
                        </div>
                    </div>
                </ImprovementHeader>
            </ImprovementContent>
            <ImprovementContent>
                <Text as="h3" size="f-4" weight="600">Negative Destination</Text>
                <Spacer height="1.625rem" />
                <Text as="p">
                    Please note, you can select more than one destination for your negative keyword.
                </Text>
                <Spacer height="1.875rem" />
                <KeywordDestinationSelector
                    :destinations="destination"
                    v-model:entities="negativeListSelection"
                    :error="onPushError"
                >
                    <template #custom-entity-negative-list="{ selector }">
                        <KeywordDestinationEntity
                            v-if="isEntityVisible(accountLevelEntity, selector.searchQuery)"
                            v-model:entity="accountLevelEntity"
                            color="red"
                        />
                    </template>
                    <template #empty-state-negative-list>
                        <KeywordDestinationEmptyState>
                            <template #text>
                                Opteo could not find any shared negative lists in your account. To
                                create one, click the button below. Otherwise, you can add this
                                negative to a campaign (or ad group) by switching to the
                                <b>Campaigns</b> tab.
                            </template>
                            <template #button>
                                <oButton color="white" @clicked="negativeListModalOpen = true">
                                    Create Negative List
                                </oButton>
                            </template>
                        </KeywordDestinationEmptyState>
                    </template>
                    <template #bottom-row-negative-list="{ selector }">
                        <oButton
                            v-if="!selector.empty"
                            color="white"
                            @clicked="negativeListModalOpen = true"
                        >
                            Create Negative List
                        </oButton>
                    </template>
                </KeywordDestinationSelector>
            </ImprovementContent>
        </template>
    </ImprovementView>

    <NewNegativeListModal
        v-model="negativeListModalOpen"
        :campaigns="campaigns"
        :existing-shared-sets="existingSharedSetNames"
        @update:newListData="(value: any) => mutateNegativeList(value)"
    />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import {
    ImprovementHeader,
    ImprovementContent,
    ImprovementCreatedDate,
    oButton,
    oInput,
    Number,
    Spacer,
    Text,
    EntityPill,
    RelatedSearchTerms,
    EntityTransform,
    CheckIcon,
    WarnIcon,
    Label,
} from '@opteo/components-next'

import KeywordDestinationSelector from '@/components/improvement/shared-components/keyword-destination/KeywordDestinationSelector.vue'
import KeywordDestinationEntity from '@/components/improvement/shared-components/keyword-destination/KeywordDestinationEntity.vue'
import KeywordDestinationEmptyState from '@/components/improvement/shared-components/keyword-destination/KeywordDestinationEmptyState.vue'
import { isEntityVisible } from '@/components/improvement/shared-components/keyword-destination/utils'
import NewNegativeListModal from '@/components/improvement/shared-components/NewNegativeListModal.vue'
import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import Money from '@/components/global/oMoney.vue'
import Ent from '@/components/util/Ent.vue'

import { useAddRobotNegative } from '@/composition/improvement/types/useAddRobotNegative'

export default defineComponent({
    name: 'AddRobotNegative',
    components: {
        ImprovementHeader,
        ImprovementContent,
        ImprovementCreatedDate,
        ImprovementView,
        ImprovementHelpLink,
        oButton,
        oInput,
        Ent,
        Money,
        Number,
        Spacer,
        Text,
        EntityPill,
        RelatedSearchTerms,
        EntityTransform,
        CheckIcon,
        WarnIcon,
        Label,
        KeywordDestinationSelector,
        KeywordDestinationEntity,
        KeywordDestinationEmptyState,
        NewNegativeListModal,
    },
    setup() {
        return { ...useAddRobotNegative(), ...{ isEntityVisible } }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

// Create Keyword Form
.create-keyword-form {
    @include container;
    @include relative;
    @include flex-center;
    flex-direction: column;
    @include br-24;
}
.create-keyword-form-preview {
    @include flex;
    @include items-center;
    @include justify-center;
    @include mv-48;
}
.modify-keyword-button {
    @include flex-center;
}
.modify-keyword-section {
    border-top: 1px solid $opteo-light-gray;
    width: 100%;
    @include ph-32;
    @include pt-32;
    @include pb-20;
}
.search-term-word-checkbox {
    display: inline-block;
    @include mr-12;
    @include mb-12;
}

// Conflict Warning
.conflict-warning-container {
    @include ph-32;
    @include w-100;
}
.conflict-warning {
    @include container;
    width: 100%;
    @include flex;
    @include ph-24;
    @include pv-20;
    @include items-center;
    @include br-20;
}
.conflict-warning .icon {
    @include mr-16;
    flex-shrink: 0;
}

// Special Toggle
.match-type-special-toggle {
    box-shadow: $opteo-shadow;
    @include inline-block;
    @include mr-12;
    @include mb-12;
    cursor: pointer;
    @include br-12;
}
.match-type-special-toggle-content {
    @include flex;
    @include items-center;
    @include ph-20;
    @include pv-16;
    text-transform: capitalize;
    @include br-12;
    transition: box-shadow cubic-bezier(0.19, 1, 0.22, 1) 0.25s;
}
.match-type-special-toggle-content:focus {
    box-shadow: $opteo-shadow-focus-ring;
}
.match-type-special-toggle-radio {
    width: 18px;
    height: 18px;
    box-shadow: $opteo-shadow;
    @include bg-opteo-white;
    @include br-999;
    @include relative;
}
.match-type-special-toggle-radio.active {
    @include bg-opteo-blue;
}
.match-type-special-toggle-radio.active::after {
    content: '';
    width: 0.375rem;
    height: 0.375rem;
    @include absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    @include bg-opteo-white;
    @include br-999;
}
</style>
