<template>
    <ImprovementView
        :push-messages="pushMessages"
        :push-action-text="pushActionText"
        :on-push="onPush"
        :steps="adjustSteps"
        @adjust-reset="onReset"
    >
        <ImprovementContent>
            <ImprovementHeader :title="header">
                <div v-if="aboveMobile">
                    <EntityPill
                        :type="Improvement.LocationEntity.CampaignGroup"
                        :content="campaignGroupName"
                    />
                    <Spacer height="2rem" />
                </div>
                <Text as="p">
                    In the campaign group listed above, your
                    <span v-if="isShopping">product</span> ads are being triggered by search terms
                    containing the n-gram below. This n-gram has spent a significant amount, but
                    converted few searchers by comparison. Consider adding it as a negative.
                </Text>
                <Spacer height="2rem" />
                <EntityTransform
                    :entityBefore="ngramText"
                    entityBeforeType="n-gram"
                    :entityAfter="'“' + ngramText + '”'"
                    entityAfterType="negative-keyword"
                />
                <Spacer height="2rem" />
                <Text as="p">
                    Click
                    <Text as="span" weight="600" color="blue">{{ adjustSteps[0] }}</Text> to add the
                    listed n-gram as a
                    <Text as="span" color="red" weight="600">negative keyword</Text>. On the next
                    page, you can specify which shared negative lists or campaigns you wish to add
                    your negative to. Please note, you can select more than one destination for your
                    negative.
                </Text>
            </ImprovementHeader>
            <ImprovementHelpLink path="/en/articles/6124075-add-n-gram-negative">
                Click here to learn more about adding negative keywords in Opteo.
            </ImprovementHelpLink>
        </ImprovementContent>

        <ImprovementContent>
            <SegmentsChart
                v-if="performanceMode === PerformanceMode.CPA"
                :items="scatterPlotItems"
                :graphKeys="
                    hasTarget
                        ? ['Below CPA Target', 'Above CPA Target', 'Needs Attention']
                        : ['Below CPA Average', 'Above CPA Average', 'Needs Attention']
                "
                :target="campaignGroupTarget"
                :axis="{
                    x: {
                        dataType: 'percentage',
                        label: hasTarget
                            ? 'Percentage Difference from CPA Target'
                            : 'Percentage Difference from CPA Average',
                    },
                    y: { currency, dataType: 'money', label: 'Cost' },
                }"
                :metric="{ dataType: 'percentage', inverted: true, label: 'CPA' }"
            />
            <SegmentsChart
                v-if="performanceMode === PerformanceMode.ROAS"
                :items="scatterPlotItems"
                :graphKeys="
                    hasTarget
                        ? ['Needs Attention', 'Below ROAS Target', 'Above ROAS Target']
                        : ['Needs Attention', 'Below ROAS Average', 'Above ROAS Average']
                "
                :target="campaignGroupTarget"
                :axis="{
                    x: {
                        dataType: 'percentage',
                        label: hasTarget
                            ? 'Percentage Difference from ROAS Target'
                            : 'Percentage Difference from ROAS Average',
                    },
                    y: { currency, dataType: 'money', label: 'Cost' },
                }"
                :metric="{ dataType: 'percentage', inverted: false, label: 'ROAS' }"
            />

            <Spacer height="2rem" />
            <!-- ROAS Note - cost and conversion value-->
            <ImprovementNote v-if="performanceMode === PerformanceMode.ROAS">
                <b>Note:</b> If you are actively targeting searches related to this n-gram, and
                <b><Money :value="ngramCost" :currency="currency" /></b> spend to generate
                <b><Money :value="ngramConversionsValue" /></b> conversion value is acceptable, feel
                free to <b>Dismiss</b> this improvement.
            </ImprovementNote>

            <!-- CPA Note - cost and conversions-->
            <ImprovementNote v-else>
                <b>Note:</b> If you are actively targeting searches related to this n-gram, and
                <b><Money :value="ngramCost" :currency="currency" /></b> spend to generate
                <b><Number :value="ngramConversions" /></b> conversion<span
                    v-if="ngramConversions !== 1"
                    >s</span
                >
                is acceptable, feel free to <b>Dismiss</b> this improvement.
            </ImprovementNote>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementStatistics :items="stats">
                <!-- ngram Cost -->
                <template v-slot:cost>
                    You spent <b><Money :value="ngramCost" /></b> on this n-gram over the last
                    <b>{{ dataPeriod }}</b
                    >.
                </template>

                <!-- ngram Conversions (CPA)-->
                <template v-slot:conversions>
                    Over the last <b>{{ dataPeriod }}</b
                    >, this n-gram generated
                    <b><Number :value="ngramConversions" /></b> conversion<span
                        v-if="ngramConversions !== 1"
                        >s</span
                    >.
                </template>

                <!-- ngram conversionsValue (ROAS) -->
                <template v-slot:conversionsValue>
                    Over the last <b>{{ dataPeriod }}</b
                    >, this n-gram generated
                    <b><Money :value="ngramConversionsValue" /></b> conversion value.
                </template>

                <!-- ngram CPA (CPA) -->
                <template v-slot:wordCpa>
                    Over the last <b>{{ dataPeriod }}</b
                    >, this n-gram has an average CPA of <b><Money :value="ngramCpa" /></b>.
                </template>

                <!-- ngram ROAS (ROAS) -->
                <template v-slot:wordRoas>
                    Over the last <b>{{ dataPeriod }}</b
                    >, this n-gram has an average ROAS of <b><Roas :value="ngramRoas" /></b>.
                </template>

                <!-- campaign group target/avg CPA -->
                <template v-slot:targetCpa v-if="hasTarget">
                    The current target CPA for this campaign group is
                    <b><Money :value="campaignGroupTarget" /></b>.
                </template>
                <template v-slot:targetCpa v-else>
                    Over the last <b>{{ dataPeriod }}</b
                    >, the parent campaign group has an average CPA of
                    <b><Money :value="campaignGroupTarget" /></b>.
                </template>

                <!-- campaign group target/avg ROAS -->
                <template v-slot:targetRoas v-if="hasTarget">
                    The current target ROAS for this campaign group is
                    <b><Roas :value="campaignGroupTarget" /></b>.
                </template>
                <template v-slot:targetRoas v-else>
                    Over the last <b>{{ dataPeriod }}</b
                    >, the parent campaign group has an average ROAS of
                    <b><Roas :value="campaignGroupTarget" /></b>.
                </template>
            </ImprovementStatistics>
        </ImprovementContent>

        <ImprovementContent>
            <RelatedSearchTerms :items="contributingSearchTerms" :keyword="ngramText">
            </RelatedSearchTerms>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="window" />
        </ImprovementContent>

        <template v-slot:adjust-step-1>
            <ImprovementContent>
                <ImprovementHeader title="Create Negative">
                    <Text as="p">
                        Click <b>Modify Keyword</b> to make any necessary adjustments.
                    </Text>
                    <Spacer height="1.875rem" />
                    <div class="create-keyword-form">
                        <Spacer height="2rem" />
                        <!-- Warning determined by selected words in Modify Keyword element above -->
                        <div class="conflict-warning-container">
                            <div class="conflict-warning">
                                <!-- ICONS -->
                                <WarnIcon v-if="!newNgram" class="icon" />
                                <CheckIcon
                                    v-else-if="
                                        !newNgramConflictStatus.hasConflicts &&
                                        newNgramConflictStatus.conversionLoss === 0
                                    "
                                    class="icon"
                                />
                                <SkipIcon v-else class="icon" />
                                <!-- TEXT  -->
                                <Text
                                    v-if="
                                        !newNgramConflictStatus.hasConflicts &&
                                        newNgramConflictStatus.conversionLoss === 0 &&
                                        newNgram
                                    "
                                    as="p"
                                    size="f-8"
                                >
                                    This negative will not create keyword conflicts or a loss in
                                    conversions.
                                </Text>
                                <Text
                                    v-if="
                                        newNgramConflictStatus.hasConflicts &&
                                        newNgramConflictStatus.conversionLoss === 0
                                    "
                                    as="p"
                                    size="f-8"
                                >
                                    There are active keywords in this account that include this
                                    n-gram.
                                </Text>
                                <Text
                                    v-if="
                                        !newNgramConflictStatus.hasConflicts &&
                                        newNgramConflictStatus.conversionLoss > 0
                                    "
                                    as="p"
                                    size="f-8"
                                >
                                    Search terms containing this n-gram have generated
                                    <b><Number :value="newNgramConflictStatus.conversionLoss" /></b>
                                    conversions over the last <b>{{ dataPeriod }}</b
                                    >. This negative will not create any keyword conflicts.
                                </Text>
                                <Text
                                    v-if="
                                        newNgramConflictStatus.hasConflicts &&
                                        newNgramConflictStatus.conversionLoss > 0
                                    "
                                    as="p"
                                    size="f-8"
                                >
                                    There are active keywords in this account containing this
                                    n-gram. These keywords have generated
                                    <b><Number :value="newNgramConflictStatus.conversionLoss" /></b>
                                    conversions over the last <b>{{ dataPeriod }}</b
                                    >.
                                </Text>
                                <Text v-if="!newNgram" as="p" size="f-8">
                                    Please select at least one word to add as a negative.
                                </Text>
                            </div>
                        </div>
                        <div>
                            <div v-if="previewKeyword" class="create-keyword-form-preview">
                                <EntityPill :content="previewKeyword" type="negative-keyword" />
                            </div>
                            <div v-else class="create-keyword-form-preview">
                                <Text
                                    as="span"
                                    size="f-6"
                                    weight="600"
                                    style="line-height: 2.75rem; opacity: 0.16"
                                >
                                    Please select at least one word
                                </Text>
                            </div>

                            <div class="modify-keyword-button">
                                <oButton
                                    color="white"
                                    size="small"
                                    @clicked="showKeywordSelector = !showKeywordSelector"
                                >
                                    {{ showKeywordSelector ? 'Hide Section' : 'Modify Keyword' }}
                                </oButton>
                            </div>
                        </div>
                        <Spacer height="2rem" />
                        <div v-if="showKeywordSelector" class="modify-keyword-section">
                            <Label>Select Words</Label>
                            <div
                                v-for="selectableWord in selectedWords"
                                v-bind:key="selectableWord.word"
                                class="search-term-word-checkbox"
                            >
                                <oInput
                                    :label="selectableWord.word"
                                    v-model="selectableWord.selected"
                                    type="checkbox"
                                    :button-mode="true"
                                    style="display: inline-flex"
                                />
                            </div>

                            <Spacer height="0.75rem" />
                            <Label>Select Match Type</Label>
                            <div
                                v-for="matchType in keywordMatchTypes"
                                v-bind:key="matchType.matchType"
                                class="match-type-special-toggle"
                            >
                                <div
                                    class="match-type-special-toggle-content"
                                    @click="toggleMatchType(matchType.matchType)"
                                    tabindex="0"
                                >
                                    <div
                                        class="match-type-special-toggle-radio"
                                        :class="[matchType.selected ? 'active' : '']"
                                    />
                                    <Spacer width="0.75rem" height="0.25rem" />
                                    <Text as="span" size="f-8">
                                        {{ KeywordMatchType[matchType.matchType].toLowerCase() }}
                                    </Text>
                                </div>
                            </div>
                        </div>
                    </div>
                </ImprovementHeader>
            </ImprovementContent>

            <ImprovementContent>
                <Text as="h3" size="f-4" weight="600">Negative Destination</Text>
                <Spacer height="1.625rem" />
                <Text as="p">
                    Please note, you can select more than one destination for your negative keyword.
                </Text>
                <Spacer height="1.875rem" />

                <KeywordDestinationSelector
                    :destinations="destinations"
                    v-model:entities="entities"
                    :error="onPushError"
                >
                    <template #custom-entity-negative-list="{ selector }">
                        <KeywordDestinationEntity
                            v-if="isEntityVisible(accountLevelEntity, selector.searchQuery)"
                            v-model:entity="accountLevelEntity"
                            color="red"
                        />
                    </template>
                    <template #empty-state-negative-list>
                        <KeywordDestinationEmptyState>
                            <template #text>
                                Opteo could not find any shared negative lists in your account. To
                                create one, click the button below. Otherwise, you can add this
                                negative to a campaign (or ad group) by switching to the
                                <b>Campaigns</b> tab.
                            </template>
                            <template #button>
                                <oButton color="white" @clicked="negativeListModalOpen = true">
                                    Create Negative List
                                </oButton>
                            </template>
                        </KeywordDestinationEmptyState>
                    </template>
                    <template #bottom-row-negative-list="{ selector }">
                        <oButton
                            v-if="!selector.empty"
                            color="white"
                            @clicked="negativeListModalOpen = true"
                        >
                            Create Negative List
                        </oButton>
                    </template>
                </KeywordDestinationSelector>
            </ImprovementContent>
        </template>
    </ImprovementView>

    <NewNegativeListModal
        v-model="negativeListModalOpen"
        :campaigns="campaigns"
        :existing-shared-sets="existingSharedSetNames"
        @update:new-list-data="
            newNegativeList =>
                addNegativeList({
                    id: newNegativeList.shared_set_resource_name,
                    label: newNegativeList.shared_set_name,
                    type: Improvement.LocationEntity.NegativeList,
                    checked: false,
                })
        "
    />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import {
    ImprovementHeader,
    ImprovementContent,
    ImprovementNote,
    SegmentsChart,
    ImprovementStatistics,
    ImprovementCreatedDate,
    EntityPill,
    EntityPillList,
    EntityTransform,
    oInput,
    Number,
    Spacer,
    Text,
    RelatedSearchTerms,
    CircleNegativeKeywordIcon,
    oButton,
    Label,
    CheckIcon,
    SkipIcon,
    WarnIcon,
    Spinner,
    Roas,
} from '@opteo/components-next'
import { Improvement } from '@opteo/types'

import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import Money from '@/components/global/oMoney.vue'
import KeywordDestinationSelector from '@/components/improvement/shared-components/keyword-destination/KeywordDestinationSelector.vue'
import KeywordDestinationEntity from '@/components/improvement/shared-components/keyword-destination/KeywordDestinationEntity.vue'
import KeywordDestinationEmptyState from '@/components/improvement/shared-components/keyword-destination/KeywordDestinationEmptyState.vue'
import { isEntityVisible } from '@/components/improvement/shared-components/keyword-destination/utils'
import NewNegativeListModal from '@/components/improvement/shared-components/NewNegativeListModal.vue'
import { useAddNegativeNgram } from '@/composition/improvement/types/ngram/useAddNegativeNgram'
import useMediaQuery from '@/composition/global/useMediaQuery'

export default defineComponent({
    components: {
        ImprovementHeader,
        ImprovementContent,
        ImprovementNote,
        SegmentsChart,
        ImprovementStatistics,
        ImprovementCreatedDate,
        ImprovementView,
        ImprovementHelpLink,
        EntityPill,
        EntityPillList,
        EntityTransform,
        oInput,
        Money,
        Number,
        Spacer,
        Text,
        RelatedSearchTerms,
        CircleNegativeKeywordIcon,
        oButton,
        Label,
        CheckIcon,
        SkipIcon,
        WarnIcon,
        Spinner,
        Roas,
        KeywordDestinationSelector,
        KeywordDestinationEntity,
        KeywordDestinationEmptyState,
        NewNegativeListModal,
    },

    setup() {
        return {
            Improvement,
            ...useAddNegativeNgram(),
            ...useMediaQuery(),
            isEntityVisible,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.entity-pill-list-loader {
    @include container;
    @include w-100;
    height: 5.25rem;
    @include flex-center;
}

// Create Keyword Form
.create-keyword-form {
    @include container;
    @include relative;
    @include flex-center;
    flex-direction: column;
    @include br-24;
}
.create-keyword-form-preview {
    @include flex;
    @include items-center;
    @include justify-center;
    @include mv-48;
}
.modify-keyword-button {
    @include flex-center;
}
.modify-keyword-section {
    border-top: 1px solid $opteo-light-gray;
    width: 100%;
    @include ph-32;
    @include pt-32;
    @include pb-20;
}
.search-term-word-checkbox {
    display: inline-block;
    @include mr-12;
    @include mb-12;
}

:deep(.o-input__checkbox) {
    @include w-100;
}

.capitalize {
    text-transform: capitalize;
}

.conflict-warning-container {
    @include ph-32;
    @include w-100;
}

.conflict-warning {
    @include container;
    width: 100%;
    @include flex;
    @include ph-24;
    @include pv-20;
    @include items-center;
    @include br-20;
}

.conflict-warning .icon {
    @include mr-16;
    flex-shrink: 0;
}

.select-all-checkbox {
    :deep(.o-input__text) {
        font-weight: 500;
    }
}

.campaigns-loading {
    @include flex-center;
    @include pa-32;
}

// Special Toggle
.match-type-special-toggle {
    box-shadow: $opteo-shadow;
    @include inline-block;
    @include mr-12;
    @include mb-12;
    cursor: pointer;
    @include br-12;
}
.match-type-special-toggle-content {
    @include flex;
    @include items-center;
    @include ph-20;
    @include pv-16;
    text-transform: capitalize;
    @include br-12;
    transition: box-shadow cubic-bezier(0.19, 1, 0.22, 1) 0.25s;
}
.match-type-special-toggle-content:focus {
    box-shadow: $opteo-shadow-focus-ring;
}
.match-type-special-toggle-radio {
    width: 18px;
    height: 18px;
    box-shadow: $opteo-shadow;
    @include bg-opteo-white;
    @include br-999;
    @include relative;
}
.match-type-special-toggle-radio.active {
    @include bg-opteo-blue;
}
.match-type-special-toggle-radio.active::after {
    content: '';
    width: 0.375rem;
    height: 0.375rem;
    @include absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    @include bg-opteo-white;
    @include br-999;
}

@media screen and (max-width: $mq-767-max) {
    .conflict-warning-container {
        @include pb-24;
        @include ph-24;
    }
    .modify-keyword-section {
        width: calc(100% - 3rem);
        @include container;
        @include ph-24;
        @include pt-24;
        @include pb-16;
        @include mh-24;
        @include mb-24;
    }
}
</style>
