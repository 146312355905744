<template>
    <ImprovementView
        :push-messages="pushMessages"
        :push-action-text="pushActionText"
        :is-adjustable="true"
        :on-push="onPush"
    >
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <div v-if="aboveMobile">
                    <EntityPill type="placement" :content="body.placement" />
                    <Spacer height="2rem" />
                </div>
                <Text
                    >Consider excluding your ads from this website. Ads shown here perform worse
                    than average (using CPA as the primary performance indicator). Adding this
                    website to a shared exclusion list should help lower your overall CPA. Click
                    <Text as="span" color="green" weight="600">Exclude Placement</Text> to add the
                    site to a shared exclusion list. Click
                    <Text as="span" weight="600">Adjust</Text> to choose another exclusion list.
                </Text>
                <Spacer height="1rem" />
                <Text>
                    Your new placement exclusion will affect the following campaign<span
                        v-if="campaignPillList.length > 1"
                        >s</span
                    >:
                </Text>
                <Spacer height="2rem" />
                <div v-if="campaignPillList.length === 1">
                    <EntityPill
                        :type="campaignPillList[0].type"
                        :content="campaignPillList[0].content"
                    />
                </div>
                <EntityPillList v-else :items="campaignPillList" />
            </ImprovementHeader>
            <ImprovementHelpLink path="/en/articles/902702-exclude-site-placement">
                Click here to learn more about the benefits of excluding site placements.
            </ImprovementHelpLink>
        </ImprovementContent>

        <ImprovementContent>
            <SerpPreview :image-url="placementPreviewUrl" title="Placement Screenshot" />
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementStatistics :items="stats">
                <template v-slot:cost>
                    <b><Money :value="body.cost" /></b> was spent on the placement
                    <b>{{ body.placement }}</b> over the last 180 days.
                </template>

                <template v-slot:conversions>
                    Ads on <b>{{ body.placement }}</b> generated
                    <b>{{ body.conversions }}</b> conversions and <b>{{ body.clicks }}</b> clicks
                    over the last 180 days.
                </template>

                <template v-slot:placementCpa>
                    Over the last 180 days, this placement has an average CPA of
                    <b><Money :value="body.cpa" /></b>
                </template>

                <template v-slot:campaignGroupCpa>
                    Over the last 180 days, the parent campaign group has an average CPA of
                    <b><Money :value="body.cpa_avg" /></b>
                </template>
            </ImprovementStatistics>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="180" />
        </ImprovementContent>

        <template v-slot:adjust-step-1>
            <ImprovementContent>
                <ImprovementHeader title="Select Exclusion List">
                    <div v-if="aboveMobile">
                        <EntityPill type="placement" :content="body.placement" />
                        <Spacer height="2rem" />
                    </div>
                    <Text>
                        By default, Opteo will add this placement to a placement exclusion list
                        called
                        <b>{{ defaultSharedSetName }}</b
                        >. To add this placement to a different placement exclusion list, please
                        select it from the dropdown menu below.
                    </Text>
                </ImprovementHeader>
                <div class="adjustment-container">
                    <oInput
                        class="adjustment-input"
                        v-model="selectedSharedSet"
                        :items="sharedSetDropDownItems"
                        type="select"
                        label="Placement Exclusion List"
                    />
                </div>
            </ImprovementContent>
        </template>
    </ImprovementView>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

import {
    ImprovementHeader,
    ImprovementContent,
    ImprovementCreatedDate,
    EntityPill,
    EntityPillList,
    oInput,
    Text,
    Ent,
    Spacer,
    SerpPreview,
    ImprovementStatistics,
} from '@opteo/components-next'
import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import Money from '@/components/global/oMoney.vue'
import useMediaQuery from '@/composition/global/useMediaQuery'
import { useExcludePlacement } from '@/composition/improvement/types/useExcludePlacement'
import { useDomain } from '@/composition/domain/useDomain'

export default defineComponent({
    name: 'ExcludePlacement',
    components: {
        ImprovementHeader,
        ImprovementContent,
        ImprovementCreatedDate,
        ImprovementView,
        ImprovementHelpLink,
        EntityPill,
        EntityPillList,
        oInput,
        Ent,
        Money,
        Text,
        Spacer,
        SerpPreview,
        ImprovementStatistics,
    },
    setup() {
        return { ...useExcludePlacement(), ...useDomain(), ...useMediaQuery() }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.adjustment-container {
    @include container;
    @include pa-24;
}
.adjustment-input {
    width: 100%;
}

// Responsive

@media (min-width: $mq-321-min) and (max-width: $mq-375-max) {
    .adjustment-input {
        width: 100%;
    }
}
</style>
