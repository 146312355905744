<template>
    <ImprovementView
        :push-messages="pushMessages"
        :push-action-text="pushActionText"
        :steps="adjustSteps"
        :on-push="onPush"
    >
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <Text v-if="hasExistingCallouts" as="p">
                    Opteo recommends adding at least <b>{{ MINIMUM_CALLOUT_EXTENSIONS }}</b> callout
                    extensions at the account level. Callouts can help to increase engagement and
                    boost ad performance. Callouts are applied at the account level, and ensure
                    coverage across all campaigns in your account:
                </Text>
                <Text v-else as="p">
                    Opteo recommends adding at least <b>{{ MINIMUM_CALLOUT_EXTENSIONS }}</b> callout
                    extensions at the account level. Callouts can help to increase engagement and
                    boost ad performance. Callouts are applied at the account level, and ensure
                    coverage across all campaigns in your account:
                </Text>

                <Spacer height="2rem" />
                <EntityPillList :items="entityPillList" />
                <Spacer height="2rem" />
                <Text>
                    Click <Text weight="600" color="blue" as="span">{{ adjustSteps[0] }}</Text> to
                    write, preview and add callout extensions to your campaigns. New campaigns will
                    have callout extensions applied automatically.
                </Text>
            </ImprovementHeader>
            <ImprovementHelpLink path="/improvements/extensions/add-callout-extension">
                Click here to learn more about the benefits of adding callout extensions.
            </ImprovementHelpLink>
        </ImprovementContent>

        <ImprovementContent>
            <Text as="h3" size="f-4" weight="600">Callout Extension Examples</Text>
        </ImprovementContent>
        <ImprovementContent :ad-width="true">
            <div v-for="({ title, callouts }, index) in calloutExtensionExamples" :key="title">
                <AdExtensionPreview
                    :title-tag="{
                        title: `${title} Example`,
                        classes: 'opteo-blue',
                    }"
                    :callouts="callouts"
                />
                <Spacer height="2.5rem" v-if="index !== calloutExtensionExamples.length - 1" />
            </div>
            <Spacer height="3rem" />
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="90" />
        </ImprovementContent>

        <template #adjust-step-1>
            <ImprovementContent>
                <ImprovementHeader title="Input Callout Extensions">
                    <Text as="p">
                        Use the form below to add account-level callout extensions to your
                        campaigns. Opteo recommends adding at least
                        <b>{{ MINIMUM_CALLOUT_EXTENSIONS }}</b> account-level callouts. Use the
                        preview of your highest clicked ad to see how your callouts will appear on
                        the search engine results page.
                    </Text>
                    <Spacer height="1rem" />
                    <Text as="p">
                        Click
                        <Text as="span" color="green" weight="600">{{ pushActionText }}</Text> to
                        push your callout extensions to Google Ads.
                    </Text>
                </ImprovementHeader>
            </ImprovementContent>

            <ImprovementContent :ad-width="true">
                <TextAd :ad="highestClickedAd" highlight="callouts" :title-tag="adTitleTag" />
            </ImprovementContent>

            <Spacer height="3rem" />
            <ImprovementContent>
                <div class="callouts-form-container">
                    <oForm ref="calloutForm">
                        <div class="callout-inputs">
                            <div v-for="(extension, index) in calloutExtensions" :key="index">
                                <oInput
                                    :ref="
                                        el => {
                                            inputRefs[index] = el
                                        }
                                    "
                                    v-model="extension.calloutText"
                                    :name="`extension${index}`"
                                    :label="`Callout ${getNumberWord(index + 1)}`"
                                    :placeholder="`Callout ${getNumberWord(index + 1)}`"
                                    :required="index < MINIMUM_CALLOUT_EXTENSIONS"
                                    :max-characters="MAXIMUM_CALLOUT_LENGTH"
                                />
                                <Spacer
                                    height="1.5rem"
                                    v-if="index + 1 < calloutExtensions.length"
                                />
                            </div>
                        </div>
                        <div class="button-container">
                            <oButton
                                :disabled="calloutExtensions.length === MAXIMUM_CALLOUT_EXTENSIONS"
                                color="white"
                                :size="aboveMobile ? 'small' : 'medium'"
                                @clicked="addCalloutExtensions"
                                :fullWidth="aboveMobile ? false : true"
                            >
                                Add Callout
                            </oButton>
                            <Spacer v-if="!aboveMobile" height="0.75rem" />
                            <div class="form-buttons">
                                <oButton
                                    color="white"
                                    :size="aboveMobile ? 'small' : 'medium'"
                                    @clicked="resetCalloutExtensions(false)"
                                    :fullWidth="aboveMobile ? false : true"
                                >
                                    Reset Form
                                </oButton>
                                <Spacer v-if="aboveMobile" width="0.5rem" />
                                <Spacer v-else height="0.75rem" />
                                <oButton
                                    color="white"
                                    :size="aboveMobile ? 'small' : 'medium'"
                                    @clicked="resetCalloutExtensions(true)"
                                    :fullWidth="aboveMobile ? false : true"
                                >
                                    Clear Form
                                </oButton>
                            </div>
                        </div>
                    </oForm>
                </div>
            </ImprovementContent>
        </template>
    </ImprovementView>
</template>
<script lang="ts">
import {
    ImprovementHeader,
    ImprovementContent,
    ImprovementCreatedDate,
    EntityPill,
    EntityPillList,
    AdExtensionPreview,
    oInput,
    oForm,
    Text,
    Spacer,
    TextAd,
    oButton,
} from '@opteo/components-next'
import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import { useAddCalloutExtension } from '@/composition/improvement/types/useAddCalloutExtension'
import { defineComponent } from 'vue'
import { getNumberWord } from '@/lib/globalUtils'
import useMediaQuery from '@/composition/global/useMediaQuery'

export default defineComponent({
    name: 'AddCalloutExtension',
    components: {
        ImprovementHeader,
        ImprovementContent,
        ImprovementCreatedDate,
        ImprovementView,
        ImprovementHelpLink,
        EntityPill,
        EntityPillList,
        AdExtensionPreview,
        oInput,
        oForm,
        Text,
        Spacer,
        TextAd,
        oButton,
    },
    setup() {
        const { aboveMobile } = useMediaQuery()

        return { getNumberWord, ...useAddCalloutExtension(), aboveMobile }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.callouts-form-container {
    @include container;
    position: relative;
}
.callout-inputs {
    @include pa-36;
}
.button-container {
    border-top: 1px solid;
    @include opteo-border;
    @include flex;
    @include items-center;
    @include justify-between;
    @include ph-36;
    @include pv-28;
}
.form-buttons {
    @include flex;
    @include items-center;
}

@media screen and (max-width: $mq-767-max) {
    .button-container {
        @include block;
        @include pa-24;
    }
    .form-buttons {
        @include block;
    }
    .callout-inputs {
        @include pa-24;
    }
}
</style>
