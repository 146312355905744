import { Routes } from '@/router/routes'

import {
    LS_ACCOUNT_ID,
    LS_BILLING_LOCKED,
    LS_OVER_LIMIT,
    LS_TOKEN_NAME,
    LS_USER_ID,
} from '@/lib/cookies'
import { useLocalStorage, StorageSerializers } from '@vueuse/core'
import { computed, watch, Ref } from 'vue'
import { NavigationGuard, useRoute, useRouter, RouteLocationNormalized } from 'vue-router'
import { authRequest, Endpoint } from '../api/useAPI'
import maxBy from 'lodash-es/maxBy'
import { Billing, Tracking, User, Account } from '@opteo/types'
import { ApiError } from '../api/ApiError'
import { NODE_ENV } from '@/lib/env'

// TODO: Use httpOnly cookies instead of local storage (requires backend auth changes)
export function useAuth() {
    const token = useLocalStorage<string | null>(LS_TOKEN_NAME, null, {
        serializer: StorageSerializers.string,
    })

    const userId = useLocalStorage<number | null>(LS_USER_ID, null, {
        serializer: StorageSerializers.number,
    }) as unknown as Ref<number | null>

    const accountId = useLocalStorage<Account.ID | null>(LS_ACCOUNT_ID, null, {
        serializer: StorageSerializers.string,
    })

    const { currentRoute, push } = useRouter()

    watch(currentRoute, () => {
        setAccountId()
    })

    const loggedIn = computed(() => token.value && userId.value)

    const setCredentials = (newToken: string, newUserId: number) => {
        if (token) token.value = newToken
        if (userId) userId.value = newUserId
    }

    const setAccountId = () => {
        accountId.value = currentRoute.value.params.accountId as Account.ID | undefined
    }

    setAccountId()

    const clearSession = () => {
        token.value = null
        userId.value = null
        accountId.value = null
        push({ name: Routes.Login })
    }

    return {
        loggedIn,
        token,
        userId,
        accountId,
        setCredentials,
        clearSession,
    }
}

export const getCurrentCredentials = () => {
    const token = localStorage.getItem(LS_TOKEN_NAME)
    const userId = localStorage.getItem(LS_USER_ID)
    const accountId = localStorage.getItem(LS_ACCOUNT_ID)

    return {
        token: token,
        userId: userId ? +userId : null,
        accountId: accountId ? accountId : null,
    }
}

const isLoggedIn = () => {
    const { token, userId } = getCurrentCredentials()
    if (token && userId) {
        return userId
    }
}

// get token from localstorage
// if undef -> redirect to /login
// if def -> check valid with api
//      if valid -> redirect to /user
//      if invalid -> clear token, redirect to /login

export const checkLoggedIn: NavigationGuard = (to, _from, next) => {
    // Redirect to account centre if logged in
    const loggedInUser = isLoggedIn()
    if (loggedInUser) {
        next({ name: Routes.AccountCentre, params: { id: loggedInUser } })
        // Prevent infinite navigation if already at login page
    } else if (to.name === Routes.Login) {
        next()
    } else {
        next({ name: Routes.Login })
    }
}

export const doOnboardingIfRequired: NavigationGuard = async (_to, _from, next) => {
    if (
        _to.name === Routes.BillingCentreSubscription ||
        _to.name === Routes.NewPaymentMethod ||
        _to.name === Routes.ConnectGoogleAds
    ) {
        next()
        return
    }

    const [stripeCustomer, userhasConnectedAccounts] = await Promise.all([
        authRequest<Billing.OpteoCustomer>(Endpoint.GetStripeCustomer),
        authRequest<boolean>(Endpoint.DoesUserhaveConnectedAccounts),
    ])

    // Possible values are incomplete, incomplete_expired, trialing, active, past_due, canceled, or unpaid.
    const latest_subscription_status = maxBy(stripeCustomer.subscriptions?.data, s => s.created)
        ?.status

    const loggedInUser = isLoggedIn()

    // If the user has nothing linked or unlinked, take them to /connectgoogleads
    if (!userhasConnectedAccounts && !stripeCustomer.default_payment_method) {
        next({
            name: Routes.ConnectGoogleAds,
            params: { id: loggedInUser },
        })
        return
    }

    // If the user has no credit card, take them to /initialpaymentmethod
    if (stripeCustomer.cc_up_front && !stripeCustomer.default_payment_method) {
        next({
            name: Routes.NewPaymentMethod,
            params: { id: loggedInUser },
        })
        return
    }

    // If the user should have no access to the app due to a billing issue, take them to billing centre
    if (
        !latest_subscription_status ||
        ['incomplete', 'unpaid', 'canceled', 'incomplete_expired'].includes(
            latest_subscription_status
        )
    ) {
        next({
            name: Routes.BillingCentre,
            params: { id: loggedInUser },
        })
        return
    }

    next()
}

export const checkAuthValid: NavigationGuard = async (to, _from, next) => {
    const query = to.path ? { to: to.path } : {}
    const loggedInUser = isLoggedIn()
    if (loggedInUser) {
        /*
            Probe the API to see if the login token still works. 
            If not, clear the token and redirect to the login page.
        */
        try {
            await authRequest<Billing.OpteoCustomer>(Endpoint.TestAccess)

            doOnboardingIfRequired(to, _from, next)
        } catch (err) {
            if (err instanceof ApiError && err.message === 'bad_token') {
                localStorage.removeItem(LS_USER_ID)
                localStorage.removeItem(LS_TOKEN_NAME)
                localStorage.removeItem(LS_ACCOUNT_ID)
                next({ name: Routes.Login, query })
            } else {
                next()
            }
        }
    } else {
        next({ name: Routes.Login, query })
    }
}

export const checkIfOverLimit: NavigationGuard = async (_to, _from, next) => {
    const overLimit = localStorage.getItem(LS_OVER_LIMIT) ?? '0'
    const billingLocked = localStorage.getItem(LS_BILLING_LOCKED) ?? '0'

    const loggedInUser = isLoggedIn()
    if (+overLimit) {
        next({ name: Routes.AccountCentre, params: { id: loggedInUser } })
        return
    }

    if (+billingLocked) {
        next({ name: Routes.BillingCentre, params: { id: loggedInUser } })
        return
    }

    next()
}

export const hasFeatureFlagAccess = async (
    featureFlagId: User.FeatureFlagId,
    _to: RouteLocationNormalized,
    _from: RouteLocationNormalized,
    next: Function
) => {
    const loggedInUser = isLoggedIn()
    const hasFlagAccess = await authRequest<boolean>(Endpoint.GetIsUserTeamEnrolledInFeature, {
        body: { featureFlagId },
    })
    if (!hasFlagAccess) {
        next({ name: Routes.AccountCentre, params: { id: loggedInUser } })
    }
    next()
}

export const trackUserPageLoad = async (to: RouteLocationNormalized) => {
    const loggedInUser = isLoggedIn()
    if (!loggedInUser) {
        return
    }

    const account_id = to.params.accountId as Account.ID | undefined

    const body: Tracking.TrackParams = {
        user_id: +loggedInUser,
        account_id,
        action_name: Tracking.ActionName.LoadedPage,
        metadata: to.fullPath,
        // @ts-ignore NPM_VERSION is defined in vite.config.ts
        vueo_version: NPM_VERSION,
        env: NODE_ENV,
    }

    await authRequest(Endpoint.TrackUserAction, { body })
}
