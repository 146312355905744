<template>
    <ImprovementView :push-messages="pushMessages" :push-action-text="pushActionText">
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <div v-if="aboveMobile">
                    <EntityPill :content="entityPill.content" :type="entityPill.type" />
                    <Spacer height="2rem" />
                </div>
                <Text v-if="multipleCampaigns">
                    In the campaign group above, you're targeting searchers considered by Google to
                    be
                    <b>interested in</b> your location, but <b>not</b> physically present there.
                    Your ads are performing poorly for these searchers, compared to those actually
                    located in your target area.
                </Text>
                <Text v-else>
                    In the campaign listed above, you're targeting searchers considered by Google to
                    be
                    <b>interested in</b> your location, but <b>not</b> physically present there.
                    Your ads are performing poorly for these searchers, compared to those actually
                    located in your target area.
                </Text>
                <Spacer height="1rem" />
                <Text
                    v-if="
                        conversionsForLocationsOfInterest || conversionsValueForLocationsOfInterest
                    "
                >
                    This improvement will prevent your ads from being shown to searchers outside of
                    your target location. This change should lead to a small decrease in
                    {{ isCpaMode ? 'conversions' : 'conversion value' }}
                    — around
                    <b>
                        <Number v-if="isCpaMode" :value="conversionLoss" />
                        <Money v-else :value="conversionValueLoss" :currency="domainCurrencyCode" />
                    </b>
                    per month, however monthly spend should be reduced by
                    <b><Money :value="costSavings" :currency="domainCurrencyCode" /></b>
                    <Text as="span" color="green" weight="600">
                        (-<Percent :value="costSavingPercent" />)</Text
                    >
                    and overall
                    {{ isCpaMode ? 'CPA should decrease' : 'ROAS should increase' }} from
                    <template v-if="isCpaMode">
                        <b><Money :value="performanceBefore" :currency="domainCurrencyCode" /> </b>
                        to
                        <b><Money :value="performanceAfter" :currency="domainCurrencyCode" /> </b>
                    </template>
                    <template v-else>
                        <b><Roas :value="performanceBefore" /></b>
                        to
                        <b><Roas :value="performanceAfter" /></b>
                    </template>
                    <Text as="span" color="green" weight="600">
                        ({{ isCpaMode ? '-' : '+'
                        }}<Percent :value="performanceDifferencePercent" />)</Text
                    >.
                </Text>
                <Text v-else>
                    This improvement will prevent your ads from being shown to searchers outside of
                    your target location. This change should reduce spend by
                    <b><Money :value="interestCost" :currency="domainCurrencyCode" /></b>
                    <Text as="span" color="green" weight="600">
                        (-<Percent :value="costSavingPercent" />)</Text
                    >
                    each month and {{ isCpaMode ? 'lower CPA' : 'increase ROAS' }} from
                    <template v-if="isCpaMode">
                        <b><Money :value="performanceBefore" :currency="domainCurrencyCode" /> </b>
                        to
                        <b><Money :value="performanceAfter" :currency="domainCurrencyCode" /> </b>
                    </template>
                    <template v-else>
                        <b><Roas :value="performanceBefore" /></b>
                        to
                        <b><Roas :value="performanceAfter" /></b>
                    </template>
                    <Text as="span" color="green" weight="600">
                        ({{ isCpaMode ? '-' : '+'
                        }}<Percent :value="performanceDifferencePercent" />)</Text
                    >
                    with <b>no loss</b> of {{ isCpaMode ? 'conversions' : 'conversion value' }}.
                </Text>
                <Spacer height="1rem" />
                <div v-if="multipleCampaigns">
                    <Text
                        >Opteo will disable Locations of Interest for the following campaigns:</Text
                    >
                    <Spacer height="1.5rem" />
                    <EntityPillList :items="entityPillList" />
                    <Spacer height="2rem" />
                </div>
                <Text>
                    Click <Text as="span" color="green" weight="600">{{ pushActionText }}</Text> to
                    prevent your ads from being shown to these searchers. If this recommendation is
                    a false positive, feel free to click <b>Dismiss</b>.
                </Text>
            </ImprovementHeader>
            <ImprovementHelpLink path="/en/articles/2249958-disable-locations-of-interest">
                Click here to learn more about location targeting in Opteo.
            </ImprovementHelpLink>
        </ImprovementContent>

        <ImprovementContent
            v-if="conversionsForLocationsOfInterest || conversionsValueForLocationsOfInterest"
        >
            <Text as="h3" size="f-4" weight="600">{{
                isCpaMode ? 'CPA Comparison' : 'ROAS Comparison'
            }}</Text>
            <Spacer height="2rem" />
            <div class="graph-container">
                <BarChart
                    :items="chartItems"
                    :metric="chartMetric"
                    :horizontal-markers="chartMarkers"
                    disableFallback
                />
            </div>
        </ImprovementContent>
        <ImprovementContent>
            <Text as="h3" size="f-4" weight="600">Cost Comparison</Text>
            <Spacer height="2rem" />
            <!-- perhaps add a qualifier of what the donut shows i.e campaigns cost by breakdown of searcher type -->
            <div class="graph-container">
                <DonutChart :items="costChartItems" :metric="costChartMetric" :show-keys="true" />
            </div>
        </ImprovementContent>

        <ImprovementContent>
            <Text as="h3" size="f-4" weight="600">Comparison Table</Text>
            <Spacer height="2rem" />
            <oTable
                :headers="tableHeaders"
                :items="tableItems"
                :responsive-mode="aboveSmallMobile ? 'columns' : 'stacked'"
                :responsive-col-size="aboveSmallMobile ? '60% 1fr' : ''"
                responsive-breakpoint="768px"
            >
                <!-- Metric Column -->
                <template v-slot:[`column.metric`]="cellData">
                    <Text size="f-8" :style="aboveMobile ? 'width: 9.5rem' : ''">{{
                        cellData.value
                    }}</Text>
                </template>
                <!-- Locations of Presence Column -->
                <template v-slot:[`column.presence`]="cellData">
                    <!-- Conversions Number Formatting -->
                    <div v-if="isCpaMode && cellData.row.id === 'conversions'">
                        <Number :value="cellData.value" />
                    </div>
                    <!-- Conversion Value Number Formatting -->
                    <div v-else-if="!isCpaMode && cellData.row.id === 'conversionsValue'">
                        <Money :value="cellData.value" :currency="domainCurrencyCode" />
                    </div>
                    <!-- CPA or ROAS vs. Average Row Formatting -->
                    <div v-if="cellData.row.id === 'vs_average'">
                        <Text
                            v-if="cellData.value < 0"
                            :color="isCpaMode ? 'green' : 'red'"
                            size="f-8"
                            weight="500"
                        >
                            <Percent :value="cellData.value" />
                        </Text>
                        <Text
                            v-if="cellData.value > 0"
                            :color="isCpaMode ? 'red' : 'green'"
                            size="f-8"
                            weight="500"
                        >
                            +<Percent :value="cellData.value" />
                        </Text>
                        <Text v-if="cellData.value === 0" size="f-8">
                            <Percent :value="cellData.value" />
                        </Text>
                    </div>
                </template>
                <!-- Locations of Interest Column -->
                <template v-slot:[`column.interest`]="cellData">
                    <!-- Conversions Number Formatting -->
                    <div v-if="isCpaMode && cellData.row.id === 'conversions'">
                        <Number :value="cellData.value" />
                    </div>
                    <!-- Conversion Value Number Formatting -->
                    <div v-else-if="!isCpaMode && cellData.row.id === 'conversionsValue'">
                        <Money :value="cellData.value" :currency="domainCurrencyCode" />
                    </div>
                    <!-- CPA or ROAS vs. Average Row Formatting -->
                    <div v-if="cellData.row.id === 'vs_average'">
                        <Text
                            v-if="cellData.value < 0"
                            :color="isCpaMode ? 'green' : 'red'"
                            size="f-8"
                            weight="500"
                        >
                            <Percent :value="cellData.value" />
                        </Text>
                        <Text
                            v-if="cellData.value > 0"
                            :color="isCpaMode ? 'red' : 'green'"
                            size="f-8"
                            weight="500"
                        >
                            +<Percent :value="cellData.value" />
                        </Text>
                        <Text v-if="cellData.value === 0" size="f-8">
                            <Percent :value="cellData.value" />
                        </Text>
                    </div>
                </template>
            </oTable>
            <Spacer height="2rem" />
            <ImprovementNote v-if="isCpaMode">
                If
                <span v-if="conversionsForLocationsOfInterest">
                    you consider
                    <b>{{ formattedInterestCpa }}</b> an acceptable CPA,
                </span>
                <span v-else>
                    you consider spending
                    <b>{{ formattedInterestCost }}</b>
                    for <b>0</b> conversions acceptable,
                </span>
                consider adjusting the Target CPA for the parent campaign group in Campaign Groups.
                This helps Opteo generate higher quality improvements more suited to your
                requirements.
            </ImprovementNote>
            <ImprovementNote v-else>
                If
                <span v-if="conversionsValueForLocationsOfInterest">
                    you consider
                    <b>{{ formattedInterestRoas }}</b> an acceptable ROAS,
                </span>
                <span v-else>
                    you consider spending
                    <b>{{ formattedInterestCost }}</b>
                    for <b>0</b> conversion value acceptable,
                </span>
                consider adjusting the Target ROAS for the parent campaign group in Campaign Groups.
                This helps Opteo generate higher quality improvements more suited to your
                requirements.
            </ImprovementNote>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="lookbackWindow" />
        </ImprovementContent>
    </ImprovementView>
</template>
<script lang="ts">
import {
    ImprovementHeader,
    ImprovementCreatedDate,
    ImprovementContent,
    ImprovementNote,
    Text,
    Spacer,
    EntityPill,
    EntityPillList,
    HelpLink,
    oTable,
    DonutChart,
    BarChart,
    Money,
    Number,
    Percent,
    Roas,
} from '@opteo/components-next'
import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import { useDisableInterestLocationsV2 } from '@/composition/improvement/types/useDisableInterestLocationsV2'
import useMediaQuery from '@/composition/global/useMediaQuery'

export default {
    name: 'DisableInterestLocationsV2',
    components: {
        ImprovementHeader,
        ImprovementContent,
        ImprovementView,
        ImprovementCreatedDate,
        ImprovementNote,
        ImprovementHelpLink,
        Text,
        Spacer,
        EntityPill,
        EntityPillList,
        HelpLink,
        oTable,
        DonutChart,
        BarChart,
        Money,
        Number,
        Percent,
        Roas,
    },
    setup() {
        return { ...useDisableInterestLocationsV2(), ...useMediaQuery() }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.graph-container {
    @include container;
    @include pa-36;
}
</style>
