<template>
    <div>
        <ImprovementView
            :push-messages="pushMessages"
            :push-action-text="pushAction"
            :on-push="onPush"
            @back-clicked="bidToPush = recommendedBid"
            is-adjustable
        >
            <ImprovementContent>
                <ImprovementHeader :title="title">
                    <div v-if="aboveMobile">
                        <EntityPill type="product-group" :content="productGroupLabel" />
                        <Spacer width="2rem" />
                    </div>

                    <!-- CPA Intro -->
                    <Text v-if="performanceMode === Targets.PerformanceMode.CPA">
                        The product group above has generated
                        <b><Number :value="Math.round(productGroupConversions)" /></b> conversions
                        at an average CPA of <b><Money :value="performanceValue" /></b>. In
                        comparison, the parent campaign group has
                        {{ isUsingTarget ? 'a Target' : 'an average' }} CPA of
                        <b><Money :value="performanceTarget" /></b>.
                    </Text>

                    <!-- ROAS Intro -->
                    <Text v-else>
                        The product group above has generated
                        <b><Money :value="productGroupConversionsValue" /></b> conversion value with
                        a ROAS of <b><Roas :value="performanceValue" /></b>. For comparison, the
                        parent campaign group has a ROAS of
                        <b><Roas :value="performanceTarget" /></b>.
                    </Text>

                    <Spacer height="1rem" />

                    <Text v-if="bidIncrease">
                        Opteo recommends increasing the bid for this product group by
                        <Text as="span" weight="600" color="green"
                            ><Percent :value="bidChange" stripped
                        /></Text>
                        — from <b><Money :value="currentCpcBid" /></b> to
                        <b><Money :value="+recommendedBid" /></b> — this should
                        {{
                            performanceMode === Targets.PerformanceMode.CPA
                                ? 'generate more conversions'
                                : 'increase conversion value'
                        }}
                        at a reasonable cost.
                    </Text>
                    <Text v-else>
                        Opteo recommends decreasing the bid for this product group by
                        <Text as="span" weight="600" color="red"
                            ><Percent :value="bidChange" stripped
                        /></Text>
                        — from <b><Money :value="currentCpcBid" /></b> to
                        <b><Money :value="+recommendedBid" /></b> — this should
                        {{
                            performanceMode === Targets.PerformanceMode.CPA
                                ? 'reduce CPA'
                                : 'boost ROAS'
                        }}
                        and allow for smarter budget allocation.
                    </Text>
                    <Spacer height="1rem" />
                    <Text>
                        Click
                        <Text as="span" color="green" weight="600">{{ pushAction }}</Text> to apply
                        the recommended adjustment to this product group. Click <b>Adjust</b> to
                        {{ bidIncrease ? 'increase' : 'decrease' }} your bid by a different amount
                        (or to {{ bidIncrease ? 'decrease' : 'increase' }} your bid).
                    </Text>
                </ImprovementHeader>
                <ImprovementHelpLink path="/en">
                    Click here to learn more about adjusting product group bids in Opteo.
                </ImprovementHelpLink>
            </ImprovementContent>

            <ImprovementContent v-if="isUsingBidFactory">
                <ImprovementContent class="keyword-bid-simulation-header">
                    <Text as="h3" size="f-4" weight="600">Product Group Bid Simulation</Text>
                    <Spacer height="1.5rem" />
                    <Text as="p">
                        To simulate
                        {{
                            performanceMode === Targets.PerformanceMode.CPA
                                ? 'conversions'
                                : 'conversion value'
                        }}
                        and
                        {{ performanceMode === Targets.PerformanceMode.CPA ? 'CPA' : 'ROAS' }} for
                        each bid, Opteo assumes a conversion rate of
                        <b> <Percent :value="conversionRate" :decimal-places="2" /> </b>. This is
                        based on the conversion rate of this product group over the last
                        <b>30</b> days. For reference, Opteo caps bid adjustments at <b>30%</b> to
                        reduce volatility.
                    </Text>
                </ImprovementContent>
                <Text as="h3" size="f-4" weight="600">Simulation Chart</Text>
                <Spacer height="2rem" />
                <div class="graph-container">
                    <BidFactoryChart
                        v-if="performanceMode === Targets.PerformanceMode.CPA"
                        :prediction-items="predictionItems"
                        :simulation-points="simulationPoints"
                        :target="performanceTarget"
                        :performance-mode="Targets.PerformanceMode.CPA"
                        :currency-code="currencyCode"
                        :is-auto-targeting="!isUsingTarget"
                    />
                    <BidFactoryChart
                        v-else
                        :prediction-items="predictionItems"
                        :simulation-points="simulationPoints"
                        :target="performanceTarget"
                        :performance-mode="Targets.PerformanceMode.ROAS"
                        :currency-code="currencyCode"
                        :is-auto-targeting="!isUsingTarget"
                    />
                </div>
            </ImprovementContent>

            <ImprovementContent v-else>
                <ScatterPointChart
                    :items="chartItems"
                    :graph-keys="graphKeys"
                    :axis="axis"
                    :metric="chartMetrics"
                    :cut-off="cutOff"
                />

                <Spacer height="2rem" />
                <ImprovementNote v-if="performanceMode === Targets.PerformanceMode.CPA">
                    <strong>Note:</strong> Hover over any product group point to explore its
                    performance. Product groups in green zones are below your CPA
                    {{ targetOrAverageText }}, whilst red zones are above {{ targetOrAverageText }}.
                </ImprovementNote>
                <ImprovementNote v-else>
                    <strong>Note:</strong> Hover over any product group point to explore its
                    performance. Product groups in green zones are above your ROAS
                    {{ targetOrAverageText }}, whilst red zones are below {{ targetOrAverageText }}.
                </ImprovementNote>
            </ImprovementContent>

            <ImprovementContent>
                <Text as="h3" size="f-4" weight="600">Product Group Breakdown</Text>
                <Spacer height="2rem" />
                <ProductGroupTable :groups="mainProductGroupTree" :currency-code="currencyCode" />
            </ImprovementContent>

            <ImprovementContent>
                <ImprovementStatistics :items="bidAdjustmentItems" title="Bid Adjustment">
                    <template v-slot:bidChange>
                        Opteo recommends <span v-if="bidIncrease">increasing</span
                        ><span v-else>decreasing</span> the bid for this product group by
                        <Text as="span" weight="600" :color="bidIncrease ? 'green' : 'red'">
                            <Percent :value="bidChange" stripped /> </Text
                        >.
                    </template>
                </ImprovementStatistics>
            </ImprovementContent>

            <ImprovementContent>
                <ImprovementStatistics :items="statsItems">
                    <template #productGroupCpa>
                        <Text size="f-8">
                            Over the last 30 days, this product group cost
                            <b>
                                <Money :value="productGroupCost" />
                            </b>
                            and generated
                            <b>
                                <Number :value="productGroupConversions" />
                            </b>
                            conversions, resulting in a CPA of
                            <b>
                                <Money :value="performanceValue" />
                            </b>
                            This product group is currently performing
                            <b>
                                <Percent :value="differenceFromTarget" stripped />
                            </b>
                            {{ aboveOrBelowTargetText }} its
                            <span v-if="!isUsingTarget"> campaign group average</span
                            ><span v-if="isUsingTarget"> target</span> of
                            <b> <Money :value="performanceTarget" /> </b>.</Text
                        >
                    </template>

                    <template #productGroupRoas>
                        Over the last 30 days, this product group cost
                        <b>
                            <Money :value="productGroupCost" />
                        </b>
                        and generated
                        <b>
                            <Money :value="productGroupConversionsValue" />
                        </b>
                        conversion value, resulting in a ROAS of
                        <b> <Roas :value="performanceValue" /> </b>. This product group is currently
                        performing
                        <b>
                            <Percent :value="differenceFromTarget" stripped />
                        </b>
                        {{ aboveOrBelowTargetText }} its
                        <span v-if="!isUsingTarget"> campaign group average</span
                        ><span v-if="isUsingTarget"> target</span>
                        of
                        <b> <Roas :value="performanceTarget" /> </b>.
                    </template>

                    <template #targetCpa>
                        <Text size="f-8">
                            This product group is part of the campaign group
                            <b>{{ campaignGroupLabel }}</b
                            >, which has {{ targetOrAverageText }} CPA of
                            <b> <Money :value="performanceTarget" /> </b>.
                        </Text>
                    </template>

                    <template #targetRoas>
                        <Text size="f-8">
                            This product group is part of the campaign group
                            <b>{{ campaignGroupLabel }}</b
                            >, which has {{ targetOrAverageText }} ROAS of
                            <b> <Roas :value="performanceTarget" /> </b>.
                        </Text>
                    </template>

                    <template #searchImpressionShare>
                        <Text size="f-8">
                            The search impression share for this product group was
                            <b> <Percent :value="searchImpressionShare" /> </b>.
                        </Text>
                    </template>
                    <template #absoluteTopImpressionShare>
                        <Text size="f-8" v-if="searchAbsoluteTopImpressionShare >= 0.1">
                            The absolute top search impression share for this product group was
                            <b> <Percent :value="searchAbsoluteTopImpressionShare" /> </b>.
                        </Text>
                        <Text size="f-8" v-else>
                            The absolute top search impression share for this product group was
                            <b> &lt; <Percent :value="0.1" :decimal-places="0" /> </b>.
                        </Text>
                    </template>
                </ImprovementStatistics>
            </ImprovementContent>

            <ImprovementContent>
                <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="30" />
            </ImprovementContent>

            <template v-slot:adjust-step-1>
                <ImprovementContent>
                    <ImprovementHeader :title="title">
                        <div v-if="aboveMobile">
                            <EntityPill type="product-group" :content="productGroupLabel" />
                            <Spacer height="2rem" />
                        </div>
                        <!-- CPA Intro -->
                        <Text v-if="performanceMode === Targets.PerformanceMode.CPA">
                            The product group above has generated
                            <b><Number :value="Math.round(productGroupConversions)" /></b>
                            conversions at an average CPA of
                            <b><Money :value="performanceValue" /></b>. In comparison, the parent
                            campaign group has {{ isUsingTarget ? 'a Target' : 'an average' }} CPA
                            of <b><Money :value="performanceTarget" /></b>.
                        </Text>

                        <!-- ROAS Intro -->
                        <Text v-else>
                            The product group above has generated
                            <b><Money :value="productGroupConversionsValue" /></b> conversion value
                            with a ROAS of <b><Roas :value="performanceValue" /></b>. For
                            comparison, the parent campaign group has a ROAS of
                            <b><Roas :value="performanceTarget" /></b>.
                        </Text>

                        <Spacer height="1rem" />

                        <Text v-if="bidIncrease">
                            Opteo recommends increasing the bid for this product group by
                            <Text as="span" weight="600" color="green"
                                ><Percent :value="bidChange" stripped
                            /></Text>
                            — from <b><Money :value="currentCpcBid" /></b> to
                            <b><Money :value="+recommendedBid" /></b> — this should
                            {{
                                performanceMode === Targets.PerformanceMode.CPA
                                    ? 'generate more conversions'
                                    : 'increase conversion value'
                            }}
                            at a reasonable cost.
                        </Text>
                        <Text v-else>
                            Opteo recommends decreasing the bid for this product group by
                            <Text as="span" weight="600" color="red"
                                ><Percent :value="bidChange" stripped
                            /></Text>
                            — from <b><Money :value="currentCpcBid" /></b> to
                            <b><Money :value="+recommendedBid" /></b> — this should
                            {{
                                performanceMode === Targets.PerformanceMode.CPA
                                    ? 'reduce CPA'
                                    : 'boost ROAS'
                            }}
                            and allow for smarter budget allocation.
                        </Text>
                    </ImprovementHeader>

                    <InlineInputContainer :single-side="true">
                        <template #left-side>
                            <Text as="span" size="f-8">Set product group bid to</Text>
                        </template>
                        <template #input>
                            <oInput
                                v-model="bidToPush"
                                type="number"
                                :prefix="currencySymbol"
                                autofocus
                                class="adjustment-input"
                            />
                        </template>
                    </InlineInputContainer>
                </ImprovementContent>
            </template>
        </ImprovementView>
    </div>
</template>
<script lang="ts" setup>
import {
    Percent,
    ImprovementHeader,
    ImprovementContent,
    ImprovementNote,
    ImprovementStatistics,
    ImprovementCreatedDate,
    EntityPill,
    ScatterPointChart,
    Spacer,
    Text,
    ProductGroupTable,
    Number,
    oInput,
    Roas,
    BidFactoryChart,
} from '@opteo/components-next'
import { Targets } from '@opteo/types'

import Money from '@/components/global/oMoney.vue'

import { useAdjustProductGroupBid } from '@/composition/improvement/types/product-group-bids/useAdjustProductGroupBid'
import useMediaQuery from '@/composition/global/useMediaQuery'

import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import InlineInputContainer from '@/components/improvement/shared-components/InlineInputContainer.vue'

const {
    // Improvement Info
    onPush,
    lastUpdated,
    pushMessages,
    pushAction,
    title,
    currencySymbol,
    currencyCode,

    // Targets
    performanceMode,
    performanceValue,
    performanceTarget,
    targetOrAverageText,
    isUsingTarget,
    differenceFromTarget,
    aboveOrBelowTargetText,

    // Product Goup Info
    productGroupLabel,
    campaignGroupLabel,

    // Product Group Metrics
    productGroupCost,
    productGroupConversions,
    productGroupConversionsValue,
    searchImpressionShare,
    searchAbsoluteTopImpressionShare,
    conversionRate,
    mainProductGroupTree,

    // Predictions
    predictionItems,
    simulationPoints,
    isUsingBidFactory,
    additionalPredictedAllConversionsValue,
    additionalPredictedConversions,

    // No Predictions
    chartItems,
    graphKeys,
    axis,
    chartMetrics,
    cutOff,
    bidAdjustmentItems,

    // Stats
    statsItems,

    // Bid Adjustments
    currentCpcBid,
    bidToPush,
    bidIncrease,
    bidChange,
    recommendedBid,
} = useAdjustProductGroupBid()

const { aboveMobile } = useMediaQuery()
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.bidding-strategy-text {
    @include inline;
}

// Responsive
@media (min-width: 767px) {
    .adjustment-input {
        width: 8rem;
    }
}
</style>
