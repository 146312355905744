<template>
    <ImprovementView :push-messages="pushMessages" :push-action-text="pushActionText">
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <div v-if="aboveMobile">
                    <EntityPillList
                        :items="[
                            { type: 'campaign', content: campaignName },
                            { type: 'adgroup', content: adGroupName },
                            { type: 'keyword', content: keyword },
                        ]"
                        :scrollToRight="true"
                    />
                    <Spacer width="100%" height="1.5rem" />
                </div>
                <Text as="p" v-if="!!keywordCpa">
                    The keyword listed above spends a significant amount, but converts few
                    searchers. This keyword has an average CPA of
                    <b><Money :value="keywordCpa" :currency="currencyCode" /></b>. In comparison,
                    the parent campaign group has an {{ campaignGroupTargetModeString }} CPA of
                    <b><Money :value="campaignGroupTargetCpa" :currency="currencyCode" /> </b>.
                    Opteo recommends pausing this keyword.
                    <Spacer height="1rem" />
                    Click <Text as="span" weight="600" color="green">{{ pushActionText }}</Text> to
                    pause this keyword. If you consider this improvement a false positive and this
                    keyword has acceptable performance, feel free to click <b>Dismiss</b>.
                </Text>
                <Text as="p" v-else>
                    The keyword above spends a significant amount, but has generated
                    <b>0</b> conversions over the last <b>{{ window }}</b> days. Opteo recommends
                    pausing this keyword to reduce wasted spend. Click
                    <Text as="span" weight="600" color="green">{{ pushActionText }}</Text> to pause
                    this keyword. If you consider this improvement a false positive and the
                    performance of this keyword acceptable, click <b>Dismiss</b>.
                </Text>
            </ImprovementHeader>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementStatistics :items="statistics">
                <template #cost>
                    <b><Money :value="keywordSpend" :currency="currencyCode" /></b> was spent on
                    this keyword over the last <b>{{ window }}</b> days.
                </template>
                <template #conversions>
                    This keyword generated
                    <b><Number :value="keywordConversions" /></b> conversion<span
                        v-if="keywordConversions !== 1"
                        >s</span
                    >
                    over the last <b>{{ window }}</b> days. Please note,
                    {{ keywordConversions !== 1 ? 'these conversions are' : 'this conversion is' }}
                    based on your conversion types set in <b>Campaign Groups</b>.
                </template>
                <template #targetCpa>
                    The parent campaign group has a {{ campaignGroupTargetModeString }} CPA of
                    <b><Money :value="campaignGroupTargetCpa" :currency="currencyCode" /></b>.
                </template>
                <template v-if="keywordCpa !== 0" #keywordCpa>
                    This keyword has an average CPA of
                    <b><Money :value="keywordCpa" :currency="currencyCode" /></b> over the last
                    <b>{{ window }}</b> days.
                </template>
            </ImprovementStatistics>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="window" />
        </ImprovementContent>
    </ImprovementView>
</template>

<script lang="ts" setup>
import {
    ImprovementHeader,
    ImprovementContent,
    ImprovementStatistics,
    ImprovementCreatedDate,
    EntityPillList,
    Spacer,
    Number,
    Text,
} from '@opteo/components-next'
import Money from '@/components/global/oMoney.vue'
import ImprovementView from '@/components/improvement/ImprovementView.vue'
import useMediaQuery from '@/composition/global/useMediaQuery'
import { usePauseKeyword } from '@/composition/improvement/types/usePauseKeyword.ms'

const { aboveMobile } = useMediaQuery()
const {
    title,
    pushMessages,
    pushActionText,
    lastUpdated,
    window,
    campaignGroupTargetModeString,
    campaignName,
    adGroupName,
    keyword,
    keywordCpa,
    keywordSpend,
    keywordConversions,
    campaignGroupTargetCpa,
    statistics,
    currencyCode,
} = usePauseKeyword()
</script>
