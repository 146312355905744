<template>
    <ImprovementView
        :push-messages="pushMessages"
        :push-action-text="pushActionText"
        :is-adjustable="true"
        :on-push="onPush"
    >
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <div v-if="aboveMobile">
                    <EntityPill :content="campaignName" type="campaign" />
                    <Spacer height="2rem" />
                </div>

                <Text as="p">
                    Consider adding a frequency cap to this campaign. Without one, your audience
                    could be seeing your ads dozens of times per day. This can often lead to
                    overexposure and generate negative sentiment with searchers. Click
                    <Text as="span" weight="600" color="green">{{ pushActionText }}</Text> to add a
                    cap of <b><Number :value="+selectedFrequencyCap" /></b> impressions per user,
                    per day to this campaign. To change the cap, click <b>Adjust</b>.
                </Text>
            </ImprovementHeader>
            <ImprovementHelpLink path="/en/articles/900653-add-frequency-cap">
                Click here to learn more about adding frequency caps in Opteo.
            </ImprovementHelpLink>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementStatistics :items="improvementStatistics">
                <!-- TODO: get a couple more metrics on cookies if possible -->
                <template v-slot:cost>
                    <b><Money :value="cost" /></b> was spent on this campaign over the last
                    <b>30 days</b>.
                </template>
            </ImprovementStatistics>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="30" />
        </ImprovementContent>

        <template v-slot:adjust-step-1>
            <ImprovementContent>
                <ImprovementHeader title="Adjust Frequency Cap">
                    <Text as="p">Adjust your frequency cap using the input below.</Text>
                </ImprovementHeader>
                <div class="adjustment-container">
                    <oInput
                        v-model="selectedFrequencyCap"
                        type="number"
                        suffix="Impressions per user, per day"
                        class="inline-input"
                        autofocus
                    />
                </div>
            </ImprovementContent>
        </template>
    </ImprovementView>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

import {
    ImprovementHeader,
    ImprovementContent,
    ImprovementStatistics,
    ImprovementCreatedDate,
    EntityPill,
    EntityPillList,
    oInput,
    Number,
    Spacer,
    Text,
} from '@opteo/components-next'
import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import Money from '@/components/global/oMoney.vue'
import { useAddFrequencyCapping } from '@/composition/improvement/types/useAddFrequencyCapping'
import useMediaQuery from '@/composition/global/useMediaQuery'

export default defineComponent({
    name: 'AddFrequencyCapping',
    components: {
        ImprovementHeader,
        ImprovementContent,
        ImprovementStatistics,
        ImprovementCreatedDate,
        ImprovementView,
        ImprovementHelpLink,
        EntityPill,
        EntityPillList,
        oInput,
        Money,
        Number,
        Spacer,
        Text,
    },
    setup() {
        return { ...useAddFrequencyCapping(), ...useMediaQuery() }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.adjustment-container {
    @include container;
    @include pa-32;
}
.inline-input {
    width: 16rem;
}

@media screen and (max-width: $mq-767-max) {
    .adjustment-container {
        @include pa-24;
    }
    .inline-input {
        width: 100%;
    }
}
</style>
