import { computed, ComputedRef } from 'vue'
import addDates from 'date-fns/add'
import formatDate from 'date-fns/format'
import { useIntercom } from '@/lib/intercom/useIntercom'
import { useUser } from '@/composition/user/useUser'
import { useLayouts } from '@/composition/layouts/useLayouts'
import { useLogin } from '@/composition/auth/useLogin'
import { Routes } from '@/router/routes'
import { useRouter } from 'vue-router'
import { Endpoint, authRequest } from '@/composition/api/useAPI'
import { pushToDatalayer } from '@/lib/datalayer'

function generateTrialEndDate(): string {
    const trialEndDate = addDates(new Date(), { days: 30 })
    const day = formatDate(trialEndDate, 'do')
    const month = formatDate(trialEndDate, 'MMMM')

    return `${day} of ${month}`
}

interface UseNewPaymentMethod {
    trialEndDate: string
    monthlyFee: ComputedRef<number>
    showNewMessage: (message?: string | undefined) => any
    onSuccess: (paymentMethod: string) => Promise<void>
    logout: () => void
}

export function useNewPaymentMethod(): UseNewPaymentMethod {
    useLayouts('full-screen')
    const trialEndDate = generateTrialEndDate()

    const { showNewMessage } = useIntercom()
    const { currentPlan, userId, mutateUserInfo } = useUser()
    const { logout } = useLogin()
    const router = useRouter()

    const monthlyFee = computed(() => currentPlan.value?.price ?? 0)

    async function onSuccess(paymentMethod: string) {
        await authRequest(Endpoint.UpdateCustomerCc, { body: { token: paymentMethod } })
        await authRequest(Endpoint.PushBackTrialEnd)
        await mutateUserInfo()
        pushToDatalayer('gtm_added_cc')
        router.push({ name: Routes.AccountCentre, params: { id: userId.value } })
    }

    return { trialEndDate, monthlyFee, showNewMessage, onSuccess, logout }
}
