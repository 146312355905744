<template>
    <div>
        <ImprovementView
            :push-action-text="pushActionText"
            :push-messages="pushMessages"
            :on-push="onPush"
            :is-adjustable="true"
            :title="title"
            @back-clicked="resetSelection"
        >
            <ImprovementContent>
                <ImprovementHeader :title="title">
                    <div v-if="aboveMobile">
                        <EntityPill type="campaign" :content="campaignName" />
                    </div>
                    <Spacer width="100%" height="2rem" />
                    <Text as="p">
                        Your master shared negative list (listed below this paragraph) has not been
                        applied to the campaign above. Click
                        <Text as="span" weight="600" color="green">{{ pushActionText }}</Text> to
                        apply your master shared negative list to this campaign. If you need to
                        select a different negative list, or to apply multiple negative lists, click
                        <b>Adjust</b>. Otherwise, feel free to <b>Dismiss</b> this improvement.
                        <Spacer height="2rem" />
                        <EntityPillList
                            v-if="!aboveMobile && primarySharedSet.shared_set_name.length > 30"
                            :items="[
                                {
                                    type: 'negative-list',
                                    content: primarySharedSet.shared_set_name,
                                },
                            ]"
                        />
                        <EntityPill
                            v-else
                            type="negative-list"
                            :content="primarySharedSet.shared_set_name"
                        />
                    </Text>
                </ImprovementHeader>
                <ImprovementHelpLink path="/en/articles/2198514-apply-negative-list">
                    Click here to learn more about applying shared negative lists in Opteo.
                </ImprovementHelpLink>
            </ImprovementContent>

            <template v-slot:adjust-step-1>
                <ImprovementContent>
                    <ImprovementHeader :title="title">
                        <div v-if="aboveMobile">
                            <EntityPill type="campaign" :content="campaignName" />
                            <Spacer width="100%" height="2rem" />
                        </div>
                        <Text as="p">
                            Select shared negative list(s) to apply to the above campaign.
                        </Text>
                        <Spacer height="2rem" />
                        <div class="shared-list">
                            <div
                                v-for="(sharedSet, index) in sharedSets"
                                :key="sharedSet.shared_set_id"
                            >
                                <oInput
                                    type="checkbox"
                                    v-model="sharedSet.selected"
                                    :name="`checkbox${sharedSet.shared_set_id}`"
                                    :label="sharedSet.shared_set_name"
                                    :button-mode="true"
                                />
                                <Spacer v-if="sharedSets.length === index + 1" height="0rem" />
                                <Spacer v-else height="0.75rem" />
                            </div>
                            <Text v-if="error" as="span" size="f-8" color="red">
                                <Spacer height="1rem" />
                                {{ error }}
                            </Text>
                        </div>
                    </ImprovementHeader>
                </ImprovementContent>
            </template>

            <ImprovementContent>
                <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="30" />
            </ImprovementContent>
        </ImprovementView>
    </div>
</template>

<script lang="ts">
import {
    ImprovementHeader,
    ImprovementContent,
    ImprovementNote,
    ImprovementStatistics,
    ImprovementCreatedDate,
    Spacer,
    Text,
    Link,
    oInput,
    EntityPill,
    EntityPillList,
} from '@opteo/components-next'

import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import Ent from '@/components/util/Ent.vue'

import { useAddMissingCampaignsToSharedSet } from '@/composition/improvement/types/useAddMissingCampaignsToSharedSet'
import useMediaQuery from '@/composition/global/useMediaQuery'

export default {
    name: 'AddMissingCampaignsToSharedSet',
    setup() {
        return { ...useAddMissingCampaignsToSharedSet(), ...useMediaQuery() }
    },
    components: {
        ImprovementHelpLink,
        ImprovementHeader,
        ImprovementContent,
        ImprovementNote,
        ImprovementView,
        ImprovementStatistics,
        ImprovementCreatedDate,
        Spacer,
        Text,
        Link,
        Ent,
        oInput,
        EntityPill,
        EntityPillList,
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.shared-lists-box {
    @include opteo-background;
    @include lh-150;
}

.shared-list {
    @include container;
    @include pa-32;
}

:deep(.o-input__checkbox) {
    @include w-100;
}

@media screen and (max-width: $mq-767-max) {
    .shared-list {
        @include container;
        @include pa-24;
    }
}
</style>
