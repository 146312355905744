<template>
    <ImprovementView :push-messages="pushMessages" :push-action-text="pushActionText">
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <!-- At least one conversion -->
                <Text v-if="body.cpa > 0" as="p">
                    Mobile apps tend to attract accidental clicks and few conversions. When your ads
                    are shown on mobile apps, they have an average CPA of
                    <b><Money :value="body.cpa" /></b>. In comparison, the parent campaign group has
                    an average CPA of <b><Money :value="body.cpa_avg" /></b>. Click
                    <Text as="span" weight="600" color="green">{{ pushActionText }}</Text> to
                    prevent your ads from being shown on mobile apps.
                </Text>
                <!-- Zero conversions -->
                <Text v-else>
                    Mobile apps tend to attract accidental clicks and few conversions. Your ads have
                    generated <b>0</b> conversions when displayed on mobile apps. For context, the
                    parent campaign group has an average CPA of
                    <b><Money :value="body.cpa_avg" /></b>. Click
                    <Text as="span" weight="600" color="green">{{ pushActionText }}</Text> to
                    prevent your ads from being shown on mobile apps.
                </Text>
                <Spacer height="1rem" />
                <Text as="p">
                    When you push this improvement, Opteo will automatically create a new placement
                    exclusion list called
                    <Text as="span" weight="600">Mobile App Exclusions (mobile_app_exclusion)</Text
                    >. This placement exclusion list will be applied to the following campaign<span
                        v-if="affectedCampaigns.length > 1"
                        >s</span
                    >:
                </Text>
                <Spacer height="2rem" />
                <div v-if="affectedCampaigns.length === 1">
                    <EntityPill
                        :type="affectedCampaigns[0].type"
                        :content="affectedCampaigns[0].content"
                    />
                </div>
                <EntityPillList v-else :items="affectedCampaigns" />
            </ImprovementHeader>
            <ImprovementHelpLink path="/en/articles/900676-exclude-mobile-apps">
                Click here to learn more about the benefits of excluding mobile apps.
            </ImprovementHelpLink>
        </ImprovementContent>
        <ImprovementContent>
            <MobileAppGrid :items="mobileApps" />
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementStatistics :items="stats">
                <template v-slot:cost>
                    <b><Money :value="body.cost" /></b> was spent on in-app mobile placements over
                    the last <b>180</b> days.
                </template>
                <template v-slot:conversions>
                    In-app mobile placements generated
                    <b>{{ body.conversions }}</b> conversions over the last <b>180</b> days.
                </template>
                <template v-slot:cpa>
                    In-app mobile placements have a CPA of
                    <b><Money :value="body.cpa" /></b> over the last <b>180</b> days.
                </template>
                <template v-slot:avgCpa>
                    The parent campaign group has an average CPA of
                    <b><Money :value="body.cpa_avg" /></b>
                    over the last <b>180</b> days.
                </template>
            </ImprovementStatistics>
        </ImprovementContent>
        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="180" />
        </ImprovementContent>
    </ImprovementView>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import {
    ImprovementHeader,
    ImprovementContent,
    ImprovementStatistics,
    ImprovementCreatedDate,
    oTable,
    Text,
    EntityPillList,
    EntityPill,
    Spacer,
    oInput,
    MobileAppGrid,
} from '@opteo/components-next'
import Money from '@/components/global/oMoney.vue'
import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'

import { useExcludeMobileApps } from '@/composition/improvement/types/useExcludeMobileApps'

export default defineComponent({
    name: 'ExcludeMobileApps',
    components: {
        ImprovementHeader,
        ImprovementContent,
        ImprovementView,
        ImprovementHelpLink,
        ImprovementStatistics,
        ImprovementCreatedDate,
        EntityPillList,
        EntityPill,
        MobileAppGrid,
        Money,
        oTable,
        Text,
        Spacer,
        oInput,
    },
    setup() {
        return useExcludeMobileApps()
    },
})
</script>
<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.category-row {
    @include flex;
    @include items-center;
}
</style>
