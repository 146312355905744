<template>
    <ImprovementView :push-messages="pushMessages" :push-action-text="pushActionText">
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <div v-if="aboveMobile">
                    <div v-if="campaignCopy === 'campaigns'">
                        <EntityPill :content="campaignGroup.content" :type="campaignGroup.type" />
                    </div>
                    <div v-else>
                        <EntityPill
                            v-for="(campaign, index) in campaignNames"
                            :key="index"
                            :type="campaign.type"
                            :content="campaign.content"
                        />
                    </div>
                    <Spacer height="2rem" />
                </div>
                <Text v-if="campaignCopy === 'campaigns'" as="p">
                    In the campaign group above, you're targeting searchers considered by Google to
                    be
                    <b>interested in</b> your location, but <b>not</b> physically present there.
                    Your ads are performing poorly for these searchers, compared to those actually
                    located in your target area.
                </Text>
                <Text v-else as="p">
                    In the campaign listed above, you're targeting searchers considered by Google to
                    be
                    <b>interested in</b> your location, but <b>not</b> physically present there.
                    Your ads are performing poorly for these searchers, compared to those actually
                    located in your target area.
                </Text>
                <Spacer height="1rem" />
                <Text v-if="conversionLoss" as="p">
                    This improvement will prevent your ads from being shown to searchers outside of
                    your target location. Although we expect to lose
                    <b><Number :value="Math.round(conversionLoss * 100) / 100" /></b>
                    <span v-if="conversionLoss === 1"> conversion</span>
                    <span v-else> conversions</span> each month, spend should be reduced by
                    <b><Money :value="costSavings" /></b>
                    <Text as="span" color="green" weight="600">
                        (-<Percent :value="costSavingPercent" />)</Text
                    >
                    and CPA by <b><Money :value="cpaDecrease" /> </b>
                    <Text as="span" color="green" weight="600">
                        (-<Percent :value="cpaDecreasePercent" />)</Text
                    >.
                </Text>
                <Text v-else as="p">
                    This improvement will prevent your ads from being shown to searchers outside of
                    your target location. We expect to reduce spend by
                    <b><Money :value="costSavings" /></b>
                    <Text as="span" color="green" weight="600">
                        (-<Percent :value="costSavingPercent" />)</Text
                    >
                    over the next month and lower CPA by
                    <b><Money :value="cpaDecrease" /> </b>
                    <Text as="span" color="green" weight="600">
                        (-<Percent :value="cpaDecreasePercent" />)</Text
                    >, with <b>no loss</b> of conversions.
                </Text>
                <Spacer height="1rem" />
                <Text as="p">
                    Click <Text as="span" color="green" weight="600">{{ pushActionText }}</Text> to
                    prevent your ads from being shown to these searchers. If this recommendation is
                    a false positive, feel free to click <b>Dismiss</b>.
                </Text>
            </ImprovementHeader>
            <ImprovementHelpLink path="/en/articles/2249958-disable-locations-of-interest">
                Click here to learn more about location targeting in Opteo.
            </ImprovementHelpLink>
        </ImprovementContent>

        <ImprovementContent>
            <div v-if="campaignCopy === 'campaigns'">
                <Text as="p">
                    The following {{ campaignCopy }} will be affected by this improvement:
                </Text>
                <Spacer height="2rem" />
                <EntityPillList :items="campaignNames" />
            </div>
        </ImprovementContent>

        <ImprovementContent v-if="showBarChart">
            <Text as="h3" size="f-4" weight="600">CPA Comparison</Text>
            <Spacer height="2rem" />
            <div class="graph-container">
                <BarChart
                    :items="barChartItems"
                    :metric="barChartMetric"
                    :horizontal-markers="barChartMarkers"
                />
            </div>
        </ImprovementContent>
        <ImprovementContent>
            <Text as="h3" size="f-4" weight="600">Cost Comparison</Text>
            <Spacer height="2rem" />
            <div class="graph-container">
                <DonutChart :items="donutChartItems" :metric="donutChartMetric" />
            </div>
        </ImprovementContent>

        <ImprovementContent>
            <Text as="h3" size="f-4" weight="600">Comparison Table</Text>
            <Spacer height="2rem" />
            <oTable :headers="tableHeaders" :items="tableItems">
                <!-- Metric Column -->
                <template v-slot:[`column.metric`]="cellData">
                    <Text size="f-8" style="width: 9.5rem">{{ cellData.value }}</Text>
                </template>
                <!-- Locations of Presence Column -->
                <template v-slot:[`column.presence`]="cellData">
                    <!-- Conversions Number Formatting -->
                    <div v-if="cellData.row.id === 'conversions'">
                        <Number :value="cellData.value" />
                    </div>
                    <!-- CPA Comparison — Above Average (Bad) -->
                    <div
                        v-if="
                            cellData.row.id === 'cpa_comparison' &&
                            cellData.row.presence.includes('+')
                        "
                    >
                        <Text color="red" size="f-8" weight="500">{{ cellData.value }}</Text>
                    </div>
                    <!-- CPA Comparison — Below Average (Good) -->
                    <div
                        v-if="
                            cellData.row.id === 'cpa_comparison' &&
                            cellData.row.presence.includes('-')
                        "
                    >
                        <Text color="green" size="f-8" weight="500">{{ cellData.value }}</Text>
                    </div>
                    <!-- CPA Comparison — On Target -->
                    <div
                        v-if="
                            cellData.row.id === 'cpa_comparison' &&
                            cellData.row.presence.includes('On Target')
                        "
                    >
                        {{ cellData.value }}
                    </div>
                </template>
                <!-- Locations of Interest Column -->
                <template v-slot:[`column.interest`]="cellData">
                    <!-- Conversions Number Formatting -->
                    <div v-if="cellData.row.id === 'conversions'">
                        <Number :value="cellData.value" />
                    </div>
                    <!-- CPA Comparison — Above Average (Bad) -->
                    <div
                        v-if="
                            cellData.row.id === 'cpa_comparison' &&
                            cellData.row.interest.includes('+')
                        "
                    >
                        <Text color="red" size="f-8" weight="500">{{ cellData.value }}</Text>
                    </div>
                    <!-- CPA Comparison — Below Average (Good) -->
                    <div
                        v-if="
                            cellData.row.id === 'cpa_comparison' &&
                            cellData.row.interest.includes('-')
                        "
                    >
                        <Text color="green" size="f-8" weight="500">{{ cellData.value }}</Text>
                    </div>
                    <!-- CPA Comparison — On Target -->
                    <div
                        v-if="
                            cellData.row.id === 'cpa_comparison' &&
                            cellData.row.interest.includes('On Target')
                        "
                    >
                        {{ cellData.value }}
                    </div>
                </template>
            </oTable>
            <Spacer height="2rem" />
            <ImprovementNote>
                If
                <span v-if="interestCpa > 0">
                    you consider <b><Money :value="interestCpa" /></b> an acceptable CPA,
                </span>
                <span v-else>
                    spending
                    <b><Money :value="interestCost" /></b> for <b>0</b> conversions is acceptable,
                </span>
                consider adjusting the Target CPA for the parent campaign group in Campaign Groups.
                This helps Opteo generate higher quality improvements more suited to your
                requirements.
            </ImprovementNote>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="90" />
        </ImprovementContent>
    </ImprovementView>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import {
    ImprovementHeader,
    ImprovementContent,
    ImprovementNote,
    ImprovementCreatedDate,
    EntityPillList,
    EntityPill,
    Number,
    Percent,
    oButton,
    Text,
    Spacer,
    BarChart,
    DonutChart,
    oTable,
} from '@opteo/components-next'

import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import Money from '@/components/global/oMoney.vue'
import useMediaQuery from '@/composition/global/useMediaQuery'

import { useDisableInterestLocations } from '@/composition/improvement/types/useDisableInterestLocations'

export default defineComponent({
    name: 'DisableInterestLocations',
    components: {
        ImprovementView,
        ImprovementHeader,
        ImprovementContent,
        ImprovementNote,
        ImprovementHelpLink,
        ImprovementCreatedDate,
        EntityPillList,
        EntityPill,
        Money,
        Number,
        Percent,
        oButton,
        Text,
        Spacer,
        BarChart,
        DonutChart,
        oTable,
    },
    setup() {
        return { ...useDisableInterestLocations(), ...useMediaQuery() }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.graph-container {
    @include container;
    @include pa-32;
}
</style>
